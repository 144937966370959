import React from 'react';
import ReactDOM from 'react-dom';
import ComfirmModal from './Components/ConfirmModal';
import MessageTop from './Components/MessageTop';
import { storage } from './firebase';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import thailand from './thailand.json'

const Compress = require('compress.js');
const compress = new Compress();

export const DragHandle = sortableHandle(({ children }) => (<React.Fragment key={children.key}>{children}</React.Fragment>));

export const SortableItem = sortableElement(({ children }) => (<React.Fragment key={children.key}>{children}</React.Fragment>));

export const SortableContainer = sortableContainer(({ children }) => (<React.Fragment key={children.key}>{children}</React.Fragment>));

export const reportUsersTypes = {
  spam: 'แสปม',
  inappropriate: 'รูปภาพไม่เหมาะสม'
}
export const RankTranslate = {
  canChangeLocation: { th: "เปลี่ยนสถานที่", en: "" },
  canFollower: { th: "ผู้ติดตามติดตาม", en: "" },
  canFollowing: { th: "ติดตามผู้ใช้งานท่านอื่น", en: "" },
  canForceChat: { th: "บังคับแชท", en: "" },
  canHideAge: { th: "ซ่อนอายุ", en: "" },
  canHideProfile: { th: "ซ่อนโปรไฟล์", en: "" },
  canMatch: { th: "จับคู่", en: "" },
  canPost: { th: "โพส", en: "" },
  canPreviewStore: { th: "ดูข้อมูลร้าน", en: "" },
  canStalker: { th: "สตอล์กเกอร์", en: "" },
  canTopPick: { th: "Top pick", en: "" },
  canViewWhoPoke: { th: "แสดงผู้สกิด", en: "" },
  canVisible: { th: "ซ่อนตัว", en: "" },
  followerCapacity: { th: "จำนวนผู้ติดตาม", en: "" },
  followingCapacity: { th: "จำนวนการติดตามผู้ใช้งานท่านอื่น", en: "" },
  forceChatCapacity: { th: "จำนวนที่บังคับแชท", en: "" },
  forceChatResponseValue: { th: "จำนวนที่ตัวอักษรที่บังคับแชทรับ", en: "" },
  forceChatValue: { th: "จำนวนที่ตัวอักษรที่บังคับแชทส่ง", en: "" },
  matchCapacity: { th: "จำนวนที่สามารถจับคู่", en: "" },
  pokePerDay: { th: "จำนวนที่สามารถสะกิดใน 1 วัน", en: "" },
  previewStore: { th: "รายละเอียดร้าน", en: "" },
  canPreviewPromotion: { th: "ดูรายละเอียดโปรโมชั่น", en: "" },
  canPreviewCustomer: { th: "ดูรายละเอียดลูกค้า", en: "" },
  canPreviewFeed: { th: "ดูรายละเอียด Feed", en: "" },
  canPostOnPreview: { th: "canPostOnPreview", en: "" },
  canPreviewStore: { th: "ดูรายละเอียดร้าน", en: "" },
  canRequestMusic: { th: "ขอเพลง", en: "" },
  canPreviewMusic: { th: "ดูรายละเอียดการขอเพลง", en: "" },
  canMatchOnPreview: { th: "canMatchOnPreview", en: "" },
  canChatOnPreview: { th: "canChatOnPreview", en: "" },
  topPickCapacity: { th: "จำนวน Top Pick", en: "" },
  forceChatType: { th: "ประเภทการชำระเงินในการบังคับแชท", en: "" },
}
export const WGService = {
  startPayment(payment_url, data) {
    window.$('body').append(`<form id="wg-form" action="${payment_url}" method='post'>
    <button style="display: none" type="submit">Submit</button>
</form>`);
    this.wgform = window.$('#wg-form');

    for (const key in data) {
      this.wgform.append(`<input type="hidden" name="${key}" value="${data[key]}"/>`);
    }
    this.wgform.submit();
  }
}
export const PackageOptionsList = [
  { name: 'ชำระผ่านผู้ดูแลระบบ', value: 'adminService' }
]
export const PacakageOptions = {
  freePackage: 'ฟรีแพกเกจ',
  adminService: 'ชำระผ่านผู้ดูแลระบบ',
  creditcard: 'บัตรเครดิต'
}
export const Helper = {
  monthRage(expired_date) {

    let expiredMonth = false;
    let expiredYear = false;
    let startMonth = false;
    let startYear = false;
    let expired = new Date(expired_date);
    // console.log(expired)
    expiredMonth = expired.getMonth() + 1;
    expiredYear = expired.getFullYear() + 543;
    startMonth = expiredMonth - 1;
    startYear = expiredYear;
    if (startMonth === 0) {
      startMonth = 12;
      startYear = expiredYear - 1;
    }
    let return_data = {};
    return_data.startMonth = startMonth;
    return_data.startYear = startYear;
    return_data.expiredMonth = expiredMonth;
    return_data.expiredYear = expiredYear;
    return return_data;
  },
  getStoreStatus(status) {
    if (status
      && status === 'approved') {
      return (
        <>
          <i className="fas fa-circle" style={{ color: "green" }}></i> อนุมัติแล้ว
        </>
      )
    }
    if (status
      && status === 'wait_approve') {
      return (
        <>
          <i className="fas fa-circle" style={{ color: "orange" }}></i> รออนุมัติ
        </>
      )
    }
    if (status
      && status === 'not_approve') {
      return (
        <>
          <i className="fas fa-circle" style={{ color: "red" }}></i> ไม่อนุมัติ
        </>
      )
    }
    if (status
      && status === 'online') {
      return (
        <>
          <i className="fas fa-circle" style={{ color: "green" }}></i> ออนไลน์
        </>
      )
    }
    if (status
      && status === 'offline') {
      return (
        <>
          <i className="fas fa-circle" style={{ color: "grey" }}></i> ออฟไลน์
        </>
      )
    }
    if (status
      && status === 'pause') {
      return (
        <>
          <i className="fas fa-circle" style={{ color: "orange" }}></i> ระงับการใช้งาน (รอชำระเงิน)
        </>
      )
    }
    if (status
      && status === 'cancel') {
      return (
        <>
          <i className="fas fa-circle" style={{ color: "red" }}></i> ถูกแบน
        </>
      )
    }
  },
  getReportStatus(type) {
    if (type === 1) {
      return 'ข้อผิดพลาด';
    }
    if (type === 2) {
      return 'อื่นๆ';
    }
    if (type === 3) {
      return 'แสปม';
    }
    if (type === 4) {
      return 'เนื้อหารุนแรง';
    }
    if (type === 5) {
      return 'อนาจาร';
    }
    if (type === 6) {
      return 'ละเมิดลิขสิทธิ์';
    }
  },
  formatDate(date, options) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  },
  setPrice(price) {
    let _price = price.toString().slice(0, -2);
    let last_digists = price.toString().slice(-2);
    let _return = _price + '.' + last_digists;
    return _return
  },
  weakPasswordException(pwd) {
    let result = {}
    let numUpper = (pwd.match(/[A-Z]/g) || []).length;
    if (numUpper === 0) {
      result.error = true;
      result.message = 'รหัสผ่านง่ายเลยไป (รหัสผ่านประกอบไปด้วย เลข,ตัวหนังสือพิมพ์เล็กและพิมพ์ใหญ่)';
    }
    let pwdCount = pwd.length;
    if (pwdCount < 6) {
      result.error = true;
      result.message = 'กรุณากรอกรหัสผ่านอย่างน้อย 6 ตัวอักษร';
    }
    let numcount = (pwd.match(/[0-9]/g) || []).length;
    let numLower = (pwd.match(/[a-z]/g) || []).length;
    if (numcount > 0 && numLower > 0 && numUpper > 0 && pwdCount >= 6) {
      result.success = true;
      result.message = 'รหัสผ่านใช้งานได้';
    } else {
      result.error = true;
      result.message = 'กรอกรหัสผ่านใหม่ รหัสผ่านอย่างน้อย 6 ตัวอักษร ต้องมีเลข,ตัวหนังสือพิมพ์เล็กและพิมพ์ใหญ่ ';
    }

    // result.numUpper = numUpper;
    // result.pwdCount = pwdCount;
    // result.numcount = numcount;
    // result.numLower = numLower;
    return result;
  },
  async removeFile(path) {
    var desertRef = storage.ref().child(path);
    let _response = {};
    desertRef.delete().then(() => {
      _response.success = true;
    }).catch((error) => {
      _response.error = true;
      _response.error_data = error;
    });
  },
  async uploadFile(node, path, options) {
    // let id = this.state.provider_id;
    let upload_btn = node.parent().find('.btn');
    if (upload_btn.hasClass("loading")) { return false }
    upload_btn.addClass('loading');
    let file = node[0].files[0];
    if (file
      && file.type !== 'image/png'
      && file.type !== 'image/jpeg'
      && file.type !== 'image/jpg'
    ) {
      if (options.onError) {
        options.onError();
      }

      window.$(document).Toasts('create', {
        title: 'กรุณาอัพโหลดเฉพาะรูปภาพเท่านั้น',
        icon: 'fas fa-times',
        autohide: true,
        class: "bg-danger",
        delay: 3000,
      })
      return;
    }

    const filename = Helper.setNewImageFileName(file);
    let reszieFile = file;
    if (options.resize) {
      reszieFile = await Helper.resizeImageFn(file)
    }
    // console.log(path + filename)
    var uploadTask = storage.ref().child(path + filename).put(reszieFile);
    uploadTask.on('state_changed', (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // console.log(snapshot);
    }, (error) => {
      // console.log(error);
      Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
      upload_btn.removeClass('loading');
      if (options.onError) {
        options.onError();
      }
    }, async () => {
      let url = {};
      url.storePath = path + filename;
      url.imagePath = await storage.ref(path + filename).getDownloadURL();
      // console.log(url)
      if (options.onSuccess) {
        options.onSuccess(url);
      }
    });




    // let id = options.PromotionId;
    // let upload_btn = node.parent().find('.btn');
    // if (upload_btn.hasClass("loading")) { return false }
    // upload_btn.addClass('loading');
    // let file = node[0].files[0];
    // const filename = Helper.setNewImageFileName(file);
    // var uploadTask = storage.ref().child(path + '/' + filename).put(file);
    // uploadTask.on('state_changed', (snapshot) => {
    //   // Observe state change events such as progress, pause, and resume
    //   console.log(snapshot);
    // }, (error) => {
    //   console.log(error);
    //   Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
    //   upload_btn.removeClass('loading');
    //   if (options.onError) {
    //     options.onError();
    //   }
    // }, async () => {
    //   let url = {};
    //   url.storePath = path + '/' + filename;
    //   url.imagePath = await storage.ref(url).getDownloadURL();
    //   console.log(url)
    //   if (options.onSuccess) {
    //     options.onSuccess(url);
    //   }
    // });
  },
  readURL(input) {
    let show_image_Ref = input.parent().find('#show_image');
    let checktype = false;
    if (input[0].files
      && input[0].files[0]
      && (input[0].files[0].type === 'image/png'
        || input[0].files[0].type === 'image/jpeg'
        || input[0].files[0].type === 'image/jpg'
      )) {
      checktype = true;
    }
    console.log(checktype);
    if (input[0].files && input[0].files[0] && checktype) {

      var reader = new FileReader();
      reader.onload = (e) => {
        input.parent().find('.button').text('เปลี่ยนรูป');
        if (show_image_Ref) {
          show_image_Ref.html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
          show_image_Ref.html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
        }
      };

      reader.readAsDataURL(input[0].files[0]);
    } else {
      // show_image_Ref.html('');
      input.val('');
      window.$(document).Toasts('create', {
        title: 'กรุณาอัพโหลดเฉพาะรูปภาพเท่านั้น',
        icon: 'fas fa-times',
        autohide: true,
        class: "bg-danger",
        delay: 3000,
      })
      return false;
    }
  },
  readImage(input, options) {

    let show_image_Ref = input.parent().find('#show_image');
    let checktype = false;
    checktype = true;
    console.log(checktype)
    if (input[0].files && input[0].files[0] && checktype) {
      let can_use_image = true;
      // console.log(input[0].files[0])
      var reader = new FileReader();
      reader.onload = async (e) => {
        var image = new Image();
        image.src = e.target.result;

        image.onload = await function () {
          // access image size here 
          // console.log(this.width + ' - ' + this.height, options.maxHeight + ' - ' + options.maxWidth);
          if (options) {
            if ((options.maxHeight && this.height > options.maxHeight)
              || (options.maxWidth && this.width > options.maxWidth)) {
              console.log(`image limit`)
              input.val('');
              can_use_image = false;
              let message = ` `;
              if (options.maxHeight && options.maxWidth) {
                message += `สูงไม่เกิน ${options.maxHeight} และกว้างไม่เกิน ${options.maxWidth} `;
              } else if (!options.maxHeight && options.maxWidth) {
                message += `กว้างไม่เกิน ${options.maxWidth} `;
              } else if (options.maxHeight && !options.maxWidth) {
                message += `สูงไม่เกิน ${options.maxHeight}`;
              }
              show_image_Ref.html(`<div class="alert alert-warning" style={{ marginTop: 15 }}>รูปภาพควรมีขนาด${message}</div>`);
            } else {
              console.log(`image success`)
              initImage();
            }
          } else {
            console.log(`image success`)
            initImage();
          }
        };

        function initImage() {
          input.parent().find('.button').text('เปลี่ยนรูป');

          if (show_image_Ref) {
            show_image_Ref.html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
            show_image_Ref.html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
          }
        }

      };

      reader.readAsDataURL(input[0].files[0]);
    } else {
      // show_image_Ref.html('');
      input.val('');
      window.$(document).Toasts('create', {
        title: 'กรุณาอัพโหลดเฉพาะรูปภาพเท่านั้น',
        icon: 'fas fa-times',
        autohide: true,
        class: "bg-danger",
        delay: 3000,
      })
      return false;
    }
  },
  getAllProvinces() {
    let provinces = [];
    for (const _thailand in thailand) {
      provinces.push({
        code: _thailand,
        ...thailand[_thailand]
      })
    }
    return provinces;
  },
  prepareDataLimit(data, limit, page, options) {
    let return_data = [];
    data = data.filter((__data) => { return __data });
    if (options && options.mergePage) {
      return_data = data.filter((__data, __data_i) => {
        return __data_i < (limit * page)
      })
    }
    if (!options) {
      return_data = data.filter((__data, __data_i) => {
        return __data_i < (limit * page)
          && __data_i > (((limit * page) - limit) - 1)
      })
    }
    return return_data;
  },
  callAmphoe(str) {
    if (typeof str != "string") { return str }
    if (!isNaN(str) && !isNaN(parseFloat(str))) {
      let amphoe = false;
      for (const [key, value] of Object.entries(thailand)) {
        console.log(key)
        for (const [_key, _value] of Object.entries(value.amphoes)) {
          if (_key === str) {
            amphoe = _value.name.th
          }
        }
      }
      str = amphoe
    }
    return str
  },
  providerUpdateData(data, admin, options) {
    let _data = { ...data }
    _data.updateBy = [];
    if (data.updateBy) {
      _data.updateBy = [...data.updateBy];
    }
    _data.updateBy.push({
      adminUID: admin.id.stringValue,
      adminName: admin.displayName.stringValue,
      updateAt: new Date().getTime(),
      updateType: options && options.updateType ? options.updateType : 'None'
    })
    _data.updatedAt = new Date().getTime();
    return _data;
  },
  async resizeImageFn(file, options) {
    const resizedImage = await compress.compress([file], {
      size: 20, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: options && options.maxWidth ? options.maxWidth : 500, // the max width of the output image, defaults to 1920px
      maxHeight: options && options.maxHeight ? options.maxHeight : 500, // the max height of the output image, defaults to 1920px
      resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    // return img;
    const base64str = img.data
    const imgExt = img.ext
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    return resizedFiile;
  },
  renderDate(options) {
    let days = window.$('.react-datepicker__day-names').find(".react-datepicker__day-name");
    let thai_days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุทธ', 'พฤหัส', 'ศุกร์', 'เสาร์'];
    if (options && options.short_day) {
      thai_days = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'];
    }
    for (let index = 0; index <= days.length; index++) {
      window.$(days[index]).text(thai_days[index])
    }
  },
  timeDifference(date1, date2) {
    // var difference = date1.getTime() - date2.getTime();
    var difference = date1 - date2;

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    var secondsDifference = Math.floor(difference / 1000);
    let data = {};
    data.day = daysDifference;
    data.hour = hoursDifference;
    data.minute = minutesDifference;
    data.second = secondsDifference;
    return data;
  },
  getDateThai(_date, options) {
    let date = new Date(_date);
    let time = '';
    if (options && options.plus_hours) {
      date = new Date(date.setTime(date.getTime() + (parseFloat(options.plus_hours) * 60 * 60 * 1000)));
    }
    if (options && options.is_show_time) {
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hour < 10) {
        hour = '0' + date.getHours()
      }
      if (minute < 10) {
        minute = '0' + date.getMinutes()
      }
      if (second < 10) {
        second = '0' + date.getSeconds()
      }
      // time = ' - ' + hour + ':' + minute + ':' + second;
      time = ' - ' + hour + ':' + minute;
    }
    if (isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear())) {
      return "";
    }
    return date.getDate() + ' ' + window.months_th[parseFloat(date.getMonth())] + ' ' + (date.getFullYear() + 543) + time;
  },
  getTime(_date, options) {
    if (!_date) { return null }
    let date = new Date(_date);
    let time = '';
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    if (hour < 10) {
      hour = '0' + date.getHours()
    }
    if (minute < 10) {
      minute = '0' + date.getMinutes()
    }
    if (second < 10) {
      second = '0' + date.getSeconds()
    }
    time = hour + ':' + minute;

    if (options && options.bookSelectHalfTime) {
      if (parseFloat(minute) === 0) {
        time = parseFloat(hour);
      }
      if (parseFloat(minute) > 0) {
        time = parseFloat(hour) + '.' + minute;
      }
    }
    return time;
  },
  getDateToEdit(date) {
    // MMMM d, yyyy h:mm aa
    var timestampInSeconds = Math.floor(date / 1000);
    var _date = new Date(timestampInSeconds * 1000);
    return _date;
  },
  dataURLToBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  },
  getFormInputObject(form) {
    let data = {};
    form.serializeArray().map((item) => {
      data[item.name] = item.value;
      return false;
    })
    return data;
  },
  setNewImageFileName(file) {
    let date = new Date();
    let file_type = file.name.substr(file.name.indexOf('.'), file.name.indexOf('.') + 3);
    let file_name = file.name.substr(0, file.name.indexOf('.'));
    return file_name + Date.parse(date) + file_type;
  },
  reactToDom($dom, Component, props) {
    // console.log(typeof Component);
    if (typeof Component === 'function') {
      ReactDOM.render(React.createElement(Component, props, null), document.createElement('div'), function () {
        $dom.append(ReactDOM.findDOMNode(this));
      });
    }
    if (typeof Component === 'object') {
      ReactDOM.render(Component, document.createElement('div'), function () {
        $dom.append(ReactDOM.findDOMNode(this));
      });
    }
  },
  messageTop(options) {
    Helper.reactToDom(window.$('body'), <MessageTop {...options} />)
  },
  confirmModal(options) {
    Helper.reactToDom(window.$('body'),
      <ComfirmModal  {...options} />
    );
  },
  setCookie(cname, cvalue, exdays) {

    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    // cvalue = window.escape(cvalue);
    cvalue = window.escape(cvalue);
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        // c = window.unescape(c);
        c = window.unescape(c);
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  getQueryStringLinkFromObject(query_string, url) {
    var q = [];
    window.$.each(query_string, function (key, value) {
      q.push(key + '=' + encodeURIComponent(value));
    });
    var page_name = window.location.pathname;
    if (url) page_name = url;
    if (q.length) {
      page_name += '?' + q.join('&');
    }
    return page_name;
  },
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  getAllUrlParams(url) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');

        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {

          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string') {
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  },
  MonthTh() {
    return ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน.", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
  },
  Month() {
    return ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  },
  Today() {
    let today = new Date();
    // let dd = String(today.getDate()).padStart(2, '0');
    // let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // let yyyy = today.getFullYear();

    // today = yyyy + '-' + mm + '-' + dd;

    // today = mm + '-' + dd + '-' + yyyy;
    return today;
  },
  FormatDateNumber(data) {
    let today = new Date();
    let current_datetime = new Date(data);
    let dd = String(current_datetime.getDate()).padStart(2, '0');
    let mm = String(current_datetime.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = current_datetime.getFullYear();
    if (current_datetime.getFullYear() > today.getFullYear()) {
      yyyy = today.getFullYear()
    }
    let formatted_date = yyyy + '-' + mm + '-' + dd + ' 00:00:00';
    return formatted_date;
  },
  FormatDate(data) {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let current_datetime = new Date(data.replace(' ', 'T'))
    let formatted_date = current_datetime.getDate() + " " + months[current_datetime.getMonth()] + " " + current_datetime.getFullYear()
    return formatted_date;
  },
  FormatDateTh(data) {
    const months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    let current_datetime = new Date(data.replace(' ', 'T'));
    let formatted_date = current_datetime.getDate() + " " + months[current_datetime.getMonth()] + " " + (current_datetime.getFullYear() + 543)
    return formatted_date;
  },
  FormatTime(data) {

    let current_datetime = new Date(data.replace(' ', 'T'))
    let Hour = current_datetime.getHours() < 10 ? '0' + current_datetime.getHours() : current_datetime.getHours();
    let Minute = current_datetime.getMinutes() < 10 ? '0' + current_datetime.getMinutes() : current_datetime.getMinutes();
    let formatted_date = Hour + ":" + Minute;
    return formatted_date;
  },
  dataDiff(start_date, end_date, diff_type) {
    const date1 = new Date(start_date.replace(' ', 'T'));
    const date2 = new Date(end_date.replace(' ', 'T'));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diff_type === 'time') {
      return diffTime;
    }
    return diffDays;
  },
  summaryItems(cart) {
    let summary = {};
    summary.total_price = 0;
    summary.total_deposit = 0;
    summary.total_discount = 0;
    summary.shipment_price = cart && cart.shipment_price ? cart.shipment_price : 0;
    summary.shipment_discount = 0;
    summary.voucher_discount = 0;
    summary.grand_total_price = 0;
    summary.items_amount = 0;


    if (cart && cart.items && cart.items.length > 0) {
      cart.items.forEach((item) => {
        if (item.is_select) {
          summary.total_price = summary.total_price + parseFloat(item.price);
          summary.total_discount = summary.total_discount + parseFloat(item.discount);
          summary.items_amount = summary.items_amount + parseFloat(item.quantity);
          summary.total_deposit = summary.total_deposit + parseFloat(item.deposit);
        }
      });
    }

    summary.grand_total_price = (summary.total_price - summary.total_discount) + parseFloat(summary.shipment_price) + parseFloat(summary.total_deposit);
    if (cart && cart.voucher_group) {
      if (parseFloat(cart.voucher_group.vg_type) === 1) {
        summary.voucher_discount = cart.voucher_group.vg_value + '%';
        summary.grand_total_price = (parseFloat(summary.grand_total_price) * parseFloat(cart.voucher_group.vg_value)) / 100;
      }
      if (parseFloat(cart.voucher_group.vg_type) === 2) {
        summary.voucher_discount = '฿' + Helper.numberFormat(cart.voucher_group.vg_value);
        summary.grand_total_price = parseFloat(summary.grand_total_price) - parseFloat(cart.voucher_group.vg_value);
      }
    }


    return summary;
  },
  numberFormat(number, options) {
    if (!number) { return 0 }
    number = parseFloat(number);
    if (options && options.decimal) {
      number = number.toFixed(options.decimal)
    }
    if (isNaN(number)) {
      number = 0;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  objToGetParameter(obj) {
    let str = "";
    for (let key in obj) {
      if (str !== "") {
        str += "&";
      }
      str += key + "=" + obj[key];
    }
    return str;
  },
  chkDigitPid(p_iPID) {
    var total = 0;
    var iPID;
    var chk;
    var Validchk;
    iPID = p_iPID.replace(/-/g, "");
    Validchk = iPID.substr(12, 1);
    var j = 0;
    var pidcut;
    for (var n = 0; n < 12; n++) {
      pidcut = parseInt(iPID.substr(j, 1));
      total = (total + ((pidcut) * (13 - n)));
      j++;
    }

    chk = 11 - (total % 11);

    if (chk === 10) {
      chk = 0;
    } else if (chk === 11) {
      chk = 1;
    }
    if (chk === Validchk) {
      // alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
      return true;
    } else {
      // alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
      return false;
    }

  },
  showProvinceWithAmphoe(provincesCode, amphoesCode) {
    let provinces = [];
    if (provincesCode) {
      provincesCode.forEach((provinceCode) => {
        for (const [key, value] of Object.entries(thailand)) {
          let amphoes = [];
          if (key === provinceCode) {
            for (const [_key, _value] of Object.entries(value.amphoes)) {
              if (amphoesCode && amphoesCode.length > 0) {
                amphoesCode.forEach((amphoeCode) => {
                  if (_key === amphoeCode) {
                    amphoes.push({ code: _key, amphoe: _value.name.th });
                  }
                })
              }
            }
            provinces.push({ code: key, province: value.name.th, amphoes: amphoes })
            // console.log(`${key}: ${value.name.th}`);
          }
        }
      })
    }
    return provinces;
  },
}