import React from "react";
import { Link } from '@reach/router';
import { Helper, WGService } from "./../Helper";
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Hex from 'crypto-js/enc-hex';
import { getLives, requestMusic } from "../services/ArtistServices";
import { QRCode } from 'react-qrcode-logo';
import { db } from "../firebase";


const axios = require('axios').default;



let url = "https://payment.beefastdev.com";
// let url = "http://localhost:4500";
let payment_url = `${url}/payment/start-payment`;
export default class Mockup extends React.Component {
    constructor() {
        super();
        this.state = {
            last_live: false,
            data: [],
        }
    }

    async componentDidMount() {

        // console.log("shoppingpingbuyersurnamebuyer@gmailccom0812345678100.00THBitem1ORDER-12345https://merchant.com/frontend" === "shoppingpingbuyersurnamebuyer@gmailccom0812345678100.00THBitem1ORDER-12345https://merchant.com/frontend")
    }

    async deleteStoreNoOwner() {
        let res = await axios.get(`${window.api_host}/test-call`);
        console.log(res.data);
        Helper.messageTop({ message: 'success', type_class: "alert-success" })
        this.setState({
            data: res.data.stores
        })
    }

    async apiCall() {
        let res = await requestMusic(this.state.last_live.id, {
            userId: this.userId && this.userId.val() != "" ? this.userId.val() : 14334,
            artistName: this.artistName && this.artistName.val() != "" ? this.artistName.val() : 'artistname',
            musicName: this.musicName && this.musicName.val() != "" ? this.musicName.val() : 'musicname',
            userNote: this.userNote && this.userNote.val() != "" ? this.userNote.val() : '',
            tipAmount: this.tips && this.tips.val() != "" ? this.tips.val() : 0,
            coverImage: ''
        })
        if (!res.error) {
            await axios.post(`${window.api_host}/user-request`, {
                storeUid: "5SbQemt6KvUZWwFwFh7D",
            })
            Helper.messageTop({ message: 'success', type_class: "alert-success" })
        }
        this.btnStopLoading(this.$callApiBtn);
    }

    btnLoading(node) {
        node.addClass('loading');
    }
    btnStopLoading(node) {
        node.removeClass('loading');
    }

    render() {
        if (this.props.languageTransfer) {
            return <LanguageTransfer />
        }
        return (
            <>
                {/* {
                    this.state.data.map((item) => {
                        return <div>{item.nameTH} - {item.uid}</div>
                    })
                } */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ทดสอบ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ทอสอบ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label>Artist Name</label>
                                    <input name="artist_name" className="form-control" ref={(ref) => { this.artistName = window.$(ref) }} />
                                </div>
                                <div className="form-group">
                                    <label>Music Name</label>
                                    <input name="music_name" className="form-control" ref={(ref) => { this.musicName = window.$(ref) }} />
                                </div>
                                <div className="form-group">
                                    <label>Tip Amount</label>
                                    <input type={"number"} name="tip" className="form-control" ref={(ref) => { this.tips = window.$(ref) }} />
                                </div>
                                <div className="form-group">
                                    <label>User Id <small style={{ color: 'red' }}>ถ้าไม่ใส่ default 14334</small></label>
                                    <input type={"number"} name="userID" className="form-control" ref={(ref) => { this.userId = window.$(ref) }} />
                                </div>
                                <div className="form-group">
                                    <label>User Note</label>
                                    <input type={"text"} name="userNote" className="form-control" ref={(ref) => { this.userNote = window.$(ref) }} />
                                </div>
                                <button
                                    className="btn btn-default"
                                    ref={(ref) => {
                                        this.$callApiBtn = window.$(ref);
                                    }}
                                    onClick={() => {
                                        if (this.$callApiBtn.hasClass('loading')) { return; }
                                        this.btnLoading(this.$callApiBtn);
                                        this.apiCall();
                                    }}
                                >Test Request Music</button>
                            </div>
                            <div className="col-12 col-md-8">


                                <QRCode value={`https://${process.env.REACT_APP_WEBMUSIC}/?storeUid=5SbQemt6KvUZWwFwFh7D`}
                                    logoImage={window.location.origin + "/assets/images/skitz-qr-logo.png"}
                                    size={250}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className="btn btn-default"
                    onClick={() => {
                        this.deleteStoreNoOwner();
                    }}
                >Remove Store no Owner</button>
            </>
        );
    }
}

class LanguageTransfer extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            lang_code: [],
            lang_obj: [],
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let lang = await db.collection('/languages').get();
        let version = await db.doc('/versions/language').get();
        let version_data = version.data();
        let langData = await Promise.all(
            lang.docs.map((doc) => {
                return { ...doc.data(), uid: doc.id }
            })
        )
        console.log(langData);
        let lang_code = [];
        let lang_obj = [];
        langData.map((lngData) => {
            lang_code.push(lngData.language_code);
            if (lngData.words && lngData.words.length > 0) {
                if (lngData.language_code === "th") {
                    lngData.words.map((word) => {
                        lang_obj.push({
                            keyword: word.keyword,
                        })
                        return true;
                    })
                }
            }
            return true;
        })
        langData.map((lngData) => {
            if (lngData.words && lngData.words.length > 0) {
                lngData.words.map((word) => {
                    lang_obj.map((lan, lan_i) => {
                        if (word.keyword !== lan.keyword) {
                            return false;
                        }
                        let result = "";
                        if (word.keyword === lan.keyword) {
                            result = word.result
                        }
                        lang_obj[lan_i]['word_' + lngData.language_code] = result;
                        return true;
                    })
                    return true;
                })
            }
            return true;
        })
        // console.log(lang_obj);
        this.setState({
            version: version_data.value,
            lang_code: lang_code,
            lang_obj: lang_obj,
            is_loading: false,
        })
    }

    async update(obj) {
        // console.log(obj)

        let api = window.$serverRef.val();
        if (!api) {
            Helper.messageTop({ message: 'no api server' });
            this.setState({
                is_loading: false
            })
            return;
        }
        this.setState({
            is_loading: true
        }, async () => {
            let newVersion = parseFloat(this.state.version) + 1;
            console.log(obj)
            try {

                await axios.post(`https://${api}` + '/update-language', { newVersion: newVersion, langCode: this.state.lang_code, obj: obj });
                // await Promise.all(
                //     this.state.lang_code.map(async (code) => {
                //         let words = obj.filter((_obj) => { return _obj.code === code })[0].words;
                //         await db.doc('/languages/' + code).update({ words: words });
                //         await db.doc('/versions/language').update({ value: newVersion });
                //     })
                // )
                this.fetch();
            } catch (error) {

                this.setState({
                    is_loading: false
                })
                Helper.messageTop({ message: 'erro' });
            }
        })


    }

    render() {
        // if (this.state.is_loading) {
        //     return (<div style={{ padding: '1rem 0', textAlign: 'center' }}><div className="ui active inline loader" ></div></div>)
        // }
        // console.log(this.state.lang_obj);
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการภาษา {this.state.version || null}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to={'/'}>หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการภาษา</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                {
                                    this.state.is_loading ?
                                        <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                        : null
                                }
                                <div className="row">
                                    <div className="col-12 col-md-2">
                                        <div className="form-group">
                                            <label>Keyword</label>
                                        </div>
                                    </div>
                                    {
                                        this.state.lang_code.map((code, code_i) => {
                                            return (
                                                <div className="col-12 col-md-2" key={code_i}>
                                                    <label>ถาษา {code}</label>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                {
                                    this.state.lang_obj ?
                                        <div>
                                            {this.state.lang_obj.map((word, word_i) => {
                                                return (
                                                    <div className="row" key={word_i}>
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group">
                                                                <input type="text"
                                                                    readOnly={true}
                                                                    className="form-control"
                                                                    name="keyword"
                                                                    value={word.keyword}
                                                                    onChange={(ref) => {
                                                                        let value = ref.target.value;
                                                                        let lang_obj = [...this.state.lang_obj]
                                                                        lang_obj[word_i].keyword = value;
                                                                        this.setState({
                                                                            lang_obj: lang_obj
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.lang_code.map((code, code_i) => {
                                                                return (
                                                                    <div className="col-12 col-md-2" key={code_i}>
                                                                        <div className="form-group">
                                                                            <input type="text"
                                                                                readOnly={true}
                                                                                className="form-control"
                                                                                name={'word_' + code}
                                                                                value={word['word_' + code]}
                                                                                onChange={(ref) => {
                                                                                    let value = ref.target.value;
                                                                                    let lang_obj = [...this.state.lang_obj]
                                                                                    lang_obj[word_i]['word_' + code] = value;
                                                                                    this.setState({
                                                                                        lang_obj: lang_obj
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        : null
                                }

                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <input
                                            ref={(ref) => {
                                                window.$serverRef = window.$(ref)
                                            }}
                                            type="text"
                                            className="form-control"
                                            placeholder="api host transfer"
                                        />
                                        <small>asia-southeast2-skitz-app-dev-8744b.cloudfunctions.net/backend</small>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="flex row">
                                    <div style={{ marginLeft: 'auto' }}>
                                        <button className="btn btn-default"
                                            onClick={() => {
                                                let obj = [];
                                                this.state.lang_code.map((code, code_i) => {
                                                    obj.push({
                                                        code: code,
                                                        words: []
                                                    })
                                                    this.state.lang_obj.map((lang_obj) => {
                                                        obj[code_i].words.push({ keyword: lang_obj.keyword, result: lang_obj['word_' + code] });
                                                        return true;
                                                    })
                                                    return true;
                                                })
                                                this.update(obj)
                                            }}
                                        >บันทึก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}