import { Link, navigate } from '@reach/router';
import React from 'react';
import { Helper } from '../../Helper';
import { skitz_api_host, ApplicationKey } from './../../services/HttpClient'
import { getRequestReport } from '../../services/ReportedServices';
import { getStores } from '../../services/StoreServices';
const axios = require('axios').default;

export default class TransferUser extends React.Component {
    constructor() {
        super();
        let monthList = [];
        for (let index = 1; index <= 12; index++) {
            monthList.push(index)
        }
        let date = new Date();
        let now_year = date.getFullYear();
        let yearList = [];
        for (let index = 2020; index <= now_year; index++) {
            yearList.push(index)
        }
        this.state = {
            is_loading: true,
            data: [],
            storeList: [],
            toStoreId: false,
            storeId: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {

    }

    async fetch() {
        let filter = {};
        if (this.state.month) {
            filter.month = this.state.month;
        }
        if (this.state.year) {
            filter.year = this.state.year;
        }
        if (this.state.storeId) {
            filter.storeId = this.state.storeId;
        }
        if (this.state.rows) {
            filter.rows = this.state.rows;
        }
        let store = await getStores();
        let storeList = [];
        if (!store.error) {
            storeList = store;
        }
        let res = await getRequestReport(filter);
        let items = [];
        if (!res.error) {
            items = res;
        }

        this.setState({
            data: items,
            storeList: storeList,
            is_loading: false,
        })
    }
    async transferUser() {
        let qs = {};
        qs.storeUid = this.state.storeId;
        qs.toStoreUid = this.state.toStoreId;
        if (!qs.storeUid) {
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถย้ายร้านได้',
                body: 'เลือกร้าน',
                icon: 'fas fa-exclamation',
                autohide: true,
                class: "bg-warning",
                delay: 3000,
            })
            this.transferButtonSubmit.removeClass('loading');
            return;
        }
        if (!qs.toStoreUid) {
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถย้ายร้านได้',
                body: 'เลือกร้านที่จะย้าย',
                icon: 'fas fa-exclamation',
                autohide: true,
                class: "bg-warning",
                delay: 3000,
            })
            this.transferButtonSubmit.removeClass('loading');
            return;
        }
        if (qs.storeUid == qs.toStoreUid) {
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถย้ายร้านได้',
                body: 'คุณเลือกร้านเดียวกัน',
                icon: 'fas fa-exclamation',
                autohide: true,
                class: "bg-warning",
                delay: 3000,
            })
            this.transferButtonSubmit.removeClass('loading');
            return;
        }
        try {
            let res = await axios.post(window.api_host + '/store-relocate', { ...qs });
            window.$(document).Toasts('create', {
                title: 'ย้านร้านสำเร็จ',
                // body: 'ทำการย้ายผู้',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถย้ายร้านได้',
                body: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-ban',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
        }
        this.transferButtonSubmit.removeClass('loading');

    }
    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายการขอเพลง</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">
                                        รายการขอเพลง
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-12 col-md-3'>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายการขอเพลง</h3>
                                    </div>
                                    <div className="card-body">
                                        <form
                                            ref={(ref) => {
                                                this.$filterForm = window.$(ref);
                                                this.$submit = this.$filterForm.find('button[type="submit"]');
                                                this.$selectYear = this.$filterForm.find('select[name="year"]');
                                                this.$selectMonth = this.$filterForm.find('select[name="month"]');
                                            }}

                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                let data = Helper.getFormInputObject(this.$filterForm);
                                                this.setState({
                                                    ...data,
                                                    is_loading: true
                                                })
                                            }}
                                        >
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>

                                                    <div className='form-group'>
                                                        <div className='d-flex'>
                                                            <label>ร้าน</label>
                                                            {
                                                                this.state.storeId ?
                                                                    <div className='right-floated'>
                                                                        <div className='link'
                                                                            onClick={() => {
                                                                                if (this.$storefilter) {
                                                                                    this.$storefilter.val(null).trigger('change');
                                                                                }
                                                                                this.$submit.trigger('click')
                                                                            }}
                                                                        >ยกเลิก</div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <select className={"form-control"}
                                                            ref={(ref) => {
                                                                this.$storefilter = window.$(ref);
                                                                this.$storefilter.select2({
                                                                    placeholder: 'เลือกร้านทั้งหมด',
                                                                    // ajax: {
                                                                    //     headers: {
                                                                    //         'applicationkey': ApplicationKey,
                                                                    //     },
                                                                    //     url: baseUrlStore,
                                                                    //     data: function (params) {
                                                                    //         var query = {
                                                                    //             q: params.term,
                                                                    //         }
                                                                    //         return query;
                                                                    //     },
                                                                    //     processResults: function (data) {
                                                                    //         console.log("processResults")
                                                                    //         let items = [];
                                                                    //         if (!data.error) {
                                                                    //             for (const item of data.items) {
                                                                    //                 items.push({
                                                                    //                     id: item.id,
                                                                    //                     text: item.name
                                                                    //                 })
                                                                    //             }
                                                                    //         }
                                                                    //         return {
                                                                    //             results: items
                                                                    //         };
                                                                    //     },
                                                                    // }
                                                                });

                                                                this.$storefilter.off().on("select2:select", () => {
                                                                    this.$submit.trigger('click')
                                                                })
                                                            }}
                                                            name={"storeId"}
                                                            style={{ flex: 1 }}
                                                            defaultValue={this.state.storeId}
                                                        >
                                                            <option value={""}>เลือกร้านทั้งหมด</option>
                                                            {
                                                                this.state.storeList.map((store, i) => {
                                                                    return (
                                                                        <option value={store.fireBaseUid} key={i}>{store.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className='col-12 col-md-6'>

                                                    <div className='form-group'>
                                                        <div className='d-flex'>
                                                            <label>ไปยังร้าน</label>
                                                            {
                                                                this.state.toStoreId ?
                                                                    <div className='right-floated'>
                                                                        <div className='link'
                                                                            onClick={() => {
                                                                                if (this.$toStorefilter) {
                                                                                    this.$toStorefilter.val(null).trigger('change');
                                                                                }
                                                                                this.$submit.trigger('click')
                                                                            }}
                                                                        >ยกเลิก</div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <select className={"form-control"}
                                                            ref={(ref) => {
                                                                this.$toStorefilter = window.$(ref);
                                                                this.$toStorefilter.select2({
                                                                    placeholder: 'เลือกร้านทั้งหมด',
                                                                    // ajax: {
                                                                    //     headers: {
                                                                    //         'applicationkey': ApplicationKey,
                                                                    //     },
                                                                    //     url: baseUrlStore,
                                                                    //     data: function (params) {
                                                                    //         var query = {
                                                                    //             q: params.term,
                                                                    //         }
                                                                    //         return query;
                                                                    //     },
                                                                    //     processResults: function (data) {
                                                                    //         console.log("processResults")
                                                                    //         let items = [];
                                                                    //         if (!data.error) {
                                                                    //             for (const item of data.items) {
                                                                    //                 items.push({
                                                                    //                     id: item.id,
                                                                    //                     text: item.name
                                                                    //                 })
                                                                    //             }
                                                                    //         }
                                                                    //         return {
                                                                    //             results: items
                                                                    //         };
                                                                    //     },
                                                                    // }
                                                                });

                                                                this.$toStorefilter.off().on("select2:select", () => {
                                                                    this.$submit.trigger('click')
                                                                })
                                                            }}
                                                            name={"toStoreId"}
                                                            style={{ flex: 1 }}
                                                            defaultValue={this.state.toStoreId}
                                                        >
                                                            <option value={""}>เลือกร้านทั้งหมด</option>
                                                            {
                                                                this.state.storeList.map((store, i) => {
                                                                    return (
                                                                        <option value={store.fireBaseUid} key={i}>{store.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                            <button
                                                className="btn btn-default"
                                                onClick={() => {
                                                    this.setState({
                                                        month: false,
                                                        year: false,
                                                        storeId: false,
                                                        toStoreId: false,
                                                    }, () => {
                                                        if (this.$filterForm) {
                                                            this.$filterForm.find('select[name="month"]').val("");
                                                            this.$filterForm.find('select[name="year"]').val("");
                                                        }
                                                        if (this.$storefilter) {
                                                            this.$storefilter.val(null).trigger('change');
                                                        }
                                                        if (this.$toStorefilter) {
                                                            this.$toStorefilter.val(null).trigger('change');
                                                        }
                                                        // this.fetch();
                                                    })
                                                }}
                                            >Clear</button>
                                            {/* <Link to={"/staticts"} className="btn btn-default">Clear</Link> */}
                                            <button type="submit" style={{ display: 'none' }}></button>
                                        </form>
                                    </div>
                                    <div className='card-footer'>
                                        <button
                                            ref={(ref) => {
                                                this.transferButtonSubmit = window.$(ref);
                                            }}
                                            type="button"
                                            className='btn btn-primary'
                                            onClick={() => {
                                                if (this.transferButtonSubmit.hasClass('loading')) { return; }
                                                this.transferButtonSubmit.addClass('loading');
                                                this.transferUser();
                                            }}
                                        >
                                            ย้ายร้าน
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section >
            </>
        )
    }
}