import React from 'react';
// import { db } from './../../firebase';
import { Helper } from './../../Helper';
import { Link, navigate } from '@reach/router';
import Pagination from './../../Components/Pagination';
import Chart from "react-google-charts";
import CustomDateInput from './../../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import thailand from "./../../thailand.json";
export default class Index extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        let provinces = [];
        for (const [key, value] of Object.entries(thailand)) {
            let amphoes = [];

            for (const [_key, _value] of Object.entries(value.amphoes)) {
                amphoes.push({ code: _key, amphoe: _value.name.th });
                // console.log(`${_key}: ${_value.name.th}`);
            }
            provinces.push({ code: key, province: value.name.th, amphoes: amphoes })
            // console.log(`${key}: ${value.name.th}`);
        }
        this.state = {
            timerage: 'รายเดือน',
            years: years,
            date: new Date().getTime(),
            year: parseFloat(new Date().getFullYear()),
            month: parseFloat(new Date().getMonth()),
            is_loading: true,
            is_chart_loading: true,
            provinces: provinces,
            store_all_count: 0,
            store_count: 0,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
            province_code: Helper.getParameterByName('province_code') ? Helper.getParameterByName('province_code') : '',
        }
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        this.setState({
            is_loading: false,
            is_chart_loading: false,
        })
    }

    render() {
        // let store_percent = (this.state.store_count * 100) / this.state.store_all_count;
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">เซลส์</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">เซลส์</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                {/* STORE DETAILS */}
                                <div className="info-box bg-gradient-green">
                                    <span className="info-box-icon"><i className="fas fa-user-tie"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">เซลส์ในระบบ</span>
                                        <span className="info-box-number">{this.state.store_all_count} คน</span>

                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: '0%' }}></div>
                                        </div>
                                        <span className="progress-description">
                                            จำนวนเซลส์ {this.state.store_count} คน
                                        </span>
                                    </div>
                                </div>
                                {/* FILTER */}
                                <div className="card card-outline collapsed-card">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form className="row"
                                            ref={(ref) => { this.$form = window.$(ref) }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                                    return;
                                                }
                                                let data = Helper.getFormInputObject(this.$form);
                                                let url = Helper.getQueryStringLinkFromObject({
                                                    page: 1,
                                                    name: data.keyword,
                                                    province_code: data.province_code,
                                                })
                                                navigate(url)
                                            }}
                                        >
                                            <div className="col-12 form-group">
                                                <label>ค้นหา</label>
                                                <input name="keyword" className="form-control" placeholder="ค้นหาจากชื่อเซลส์" />
                                            </div>
                                            <div className="col-12 form-group">
                                                <label>จังหวัด</label>
                                                <select
                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"province_code"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.province_code || ''}
                                                >
                                                    <option value={""}>
                                                        ทั้งหมด
                                                    </option>
                                                    {
                                                        this.state.provinces.map((provinces, provinces_i) => {
                                                            return (
                                                                <option value={provinces.code} key={provinces_i}>
                                                                    {provinces.province}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-12">
                                                <div className="flex row">
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <button type="submit" className="btn btn-default">ค้นหา</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* STORE LIST */}
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายการเซลส์</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.data.length > 0
                                                && !this.state.is_loading ?
                                                <>
                                                    <ul className="nav nav-pills flex-column">
                                                        {
                                                            this.state.data.map(() => {
                                                                return (
                                                                    <li className="nav-item">
                                                                        <Link to={"/store/view" + 1} className="nav-link">
                                                                            <div className="store-item item-row">
                                                                                <div className="icon"><i className="fas fa-store"></i></div>
                                                                                <div className="description">
                                                                                    <div>ชื่อร้าน</div>
                                                                                    <div><span className="badge badge-secondary">Bar/Restaurant</span></div>
                                                                                </div>
                                                                                <div className="right-floated">
                                                                                    <div className="small-text">ประเภทแพ๊คเกจ</div>
                                                                                    <div className="small-text">{Helper.getDateThai(new Date().getTime())}</div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </>
                                                : null
                                        }
                                    </div>
                                    {
                                        !this.state.is_loading
                                            && this.state.data.length !== 0 ?
                                            <div className="card-footer clearfix">
                                                <Pagination
                                                    now={this.state.page}
                                                    all_items={this.state.store_count}
                                                    limit={this.state.limit}
                                                    location={this.props.location}
                                                />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="card">
                                    {
                                        this.state.is_chart_loading ?
                                            <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                            : null
                                    }

                                    <div className="card-header">
                                        <h5 className="card-title">รายงาน</h5>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ display: 'block' }}>
                                        <div className="row">
                                            <div className="col-md-9">
                                                <p className="text-center">
                                                    <strong>
                                                        {
                                                            this.state.timerage === 'รายวัน' ?
                                                                <>วันที่ {Helper.getDateThai(this.state.date)}</>
                                                                : null
                                                        }
                                                        {
                                                            this.state.timerage === 'รายเดือน' ?
                                                                <>เดือน {window.months_th[this.state.month]}</>
                                                                : null
                                                        }
                                                        {
                                                            this.state.timerage === 'รายปี' ?
                                                                <>ปี {this.state.year}</>
                                                                : null
                                                        }
                                                    </strong>
                                                </p>

                                                <div className="chart">
                                                    <Chart
                                                        width={'100%'}
                                                        height={'300px'}
                                                        chartType="AreaChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.booking}
                                                        options={{
                                                            isStacked: true,
                                                            legend: { position: 'top', maxLines: 3 },
                                                            vAxis: { minValue: 0 },
                                                            chartArea: { width: '90%', height: '300px' },
                                                        }}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className={"row form-group"}>
                                                    <div className="col-md-12 form-group">
                                                        <div style={{ width: '100%' }}>
                                                            <select className={"form-control form-control-sm"}
                                                                name={"timerage"}
                                                                defaultValue={this.state.timerage}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        timerage: e.target.value,
                                                                        is_chart_loading: true,
                                                                    }, () => {
                                                                        this.fetch();
                                                                    })
                                                                }}
                                                            >
                                                                <option value={"รายวัน"}>รายวัน</option>
                                                                <option value={"รายเดือน"}>รายเดือน</option>
                                                                <option value={"รายปี"}>รายปี</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        {
                                                            this.state.timerage === 'รายวัน' ?
                                                                <div className="full-date">
                                                                    <DatePicker
                                                                        onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                                                        onChange={date => {
                                                                            this.setState({
                                                                                date: new Date(date).getTime(),
                                                                                is_chart_loading: true,
                                                                            }, () => {
                                                                                this.fetch();
                                                                            })
                                                                        }}
                                                                        locale="th-TH"
                                                                        selected={Helper.getDateToEdit(this.state.date)}
                                                                        placeholderText={"วัน / เดือน / ปี"}
                                                                        name={"birthDay"}
                                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                                        withPortal
                                                                        renderCustomHeader={({
                                                                            date,
                                                                            changeYear,
                                                                            changeMonth,
                                                                            decreaseMonth,
                                                                            increaseMonth,
                                                                            prevMonthButtonDisabled,
                                                                            nextMonthButtonDisabled
                                                                        }) => {
                                                                            let now = new Date();
                                                                            let selected_date = new Date(date);
                                                                            // months_th
                                                                            const years = [];
                                                                            let i;
                                                                            for (i = 1980; i <= now.getFullYear(); i++) {
                                                                                years.push(i)
                                                                            }
                                                                            return (
                                                                                <div
                                                                                    style={{
                                                                                        margin: 10,
                                                                                        display: "flex",
                                                                                        justifyContent: "center"
                                                                                    }}
                                                                                >
                                                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                                        className={"btn btn-primary "}
                                                                                        type={"button"}
                                                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                                    >
                                                                                        <i className="fas fa-chevron-left" />
                                                                                    </button>
                                                                                    <select
                                                                                        className={"form-control"}
                                                                                        value={selected_date.getFullYear()}
                                                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                                                        style={{ borderRadius: 0 }}
                                                                                    >
                                                                                        {years.map(option => (
                                                                                            <option key={option} value={option} >
                                                                                                {option + 543}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>

                                                                                    <select
                                                                                        className={"form-control"}
                                                                                        value={window.months_th[selected_date.getMonth()]}
                                                                                        onChange={({ target: { value } }) =>
                                                                                            changeMonth(window.months_th.indexOf(value))
                                                                                        }
                                                                                        style={{ borderRadius: 0 }}
                                                                                    >
                                                                                        {window.months_th.map(option => (
                                                                                            <option key={option} value={option}>
                                                                                                {option}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>

                                                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                                        className={"btn btn-primary"}
                                                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                                        type={"button"}
                                                                                    >
                                                                                        <i className="fas fa-chevron-right" />
                                                                                    </button>
                                                                                </div>
                                                                            )
                                                                        }}
                                                                        customInput={<CustomDateInput size={"sm"} />}
                                                                    />
                                                                    <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                                                                </div>
                                                                : <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                                                        }
                                                        <div className={this.state.timerage === 'รายเดือน' ? "flex row merge-selectbox" : "flex row"}>

                                                            {
                                                                this.state.timerage === 'รายเดือน' ?
                                                                    <select className={"form-control form-control-sm"}
                                                                        name={"month"}
                                                                        style={{ flex: 1 }}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                month: e.target.value,
                                                                                is_chart_loading: true,
                                                                            }, () => {
                                                                                this.fetch();
                                                                            })
                                                                        }}
                                                                        defaultValue={this.state.month}
                                                                    >
                                                                        {
                                                                            window.months_th.map((month, month_i) => (
                                                                                <option value={month_i} key={month_i}>{month}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.timerage === 'รายปี'
                                                                    || this.state.timerage === 'รายเดือน' ?
                                                                    <select className={"form-control form-control-sm"}
                                                                        style={{ flex: 1 }}
                                                                        name={"year"}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                year: e.target.value,
                                                                                is_chart_loading: true,
                                                                            }, () => {
                                                                                this.fetch();
                                                                            })
                                                                        }}
                                                                        defaultValue={this.state.year}
                                                                    >
                                                                        {
                                                                            this.state.years.map((years) => (
                                                                                <option value={years} key={years}>{years}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="divider" />
                                                {/* <p className="text-center">
                                                <strong>Goal Completion</strong>
                                            </p> */}

                                                <div className="progress-group">
                                                    แม่บ้านทั้งหมด
                                                <span className="float-right">{(0)} คน</span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-primary" style={{ width: '100%' }}></div>
                                                    </div>
                                                </div>

                                                <div className="progress-group">
                                                    ผู้ใช้ทั้งหมด
                                                <span className="float-right">{(0)} คน</span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-danger" style={{ width: '100%' }}></div>
                                                    </div>
                                                </div>
                                                <div className="progress-group">
                                                    Booking ทั้งหมด
                                                <span className="float-right">{(0)} ครั้ง</span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-warning" style={{ width: '100%' }}></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card-footer" style={{ display: 'block' }}>
                                        <div className="flex row flex-fluid-item">
                                            <div >
                                                <div className="description-block border-right">
                                                    {/* <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> 17%</span> */}
                                                    <h5 className="description-header" style={{ fontSize: 20 }}>{(0)} ชม.</h5>
                                                    <span className="description-text">จำนวนชั่วโมงเรียกใช้งาน</span>
                                                </div>
                                            </div>
                                            <div >
                                                <div className="description-block border-right">
                                                    {/* <span className="description-percentage text-warning"><i className="fas fa-caret-left"></i> 0%</span> */}
                                                    <h5 className="description-header" style={{ fontSize: 20 }}>฿{Helper.numberFormat((0).toFixed(2))}</h5>
                                                    <span className="description-text">จำนวนเงินที่ใช้บริการ</span>
                                                </div>
                                            </div>
                                            <div >
                                                <div className="description-block border-right">
                                                    {/* <span className="description-percentage text-warning"><i className="fas fa-caret-left"></i> 0%</span> */}
                                                    <h5 className="description-header" style={{ fontSize: 20 }}>฿{Helper.numberFormat((0).toFixed(2))}</h5>
                                                    <span className="description-text">ภาษี</span>
                                                </div>
                                            </div>
                                            <div >
                                                <div className="description-block border-right">
                                                    {/* <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> 20%</span> */}
                                                    <h5 className="description-header" style={{ fontSize: 20 }}>฿{Helper.numberFormat((0).toFixed(2))}</h5>
                                                    <span className="description-text">จำนวนเงินที่ได้รับ</span>
                                                </div>
                                            </div>
                                            <div >
                                                <div className="description-block border-right">
                                                    {/* <span className="description-percentage text-danger"><i className="fas fa-caret-down"></i> 18%</span> */}
                                                    <h5 className="description-header" style={{ fontSize: 20 }}>฿{Helper.numberFormat((0).toFixed(2))}</h5>
                                                    <span className="description-text">จำนวนที่แม่บ้านได้รับ</span>
                                                </div>
                                            </div>
                                            <div >
                                                <div className="description-block">
                                                    {/* <span className="description-percentage text-danger"><i className="fas fa-caret-down"></i> 18%</span> */}
                                                    <h5 className="description-header" style={{ fontSize: 20 }}>฿{Helper.numberFormat((0).toFixed(2))}</h5>
                                                    <span className="description-text">จำนวนส่วนลด</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}