import React from 'react';
import { Helper } from '../../Helper';
import { Link, navigate } from '@reach/router';
import Pagination from '../../Components/Pagination';
import { getChannels, deletePackageChannels, getPackageChannels, updatePackageChannels, createPackageChannels } from '../../services/ChannelsServices';
import { getStorePackageChannels, deleteStorePackageChannels, createStorePackageChannels, updateStorePackageChannels } from '../../services/StoreServices';
const axios = require('axios').default;

export default class Channels extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            all_items: 0,
            is_loading: true,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: Helper.getCookie('user') && Helper.getCookie('user') !== "" ? JSON.parse(Helper.getCookie('user')).uid : false,
        }
    }


    componentDidMount() {
        console.log(this.props.packageType)
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetch();
            })
        }
    }
    async fetch() {
        let res = false;
        if (this.props.packageType == 'user') {
            res = await getPackageChannels(this.props.id);
        } else if (this.props.packageType == 'store') {
            res = await getStorePackageChannels(this.props.id)
        }
        let items = [];
        if (res && !res.error) {
            items = res;
        }
        this.setState({
            data: items,
            is_loading: false,
        })
    }
    async delete(data, options) {
        let res = false;
        if (this.props.packageType == 'user') {
            res = await deletePackageChannels(data.id);
        } else if (this.props.packageType == 'store') {
            res = await deleteStorePackageChannels(data.id);
        }
        if (!res || res.error) {
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถลบได้กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            });
            if (options && options.onError) {
                options.onError();
            }
            return;
        }
        this.fetch();
    }
    render() {
        let packageTypeText = "";
        if (this.props.packageType == 'user') {
            packageTypeText = "สมาชิก"
        }
        if (this.props.packageType == 'store') {
            packageTypeText = "ร้าน"
        }
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">เพิ่มแพ็กเกจ{packageTypeText}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/package-channels">ช่องทางการชำระเงิน</Link></li>
                                    <li className="breadcrumb-item active">แพ็กเกจ{packageTypeText}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header align-items-center" style={{ display: 'flex' }}>
                                <h3 className="card-title">เพิ่มแพ็กเกจ{packageTypeText}</h3>
                                <div style={{ marginLeft: 15 }}>
                                    <button className={"btn btn-primary"}
                                        onClick={() => {
                                            Helper.reactToDom(
                                                window.$('body'),
                                                <Form
                                                    packageType={this.props.packageType}
                                                    packageId={this.props.id}
                                                    mode={"create"}
                                                    onSuccess={(data) => {
                                                        this.fetch();
                                                    }}
                                                />
                                            );
                                        }}
                                    >
                                        เพิ่มแพ็กเกจ{packageTypeText}
                                    </button>
                                </div>

                            </div>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th >ชื่อ</th>
                                            <th style={{ width: 200 }}>ราคา</th>
                                            <th style={{ width: 200 }}>จำนวนเหรียญ</th>
                                            <th style={{ width: 200 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    let amountType = "C"
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                <div className='d-flex d-flex align-items-center'>
                                                                    {_data.name}
                                                                </div>
                                                            </td>
                                                            <td>{Helper.numberFormat(_data.price)} ฿</td>
                                                            <td>{Helper.numberFormat(_data.coinAmount)}
                                                                <div style={{ display: 'inline-flex', width: 15, height: 15, borderRadius: 35, marginLeft: 3, backgroundColor: amountType == "C" ? 'orange' : '#50AFFF', overflow: 'hidden', marginLeft: 2, alignItems: 'center', justifyContent: 'center' }}>
                                                                    <span style={{ color: 'white', fontSize: 10, fontWeight: 'bold' }}>{amountType}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <>
                                                                        <button className="btn btn-default"
                                                                            onClick={() => {
                                                                                Helper.reactToDom(
                                                                                    window.$('body'),
                                                                                    <Form
                                                                                        packageType={this.props.packageType}
                                                                                        mode={"edit"}
                                                                                        packageId={this.props.id}
                                                                                        data={_data}
                                                                                        // adminUid={this.props.adminUid}
                                                                                        onSuccess={(data) => {
                                                                                            this.fetch();
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        >แก้ไข</button>
                                                                        <button type="button" className="btn btn-danger"
                                                                            ref={(ref) => {
                                                                                window.$(ref).off().on('click', () => {
                                                                                    if (window.$(ref).hasClass('loading')) { return; }
                                                                                    window.$(ref).addClass('loading');
                                                                                    Helper.confirmModal({
                                                                                        title: "ยืนยันลบ",
                                                                                        description: "ต้องการลบ Package นี้หรือไม่ ?",
                                                                                        onConfirm: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                            this.delete(_data, {
                                                                                                onError: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                }
                                                                                            });
                                                                                        },
                                                                                        onCancel: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                        }
                                                                                    });
                                                                                })
                                                                            }
                                                                            }
                                                                        >ลบ</button>
                                                                    </>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data || {},
        }
    }

    async create(data) {
        let res = false;
        if (this.props.packageType == 'user') {
            res = await createPackageChannels(this.props.packageId, data);
        } else if (this.props.packageType == 'store') {
            res = await createStorePackageChannels(this.props.packageId, data);
        }
        if (!res.error) {
            if (this.props.onSuccess) { this.props.onSuccess() }
            this.$modal.modal('hide');
        } else {

        }
    }

    async update(data) {
        if (!data.image && this.state.data.image) {
            data.image = this.state.data.image;
        }
        let res = false;
        if (this.props.packageType == 'user') {
            res = await updatePackageChannels(this.state.data.id, data);
        } else if (this.props.packageType == 'store') {
            res = await updateStorePackageChannels(this.state.data.id, data);
        }
        if (!res.error) {
            if (this.props.onSuccess) { this.props.onSuccess() }
            this.$modal.modal('hide');
        } else {

        }
    }

    render() {
        let title = "แก้ไขแพ็กเกจ";
        let buttoNText = 'แก้ไข';
        if (this.props.mode === 'create') {
            title = "เพิ่มแพ็กเกจ";
            buttoNText = "เพิ่ม";
        }
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            if (this.props.mode === 'create') {
                                this.create(data);
                            }
                            if (this.props.mode === 'edit') {
                                this.update(data);
                            }
                            return;

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">{title}</h4>
                            </div>
                            <div className="modal-body">
                                <div className={"form-group"}>
                                    <label>ชื่อ</label>
                                    <input className={"form-control"} type="text" name="name" defaultValue={this.state.data.name || ''} />
                                </div>
                                <div className={"form-group"}>
                                    <label>รายบละเอียด</label>
                                    <input className={"form-control"} type="text" name="description" defaultValue={this.state.data.description || ''} />
                                </div>
                                <div className={"form-group"}>
                                    <label>ราคา</label>
                                    <input className={"form-control"} type="number" name="price" defaultValue={this.state.data.price || ''} />
                                </div>
                                <div className={"form-group"}>
                                    <label>จำนวนเหรียญ <small>ที่ได้รับ</small></label>
                                    <input className={"form-control"} type="number" name="coinAmount" defaultValue={this.state.data.coinAmount || ''} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}