import React from 'react';
import { Helper } from '../../../Helper';
// import { navigate } from '@reach/router';
import Pagination from './../../../Components/Pagination'
import { storage } from './../../../firebase';
const axios = require('axios').default;
const limit = 10;

export default class Foods extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            is_loading_filter: true,
            foods: [],
            count_all_item: 0,
            count_item: 0,
            limit: limit,
            page: 1,
            menuType: 'food',
            type: 'foods',
            categoryUid: props.categoryUid || false,
        }
    }

    componentDidMount() {
        this.fetch(true);
    }


    async fetch(category_data) {
        let data = {};
        data.limit = this.state.limit;
        data.page = this.state.page;
        data.keyword = this.state.keyword;
        data.storeUid = this.props.uid;
        if (category_data) {
            data.get_category_data = true;
        }
        if (this.state.categoryUid) {
            data.categoryUid = this.state.categoryUid;
        }
        let res = await axios.get(window.api_host + '/foods?' + window.$.param(data));
        // console.log(res);
        let count_item = 0;
        let count_all_item = 0;
        let foods = [];
        let food_category = [];
        if (res.data.success) {
            foods = res.data.foods;
            count_all_item = res.data.count_all_item;
            count_item = res.data.count_item;
            food_category = res.data.food_category;
        }
        let setState = {
            foods: foods,
            is_loading: false,
            is_loading_filter: false,
            count_item: count_item,
            count_all_item: count_all_item,
        }
        if (category_data) {
            setState.food_category = food_category;
        }
        this.setState(setState)
    }

    async delete(id) {
        // console.log(id);
        let res = await axios.delete(window.api_host + '/foods?storeUid=' + this.props.uid + '&uid=' + id);
        if (res.data.success) {
            this.setState({
                is_loading: true,
            }, () => {
                this.fetch();
            })
        }
    }

    renderCategoryLabel(item) {
        return (
            <>
                {
                    item.category.map((id, i) => {
                        let get_category = this.state.food_category.filter((e) => { return e.id == id });
                        let category_name = false;
                        if (get_category.length > 0) {
                            category_name = get_category[0].name;
                        }
                        if (!category_name) { return null; }
                        return <span className='badge badge-primary mr-2' key={i}>{category_name}</span>
                    })
                }
            </>
        )
    }

    render() {
        let cartegory_name = false;
        if (this.state.categoryUid && this.state.food_category && this.state.food_category.length > 0) {
            let get_categody = this.state.food_category.filter((e) => { return e.id === this.state.categoryUid });
            if (get_categody.length > 0) {
                cartegory_name = get_categody[0].name;
            }
        }
        return (
            <>
                <div className="row">
                    {/* FILTER */}
                    <div className="col-4 col-xs-12">
                        <div className="card card-outline ">
                            <div className="card-header">
                                <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>

                                <div className="card-tools" style={{ margin: 0 }}>
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse" >
                                        <i className="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body" style={{ display: 'block' }}>
                                <form className="row"
                                    ref={(ref) => { this.$form = window.$(ref) }}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                            return;
                                        }
                                        let data = Helper.getFormInputObject(this.$form);
                                        this.setState({
                                            is_loading: true,
                                            page: 1,
                                            ...data
                                        }, () => {
                                            this.fetch();
                                        })
                                    }}
                                >
                                    <div className="col-12 form-group">
                                        <label>ค้นหา</label>
                                        <input name="keyword" className="form-control" placeholder="ค้นหาจากชื่ออาหาร" />
                                    </div>

                                    <div className="col-12 form-group">
                                        <label>ประเภท <small className='link'
                                            onClick={() => { this.props.backToCategory() }}
                                        >ประเภทอาหาร</small></label>
                                        {
                                            !this.state.is_loading_filter ?
                                                <select name="categoryUid"
                                                    className="form-control"
                                                    defaultValue={this.state.categoryUid}
                                                >
                                                    <option value="">ทั้งหมด</option>
                                                    {
                                                        this.state.food_category.map((item, item_i) => {
                                                            return (
                                                                <option value={item.id} key={item_i}>{item.name}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                :
                                                <input disabled={true} className="form-control" placeholder="กำลังโหลดข้อมูล" />
                                        }
                                    </div>

                                    <input name="menuType" type="hidden" value={this.state.menuType} />
                                    <input name="type" type="hidden" value={this.state.type} />
                                    <div className="col-12">
                                        <div className="flex row">
                                            <div style={{ marginLeft: 'auto' }}>
                                                <button type="submit" className="btn btn-default">ค้นหา</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* LIST */}
                    <div className="col-6 col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='d-flex'>
                                    <div>
                                        <h3 className="card-title">รายการอาหาร {cartegory_name ? `- ${cartegory_name}` : null}</h3>
                                        {
                                            !this.state.is_loading ?
                                                <div><small>ทั้งหมด {this.state.count_all_item} รายการ</small></div> :
                                                null
                                        }
                                    </div>
                                    <div className='right-floated btn-group'>
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => {
                                                Helper.reactToDom(
                                                    window.$('body'),
                                                    <FormFoods
                                                        mode={"create"}
                                                        item={{ category: !this.state.categoryUid ? [] : [...this.state.categoryUid] }}
                                                        food_category={this.state.food_category}
                                                        storeUid={this.props.uid}
                                                        itemTypeUid={this.props.itemTypeUid}
                                                        onSuccess={() => {
                                                            this.setState({
                                                                is_loading: true
                                                            }, () => {
                                                                this.fetch();
                                                            })
                                                        }}
                                                    />
                                                )
                                            }}
                                        >เพิ่มอาหาร</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {
                                    !this.state.is_loading
                                        && this.state.foods.length === 0 ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                        : null
                                }
                                {
                                    this.state.is_loading ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                            <div className="ui active inline loader small" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    this.state.foods.length > 0
                                        && !this.state.is_loading ?
                                        <>
                                            <ul className="nav nav-pills flex-column">
                                                {
                                                    this.state.foods.map((item, item_i) => {
                                                        return (
                                                            <li className="nav-item" key={item_i}>
                                                                <div
                                                                    // to={"/member/view/" + item.uid}
                                                                    className="nav-link"
                                                                >
                                                                    <div className="store-item item-row">
                                                                        <div className="list-icon">
                                                                            {
                                                                                item.photoPath ?
                                                                                    <img src={item.photoPath} alt="food" />
                                                                                    :
                                                                                    <img src={window.location.origin + '/assets/images/no_img.png'} alt="food" />
                                                                            }
                                                                        </div>
                                                                        <div className="description">
                                                                            <div  >
                                                                                {item.name}
                                                                                <span className='ml-2'>{this.renderCategoryLabel(item)}</span>
                                                                            </div>
                                                                            <div  >
                                                                                <small className=' mr-2'>฿{Helper.numberFormat(item.price || 0)}</small>
                                                                                {
                                                                                    item.usePoint ?
                                                                                        <>
                                                                                            |
                                                                                            <small className=' ml-2'>
                                                                                                {Helper.numberFormat(item.pointAmount || 0)}
                                                                                                <img src={window.location.origin + "/assets/img/skitzpoint.png"} style={{ width: 20, height: 20, marginLeft: 3 }} />
                                                                                            </small>
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="right-floated">
                                                                            {/* <div className="small-text">{Helper.getDateThai(new Date(item.createdAt).getTime())}</div>
                                                                            <div className="small-text">{Helper.getTime(new Date(item.createdAt).getTime())}</div> */}
                                                                            <div className="btn btn-group" style={{ padding: 0 }}>
                                                                                <button className="btn btn-default"
                                                                                    ref={(ref) => {
                                                                                        window.$(ref).off().on('click', () => {
                                                                                            if (window.$(ref).hasClass("loading")) { return; }
                                                                                            window.$(ref).addClass("loading")
                                                                                            Helper.reactToDom(
                                                                                                window.$('body'),
                                                                                                <FormFoods
                                                                                                    mode={"edit"}
                                                                                                    item={item}
                                                                                                    storeUid={this.props.uid}
                                                                                                    food_category={this.state.food_category}
                                                                                                    onSuccess={(food) => {
                                                                                                        let foods = [...this.state.foods];
                                                                                                        foods[item_i] = food;
                                                                                                        this.setState({
                                                                                                            foods: foods
                                                                                                        }, () => {
                                                                                                            window.$(ref).removeClass("loading")
                                                                                                        })
                                                                                                    }}
                                                                                                    onCancel={() => {
                                                                                                        window.$(ref).removeClass("loading")
                                                                                                    }}
                                                                                                />
                                                                                            )
                                                                                        })

                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-pen" />
                                                                                </button>
                                                                                <button className="btn btn-default"
                                                                                    ref={(ref) => {
                                                                                        window.$(ref).off().on('click', () => {
                                                                                            if (window.$(ref).hasClass("loading")) { return; }
                                                                                            window.$(ref).addClass("loading")
                                                                                            Helper.confirmModal({
                                                                                                title: 'ลบอาหาร',
                                                                                                description: 'ยืนยันการลบอาหาร ' + item.name,
                                                                                                onConfirm: () => {
                                                                                                    this.delete(item.id)
                                                                                                },
                                                                                                onCancel: () => {
                                                                                                    window.$(ref).removeClass("loading")
                                                                                                }
                                                                                            })
                                                                                        })

                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-trash-alt" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>

                                        </>
                                        : null
                                }
                            </div>
                            {
                                !this.state.is_loading
                                    && this.state.foods.length !== 0 ?
                                    <div className="card-footer clearfix">
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.count_all_item}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                            onReturnPage={(page) => {
                                                this.setState({
                                                    is_loading: true,
                                                    page: page,
                                                }, () => {
                                                    this.fetch();
                                                })
                                            }}
                                        />
                                    </div>
                                    : null
                            }
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
export class FormFoods extends React.Component {
    constructor(props) {
        super(props);
        let selected_category = [];
        if (props.item && props.item.category) {
            selected_category = props.item.category;
        }
        this.state = {
            selected_category: selected_category
        }
    }
    filterCategory() {
        let category = [];
        this.props.food_category.map((_food_category) => {
            let match = 0;
            this.state.selected_category.map((_selected_category) => {
                if (_food_category.id === _selected_category) {
                    match++;
                }
                return true;
            })
            if (match === 0) {
                category.push(_food_category);
            }
            return true;
        })
        return category;
    }

    async create(data) {
        let res = await axios.post(window.api_host + '/foods', { ...data });
        if (res.data.success) {
            this.$submit.removeClass('loading');
            this.$modal.modal('hide');
            this.props.onSuccess()
        }
        if (res.data.error) {
            this.$submit.removeClass('loading');
        }
    }

    async update(data) {
        let _data = {}
        _data.uid = this.props.item.id;
        _data.update = data;
        if (data.photoPath && this.props.item.storagePath) {
            await storage.ref(this.props.item.storagePath).delete();
        }
        let res = await axios.put(window.api_host + '/foods', { ..._data })
        if (res.data.success) {
            this.props.onSuccess(res.data.food)
            this.$modal.modal('hide');
        }
        if (res.data.error) {
            this.$submit.removeClass('loading');
        }
    }

    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            if (this.props.onCancel) {
                                this.props.onCancel();
                            }
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                        this.$form = this.$modal.find('form');
                        this.$submit = this.$form.find('button[type="submit"]')
                    }}
                >
                    <form
                        className="modal-dialog"
                        role="document"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            if (this.$submit.hasClass('loading')) { return false; }
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            data.category = this.state.selected_category;
                            if (this.state.selected_category.length == 0) {
                                Helper.messageTop({ message: "กรุณาเลือกประเภท" })
                                this.$submit.removeClass('loading');
                                return;
                            }

                            if (this.$file && this.$file[0].files[0]) {
                                await Helper.uploadFile(this.$file, 'foods/', {
                                    resize: true,
                                    onSuccess: async (url) => {
                                        data.storagePath = url.storePath;
                                        data.photoPath = url.imagePath;
                                        if (this.props.mode === 'create') {
                                            this.create(data);
                                        }
                                        if (this.props.mode === 'edit') {
                                            this.update(data);
                                        }
                                    },
                                    onError: async () => {
                                        this.submit.removeClass('loading');
                                    }
                                })
                            } else {
                                if (!data.usePoint) {
                                    data.usePoint = false;
                                    data.pointAmount = 0;
                                }
                                if (this.props.mode === 'create') {
                                    this.create(data);
                                }
                                if (this.props.mode === 'edit') {
                                    this.update(data);
                                }
                            }
                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">
                                    {this.props.mode === 'create' ? "เพิ่มอาหาร" : null}
                                    {this.props.mode === 'edit' ? "แก้ไขอาหาร" : null}
                                </h4>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <input name="image"
                                        type={"file"}
                                        accept="image/*"
                                        ref={(ref) => this.$file = window.$(ref)}
                                        style={{ display: 'none' }}
                                        id="file_image_food"
                                        onChange={(e) => {
                                            Helper.readURL(this.$file);
                                        }}
                                    />
                                    <div id="show_image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {
                                            this.props.item && this.props.item.photoPath ?
                                                <img src={this.props.item.photoPath} alt={"Picture"} style={{ maxWidth: '100%', marginBottom: 5 }} />
                                                : null
                                        }
                                    </div>
                                    <label htmlFor="file_image_food"
                                        type={"button"}
                                        className="btn btn-default"
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            this.state.foods && this.state.foods.photoPath ?
                                                "เปลี่ยนรูป"
                                                : "อัพโหลด"
                                        }
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label>ชื่อ (ภาษาไทย)</label>
                                    <input name="nameTH" className="form-control" required={true} defaultValue={this.props.item && this.props.item.nameTH ? this.props.item.nameTH : ''} />
                                </div>
                                <div className="form-group">
                                    <label>ชื่อ (ภาษาอังกฤษ)</label>
                                    <input name="nameEN" className="form-control" required={true} defaultValue={this.props.item && this.props.item.nameEN ? this.props.item.nameEN : ''} />
                                </div>
                                <div className="form-group">
                                    <label>ราคา</label>
                                    <input type="number" name="price" className="form-control" required={true} defaultValue={this.props.item && this.props.item.price ? this.props.item.price : 0} />
                                </div>
                                <div className="custom-control custom-switch">
                                    <input type="checkbox"
                                        className="custom-control-input"
                                        id={"usePoint"}
                                        name={"usePoint"}
                                        defaultValue={"on"}
                                        defaultChecked={
                                            this.props.item && this.props.item.usePoint
                                        }
                                        onChange={(ref) => {
                                            let _ref = window.$(ref.target);
                                            if (_ref.prop("checked") === true) {
                                                window.$(`#pointForm`).show()
                                            }
                                            else if (_ref.prop("checked") === false) {
                                                window.$(`#pointForm`).hide()
                                            }
                                        }}
                                    />
                                    <label className="custom-control-label" htmlFor={"usePoint"}>ใช้แต้ม</label>
                                </div>
                                <div className="form-group" id="pointForm" style={{ display: this.props.item && this.props.item.usePoint ? 'block' : 'none' }}>
                                    <label>แต้ม</label>
                                    <input type="number" name="pointAmount" className="form-control" required={true} defaultValue={this.props.item && this.props.item.pointAmount ? this.props.item.pointAmount : 0} />
                                </div>
                                <div className="form-group">
                                    <label>ประเภท <small>เลือกได้มากกว่า 1 ประเภท</small></label>
                                    <select
                                        className="form-control"
                                        value={''}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            if (!val) return;
                                            let selected_category = [...this.state.selected_category];
                                            selected_category.push(val)
                                            this.setState({
                                                selected_category: selected_category
                                            })
                                        }}
                                    >
                                        <option value={''}>{this.state.selected_category.length > 0 ? "เลือกเพิ่ม" : "เลือกประเภท"}</option>
                                        {
                                            this.filterCategory().map((item, item_i) => {
                                                return (
                                                    <option value={item.id} key={item_i}>{item.name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                                {
                                    this.state.selected_category.length > 0 ?
                                        <div className="form-group" style={{ margin: '0 -2px' }}>
                                            {
                                                this.state.selected_category.map((item, i) => {
                                                    let category_data = this.props.food_category.filter((e) => { return e.id === item })[0];
                                                    // console.log(category_data);
                                                    if (!category_data) {
                                                        return null;
                                                    }
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <span className="badge badge-warning" style={{ margin: '0 2px', fontSize: 14 }}>
                                                                {category_data.name}
                                                                <i className={"fas fa-times"}
                                                                    style={{ marginLeft: 5, cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        let selected_category = this.state.selected_category.filter((e) => { return e !== item });
                                                                        this.setState({
                                                                            selected_category: selected_category,
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </React.Fragment>
                                                    );
                                                })
                                            }
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="modal-footer">
                                <div className="flex row" style={{ width: '100%' }}>
                                    <input type="hidden" name="storeUid" value={this.props.storeUid} />
                                    {
                                        this.props.itemTypeUid ?
                                            <input type="hidden" name="type" value={this.props.itemTypeUid} />
                                            : null
                                    }
                                    <button type={"button"} className="btn btn-default"
                                        onClick={() => {
                                            this.$modal.modal('hide');
                                        }}
                                    >ปิด</button>
                                    <button type="submit" className="btn btn-primary" style={{ marginLeft: 'auto' }}>
                                        {this.props.mode === 'create' ? "เพิ่ม" : null}
                                        {this.props.mode === 'edit' ? "แก้ไข" : null}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}