import React from 'react';
import { Helper, SortableContainer, SortableItem } from '../../../Helper';
import { storage } from '../../../firebase';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const axios = require('axios').default;
export default class StoreGallery extends React.Component {

    async create(url) {
        let ref = await axios.post(window.api_host + '/create-store-gallery', {
            ...url,
            uid: this.props.uid
        });
        if (ref.data.success) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มรูปภาพสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            this.props.fetch();
            this.$file_btn.removeClass('loading');
        }
        if (ref.data.error) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มรูปภาพไม่สำเร็จ',
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            // this.fetch();
            this.$file_btn.removeClass('loading');
        }
    }

    async delete(store_gallery, options) {

        let ref = await axios.post(window.api_host + '/delete-store-gallery', {
            uid: store_gallery.uid,
            storeUid: this.props.uid
        });
        if (ref.data.success) {
            await storage.ref().child(store_gallery.storePath).delete();
            window.$(document).Toasts('create', {
                title: 'ลบรูปภาพสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            // this.fetch();
            // this.$file_btn.removeClass('loading');
            if (options.onSuccess) {
                options.onSuccess();
            }
        }
        if (ref.data.error) {
            window.$(document).Toasts('create', {
                title: 'ลบรูปภาพไม่สำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            // this.fetch();
            // this.$file_btn.removeClass('loading');
            if (options.onError) {
                options.onError();
            }
        }
    }

    render() {
        return (
            <>
                <div className="flex row">
                    <h6 style={{ fontWeight: 'bold' }}>แกลอรี่</h6>
                </div>
                <div className="form-group">
                    <input name="image"
                        type={"file"}
                        accept="image/*"
                        ref={(ref) => {
                            this.$file = window.$(ref);
                            this.$file_btn = this.$file.parent().find('.btn');
                        }}
                        style={{ display: 'none' }}
                        id="file_image_bank"
                        onChange={async (e) => {
                            if (this.$file_btn.hasClass('loading')) { return false; }
                            await Helper.uploadFile(this.$file, 'store-gallery/', {
                                onSuccess: (url) => {
                                    this.create(url);
                                },
                                onError: () => {
                                    this.$file_btn.removeClass('loading');
                                },
                                resize: true
                            })
                        }}
                    />
                    <label htmlFor="file_image_bank"
                        type={"button"}
                        className="btn btn-default"
                    >
                        อัพโหลด
                    </label>
                </div>
                <SortableContainer key={"promotion-slider"}
                    onSortEnd={({ oldIndex, newIndex }) => {
                        // this.setSequence(oldIndex, newIndex);
                    }}
                    useDragHandle
                    axis={'xy'}
                >
                    <div className={"row"}>

                        {
                            this.props.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            this.props.is_sequence_loading ?
                                <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                : null
                        }
                        {
                            !this.props.is_loading
                                && this.props.store_gallery.length === 0 ?
                                <h5 style={{ padding: '1rem', textAlign: 'center' }}>ไม่มีข้อมูล</h5>
                                : null
                        }
                        {
                            !this.props.is_loading ?
                                this.props.store_gallery.map((store_gallery, store_gallery_i) => {
                                    return (
                                        <SortableItem
                                            key={store_gallery_i}
                                            index={store_gallery_i}
                                        >
                                            <div
                                                className={"col-sm-12 col-lg-3"}
                                                key={store_gallery_i}
                                            >
                                                <div className={"card"}>
                                                    <div className={"card-body"} style={{ padding: '0.5rem' }}>
                                                        <div className={"promotion-slide-frame"}>
                                                            {/* <div className="promotion-drag-btn">
                                                                <DragHandle key={`item-${store_gallery_i}`}>
                                                                    <button className={"btn btn-default"}>
                                                                        <i className="fas fa-arrows-alt" />
                                                                    </button>
                                                                </DragHandle>
                                                            </div> */}
                                                            <div className={"promotion-delete"}>
                                                                <button className={"btn btn-danger"}
                                                                    ref={(ref) => {
                                                                        window.$(ref).off().on('click', () => {
                                                                            if (window.$(ref).hasClass('loading')) { return; }
                                                                            window.$(ref).addClass('loading');
                                                                            Helper.confirmModal({
                                                                                title: "ยืนยันลบรูปสไลด์",
                                                                                description: "ต้องการลบรูปสไลด์นี้หรือไม่ ?",
                                                                                onConfirm: () => {
                                                                                    this.delete(store_gallery, {
                                                                                        onSuccess: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                            this.props.fetch();
                                                                                        },
                                                                                        onError: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                        }
                                                                                    });
                                                                                },
                                                                                onCancel: () => {
                                                                                    window.$(ref).removeClass('loading');
                                                                                }
                                                                            });
                                                                        })
                                                                    }
                                                                    }
                                                                >
                                                                    <i className={"fas fa-trash-alt"} />
                                                                </button>
                                                            </div>
                                                            <a href={store_gallery.imagePath ? store_gallery.imagePath : null} data-fancybox="gallery">
                                                                <img src={store_gallery.imagePath ? store_gallery.imagePath : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SortableItem>
                                    );
                                })
                                : null
                        }
                    </div>
                </SortableContainer>
            </>
        );
    }
}