import React from 'react';
import { Helper } from '../Helper';
const axios = require('axios').default;

export default class OwnerFormModal extends React.Component {
    componentDidMount() {
        this.$modal.modal('show');
    }

    async onSubmit() {
        if (this.$btn.hasClass('loading')) { return; }
        this.setBtnLoading();
        let data = Helper.getFormInputObject(this.owner_form);
        try {
            let res = await axios.post(window.api_host + '/create-owner-store', { data: data });
            // console.log(res.data)
            if (res.data.success) {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มเจ้าของร้านเรียบร้อยแล้ว',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
                this.$modal.modal('hide')
            }
            if (res.data.error) {
                window.$(document).Toasts('create', {
                    title: res.data.message,
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                });
                this.cancelBtnLoading();
            }
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            });
            this.cancelBtnLoading();
        }

    }



    setBtnLoading() {
        this.$modal.find('button').attr('disabled', true);
        this.$btn.addClass("loading")
    }
    cancelBtnLoading() {
        this.$modal.find('button').attr('disabled', false);
        this.$btn.removeClass("loading")
    }

    render() {
        return (
            <div ref={(ref) => {
                this.$modal = window.$(ref);
                this.$modal.modal('show');
                this.$modal.on('hidden.bs.modal', () => {
                    if (this.props.onCancel) {
                        this.props.onCancel();
                    }
                    let settime = window.setTimeout(() => {
                        this.$modal.remove();
                        clearTimeout(settime);
                    }, 500)
                });
            }}
                className="modal fade" tabIndex="-1" role="dialog"
                data-keyboard="false" data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">เพิ่มเจ้าของร้าน</h4>
                        </div>
                        <form
                            ref={(ref) => { this.owner_form = window.$(ref) }}
                            className="modal-body"
                        >
                            <div class="form-group">
                                <label>ชื่อผู้สมัคร</label>
                                <input type="text" name="displayName" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label>เบอร์โทรศัพท์</label>
                                <input type="text" name="phoneNumber" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label>อีเมล</label>
                                <input type="email" name="email" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label>รหัสผ่าน</label>
                                <input type="password" name="password" class="form-control" />
                                <span style={{ fontSize: 13, color: 'rgba(0,0,0,0.5)' }}>รหัสผ่านประกอบไปด้วย เลข,ตัวหนังสือพิมพ์เล็กและพิมพ์ใหญ่</span>
                            </div>
                            <input type="hidden" name="storeUid" defaultValue={this.props.storeUid}/>
                            <input type="hidden" name="adminUid" defaultValue={window.adminUid}/>
                        </form>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (this.props.onCancel) {
                                        this.props.onCancel();
                                    }
                                    this.$modal.modal('hide');
                                }}
                            >{this.props.cancle_text || "ยกเลิก"}</button>
                            <button type="button" className="btn btn-primary"
                                ref={ref => { this.$btn = window.$(ref) }}
                                onClick={() => {
                                    this.onSubmit();
                                }}
                            >{this.props.submit_text || "ตกลง"}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}