import React from "react";
import Pagination from "../../Components/Pagination";
import { Helper } from "../../Helper";
import { getPaymentTransaction, paymentRefund } from "../../services/PaymentServices";
import { Link, navigate } from "@reach/router";
import CustomDateInput from '../../Components/CustomDateInput';
import DatePicker from "react-datepicker";
const default_limit = 5;
export default class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            page: 1,
            date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            channel: Helper.getParameterByName('channel') ? Helper.getParameterByName('channel') : '',
            q: Helper.getParameterByName('q') ? Helper.getParameterByName('q') : '',
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            total_items: 0,
            count_items: 0,
        }
    }

    async fetch() {
        let date = false;
        if (this.state.date) {
            let get_date = Helper.getDateToEdit(this.state.date);
            // get_date = new Date(get_date.setTime(get_date.getTime() + (parseFloat(7) * 60 * 60 * 1000)));
            let day = get_date.getDate().toString().padStart(2, 0);
            let month = (get_date.getMonth() + 1).toString().padStart(2, 0);
            date = `${day}-${month}-${get_date.getFullYear()}`;
        }
        let res = await getPaymentTransaction(false, this.state.page, this.state.limit, date, this.state.status, this.state.channel, this.state.q);
        // console.log(res);
        let data = [...this.state.data];
        let total_items = 0;
        let count_items = 0;
        let page = this.state.page;
        let is_done = false;
        if (!res.error) {
            data = data.concat(res.items);
            page = parseFloat(this.state.page) + 1;
            total_items = res.total;
            count_items = res.itemEnd;
            is_done = res.total == res.itemEnd;
        }
        this.setState({
            data: data,
            total_items: total_items,
            count_items: count_items,
            page: page,
            is_done: is_done,
            is_loading: false,
        })
    }

    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {

            let obj = Object.assign({
                is_loading: true,
                data: [],
                page: 1,
                date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                channel: Helper.getParameterByName('channel') ? Helper.getParameterByName('channel') : '',
                q: Helper.getParameterByName('q') ? Helper.getParameterByName('q') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            })
            this.setState({
                ...obj
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                data: [],
                limit: default_limit,
                date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                channel: Helper.getParameterByName('channel') ? Helper.getParameterByName('channel') : '',
                q: Helper.getParameterByName('q') ? Helper.getParameterByName('q') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            }, () => {
                this.fetch();
            })
        }
    }


    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ประวัติการชำระเงิน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ประวัติการชำระเงิน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                {/* FILTER */}
                                <div className="card card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form className="row"
                                            ref={(ref) => { this.$form = window.$(ref) }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                                    return;
                                                }
                                                let data = Helper.getFormInputObject(this.$form);
                                                let url = Helper.getQueryStringLinkFromObject({
                                                    ...data
                                                })
                                                navigate(url)
                                            }}
                                        >
                                            <div className="col-6 form-group">
                                                <label>ค้นหา </label>
                                                <input type="text" name="q" className="form-control" defaultValue={this.state.q} />
                                            </div>
                                            <div className="col-6 form-group">
                                                <label className="d-flex">วันที่
                                                    {
                                                        this.state.date ?
                                                            <span className="right-floated date-cancle-icon"
                                                                onClick={() => {
                                                                    this.setState({ date: "" })
                                                                }}
                                                            >  ยกเลิก
                                                            </span>
                                                            : null
                                                    }
                                                </label>
                                                <div style={{ position: 'relative' }}>
                                                    <DatePicker
                                                        onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                                        onChange={date => {
                                                            this.setState({
                                                                date: new Date(date).getTime(),
                                                            })
                                                        }}
                                                        locale="th-TH"
                                                        selected={this.state.date ? Helper.getDateToEdit(this.state.date) : false}
                                                        placeholderText={"วัน / เดือน / ปี"}
                                                        name={"birthDay"}
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        withPortal
                                                        renderCustomHeader={({
                                                            date,
                                                            changeYear,
                                                            changeMonth,
                                                            decreaseMonth,
                                                            increaseMonth,
                                                            prevMonthButtonDisabled,
                                                            nextMonthButtonDisabled
                                                        }) => {
                                                            let now = new Date();
                                                            let selected_date = new Date(date);
                                                            // months_th
                                                            const years = [];
                                                            let i;
                                                            for (i = 1980; i <= now.getFullYear(); i++) {
                                                                years.push(i)
                                                            }
                                                            return (
                                                                <div
                                                                    style={{
                                                                        margin: 10,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                        className={"btn btn-primary "}
                                                                        type={"button"}
                                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                    >
                                                                        <i className="fas fa-chevron-left" />
                                                                    </button>
                                                                    <select
                                                                        className={"form-control"}
                                                                        value={selected_date.getFullYear()}
                                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                                        style={{ borderRadius: 0 }}
                                                                    >
                                                                        {years.map(option => (
                                                                            <option key={option} value={option} >
                                                                                {option + 543}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <select
                                                                        className={"form-control"}
                                                                        value={window.months_th[selected_date.getMonth()]}
                                                                        onChange={({ target: { value } }) =>
                                                                            changeMonth(window.months_th.indexOf(value))
                                                                        }
                                                                        style={{ borderRadius: 0 }}
                                                                    >
                                                                        {window.months_th.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                        className={"btn btn-primary"}
                                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                        type={"button"}
                                                                    >
                                                                        <i className="fas fa-chevron-right" />
                                                                    </button>
                                                                </div>
                                                            )
                                                        }}
                                                        customInput={<CustomDateInput />}
                                                    />
                                                    <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />


                                                </div>
                                            </div>

                                            <div className="col-6 form-group">
                                                <label>สถานะ</label>
                                                <select
                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"status"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.status || ''}
                                                >
                                                    <option value={""}>
                                                        ทั้งหมด
                                                    </option>
                                                    <option value={"1"}>
                                                        ยังไม่ทำรายการ
                                                    </option>
                                                    <option value={"2"}>
                                                        รอชำระเงิน
                                                    </option>
                                                    <option value={"3"}>
                                                        ชำระเงินเสร็จ
                                                    </option>
                                                    <option value={"4"}>
                                                        ยกเลิก
                                                    </option>
                                                    <option value={"5"}>
                                                        ขอคืนเงิน
                                                    </option>
                                                    <option value={"6"}>
                                                        คือเงินแล้ว
                                                    </option>
                                                </select>
                                            </div><div className="col-6 form-group">
                                                <label>วิธีการชำระเงิน</label>
                                                <select
                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"channel"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.channel || ''}
                                                >
                                                    <option value={""}>
                                                        ทั้งหมด
                                                    </option>
                                                    <option value={"1"}>
                                                        Google Pay
                                                    </option>
                                                    <option value={"2"}>
                                                        Apple Store
                                                    </option>
                                                    <option value={"3"}>
                                                        WedevGateway
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="col-12">
                                                <div className="flex row">
                                                    <Link to="/transaction" className="btn btn-default">รีเซ็ท</Link>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <button type="submit" className="btn btn-default">ค้นหา</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5">
                                <div className="card">
                                    <div className="card-header align-items-center" style={{ display: 'flex' }}>
                                        <h3 className="card-title">ประวัติการชำระเงิน</h3>

                                    </div>
                                    <div className="card-body">

                                        {
                                            this.state.is_loading ?
                                                <div className={"absolute_loading"}>
                                                    <div className="ui active inline loader" ></div>
                                                </div>
                                                : null
                                        }
                                        <RenderPaymentTransactionList
                                            data={this.state.data}
                                            isCanRefund={this.props.user && this.props.user.permission && this.props.user.permission.transaction && this.props.user.permission.transaction.refund ? true : false}
                                            onRefund={(obj) => {
                                                console.log('obj', obj)
                                                let datas = [...this.state.data];
                                                let i = 0;
                                                for (const data of datas) {
                                                    if (data.id == obj.id) {
                                                        datas[i] = obj;
                                                    }
                                                    i++;
                                                }
                                                this.setState({
                                                    data: datas,
                                                })
                                            }}
                                        />
                                        <div className="d-flex mt-2 align-items-center">
                                            {
                                                !this.state.is_loading &&
                                                    !this.state.is_done ?
                                                    <button
                                                        className="btn btn-default"
                                                        onClick={(ref) => {
                                                            let node = window.$(ref.target);
                                                            if (node.hasClass('loading')) { return }
                                                            node.addClass('loading');
                                                            this.setState({
                                                                is_loading: true
                                                            }, () => {
                                                                this.fetch()
                                                            })
                                                        }}
                                                    >โหลดเพิ่ม</button>
                                                    :
                                                    !this.state.is_done ?
                                                        <button className="btn btn-default loading">โหลดเพิ่ม</button>
                                                        : null
                                            } <span className='right-floated'>{this.state.count_items}/{this.state.total_items}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

class RenderPaymentTransactionList extends React.Component {
    render() {
        if (!this.props.data || (this.props.data.length === 0)) {
            return (
                <li className="list-group-item">
                    <strong>ไม่มีข้อมูลการชำระเงิน</strong>
                </li>
            )
        }
        return (
            <>
                {
                    this.props.data.map((item, i) => {
                        let amount = item.amount ?? 0;
                        let amountType = "c";
                        return (
                            <li key={i} className="list-group-item">
                                <div className="d-flex">
                                    <p className='mb-0'><strong>#{item.transactionNumber}</strong></p>
                                    {
                                        item.created ?
                                            <p className="right-floated mb-0">{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</p>
                                            : null
                                    }
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <p className="mb-0">ผู้ชำระเงิน : {item.payerName}</p>
                                        <div className="d-flex align-items-center">
                                            <p className='mb-0'>รายการ : {item.paymentType.name} {item.coinAmount ? `${Helper.numberFormat(item.coinAmount)}` : null}</p>
                                            <div style={{ display: 'flex', width: 15, height: 15, borderRadius: 35, marginLeft: 3, backgroundColor: amountType == "c" ? 'orange' : '#50AFFF', overflow: 'hidden', marginLeft: 2, alignItems: 'center', justifyContent: 'center' }}>
                                                <span style={{ color: 'white', fontSize: 10, fontWeight: 'bold', textTransform: 'uppercase' }}>{amountType}</span>
                                            </div>
                                        </div>
                                        <p className='mb-0'>ช่องทางชำระเงิน : {item.paymentChannel.name}</p>
                                        {
                                            item.referenceNumber ?
                                                <p className='mb-0' style={{ wordBreak: 'break-all' }}>Ref No. : {item.referenceNumber}</p>
                                                : null
                                        }
                                        {
                                            item.invoiceNumber ?
                                                <p className='mb-0' style={{ wordBreak: 'break-all' }}>Invoice No. : {item.invoiceNumber}</p>
                                                : null
                                        }
                                        <p className='mb-0'>สถานะการชำระเงิน : <span style={{
                                            color:
                                                item.paymentStatusId == 3 ? 'green' :
                                                    item.paymentStatusId == 4 ? 'red' :
                                                        item.paymentStatusId == 2 ? "orange" :
                                                            item.paymentStatusId == 6 ? "gray" :
                                                                '#000'
                                        }}>{item.paymentStatus.name}</span>
                                            {
                                                item.paymentStatusId == 3 && item.paidDateTime ?
                                                    <small style={{ fontSize: 12, color: '#999', marginLeft: 5 }}>{Helper.getDateThai(item.paidDateTime, { is_show_time: true, plus_hours: 7 })}</small>
                                                    : null
                                            }
                                            {
                                                item.paymentStatusId == 6 && item.updated ?
                                                    <small style={{ fontSize: 12, color: '#999', marginLeft: 5 }}>{Helper.getDateThai(item.updated, { is_show_time: true, plus_hours: 7 })}</small>
                                                    : null
                                            }
                                        </p>
                                        {
                                            item.refundNumber ?
                                                <p className='mb-0'>Refund No. : {item.refundNumber}</p>
                                                : null
                                        }

                                        {
                                            item.refundReason ?
                                                <p className='mb-0'>Refun reason : {item.refundReason}</p>
                                                : null
                                        }
                                    </div>
                                    <div className="right-floated" style={{ paddingLeft: 15, minWidth: 100 }}>
                                        <p className="right-floated"><strong>฿{Helper.numberFormat(amount, { decimal: 2 })}</strong></p>
                                        {
                                            this.props.isCanRefund
                                                && item.amount > 0
                                                && item.paymentStatusId == 3 ?
                                                <button
                                                    className="btn btn-default"
                                                    ref={(ref) => {
                                                        let btnRef = window.$(ref);
                                                        btnRef.off().on('click', () => {
                                                            if (btnRef.hasClass('loading')) { return; }
                                                            btnRef.addClass('loading');
                                                            Helper.reactToDom(window.$('body'),
                                                                <RefundConfirm
                                                                    data={item}
                                                                    onRefund={(data) => {
                                                                        console.log("RefundConfirm", data)
                                                                        this.props.onRefund(data);
                                                                    }}
                                                                    onClose={() => {
                                                                        btnRef.removeClass('loading');
                                                                    }}
                                                                />
                                                            )
                                                        })
                                                    }}
                                                >คืนเงิน</button>
                                                : null
                                        }
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </>
        )
    }


}



class RefundConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data || {},
        }
    }

    async refund(item, options) {

        let uploadBtn = this.uploadBtn;
        let data = { ...item };
        let refund_data = {
            transactionNumber: data.transactionNumber,
            referenceNumber: data.referenceNumber,
            refundAmount: data.amount,
            refundReason: data.refundReason,
            referenceFile: data.image,
            transferAccount: data.transferAccount,
        }
        let res = await paymentRefund(refund_data);
        if (res.isSuccess) {
            if (this.props.onRefund) {
                data.paymentStatus.name = "คืนเงินแล้ว";
                data.paymentStatusId = 6;
                data.updated = new Date().getTime();
                this.props.onRefund(data);
            }
            this.$modal.modal('hide');
        } else {
            Helper.removeFile(item.storagePath)
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' })
            this.$submit.removeClass("loading")
            uploadBtn.removeClass("loading")
        }

    }

    render() {
        let title = "คืนเงิน";
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$form = this.$modal.find('form');
                        this.$submit = this.$form.find('button[type="submit"]');
                        this.uploadBtn = this.$file.parent().find('.btn');
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            if (this.props.onClose) {
                                this.props.onClose();
                            }
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            let $submit = this.$submit;
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            // console.log(data)
                            data = { ...this.state.data, ...data }
                            if (!data.transferAccount) {
                                Helper.messageTop({ message: 'กรุณาช่องทางการคืนเงิน' })
                                this.$submit.removeClass("loading")
                                this.uploadBtn.removeClass("loading")
                                return;
                            }
                            if (!this.$file || (this.$file && this.$file[0].files.length == 0)) {
                                Helper.messageTop({ message: 'กรุณาอัพโหลดหลักฐานการโอนเงิน' })
                                this.$submit.removeClass("loading")
                                this.uploadBtn.removeClass("loading")
                                return;
                            }
                            if (!data.refundReason) {
                                Helper.messageTop({ message: 'กรุณากรอกเหตุผลการคืนเงิน' })
                                this.$submit.removeClass("loading")
                                this.uploadBtn.removeClass("loading")
                                return;
                            }

                            await Helper.uploadFile(this.$file, 'skitzrefund/', {
                                resize: false,
                                onSuccess: async (url) => {
                                    data.storagePath = url.storePath;
                                    data.image = url.imagePath;

                                    this.refund(data)
                                },
                                onError: async () => {
                                    window.$(document).Toasts('create', {
                                        title: 'อัพโหลดรูปภาพไม่สำเร็จกรุณาลองใหม่อีกครั้ง',
                                        icon: 'fas fa-times',
                                        autohide: true,
                                        class: "bg-danger",
                                        delay: 3000,
                                    })
                                    $submit.removeClass('loading');
                                }
                            })

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">{title}</h4>
                            </div>
                            <div className="modal-body">
                                <div className={"form-group"}>
                                    <label>ช่องทางการคืนเงิน</label>
                                    <textarea className={"form-control"} name="transferAccount"></textarea>
                                </div>
                                <div className="form-group">
                                    <label>หลักฐานการโอนเงิน</label>
                                    <input name="image"
                                        type={"file"}
                                        accept="image/*"
                                        ref={(ref) => this.$file = window.$(ref)}
                                        style={{ display: 'none' }}
                                        id="file_image"
                                        onChange={async (e) => {
                                            Helper.readImage(this.$file)
                                        }}
                                    />
                                    <div id="show_image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {
                                            this.state.data && this.state.data.image ?
                                                <img src={this.state.data.image} alt={"PromotionPic"} style={{ maxWidth: '100%', marginBottom: 5 }} />
                                                : null
                                        }
                                    </div>
                                    <label htmlFor="file_image"
                                        type={"button"}
                                        className="btn btn-default"
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            this.state.data && this.state.data.image ?
                                                "เปลี่ยนรูป"
                                                : "อัพโหลด"
                                        }
                                    </label>
                                </div>
                                <div className={"form-group"}>
                                    <label>หมายเหตุ</label>
                                    <textarea className={"form-control"} name="refundReason"></textarea>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                        if (this.props.onClose) { this.props.onClose(); }
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>คืนเงิน</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}