import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/chat/gift`;
export const getChatGiftTransaction = async (userId = false, page = 1, rows = 10, date = false) => {
    let url = `${baseUrl}/transaction?userId=${userId}&page=${page}&rows=${rows}`;
    if (date) {
        url += `&date=${date}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getChatGiftTransactionList = async (userId = false, page = 1, rows = 10, date = false) => {
    let url = `${baseUrl}/transaction/list?userId=${userId}&page=${page}&rows=${rows}`;
    if (date) {
        url += `&date=${date}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getChatGiftList = async () => {
    let url = `${baseUrl}/list`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}