import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../../../Helper';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pagination from '../../../Components/Pagination';
import { getStore } from '../../../services/StoreServices';
import { getArtist } from '../../../services/ArtistServices';
const axios = require('axios').default;
const defaultData = {
    is_loading: true,
    page: 1,
    limit: 10,
    data: [],
    count_today: 0,
    count_all: 0,
    count_item: 0,
    count_item_today: 0,
}
export default class StoreUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...defaultData,
            storeId: false,
        }
    }
    resetData() {
        this.setState({
            ...defaultData
        }, () => {
            this.fetch();
        })
    }

    async fetch() {
        let storeId = this.state.storeId;
        let data = {};
        data.page = this.state.page;
        data.rows = this.state.limit;
        if (!storeId && this.props.uid && !this.props.id) {
            let storeRes = await getStore(this.props.uid);
            if (!storeRes.error) {
                storeId = storeRes.id;
            } else {
                window.$(document).Toasts('create', {
                    title: 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาติดต่อผู้ดูแลระบบ',
                    icon: 'fas fa-ban',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
                this.setState({
                    is_loading: false,
                    storeId: false,
                    data: [],
                    page: 1,
                    is_done: true,
                    totalItems: 0,
                    itemEnd: 0,
                })
                return;
            }
        }
        if (storeId) {
            data.storeId = storeId;
        }

        let res = await getArtist(data.page, data.rows, false, false, data.storeId);

        let items = [];
        let count_item = 0;
        let count_all_item = 0;
        let count_item_today = 0;
        if (!res.error) {
            items = res.items;
            count_all_item = res.total;
            count_item = res.itemEnd;
        }
        this.setState({
            is_loading: false,
            data: items,
            count_item: count_item,
            count_all: count_all_item,
            count_item_today: count_item_today
        })
    }


    render() {
        return (
            <div className="row" style={{ position: 'relative' }}>
                {
                    this.state.is_loading ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                <div className="col-12 col-md-3">
                    <div>
                        <h6 style={{ fontWeight: 'bold' }}>ข้อมูลจำนวนนักดนตรี</h6>
                        <ul className="list-group list-group-unbordered no-border-lastchild mb-3">
                            <li className="list-group-item">
                                <b>นักดนตรีทั้งหมด</b> <div className="float-right">{Helper.numberFormat(this.state.count_all)}</div>
                            </li>
                            <li className="list-group-item">
                                <b>นักดนตรีวันนี้</b> <div className="float-right">{Helper.numberFormat(this.state.count_item_today)}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-9">
                    <ul className="users-list clearfix">
                        {
                            this.state.count_all > 0 ?
                                this.state.data.map((user, user_i) => {
                                    return (
                                        <li style={{ width: '20%' }} key={user_i}>
                                            <div className="profile-user-img img-frame img-fluid img-circle">
                                                <img src={user && user.imageUrl ? user.imageUrl : "/assets/images/no_img.png"} alt="User" />
                                            </div>
                                            <Link className="users-list-name" to={'/artist/view/' + user.id}>{user.name}</Link>
                                            {/* <span className="users-list-date">{Helper.getDateThai(user.lastActive, { is_show_time: true })}</span> */}
                                        </li>
                                    )
                                })
                                : null
                        }
                    </ul>
                    <Pagination
                        now={this.state.page}
                        all_items={this.state.count_all}
                        limit={this.state.limit}
                        onReturnPage={(page) => {
                            this.setState({
                                page: page,
                            }, () => {
                                this.fetch();
                            })
                        }}
                        location={this.props.location}
                    />
                </div>
            </div>
        );
    }
}