import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/reported/user`;
const baseUrlReport = `${_serverUrl}/report`;
export const getReportUsers = async (page = 1, rows = 10, typeId = false) => {
    let url = `${baseUrl}?page=${page}&rows=${rows}`;
    if (typeId) {
        url += `&typeId=${typeId}`
    }
    // console.log(url)
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const updateReportUsersStatus = async (id, update) => {
    let url = `${baseUrl}/${id}`;
    try {
        const res = await httpClient.put(url, update)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getRequestReport = async (filter) => {
    let url = `${baseUrlReport}/live/request`;
    if (filter) {
        url += `?${window.$.param(filter)}`
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}