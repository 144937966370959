import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../../Helper';
const axios = require('axios').default;
export default class View extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.post(window.api_host + '/package', { packageUid: this.props.uid });
        let _package = false;
        if (res.data.code === 200 && res.data.success) {
            _package = res.data.package
        }

        this.setState({
            data: _package,
            is_loading: false,
        })
    }
    async delete() {
        let data = { ...this.state.data };
        Helper.confirmModal({
            title: 'ยกเลิกแพ็คเกจ',
            description: 'ยืนยันการยกเลิกแพ็คเกจ',
            onConfirm: async () => {
                try {
                    let ref = await axios.post(window.api_host + '/delete-package', { data: data });
                    // console.log(ref.data);
                    if (ref.data.code === 200 && ref.data.success) {
                        window.$(document).Toasts('create', {
                            title: 'แก้ไขข้อมูลสำเร็จ',
                            icon: 'fas fa-check',
                            autohide: true,
                            class: "bg-success",
                            delay: 3000,
                        })
                        this.fetch();
                        this.delete_btn.removeClass('loading');
                    }
                    if (ref.data.code === 200 && ref.data.error) {
                        let message = {
                            icon: 'fas fa-times',
                            autohide: true,
                            class: "bg-danger",
                            delay: 3000,
                        }
                        message.title = ref.data.message;
                        if (ref.data.description) {
                            message.description = ref.data.description
                        }
                        window.$(document).Toasts('create', { ...message });
                        this.delete_btn.removeClass('loading');
                    }
                } catch (error) {
                    window.$(document).Toasts('create', {
                        title: 'ไม่สามารถเพิ่มข้อมูลได้',
                        body: 'เพิ่มข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง',
                        icon: 'fas fa-check',
                        autohide: true,
                        class: "bg-danger",
                        delay: 3000,
                    })
                    this.delete_btn.removeClass('loading');
                }
            },
            onCancel: () => {
                this.delete_btn.removeClass('loading');
            }
        })
    }

    render() {
        let type = '-';
        let paymentType = '-';
        if (this.state.data && this.state.data.option) {
            type = this.state.data.type;
            if (this.state.data.type === 'Main') {
                type = "Main Promotion";
            }
            if (this.state.data.type === 'Offer') {
                type = "Offer Promotion";
            }
        }

        if (this.state.data && this.state.data.option) {
            if (this.state.data.option.paymentType === 'billcycle') {
                paymentType = 'รายเดือน';
            }
            if (this.state.data.option.paymentType === 'dateexpire') {
                paymentType = 'กำหนดจำนวนวันสิ้นสุด ' + this.state.data.option.duration + ' วัน';
            }
            if (this.state.data.option.paymentType === 'expirebillingcycle') {
                paymentType = 'กำหนดจำนวนเดือนสิ้นสุด ' + this.state.data.option.duration + ' เดือน';
            }
        }
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">แพ็คเกจ </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/package">แพ็คเกจ</Link></li>
                                    <li className="breadcrumb-item active">ดูข้อมูล</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={"col-12 col-md-3"}>
                                <div className={"card"}>
                                    <div className="card-header">
                                        <h5 className="card-title">ดูข้อมูลแพ็คเกจ</h5>
                                        {
                                            this.state.data && this.state.data.option ?
                                                <div className="card-tools">
                                                    <Link to={"/package/edit/" + this.props.uid + "/" + this.state.data.option.uid}>
                                                        แก้ไขข้อมูล
                                                    </Link>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className="card-body">
                                        {
                                            this.state.is_loading ?
                                                <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                                : null
                                        }

                                        <div>
                                            <label>ชื่อโปรโมชั่น (ไทย)</label> {' : '}
                                            {this.state.data && this.state.data.option && this.state.data.option.nameTH ? this.state.data.option.nameTH : '-'}
                                        </div>
                                        <div>
                                            <label>รายละเอียด (ไทย)</label> {' : '}
                                            {this.state.data && this.state.data.option && this.state.data.option.descriptionTH ? this.state.data.option.descriptionTH : '-'}
                                        </div>
                                        <div className="divider" />
                                        <div>
                                            <label>ชื่อโปรโมชั่น (อังกฤษ)</label> {' : '}
                                            {this.state.data && this.state.data.option && this.state.data.option.nameEN ? this.state.data.option.nameEN : '-'}
                                        </div>
                                        <div >
                                            <label>รายละเอียด (อังกฤษ)</label> {' : '}
                                            {this.state.data && this.state.data.option && this.state.data.option.descriptionEN ? this.state.data.option.descriptionEN : '-'}
                                        </div>
                                        <div className="divider" />
                                        <div>
                                            <label>ประเภทโปรโมชั่น</label> {' : ' + type}
                                        </div>
                                        <div>
                                            <label>ราคา</label> {' : '}
                                            {
                                                this.state.data
                                                    && this.state.data.option
                                                    && this.state.data.option.summary
                                                    && this.state.data.option.summary.total ?
                                                    parseFloat(this.state.data.option.summary.total) > 0 ?
                                                        Helper.numberFormat(parseFloat(this.state.data.option.summary.total)) + " ฿"
                                                        : "ฟรี"
                                                    : "-"
                                            }
                                        </div>
                                        <div>
                                            <label>ประเภทการชำระเงิน</label> {' : ' + paymentType}
                                        </div>
                                        {/* <div className="flex row">
                                            {
                                                this.state.data
                                                    && this.state.data.isVisibleMainSite
                                                    ?
                                                    <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                    :
                                                    <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                            }
                                            <label >แสดงบนหน้าเว็บ</label>
                                        </div>
                                        <div className="flex row">
                                            {
                                                this.state.data
                                                    && this.state.data.isVisibleStore
                                                    ?
                                                    <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                    :
                                                    <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                            }
                                            <label >แสดงเป็นตัวเลือกของร้านค้า</label>
                                        </div> */}
                                        <div className="flex row">
                                            {
                                                this.state.data
                                                    && this.state.data.option
                                                    && this.state.data.option.isActive
                                                    ?
                                                    <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                    :
                                                    <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                            }
                                            <label >ใช้งาน</label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.data
                                    && this.state.data.option
                                    && this.state.data.option.isActive
                                    && !this.state.data.is_cannot_delete ?
                                        <button type="button"
                                            className="btn btn-danger"
                                            style={{ width: '100%' }}
                                            disabled={this.state.is_loading}
                                            ref={(ref) => { this.delete_btn = window.$(ref) }}
                                            onClick={() => {
                                                if (this.delete_btn.hasClass('loading')) { return; }
                                                this.delete_btn.addClass('loading');
                                                this.delete();
                                            }}
                                        >ลบ</button>
                                        : null
                                }
                            </div>
                            <div className={"col-12 col-md-3"}>
                                <div className={"card"}>
                                    <div className="card-header">
                                        <h5 className="card-title">Features</h5>
                                    </div>
                                    <div className="card-body">
                                        {
                                            this.state.is_loading ?
                                                <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                                : null
                                        }
                                        {
                                            this.state.data
                                                && this.state.data.option
                                                && this.state.data.option.features ?
                                                <>

                                                    <div className="flex row">
                                                        {
                                                            this.state.data.option.features.haveTimeline
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >ระบบโพส</label>
                                                    </div>
                                                    {/* <div className="flex row" style={{ paddingLeft: '1.5rem' }}>
                                                        {
                                                            this.state.data.option.features.canGuestPostTimeline
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >ผู้ใช้งาน <span className="text-danger">*ยังใช้งานไม่ได้</span></label>
                                                    </div> */}
                                                    <div className="flex row" style={{ paddingLeft: '1.5rem' }}>
                                                        {
                                                            this.state.data.option.features.canStorePostTimeline
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >ร้าน</label>
                                                    </div>
                                                    <div className="flex row" style={{ paddingLeft: '1.5rem' }}>
                                                        {
                                                            this.state.data.option.features.canStoreRemoveTimeline
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >ลบโพส</label>
                                                    </div>
                                                    <div className="flex row">
                                                        {
                                                            this.state.data.option.features.canKickMember
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >บังคับผู้ใช้งานออกจากระบบ (เตะผู้ใช้งาน)</label>
                                                    </div>
                                                    {/* <div className="flex row">
                                                        {
                                                            this.state.data.option.features.haveBanUser
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >แบนผู้ใช้งาน <span className="text-danger">*ยังใช้งานไม่ได้</span></label>
                                                    </div> */}
                                                    <div className="flex row">
                                                        {
                                                            this.state.data.option.features.canManageEvent
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >ระบบข่าวสารของร้าน</label>
                                                    </div>
                                                    <div className="flex row">
                                                        {
                                                            this.state.data.option.features.havePermissionStore
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >ระบบจัดการผู้ดูแลระบบและสิทธิ์การเข้าถึง</label>
                                                    </div>
                                                    {/* <div className="flex row">
                                                        {
                                                            this.state.data.option.features.haveSkitz
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >ระบบสกิดบนแอพพลิเคชั่น <i className="fas fa-mobile-alt"></i></label>
                                                    </div> */}
                                                    <div className="flex row">
                                                        {
                                                            this.state.data.option.features.isPreviewMemberProfileStore
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >ดูรูปโปรไฟล์ผู้ใช้งาน</label>
                                                    </div>
                                                    <div className="flex row">
                                                        {
                                                            this.state.data.option.features.haveArtistLive
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >ระบบนักดนตรี/Live</label>
                                                    </div>
                                                    <div className="flex row">
                                                        {
                                                            this.state.data.option.features.haveMenu
                                                                ?
                                                                <i className="fas fa-check label-icon" style={{ color: 'green' }}></i>
                                                                :
                                                                <i className="fas fa-times label-icon" style={{ color: 'red' }}></i>
                                                        }
                                                        <label >ระบบเมนู</label>
                                                    </div>
                                                    <div>
                                                        <label >จำนวนผู้เข้าสู่ระบบสูงสุด</label> {' '} {this.state.data.option.features.onlineCapacity} คน
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}