import React from 'react';
import { Helper } from '../../Helper';
import { Link, navigate } from '@reach/router';
const axios = require('axios').default;

export default class Form extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
            providers: [],
        }
    }

    componentDidMount() {
        if (this.props.mode === 'edit') {
            this.fetch();
        }
        if (this.props.mode === 'create') {
            this.fetchProviders();
        }
    }
    async fetchProviders() {
        let res = await axios.get(window.api_host + '/providers');
        let providers = res.data.providers;
        this.setState({
            providers: providers,
            is_loading: false,
        })
    }

    async fetch() {
        let query = {};
        if (this.props.uid) {
            query.uid = this.props.uid;
        }
        if (this.props.packageUid) {
            query.packageUid = this.props.packageUid;
        }
        query.getProvider = true;
        let res = await axios.post(window.api_host + '/package', { ...query });
        let _package = res.data.package;
        let providers = res.data.providers;
        this.setState({
            data: _package,
            providers: providers,
            is_loading: false,
        }, () => {
            // console.log(this.state.data);
            // if (!this.state.data.isActive) { navigate('/package/view/' + this.state.data.packageUid) }
        })
    }

    async create(data) {
        try {
            let ref = await axios.post(window.api_host + '/add-package', { data: data });
            // console.log(this.props.adminUid);
            if (ref.data.code === 200 && ref.data.success) {
                window.$(document).Toasts('create', {
                    title: 'แก้ไขข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                navigate("/package/view/" + ref.data.package.packageUid)
            }
            if (ref.data.code === 200 && ref.data.error) {
                let message = {
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                }
                message.title = ref.data.message;
                if (ref.data.description) {
                    message.description = ref.data.description
                }
                window.$(document).Toasts('create', { ...message });
                this.submit.removeClass('loading')
            }
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถเพิ่มข้อมูลได้',
                body: 'เพิ่มข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.submit.removeClass('loading')
        }
    }
    async update(data) {
        try {
            let ref = await axios.post(window.api_host + '/update-package', { data: data });
            // console.log(this.props.adminUid);
            if (ref.data.code === 200 && ref.data.success) {
                window.$(document).Toasts('create', {
                    title: 'แก้ไขข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                navigate("/package/view/" + ref.data.package.packageUid)
            }
            if (ref.data.code === 200 && ref.data.error) {
                let message = {
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                }
                message.title = ref.data.message;
                if (ref.data.description) {
                    message.description = ref.data.description
                }
                window.$(document).Toasts('create', { ...message });
                this.submit.removeClass('loading')
            }
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถเพิ่มข้อมูลได้',
                body: 'เพิ่มข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.submit.removeClass('loading')
        }
    }


    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">แพ็คเกจ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/package">แพ็คเกจ</Link></li>
                                    {
                                        this.props.mode === 'edit' ?
                                            <li className="breadcrumb-item"><Link to={"/package/view/" + this.props.packageUid}>ดูข้อมูล</Link></li>
                                            : null
                                    }
                                    <li className="breadcrumb-item active">
                                        {this.props.mode === 'create' ? 'เพิ่ม' : null}
                                        {this.props.mode === 'edit' ? 'แก้ไข' : null}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <form
                                    ref={(ref) => this.$form = window.$(ref)}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (this.submit.hasClass('loading')) { return false; }
                                        this.submit.addClass('loading');
                                        let data = Helper.getFormInputObject(this.$form);
                                        if (this.props.mode === 'create') {
                                            this.create(data);
                                        }
                                        if (this.props.mode === 'edit') {
                                            this.update(data);
                                        }
                                    }}
                                >
                                    {
                                        this.state.is_loading ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="ui active inline loader" ></div></div>
                                            : null
                                    }

                                    {
                                        !this.state.is_loading ?
                                            <div className={"row"}>
                                                <div className={"col-12 col-md-3"}>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="card-title">ข้อมูลแพ็คเกจ</div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className={"form-group"}>
                                                                <label>ชื่อแพ็คเกจ (ไทย)</label>
                                                                <input type="text" className="form-control" name="nameTH"
                                                                    defaultValue={this.state.data && this.state.data.option && this.state.data.option.nameTH ? this.state.data.option.nameTH : null} />
                                                            </div>
                                                            <div className={"form-group"}>
                                                                <label>รายละเอียด (ไทย)</label>
                                                                <textarea className={"form-control"} name="descriptionTH"
                                                                    defaultValue={this.state.data && this.state.data.option && this.state.data.option.descriptionTH ? this.state.data.option.descriptionTH : null}></textarea>
                                                            </div>
                                                            <div className="divider" />
                                                            <div className={"form-group"}>
                                                                <label>ชื่อแพ็คเกจ (อังกฤษ)</label>
                                                                <input type="text" className="form-control" name="nameEN"
                                                                    defaultValue={this.state.data && this.state.data.option && this.state.data.option.nameEN ? this.state.data.option.nameEN : null} />
                                                            </div>
                                                            <div>
                                                                <label>รายละเอียด (อังกฤษ)</label>
                                                                <textarea className={"form-control"} name="descriptionEN"
                                                                    defaultValue={this.state.data && this.state.data.option && this.state.data.option.descriptionEN ? this.state.data.option.descriptionEN : null}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-12 col-md-3"}>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="card-title">ข้อมูลแพ็คเกจ</div>
                                                        </div>
                                                        <div className="card-body">
                                                            {

                                                                (this.state.data
                                                                    && this.state.data.option
                                                                    && this.state.data.option.isMain
                                                                    && this.props.mode === 'edit')
                                                                    || this.props.mode === 'create' ?
                                                                    <div className="form-group">
                                                                        <label>ประเภทแพ็คเกจ</label>
                                                                        <select className={"form-control"} name="type"
                                                                            defaultValue={this.state.data && this.state.data.type ? this.state.data.type : null}
                                                                        >
                                                                            <option value="Main">Main Promotion</option>
                                                                            <option value="Offer">Offer Promotion</option>
                                                                        </select>
                                                                    </div>
                                                                    : null
                                                            }
                                                            <div className="form-group">
                                                                <label>ราคา</label>
                                                                <div className="input-group">
                                                                    <input type={"number"} className={"form-control"} name="price"
                                                                        defaultValue={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.summary
                                                                                && this.state.data.option.summary.total ?
                                                                                this.state.data.option.summary.total : 10
                                                                        }
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">฿</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label>ประเภทการชำระเงิน</label>
                                                                <select className={"form-control"} name="paymentType"
                                                                    defaultValue={this.state.data && this.state.data.option && this.state.data.option.paymentType ? this.state.data.option.paymentType : null}
                                                                    onChange={(ref) => {
                                                                        if (ref.target.value === "dateexpire" || ref.target.value === "expirebillingcycle") {
                                                                            this.expireValue.show();
                                                                        }
                                                                        if (ref.target.value === "dateexpire") {
                                                                            this.expireValue.find("label").find('span').text("วัน");
                                                                            this.expireValue.find(".input-group-text").text("วัน");
                                                                        }
                                                                        if (ref.target.value === "expirebillingcycle") {
                                                                            this.expireValue.find("label").find('span').text("เดือน");
                                                                            this.expireValue.find(".input-group-text").text("เดือน");
                                                                        }
                                                                        if (ref.target.value === "billcycle") {
                                                                            this.expireValue.hide();
                                                                            this.expireValue.find("input[name='expireValue']").val(0)
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="billcycle">รายเดือน</option>
                                                                    {/* <option value="dateexpire">กำหนด จำนวนวัน สิ้นสุด</option>
                                                                    <option value="expirebillingcycle">กำหนด จำนวนเดือน สิ้นสุด</option> */}
                                                                </select>
                                                            </div>
                                                            <div className={"form-group"} ref={(ref) => this.expireValue = window.$(ref)}
                                                                style={{
                                                                    display:
                                                                        this.state.data
                                                                            && this.state.data.option
                                                                            && this.state.data.option.paymentType
                                                                            && (this.state.data.option.paymentType === 'dateexpire' || this.state.data.option.paymentType === 'expirebillingcycle') ? 'block' : 'none'
                                                                }}
                                                            >
                                                                <label>จำนวน <span>
                                                                    {
                                                                        this.state.data
                                                                            && this.state.data.option
                                                                            && this.state.data.option.paymentType
                                                                            && this.state.data.option.paymentType === 'dateexpire' ?
                                                                            "วัน"
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.state.data
                                                                            && this.state.data.option
                                                                            && this.state.data.option.paymentType
                                                                            && this.state.data.option.paymentType === 'expirebillingcycle' ?
                                                                            "เดือน"
                                                                            : null
                                                                    }
                                                                </span> สิ้นสุด</label>
                                                                <div className="input-group">
                                                                    <input type={"number"} className={"form-control"} name="duration"
                                                                        defaultValue={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.duration ?
                                                                                this.state.data.option.duration : 10
                                                                        }
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">
                                                                            {
                                                                                this.state.data
                                                                                    && this.state.data.option
                                                                                    && this.state.data.option.paymentType
                                                                                    && this.state.data.option.paymentType === 'dateexpire' ?
                                                                                    "วัน"
                                                                                    : null
                                                                            }
                                                                            {
                                                                                this.state.data
                                                                                    && this.state.data.option
                                                                                    && this.state.data.option.paymentType
                                                                                    && this.state.data.option.paymentType === 'expirebillingcycle' ?
                                                                                    "เดือน"
                                                                                    : null
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input type="hidden" name={"isVisibleMainSite"} value="true" />
                                                            <input type="hidden" name={"isVisibleStore"} value="true" />
                                                            {/* <div className="custom-control custom-switch">
                                                                <input type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="isVisibleMainSite"
                                                                    name={"isVisibleMainSite"}
                                                                    defaultValue={"true"}
                                                                    defaultChecked={
                                                                        this.state.data
                                                                            && this.state.data.isVisibleMainSite ?
                                                                            this.state.data.isVisibleMainSite : false
                                                                    }
                                                                />
                                                                <label className="custom-control-label" htmlFor="isVisibleMainSite">แสดงบนหน้าเว็บ</label>
                                                            </div>
                                                            <div className="custom-control custom-switch">
                                                                <input type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="isVisibleStore"
                                                                    name={"isVisibleStore"}
                                                                    defaultValue={"true"}
                                                                    defaultChecked={
                                                                        this.state.data
                                                                            && this.state.data.isVisibleStore ?
                                                                            this.state.data.isVisibleStore : false
                                                                    }
                                                                />
                                                                <label className="custom-control-label" htmlFor="isVisibleStore">แสดงเป็นตัวเลือกของร้านค้า</label>
                                                            </div> */}

                                                            {/* <div className="custom-control custom-switch">
                                                                <input type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="isOnlyPerson"
                                                                    name={"isOnlyPerson"}
                                                                    defaultValue={"true"}
                                                                    defaultChecked={
                                                                        this.state.data
                                                                            && this.state.data.option
                                                                            && this.state.data.option.isOnlyPerson ?
                                                                            this.state.data.option.isOnlyPerson : false
                                                                    }
                                                                    onChange={(ref) => {
                                                                        let _ref = window.$(ref.target);
                                                                        if (_ref.prop("checked") === true) {
                                                                            this.personList.show();
                                                                        }
                                                                        else if (_ref.prop("checked") === false) {
                                                                            this.personList.hide();
                                                                        }
                                                                    }}
                                                                />
                                                                <label className="custom-control-label" htmlFor="isOnlyPerson">แสดงเฉพาะบุคคล</label>
                                                            </div>
                                                            <div ref={(ref) => { this.personList = window.$(ref) }} style={{
                                                                display: this.state.data
                                                                    && this.state.data.option
                                                                    && this.state.data.option.isOnlyPerson ? 'block' : 'none'
                                                            }}>
                                                                <div className="form-group">
                                                                    <label>เลือกสมาชิก</label>
                                                                    <div>
                                                                        <select
                                                                            ref={(ref) => { window.$(ref).select2(); }}
                                                                            className={"form-control select2"}
                                                                            style={{ width: '100%' }}
                                                                            onChange={(e) => {
                                                                                if (e.target.value === "" || !e.target.value) { return; }
                                                                                let selected_provider = [...this.state.selected_provider];
                                                                                let _data = false;
                                                                                this.state.providers.map((provider) => {
                                                                                    if (provider.uid === e.target.value) {
                                                                                        _data = {
                                                                                            uid: provider.uid,
                                                                                            displayName: provider.displayName,
                                                                                        }
                                                                                    }
                                                                                })
                                                                                selected_provider.push(_data);
                                                                                this.setState({
                                                                                    selected_provider: selected_provider,
                                                                                },()=> {
                                                                                    console.log(this.state.selected_provider)
                                                                                })
                                                                            }}
                                                                            value={""}
                                                                        >
                                                                            <option value="">เลือก</option>
                                                                            {
                                                                                this.state.providers.map((provider, provider_i) => {
                                                                                    return (<option value={provider.uid} key={provider_i}>{provider.displayName}</option>)
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            {
                                                                this.state.selected_provider
                                                                    && this.state.selected_provider.length > 0 ?
                                                                    <div>
                                                                        {this.state.selected_provider.map((selected_provider, selected_provider_i) => {
                                                                            return (<>
                                                                                <label>{selected_provider.displayName}</label>
                                                                            </>);
                                                                        })}
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                this.props.mode === 'edit' ?
                                                                    <div className="custom-control custom-switch">
                                                                        <input type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="isActive"
                                                                            name={"isActive"}
                                                                            defaultValue={"true"}
                                                                            defaultChecked={
                                                                                this.state.data
                                                                                    && this.state.data.option
                                                                                    && this.state.data.option.isActive ?
                                                                                    this.state.data.option.isActive : false
                                                                            }
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="isActive">ใช้งาน</label>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className={"col-12 col-md-3"}>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="card-title">Features</div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className={"form-group"}>
                                                                <div className="custom-control custom-switch mb-2" >
                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="haveTimeline"
                                                                        name={"haveTimeline"}
                                                                        defaultValue={"true"}
                                                                        defaultChecked={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.features
                                                                                && this.state.data.option.features.haveTimeline ?
                                                                                this.state.data.option.features.haveTimeline
                                                                                : false
                                                                        }
                                                                        onChange={(ref) => {
                                                                            let _ref = window.$(ref.target);
                                                                            if (_ref.prop("checked") === true) {
                                                                                this.postOptions.show();
                                                                            }
                                                                            else if (_ref.prop("checked") === false) {
                                                                                this.postOptions.hide();
                                                                                this.postOptions.find('input[type="checkbox"]').prop('checked', false)
                                                                            }
                                                                        }}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="haveTimeline">ระบบโพส</label>
                                                                </div>
                                                                <div ref={(ref) => { this.postOptions = window.$(ref) }} style={{
                                                                    display: this.state.data
                                                                        && this.state.data.option
                                                                        && this.state.data.option.features
                                                                        && this.state.data.option.features.haveTimeline
                                                                        && this.state.data.option.features.haveTimeline === true ? "block" : 'none', paddingLeft: '1rem'
                                                                }}>
                                                                    {/* <div className="custom-control custom-switch mb-2">
                                                                        <input type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="canGuestPostTimeline"
                                                                            name={"canGuestPostTimeline"}
                                                                            defaultValue={"true"}
                                                                            defaultChecked={
                                                                                this.state.data
                                                                                    && this.state.data.option
                                                                                    && this.state.data.option.features
                                                                                    && this.state.data.option.features.canGuestPostTimeline ?
                                                                                    this.state.data.option.features.canGuestPostTimeline
                                                                                    : false
                                                                            }
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="canGuestPostTimeline">ผู้ใช้งาน <span className="text-danger">*ยังใช้งานไม่ได้</span></label>
                                                                    </div> */}
                                                                    <div className="custom-control custom-switch mb-2">
                                                                        <input type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="canStorePostTimeline"
                                                                            name={"canStorePostTimeline"}
                                                                            defaultValue={"true"}
                                                                            defaultChecked={
                                                                                this.state.data
                                                                                    && this.state.data.option
                                                                                    && this.state.data.option.features
                                                                                    && this.state.data.option.features.canStorePostTimeline ?
                                                                                    this.state.data.option.features.canStorePostTimeline
                                                                                    : false
                                                                            }
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="canStorePostTimeline">ร้าน</label>
                                                                    </div>
                                                                    <div className="custom-control custom-switch mb-2">
                                                                        <input type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="canStoreRemoveTimeline"
                                                                            name={"canStoreRemoveTimeline"}
                                                                            defaultValue={"true"}
                                                                            defaultChecked={
                                                                                this.state.data
                                                                                    && this.state.data.option
                                                                                    && this.state.data.option.features
                                                                                    && this.state.data.option.features.canStoreRemoveTimeline ?
                                                                                    this.state.data.option.features.canStoreRemoveTimeline
                                                                                    : false
                                                                            }
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="canStoreRemoveTimeline">ลบโพส</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"form-group"}>
                                                                <div className="custom-control custom-switch mb-2" >
                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="canKickMember"
                                                                        name={"canKickMember"}
                                                                        defaultValue={"true"}
                                                                        defaultChecked={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.features
                                                                                && this.state.data.option.features.canKickMember ?
                                                                                this.state.data.option.features.canKickMember
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="canKickMember">บังคับผู้ใช้งานออกจากระบบ (เตะผู้ใช้งาน)</label>
                                                                </div>
                                                            </div>
                                                            {/* <div className={"form-group"}>
                                                                <div className="custom-control custom-switch mb-2" >
                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="haveBanUser"
                                                                        name={"haveBanUser"}
                                                                        defaultValue={"true"}
                                                                        defaultChecked={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.features
                                                                                && this.state.data.option.features.haveBanUser ?
                                                                                this.state.data.option.features.haveBanUser
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="haveBanUser">แบนผู้ใช้งาน <span className="text-danger">*ยังใช้งานไม่ได้</span></label>
                                                                </div>
                                                            </div> */}
                                                            <div className={"form-group"}>
                                                                <div className="custom-control custom-switch mb-2" >
                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="canManageEvent"
                                                                        name={"canManageEvent"}
                                                                        defaultValue={"true"}
                                                                        defaultChecked={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.features
                                                                                && this.state.data.option.features.canManageEvent ?
                                                                                this.state.data.option.features.canManageEvent
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="canManageEvent">ระบบข่าวสารของร้าน</label>
                                                                </div>
                                                            </div>
                                                            <div className={"form-group"}>
                                                                <div className="custom-control custom-switch mb-2" >
                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="havePermissionStore"
                                                                        name={"havePermissionStore"}
                                                                        defaultValue={"true"}
                                                                        defaultChecked={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.features
                                                                                && this.state.data.option.features.havePermissionStore ?
                                                                                this.state.data.option.features.havePermissionStore
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="havePermissionStore">ระบบจัดการผู้ดูแลระบบและสิทธิ์การเข้าถึง</label>
                                                                </div>
                                                            </div>
                                                            {/* <div className={"form-group"}>
                                                                <div className="custom-control custom-switch mb-2" >
                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="haveSkitz"
                                                                        name={"haveSkitz"}
                                                                        defaultValue={"true"}
                                                                        defaultChecked={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.features
                                                                                && this.state.data.option.features.haveSkitz ?
                                                                                this.state.data.option.features.haveSkitz
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="haveSkitz">ระบบสกิดบนแอพพลิเคชั่น <i className="fas fa-mobile-alt"></i></label>
                                                                </div>
                                                            </div> */}
                                                            <div className={"form-group"}>
                                                                <div className="custom-control custom-switch mb-2" >
                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="isPreviewMemberProfileStore"
                                                                        name={"isPreviewMemberProfileStore"}
                                                                        defaultValue={"true"}
                                                                        defaultChecked={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.features
                                                                                && this.state.data.option.features.isPreviewMemberProfileStore ?
                                                                                this.state.data.option.features.isPreviewMemberProfileStore
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="isPreviewMemberProfileStore">ดูรูปโปรไฟล์ผู้ใช้งาน</label>
                                                                </div>
                                                            </div>
                                                            <div className={"form-group"}>
                                                                <div className="custom-control custom-switch mb-2" >
                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="haveArtistLive"
                                                                        name={"haveArtistLive"}
                                                                        defaultValue={"true"}
                                                                        defaultChecked={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.features
                                                                                && this.state.data.option.features.haveArtistLive ?
                                                                                this.state.data.option.features.haveArtistLive
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="haveArtistLive">ระบบนักดนตรี/Live</label>
                                                                </div>
                                                            </div>
                                                            <div className={"form-group"}>
                                                                <div className="custom-control custom-switch mb-2" >
                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="haveMenu"
                                                                        name={"haveMenu"}
                                                                        defaultValue={"true"}
                                                                        defaultChecked={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.features
                                                                                && this.state.data.option.features.haveMenu ?
                                                                                this.state.data.option.features.haveMenu
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="haveMenu">ระบบเมนู</label>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label>จำนวนผู้เข้าสู่ระบบสูงสุด</label>
                                                                <div className="input-group">
                                                                    <input type={"number"}
                                                                        className={"form-control"}
                                                                        name="onlineCapacity"
                                                                        defaultValue={
                                                                            this.state.data
                                                                                && this.state.data.option
                                                                                && this.state.data.option.features
                                                                                && this.state.data.option.features.onlineCapacity ?
                                                                                this.state.data.option.features.onlineCapacity : 10
                                                                        } />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">คน</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                            : null
                                    }
                                    {
                                        !this.state.is_loading ?
                                            <div className="flex row">
                                                <Link to={"/package"} className="btn btn-default btn-lg" style={{ marginRight: 15 }}>ยกเลิก</Link>
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <input name="freeDate" type="hidden" value={"0"} />
                                                    {
                                                        this.props.mode === 'edit'
                                                            && this.state.data
                                                            && this.state.data.option ?
                                                            <input type="hidden" name="isMain" value={this.state.data.option.isMain} />
                                                            : null
                                                    }
                                                    {
                                                        this.props.mode === 'edit' ?
                                                            <>
                                                                <input name="packageUid" type="hidden" value={this.props.packageUid} />
                                                                <input name="uid" type="hidden" value={this.props.uid} />
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        this.props.mode === 'create' ?
                                                            <input name="isActive" type="hidden" value={"true"} />
                                                            : null
                                                    }
                                                    <button type="submit"
                                                        className="btn btn-primary btn-lg"
                                                        ref={(ref) => { this.submit = window.$(ref) }}
                                                    >
                                                        {this.props.mode === 'create' ? 'เพิ่ม' : null}
                                                        {this.props.mode === 'edit' ? 'บันทึก' : null}
                                                    </button>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </form>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}