import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../../../Helper';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pagination from '../../../Components/Pagination';
const axios = require('axios').default;
export default class StoreUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            limit: 15,
            users: [],
            is_loading: true,
            
            all_users: props.data && props.data.all_users ? props.data.all_users : 0,
            count_live: props.data && props.data.count_live ? props.data.count_live : 0,
            count_average_per_day: props.data && props.data.count_average_per_day ? props.data.count_average_per_day : 0,
        }
    }
    componentDidMount() {
        this.fetchUser();
    }
    async fetchUser(page) {
        let _page = 1;
        if (page) {
            _page = page
        }
        let data = {
            uid: this.props.uid,
            page: _page,
            limit: 12,
        }
        let res = await axios.get(window.api_host + '/store-guest?' + window.$.param(data));
        let users = [];
        let count_live = 0;
        let count_average_per_day = 0;
        let all_users = 0;
        if (res.data.success) {
            users = res.data.response.users;
            all_users = res.data.response.all_users;
            count_live = res.data.response.count_live || 0;
            count_average_per_day = res.data.response.count_average_per_day || 0;
        }
        this.setState({
            users: users,
            all_users: all_users,
            count_live: count_live,
            count_average_per_day: count_average_per_day,
            is_loading: false,
        })
    }


    render() {
        return (
            <div className="row" style={{ position: 'relative' }}>
                {
                    this.props.is_loading
                        || this.state.is_loading ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                <div className="col-12 col-md-3">
                    <div>
                        <h6 style={{ fontWeight: 'bold' }}>ข้อมูลจำนวนสมาชิก</h6>
                        <ul className="list-group list-group-unbordered no-border-lastchild mb-3">
                            <li className="list-group-item">
                                <b>สมาชิกทั้งหมด</b> <div className="float-right">{Helper.numberFormat(this.state.all_users)}</div>
                            </li>
                            <li className="list-group-item">
                                <b>สมาชิกวันนี้</b> <div className="float-right">{Helper.numberFormat(this.state.count_live)}</div>
                            </li>
                            <li className="list-group-item">
                                <b>สมาชิกเฉลี่ยต่อวัน</b> <div className="float-right">{Helper.numberFormat(this.state.count_average_per_day.toFixed(2))}</div>
                            </li>
                        </ul>
                    </div>
                    {/* <div>
                        <h6 style={{ fontWeight: 'bold' }}>วันยอดนิยม</h6>
                        <hr style={{ marginTop: 0 }} />
                        <DateProgress title="วันจันทร์" progress="40" amount="40" />
                        <DateProgress title="วันอังคาร" progress="30" amount="30" />
                        <DateProgress title="วันพุธ" progress="20" amount="20" />
                        <DateProgress title="วันพฤหัส" progress="30" amount="30" />
                        <DateProgress title="วันศุกร์" progress="80" amount="80" />
                        <DateProgress title="วันเสาร์" progress="100" amount="100" />
                        <DateProgress title="วันอาทิตย์" progress="100" amount="100" />
                    </div> */}
                </div>
                <div className="col-12 col-md-9">
                    <ul className="users-list clearfix">
                        {
                            this.state.all_users > 0 ?
                                this.state.users.map((user, user_i) => {
                                    return (
                                        <li style={{ width: '20%' }} key={user_i}>
                                            <div className="profile-user-img img-frame img-fluid img-circle">
                                                <img src={user.photoURL || "/assets/images/no_img.png"} alt="User" />
                                            </div>
                                            <Link className="users-list-name" to={'/member/view/' + user.uid}>{user.displayName}</Link>
                                            <span className="users-list-date">{Helper.getDateThai(user.createdAt)}</span>
                                        </li>
                                    )
                                })
                                : null
                        }
                    </ul>
                    <Pagination
                        now={this.state.page}
                        all_items={this.state.all_users}
                        limit={this.state.limit}
                        onReturnPage={(page) => {
                            this.setState({
                                page: page,
                                is_loading: true,
                            }, () => {
                                this.fetchUser(this.state.page);
                            })
                        }}
                        location={this.props.location}
                    />
                </div>
            </div>
        );
    }
}