import React from 'react';
import { Link } from '@reach/router'
import { Helper } from '../Helper';
import { db } from '../firebase';
import { getSetting, updateSetting } from '../services/SettingsServices';
const axios = require('axios').default;

export default class AppSetting extends React.Component {
    constructor() {
        super();
        this.state = {
            setting: false,
            is_loading: true,
            skitz_setting: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let settingRef = await db.doc('/constants/settings').get();
        let settingData = await settingRef.data();
        let res = await getSetting();
        let skitz_setting = false;
        if (!res.error) {
            skitz_setting = res;
        }
        console.log(skitz_setting)
        this.setState({
            setting: settingData,
            is_loading: false,
            skitz_setting: skitz_setting,
        })
    }

    render() {
        let skitz_setting = this.state.skitz_setting;
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการ App</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการ App</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {/* <button type="button" className={'btn btn-default'} onClick={()=> {this.transferData()}}>transferData</button> */}
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <div className="card">
                                    {
                                        this.state.is_loading ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                <div className="ui active inline loader small" ></div>
                                            </div>
                                            : null
                                    }
                                    {
                                        !this.state.is_loading ?
                                            <>
                                                <div className="card-header">
                                                    <div className="flex row">
                                                        <div className="card-title">จัดการ App</div>
                                                        <div className="right-floated">
                                                            <div className="link"
                                                                onClick={() => {
                                                                    Helper.reactToDom(
                                                                        window.$('body'),
                                                                        <SettingForm
                                                                            setting={this.state.setting}
                                                                            onSuccess={() => {
                                                                                this.fetch();
                                                                            }}
                                                                        />
                                                                    )
                                                                }}
                                                            >แก้ไข</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="flex row">
                                                        <label>OTPExpireMin</label>
                                                        <div className="right-floated">{this.state.setting.OTPExpireMin}</div>
                                                    </div>
                                                    <div className="flex row">
                                                        <label>isDisableLoginButton</label>
                                                        <div className="right-floated">
                                                            {this.state.setting.isDisableLoginButton ? <i className="fas fa-check" style={{ color: 'green' }} /> : <i className="fas fa-times" style={{ color: 'red' }} />}
                                                        </div>
                                                    </div>
                                                    <div className="flex row">
                                                        <label>isProd</label>
                                                        <div className="right-floated">
                                                            {this.state.setting.isProd ? <i className="fas fa-check" style={{ color: 'green' }} /> : <i className="fas fa-times" style={{ color: 'red' }} />}
                                                        </div>
                                                    </div>
                                                    <div className="flex row">
                                                        <label>recoverSkitzTimeSec</label>
                                                        <div className="right-floated">{this.state.setting.recoverSkitzTimeSec}</div>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }

                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="card">
                                    {
                                        this.state.is_loading ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                <div className="ui active inline loader small" ></div>
                                            </div>
                                            : null
                                    }
                                    {
                                        !this.state.is_loading ?
                                            <>
                                                <div className="card-header">
                                                    <div className="flex row">
                                                        <div className="card-title">จัดการ App (Skitz API)</div>
                                                        <div className="right-floated">
                                                            <div className="link"
                                                                onClick={() => {
                                                                    Helper.reactToDom(
                                                                        window.$('body'),
                                                                        <SettingSkitzForm
                                                                            skitz_setting={this.state.skitz_setting}
                                                                            onSuccess={() => {
                                                                                this.fetch();
                                                                            }}
                                                                        />
                                                                    )
                                                                }}
                                                            >แก้ไข</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        this.state.skitz_setting ?
                                                            Object.keys(this.state.skitz_setting).map(function (key) {
                                                                if (key != 'id') {
                                                                    return (
                                                                        <div className="flex row">
                                                                            <label>{key}</label>
                                                                            <div className="right-floated">{skitz_setting[key]}</div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                            : null
                                                    }

                                                </div>
                                            </>
                                            : null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class SettingSkitzForm extends React.Component {
    async update(data) {
        let res = await updateSetting(data);
        if (res.error) {
            this.$submit.removeClass('loading');
        } else {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            this.$modal.modal('hide');
            this.props.onSuccess()
        }
    }

    render() {
        console.log(this.props)
        let skitz_setting = this.props.skitz_setting;
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                        this.$form = this.$modal.find('form');
                        this.$submit = this.$form.find('button[type="submit"]');
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (this.$submit.hasClass('loading')) { return false; }
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            // console.log(data);
                            // return;
                            this.update(data);

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูล</h4>
                            </div>
                            <div className="modal-body">
                                {
                                    this.props.skitz_setting ?
                                        Object.keys(this.props.skitz_setting).map(function (key) {
                                            if (key != 'id') {
                                                return (
                                                    <div className="form-group">
                                                        <label>{key}</label>
                                                        <input type="number" className="form-control" name={key} defaultValue={skitz_setting[key]} />
                                                    </div>
                                                )
                                            }
                                        })
                                        : null
                                }
                                {/* <div className="form-group">
                                    <label>OTPExpireMin</label>
                                    <input type="number" className="form-control" name="OTPExpireMin" defaultValue={this.props.setting.OTPExpireMin} />
                                </div> */}
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

class SettingForm extends React.Component {
    async update(data) {
        let update_data = {
            ...data,
            isDisableLoginButton: false,
            isProd: false,
        }
        if (data.isDisableLoginButton) {
            update_data.isDisableLoginButton = true;
        }
        if (data.isProd) {
            update_data.isProd = true;
        }
        let res = await axios.post(window.api_host + '/upadte-app-setting', { update_data: update_data });
        // console.log(res)
        if (res.data.success) {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            this.$modal.modal('hide');
            this.props.onSuccess()
        }
        if (res.data.error) {
            this.$submit.removeClass('loading');
        }
    }

    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                        this.$form = this.$modal.find('form');
                        this.$submit = this.$form.find('button[type="submit"]');
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (this.$submit.hasClass('loading')) { return false; }
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            // console.log(data);
                            // return;
                            this.update(data);

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลผู้ดูแล</h4>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>OTPExpireMin</label>
                                    <input type="number" className="form-control" name="OTPExpireMin" defaultValue={this.props.setting.OTPExpireMin} />
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isDisableLoginButton"
                                            name={"isDisableLoginButton"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.props.setting.isDisableLoginButton
                                                    ? true : false
                                            }
                                        />
                                        <label className="custom-control-label" htmlFor="isDisableLoginButton">isDisableLoginButton</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isProd"
                                            name={"isProd"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.props.setting.isProd
                                                    ? true : false
                                            }
                                        />
                                        <label className="custom-control-label" htmlFor="isDisableLoginButton">isProd</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>recoverSkitzTimeSec</label>
                                    <input type="number" className="form-control" name="recoverSkitzTimeSec" defaultValue={this.props.setting.recoverSkitzTimeSec} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}