import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper, RankTranslate } from './../../Helper'
import { title } from 'process';
const axios = require('axios').default;

export default class Form extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            default_permission: [],
            rank_info: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        if (this.props.id) {
            let qs = {};
            qs.id = this.props.id;
            let res = await axios.get(`${window.api_host}/rank?${window.$.param(qs)}`);
            let rank_info = false;
            let default_permission = [];
            if (res.data.success && res.data.data.length > 0) {
                rank_info = res.data.data[0];
                default_permission = res.data.default_permission;
            }
            this.setState({
                is_loading: false,
                data: rank_info,
                default_permission: default_permission,
            })
        }
        if (!this.props.id) {
            let res = await axios.get(`${window.api_host}/default-rank`);
            if (res.data.success) {
                this.setState({
                    is_loading: false,
                    default_permission: res.data.default_permission
                })
            }
        }
    }

    async update(data) {
        let ref = await axios.put(`${window.api_host}/rank`, { data: data });
        if (ref.data.success) {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            navigate('/rank/' + ref.data.uid);
        }
        if (ref.data.error) {
            let message = {
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 5000,
            }
            message.title = ref.data.message;
            // console.log(ref.data);
            if (ref.data.description) {
                message.body = ref.data.description
            }
            window.$(document).Toasts('create', { ...message });
            this.submit.removeClass('loading');
        }
    }
    async create(data) {
        // console.log(data);
        // return;
        let ref = await axios.post(`${window.api_host}/rank`, { ...data });
        if (ref.data.success) {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            navigate('/rank/' + ref.data.uid);
        }
        if (ref.data.error) {
            let message = {
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 5000,
            }
            message.title = ref.data.message;
            // console.log(ref.data);
            if (ref.data.description) {
                message.body = ref.data.description
            }
            window.$(document).Toasts('create', { ...message });
            this.submit.removeClass('loading');
        }
    }

    prepareData(form_data) {
        let all_object = {};
        for (const item of this.state.default_permission) {
            let value = form_data[item.name] || false;
            if (item.type === 'object') {
                all_object[item.name] = {};
                for (const _item of item.value) {
                    let arr = _item.name.split('_inobject_');
                    // console.log(arr)
                    all_object[item.name][arr[0]] = form_data[_item.name + '_inobject_' + item.name] ? JSON.parse(form_data[_item.name + '_inobject_' + item.name]) : false;

                }
            }
            if (item.type === 'number') {
                if (!value) { value = 0 }
                value = parseFloat(value)
                all_object[item.name] = value;
            }
            if (item.type === 'boolean') {
                value = form_data[item.name] ? JSON.parse(value) : false
                all_object[item.name] = value;
            }
            if (item.type === 'string') {
                all_object[item.name] = value;
            }
        }
        // console.log("form_data",form_data)
        // console.log("all_object",all_object)
        // return null;
        return all_object
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Rank</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    {
                                        this.props.id ?
                                            <>
                                                <li className="breadcrumb-item"><Link to="/rank">Rank</Link></li>
                                                <li className="breadcrumb-item"><Link to={"/rank/" + this.props.id}>{this.props.id}</Link></li>
                                                <li className="breadcrumb-item active">แก้ไข</li>
                                            </>
                                            :
                                            <>
                                                <li className="breadcrumb-item"><Link to="/rank">Rank</Link></li>
                                                <li className="breadcrumb-item active">เพิ่ม</li>
                                            </>
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <form
                            ref={(ref) => this.$form = window.$(ref)}
                            onSubmit={async (e) => {
                                e.preventDefault();
                                if (this.submit.hasClass('loading')) { return false; }
                                this.submit.addClass('loading');
                                let form_data = Helper.getFormInputObject(this.$form);
                                let prepare_data = this.prepareData(form_data);
                                prepare_data.name = form_data.name;
                                if (this.props.id) {
                                    this.update(prepare_data);
                                }
                                if (!this.props.id) {
                                    this.create(prepare_data);
                                }

                            }}
                        >


                            {
                                this.state.is_loading ?

                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    {
                                                        this.props.id ?
                                                            <input type="hidden" name={"name"} defaultValue={this.props.id} />
                                                            :
                                                            <div className={"form-group"}>
                                                                <label>Rank Name</label>
                                                                <input type="text" className="form-control" name={"name"}
                                                                    defaultValue={this.props.name || ''} />
                                                            </div>
                                                    }
                                                    {
                                                        !this.state.is_rank_info_loading && this.state.default_permission ?
                                                            this.state.default_permission.map((item, key) => {
                                                                if (item.type !== 'number' && item.type !== 'string') { return null };
                                                                if (item.type === 'string') {
                                                                    const selectOptions = item.value.split(",");
                                                                    return (
                                                                        <div className={"form-group"} key={key}>
                                                                            <label>{RankTranslate[item.name].th}</label>
                                                                            <select className={'form-control'}
                                                                                name={item.name}
                                                                                defaultValue={this.state.data && this.state.data[item.name] !== undefined ? this.state.data[item.name] : null}
                                                                            >
                                                                                {
                                                                                    selectOptions.map((option, option_i) => {
                                                                                        return (
                                                                                            <option key={option_i} value={option}>{option}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    );
                                                                }
                                                                return (
                                                                    <div className={"form-group"} key={key}>
                                                                        <label>{RankTranslate[item.name].th}</label>
                                                                        <input type="text" className="form-control" name={item.name}
                                                                            defaultValue={this.state.data && this.state.data[item.name] !== undefined ? this.state.data[item.name] : item.value} />
                                                                    </div>
                                                                )
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    {
                                                        !this.state.is_rank_info_loading && this.state.default_permission ?
                                                            this.state.default_permission.map((item, key) => {
                                                                if (item.type !== 'boolean' && item.type !== 'object') return null;
                                                                return (
                                                                    <div key={key} className={"flex row middle-xs"}>
                                                                        {
                                                                            item.type === 'boolean' ?
                                                                                <CheckBox
                                                                                    name={item.name}
                                                                                    inputKeywordName={item.name}
                                                                                    checked={this.state.data && this.state.data[item.name] !== undefined ? JSON.parse(this.state.data[item.name]) : JSON.parse(item.value)}
                                                                                />
                                                                                : null
                                                                        }
                                                                        {
                                                                            item.type === 'object' ?
                                                                                <div>
                                                                                    <label style={{ marginBottom: 5 }}>{RankTranslate[item.name].th}</label>
                                                                                    {
                                                                                        item.value.map((_item, _item_i) => {
                                                                                            return (
                                                                                                <div key={_item_i}
                                                                                                    style={{ paddingLeft: 15 }}
                                                                                                >
                                                                                                    <CheckBox
                                                                                                        name={_item.name}
                                                                                                        inputKeywordName={_item.name + '_inobject_' + item.name}
                                                                                                        checked={this.state.data && this.state.data[item.name][_item.name] !== undefined ? JSON.parse(this.state.data[item.name][_item.name]) : JSON.parse(_item.value)}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="right-floated">

                                                <Link to={this.props.id ? "/rank/" + this.props.id : "/rank"}
                                                    className="btn btn-default btn-lg"
                                                    style={{ marginRight: 15 }}
                                                >ยกเลิก</Link>
                                                <button type="submit"
                                                    className="btn btn-primary btn-lg"
                                                    ref={(ref) => { this.submit = window.$(ref) }}
                                                >
                                                    {!this.props.id ? 'เพิ่ม' : null}
                                                    {this.props.id ? 'บันทึก' : null}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </form>
                    </div>
                </section>
            </>
        )
    }
}
class CheckBox extends React.Component {
    render() {
        // console.log(this.props)
        return (
            <div>
                <div className="custom-control custom-switch mb-2" >
                    <input type="checkbox"
                        className="custom-control-input"
                        id={this.props.inputKeywordName}
                        name={this.props.inputKeywordName}
                        defaultValue={"true"}
                        defaultChecked={this.props.checked}
                    />
                    <label className="custom-control-label" htmlFor={this.props.inputKeywordName}>{RankTranslate[this.props.name].th}</label>
                </div>
            </div>
        );
    }
}

class CheckBoxGroup extends React.Component {
    setSelectAll(ref) {
        let _ref = window.$(ref.target);
        if (_ref.prop("checked") === true) {
            _ref.parents('.input-field').find('input[type="checkbox"]').prop('checked', true);
        }
        else if (_ref.prop("checked") === false) {
            _ref.parents('.input-field').find('input[type="checkbox"]').prop('checked', false);
        }
    }
    checkSelectall(ref) {
        let _ref = window.$(ref.target);
        if (_ref.prop("checked") === true) {
            let count_all_checked_input = _ref.parents('.radio-list').find('input[type="checkbox"]:checked').length;
            let count_checked_input = _ref.parents('.radio-list').find('input[type="checkbox"]:checked').length;
            if (count_all_checked_input === count_checked_input) {
                _ref.parents('.input-field').find('.main-input-checkbox').find('input[type="checkbox"]').prop('checked', true);
            }
        }
        else if (_ref.prop("checked") === false) {
            _ref.parents('.input-field').find('.main-input-checkbox').find('input[type="checkbox"]').prop('checked', false);
        }
    }
    render() {
        console.log(this.props)
        return (
            <>
                <div className={"form-group input-field"}>
                    <div className="custom-control custom-switch main-input-checkbox">
                        <label className="custom-control-label" htmlFor={this.props.inputKeywordName + "_group"}>{this.props.title}</label>
                    </div>
                    <div className="flex row radio-list">
                        {
                            this.props.inputArray ?
                                this.props.inputArray.map((input, input_i) => {
                                    return (
                                        <div key={input_i} className="custom-control custom-switch">
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id={"add_" + this.props.inputKeywordName + "_permission"}
                                                name={"add_" + this.props.inputKeywordName + "_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={
                                                    this.props.permission
                                                        && this.props.permission.add ? this.props.permission.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor={`${input.name}_in_${this.props.inputKeywordName}`}>{input.name}</label>
                                        </div>
                                    )
                                })
                                : null
                        }

                    </div>
                </div>

            </>
        )
    }
}