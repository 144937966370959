import React from 'react';
import { Link } from '@reach/router';
// const NavLink = props => (
//   <Link
//     {...props}
//     getProps={({ isCurrent }) => {
//       return {
//         className: isCurrent ? "item active" : "item"
//       };
//     }}
//   />
// );
export default class SideBar extends React.Component {
  componentDidMount() {
    window.$(document).ready(() => {
      window.$('[data-widget="treeview"]').Treeview('init')
    });
  }

  componentDidUpdate() {
    // console.log(this.props.location)
  }

  currentLink = ({ isCurrent }) => {
    return {
      className: isCurrent ? "nav-link active" : "nav-link"
    };
  }

  render() {
    let user = this.props.user;
    // console.log(user.permission)
    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to={"/dashboard"} className="brand-link navbar-dark-gray" style={{ paddingTop: 3, paddingBottom: 3 }}>
          <img src={window.location.origin + "/assets/images/skitz-white.png"} alt="Skitz Logo" style={{ maxWidth: '100%', maxHeight: 40 }} />
        </Link>
        <div className="sidebar ">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link"
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent || window.location.pathname === "/" || window.location.pathname === "" ? "nav-link active" : "nav-link"
                    };
                  }}
                >
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>หน้าหลัก</p>
                </Link>
              </li>
              {
                user.permission
                  && user.permission.store
                  && (
                    user.permission.store.add
                    || user.permission.store.edit
                    || user.permission.store.delete
                    || user.permission.store.view
                  )
                  ?
                  <>
                    <li className="nav-item">
                      <Link to="/store" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/store/') !== -1
                              || window.location.pathname.indexOf('/transection/') !== -1
                              || window.location.pathname.indexOf('/store-types') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-store"></i>
                        <p>ร้านค้า</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/register-store" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/register-store/') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-handshake"></i>
                        <p>ลงทะเบียน</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/place" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/place') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-map-marked-alt"></i>
                        <p>สถานที่</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/recomment-place" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/recomment-place') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-thumbs-up"></i>
                        <p>แนะนำสถานที่</p>
                      </Link>
                    </li>
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.member
                  && (
                    user.permission.member.add
                    || user.permission.member.edit
                    || user.permission.member.delete
                    || user.permission.member.view
                  )
                  ?
                  <li className="nav-item">
                    <Link to="/member" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent
                            || window.location.pathname.indexOf('/member/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon nav-icon fas fa-users"></i>
                      <p>สมาชิก</p>
                    </Link>
                  </li>
                  : null
              }
              {
                user.permission
                  && user.permission.brand
                  && (
                    user.permission.brand.add
                    || user.permission.brand.edit
                    || user.permission.brand.delete
                    || user.permission.brand.view
                  )
                  ?
                  <li className="nav-item">
                    <Link to="/artist" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent
                            || window.location.pathname.indexOf('/artist/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon nav-icon fas fa-music"></i>
                      <p>นักดนตรี</p>
                    </Link>
                  </li>
                  : null
              }
              {
                user.permission
                  && user.permission.staticts
                  && (
                    user.permission.staticts.add
                    || user.permission.staticts.edit
                    || user.permission.staticts.delete
                    || user.permission.staticts.view
                  )
                  ?
                  <>
                    {/* <li class="nav-header">สถิติ</li> */}
                    <li className="nav-item">
                      <Link to="/staticts" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/staticts/') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon nav-icon fas fa-chart-bar"></i>
                        <p>รายการขอเพลง</p>
                      </Link>
                    </li>
                  </>
                  : null
              }
              {/* {
                user.permission
                  && user.permission.rank
                  && (
                    user.permission.rank.add
                    || user.permission.rank.edit
                    || user.permission.rank.delete
                    || user.permission.rank.view
                  )
                  ?
                  <li className="nav-item">
                    <Link to="/rank" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent
                            || window.location.pathname.indexOf('/rank/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon nav-icon fas fa-user-tag"></i>
                      <p>Rank</p>
                    </Link>
                  </li>
                  : null
              } */}
              {/* {
                user.permission
                  && user.permission.sale
                  && (
                    user.permission.sale.add
                    || user.permission.sale.edit
                    || user.permission.sale.delete
                    || user.permission.sale.view
                  )
                  ?
                  <li className="nav-item">
                    <Link to="/sale" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon nav-icon fas fa-user-tie"></i>
                      <p>เซลส์และคอมมิชชั่น</p>
                    </Link>
                  </li>
                  : null
              } */}
              {
                user.permission
                  && user.permission.report
                  && (
                    user.permission.report.add
                    || user.permission.report.edit
                    || user.permission.report.delete
                    || user.permission.report.view
                  )
                  ?
                  <>
                    <li className="nav-item">
                      <Link to="/report?report_type=post" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/report') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon nav-icon fas fa-bug"></i>
                        <p>รายงาน</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/exchange/transaction" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/exchange/transaction') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-exchange-alt"></i>
                        <p>ประวัติแลกแต้ม</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/exchange/point" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/exchange/point') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon fas fa-exchange-alt"></i>
                        <p>ขอแลกแต้ม</p>
                      </Link>
                    </li>
                  </>
                  : null
              }

              {
                user.permission
                  && user.permission.gift
                  && (
                    user.permission.gift.add
                    || user.permission.gift.edit
                    || user.permission.gift.delete
                    || user.permission.gift.view
                  )
                  ?
                  <li className="nav-item">
                    <Link to="/gift" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent
                            || window.location.pathname.indexOf('/gift/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon nav-icon fas fa-gift"></i>
                      <p>Gift</p>
                    </Link>
                  </li>
                  : null
              }
              {
                user.permission
                  && user.permission.transaction
                  && (
                    user.permission.transaction.add
                    || user.permission.transaction.edit
                    || user.permission.transaction.delete
                    || user.permission.transaction.view
                  )
                  ?
                  <li className="nav-item">
                    <Link to="/transaction" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent
                            || window.location.pathname.indexOf('/transaction/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon nav-icon fas fa-user-tag"></i>
                      <p>ประวัติการชำระเงิน</p>
                    </Link>
                  </li>
                  : null
              }
              {
                user.permission && user.permission.coinPackage
                  && (
                    user.permission.coinPackage.add
                    || user.permission.coinPackage.edit
                    || user.permission.coinPackage.delete
                    || user.permission.coinPackage.view
                  )
                  ?
                  <>
                    {/* <li className="nav-item">
                      <Link to="/coin-package" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/coin-package/') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon nav-icon fas fa-gifts"></i>
                        <p>แพ็กเกจเติมเหรียญ</p>
                      </Link>
                    </li> */}

                    <li className="nav-item">
                      <Link to="/package-channels" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent
                              || window.location.pathname.indexOf('/package-channels/') !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="nav-icon nav-icon fas fa-tasks"></i>
                        <p>แพ็กเกจเติมเหรียญ</p>
                      </Link>
                    </li>
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.admin
                  && (
                    user.permission.admin.add
                    || user.permission.admin.edit
                    || user.permission.admin.delete
                    || user.permission.admin.view
                  )
                  ?
                  <li className="nav-item">
                    <Link to="/permission" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon nav-icon fas fa-user-cog"></i>
                      <p>ผู้ดูแลและสิทธิในการใช้งาน</p>
                    </Link>
                  </li>
                  : null
              }


              {
                this.props.user.displayName ?
                  <>
                    <li className="nav-item">
                      <Link to={"/admin-detail/" + this.props.user.uid} className="nav-link"
                        getProps={this.currentLink}
                      >
                        <i className="nav-icon fas fa-user-check"></i>
                        <p>
                          {this.props.user.displayName}
                        </p>
                      </Link>
                    </li>
                  </>
                  : null
              }
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}