import React from 'react';
import { Link } from '@reach/router';
import { Helper, reportUsersTypes } from './../../Helper';
import Pagination from './../../Components/Pagination';
import { getReportUsers, updateReportUsersStatus } from '../../services/ReportedServices';
const axios = require('axios').default;
const default_limit = 10;
const user_report_types = [
    { name: 'Spammer', value: 1, text: "แสปม" },
    { name: 'ViolenceName', value: 2, text: "ชื่อไม่เหมาะสม" },
    { name: 'ViolenceImage', value: 3, text: "รูปภาพไม่เหมาะสม" },
]
const userStatusReport = [
    { statusName: "Pending", value: 1 },
    { statusName: "Confirmed", value: 2 },
    { statusName: "Declined", value: 3 },
    { statusName: "Duplicated", value: 4 },
];
export default class ReportPost extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            reports: [],
            user_report_types: [],
            type: Helper.getParameterByName("type") && Helper.getParameterByName("type") !== '' ? Helper.getParameterByName("type") : false,
            page: Helper.getParameterByName("page") && Helper.getParameterByName("page") !== '' ? Helper.getParameterByName("page") : 1,
            count_all_item: 0,
            limit: Helper.getParameterByName("limit") && Helper.getParameterByName("limit") !== '' ? Helper.getParameterByName("limit") : default_limit,
            report_type: Helper.getParameterByName("report_type") && Helper.getParameterByName("report_type") !== '' ? Helper.getParameterByName("report_type") : false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                reports: [],
                user_report_types: [],
                type: Helper.getParameterByName("type") && Helper.getParameterByName("type") !== '' ? Helper.getParameterByName("type") : false,
                page: Helper.getParameterByName("page") && Helper.getParameterByName("page") !== '' ? Helper.getParameterByName("page") : 1,
                limit: Helper.getParameterByName("limit") && Helper.getParameterByName("limit") !== '' ? Helper.getParameterByName("limit") : default_limit,
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let qs = {};
        qs.report_type = "user";
        if (this.state.type) {
            qs.type = this.state.type;
        }
        qs.page = this.state.page;
        qs.limit = this.state.limit;
        if (this.state.storeUid) {
            qs.storeUid = this.state.storeUid;
        }
        let res = await getReportUsers(qs.page, qs.limit, qs.type);
        let reports = [];
        let count_all_item = 0;
        if (!res.error) {
            reports = res.items;
            count_all_item = res.count_all_item;
        }
        this.setState({
            is_loading: false,
            reports: reports,
            user_report_types: user_report_types,
            count_all_item: count_all_item
        })
    }

    banUser() {
        Helper.confirmModal({
            title: `แบนผู้ใช้งาน`,
            description: `ยืนยันการแบนผู้ใช้งาน`,
            onConfirm: async () => {
                let data = {};
                data.uid = this.props.uid;
                data.adminUid = this.props.adminUid;
                let res = await axios.post(`${window.api_host}/user-ban`, { ...data });
                // console.log(res.data);
                if (res.data.success) {
                    this.setState({
                        data: res.data.response.user,
                    }, () => {
                        Helper.messageTop({ message: "แบน User นี้แล้ว", type_class: 'alert-success' })
                    })
                }
                if (res.data.error) {
                    Helper.messageTop({ message: res.data.message })
                }
            }
        })
    }
    renderStatus(statusId) {
        let status = userStatusReport.filter((e) => { return e.value == statusId });
        if (status.length > 0) {
            return status[0];
        }
        return null
    }
    renderType(typeId) {
        let type = user_report_types.filter((e) => { return e.value == typeId });
        if (type.length > 0) {
            return type[0];
        }
        return null
    }

    render() {
        return (
            <div className="card card-primary card-outline">
                <div className="card-header">
                    <h3 className="card-title">รายงานผู้ใช้งาน</h3>

                    {/* <div className="card-tools">
                    </div> */}
                </div>
                <div className="card-body p-0">

                    {
                        !this.state.is_loading ?
                            <div className="mailbox-controls">
                                <div className="btn btn-group">
                                    <Link to={'/report?report_type=user'} className={!this.state.type || this.state.type === '' ? "btn btn-default active" : "btn btn-default"} disabled={!this.state.type || this.state.type === ''}>ทั้งหมด</Link>
                                    {
                                        this.state.user_report_types.map((user_report_type, user_report_type_i) => {
                                            return (
                                                <Link key={user_report_type_i} to={'/report?report_type=user&type=' + user_report_type.value} className={this.state.type && this.state.type == user_report_type.value ? "btn btn-default active" : "btn btn-default"} disabled={this.state.type && this.state.type === user_report_type}>
                                                    {user_report_type.text}
                                                </Link>
                                            )
                                        })
                                    }



                                </div>
                            </div>
                            : null
                    }
                    <div className="table-responsive mailbox-messages">
                        <table className="table table-hover table-striped">

                            <thead>
                                <tr>
                                    <th scope="col" width={250}>ผู้โดนรายงาน</th>
                                    <th scope="col" width={200}>รายงาน</th>
                                    <th scope="col" >ข้อความ</th>
                                    <th scope="col" width={200}>รายงานโดย</th>
                                    <th scope="col" align={'right'} width={250}>สถานะ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.is_loading ?
                                        <tr><td colSpan={8}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="ui active inline loader small" ></div></div></td></tr>
                                        : null
                                }
                                {
                                    !this.state.is_loading
                                        && this.state.reports.length <= 0 ?
                                        <tr className="custom-tr">
                                            <td colSpan={4 + this.state.user_report_types.length} align='center'>ไม่มีข้อมูล</td>
                                        </tr>
                                        : null
                                }
                                {
                                    !this.state.is_loading ?
                                        this.state.reports.map((report, report_i) => {
                                            let i = 0
                                            return (
                                                <tr key={report_i} className="custom-tr">
                                                    <td className="mailbox-name">
                                                        {
                                                            report.user ?
                                                                <a href={`${window.location.origin}/member/view/${report.user.fireBaseUid}`} target={"_blank"}>{report.user.displayName}</a>
                                                                : null
                                                        }
                                                    </td>
                                                    <td>
                                                        <div>{this.renderType(report.typeId) ? this.renderType(report.typeId).text : '-'}</div>
                                                    </td>
                                                    <td>
                                                        {
                                                            report.message ?
                                                                <div>{report.message}</div>
                                                                : null
                                                        }
                                                    </td>
                                                    <td >
                                                        {
                                                            report.reporter ?
                                                                <a href={`${window.location.origin}/member/view/${report.reporter.fireBaseUid}`} target={"_blank"}>{report.reporter.displayName}</a>
                                                                : null
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            {this.renderStatus(report.statusId) ? this.renderStatus(report.statusId).statusName : '-'}
                                                            <button className={'btn btn-sm btn-default'}
                                                                style={{ marginLeft: 10 }}
                                                                onClick={() => {
                                                                    Helper.reactToDom(window.$('body'),
                                                                        <ChangeReportStatus
                                                                            id={report.reportedId}
                                                                            statusId={report.statusId}
                                                                            onSuccess={() => {
                                                                                this.fetch()
                                                                            }}
                                                                        />
                                                                    )
                                                                }}
                                                            >
                                                                เปลี่ยนสถานะ
                                                            </button>
                                                        </div>
                                                    </td>

                                                </tr>
                                            );
                                        }) : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer p-0">
                    <div className="mailbox-controls">
                        <Pagination
                            now={this.state.page}
                            all_items={this.state.count_all_item}
                            limit={this.state.limit}
                            location={this.props.location}
                        />
                    </div>
                </div>
            </div >
        );
    }
}

class ChangeReportStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data || {},
            userStatusReport: userStatusReport,
        }
    }


    async update(data) {
        let res = await updateReportUsersStatus(this.props.id, data)
        if (!res.error) {
            if (this.props.onSuccess) { this.props.onSuccess() }
            this.$modal.modal('hide');
        } else {

        }
    }

    render() {
        let title = "เปลี่ยนสถานะรายงาน";
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            this.update(data);
                            return;

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">{title}</h4>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <label>เลือกสถานะที่ต้องการเปลี่ยน</label>
                                    <select className='form-control' defaultValue={this.props.statusId} name="statusId">
                                        {
                                            this.state.userStatusReport.map((status, i) => {
                                                return <option value={status.value} key={i}>{status.statusName}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}