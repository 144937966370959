import React from 'react';
import { Link, navigate } from '@reach/router';
import Pagination from './../../Components/Pagination';
import { Helper, RankTranslate } from './../../Helper';
const axios = require('axios').default;
const default_limit = 8;
export default class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            is_rank_info_loading: true,
            rank_info: false,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
            isActive: Helper.getParameterByName('isActive') ? Helper.getParameterByName('isActive') : 'true',
            data: [],
            count_all: 0,
            count_item: 0,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== '/rank' && this.props.id !== prevProps.id) {
            this.setState({
                is_rank_info_loading: true,
                rank_info: false,
            }, () => {
                this.fetchRankInfo();
            })
        }
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                // is_rank_info_loading: true,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
                isActive: Helper.getParameterByName('isActive') ? Helper.getParameterByName('isActive') : 'true',
                // data: [],
                count_all: 0,
                count_item: 0,
            }, () => {
                this.fetch({ is_get_rank_info: false });
            })
        }
        if (this.props.location.pathname === '/rank' && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                is_loading: true,
                // is_rank_info_loading: true,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
                isActive: Helper.getParameterByName('isActive') ? Helper.getParameterByName('isActive') : 'true',
                // data: [],
                count_all: 0,
                count_item: 0,
            }, () => {
                this.fetch({ is_get_rank_info: this.props.id !== prevProps.id });
            })
        }
    }

    componentDidMount() {
        this.fetch();
        if (this.props.id) {
            this.fetchRankInfo()
        }
    }
    async fetchRankInfo() {
        let qs = {};
        qs.id = this.props.id;
        let res = await axios.get(`${window.api_host}/rank?${window.$.param(qs)}`);
        let rank_info = false;
        let default_permission = [];
        if (res.data.success && res.data.data.length > 0) {
            rank_info = res.data.data[0];
            default_permission = res.data.default_permission;
        }
        this.setState({
            rank_info: rank_info,
            is_rank_info_loading: false,
            default_permission: default_permission,
        })
    }

    async fetch(options) {
        let qs = {};
        qs.page = this.state.page;
        qs.limit = this.state.limit;
        if (this.state.keyword) { qs.keyword = this.state.keyword }
        let res = await axios.get(`${window.api_host}/rank?${window.$.param(qs)}`);
        let data = [];
        let count_all = 0;
        let count_item = 0;
        if (res.data.success) {
            data = res.data.data;
            count_all = res.data.count_all;
            count_item = res.data.count_item;
        }
        let is_rank_info_loading = false;
        let rank_info = this.state.rank_info;
        if (options && options.is_get_rank_info) {
            is_rank_info_loading = true;
            rank_info = false;
        }
        this.setState({
            is_loading: false,
            data: data,
            count_all: count_all,
            count_item: count_item,
            is_rank_info_loading: is_rank_info_loading,
            rank_info: rank_info
        }, () => {
            if (options && options.is_get_rank_info) {
                this.fetchRankInfo()
            }
        })
    }

    async delete(id) {
        let res = await axios.delete(window.api_host + '/rank?id=' + id);
        if (res.data.success) {
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            navigate('/rank');
        }
        if (res.data.error) {
            window.$(document).Toasts('create', {
                title: res.data.message,
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
        }
    }

    render() {

        let qs = {};
        qs.page = this.state.page;
        qs.limit = this.state.limit;
        if (this.state.keyword) { qs.keyword = this.state.keyword }
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Rank</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    {
                                        this.props.id ?
                                            <>
                                                <li className="breadcrumb-item"><Link to="/rank">Rank</Link></li>
                                                {
                                                    this.props.id ?
                                                        <li className="breadcrumb-item active">{this.props.id}</li>
                                                        : null
                                                }
                                            </>
                                            :
                                            <li className="breadcrumb-item active">Rank</li>
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <div className="card card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>

                                        {/* <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div> */}
                                    </div>
                                    <div className="card-body">
                                        <form className="row"
                                            ref={(ref) => { this.$form = window.$(ref) }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                                    return;
                                                }
                                                let data = Helper.getFormInputObject(this.$form);
                                                let url = Helper.getQueryStringLinkFromObject({
                                                    page: 1,
                                                    keyword: data.keyword,
                                                })
                                                navigate(url)
                                            }}
                                        >
                                            <div className="col-12 form-group">
                                                <label>ค้นหา</label>
                                                <input name="keyword" className="form-control" placeholder="ค้นหาจากชื่อแพ็คเกจ" />
                                            </div>
                                            {/* <div className="col-6 form-group">
                                                <label>สถานะ</label>
                                                <select
                                                    className={"form-control"}
                                                    name={"isActive"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.isActive || ''}
                                                >
                                                    <option value={"true"}>ใช้งาน</option>
                                                    <option value={"false"}>ไม่ใช้งาน</option>
                                                </select>
                                            </div> */}
                                            <div className="col-12">
                                                <div className="flex row">
                                                    <Link className="btn btn-primary" to="/rank/add">เพิ่ม</Link>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <button type="submit" className="btn btn-default">ค้นหา</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* STORE LIST */}
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายการ Rank</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                                : null
                                        }
                                        {
                                            this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                <div className="absolute_loading">
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.data.length > 0 ?
                                                <>
                                                    <ul className="nav nav-pills flex-column">
                                                        {
                                                            this.state.data.map((_data, _data_i) => {
                                                                return (
                                                                    <li className="nav-item" key={_data_i}>
                                                                        <Link to={"/rank/" + _data.id + '?' + window.$.param(qs)} className="nav-link">
                                                                            <div className="store-item item-row">
                                                                                <div className="description" style={{ padding: 15 }}>
                                                                                    <div>{_data.id}</div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </>
                                                : null
                                        }
                                    </div>
                                    {
                                        !this.state.is_loading
                                            && this.state.data.length !== 0 ?
                                            <div className="card-footer clearfix">
                                                <Pagination
                                                    now={this.state.page}
                                                    all_items={this.state.count_all}
                                                    limit={this.state.limit}
                                                    location={this.props.location}
                                                />
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>
                            <div className="col-12 col-md-4">
                                {
                                    this.props.id ?
                                        <div className="card">

                                            <div className="card-header">
                                                <h3 className="card-title">{this.props.id}</h3>
                                                <div className="card-tools">
                                                    <Link to={"/rank"}><i className="fas fa-times" /></Link>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                {
                                                    this.state.is_rank_info_loading ?
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <div className="ui active inline loader small" ></div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    !this.state.is_rank_info_loading && this.state.default_permission ?
                                                        <>
                                                            {
                                                                this.state.default_permission.map((item, key) => {
                                                                    if (item.type === 'string') return null;
                                                                    return (
                                                                        <div key={key} className={"flex row middle-xs"}>
                                                                            <div style={{ width: 40 }}>
                                                                                {item.type === 'boolean' ?
                                                                                    this.state.rank_info[item.name] ?
                                                                                        <i className="fas fa-check label-icon" style={{ color: "green" }} />
                                                                                        : <i className="fas fa-times label-icon" style={{ color: 'red' }} />
                                                                                    : null
                                                                                }
                                                                                {
                                                                                    item.type === 'number' ?
                                                                                        <span className={this.state.rank_info && this.state.rank_info[item.name] && (parseFloat(this.state.rank_info[item.name]) > 0 || item.name === "pokePerDay") ? "badge badge-success" : "badge badge-danger"} style={{ marginRight: 10 }}>
                                                                                            {item.name === "pokePerDay" && this.state.rank_info[item.name] === 0 ? <i className="fas fa-infinity"></i> : this.state.rank_info[item.name]}
                                                                                        </span>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            <strong>{RankTranslate[item.name].th}</strong>
                                                                            {
                                                                                item.type === 'string' ?
                                                                                    <>
                                                                                        <span className="text-mute" style={{ marginLeft: 5, opacity: .6 }}> : {this.state.rank_info[item.name]}</span>
                                                                                    </>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                item.type === 'object' ?
                                                                                    <div style={{ paddingLeft: 15 }}>
                                                                                        {
                                                                                            item.value.map((_item, _key) => {
                                                                                                return (
                                                                                                    <div key={_key}>
                                                                                                        {_item.type === 'boolean' ?
                                                                                                            this.state.rank_info[item.name][_item.name] ?
                                                                                                                <i className="fas fa-check label-icon" style={{ color: "green" }} />
                                                                                                                : <i className="fas fa-times label-icon" style={{ color: 'red' }} />
                                                                                                            : null
                                                                                                        } {RankTranslate[_item.name].th}
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                this.state.default_permission.map((item, key) => {
                                                                    if (item.type !== 'string') return null;
                                                                    return (
                                                                        <div key={key}>
                                                                            <strong>{RankTranslate[item.name].th}</strong> : {this.state.rank_info[item.name]}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        : null
                                                }

                                            </div>
                                            <div className="card-footer">
                                                <div className={"flex row"}>
                                                    <Link className={'btn btn-primary'} to={'/rank/edit/' + this.props.id}>แก้ไข</Link>
                                                    {/* <div className={"right-floated"}>
                                                        <button
                                                            className="btn btn-danger"
                                                            ref={(ref) => {
                                                                window.$(ref).off().on('click', () => {
                                                                    if (window.$(ref).hasClass('loading')) { return; }
                                                                    window.$(ref).addClass('loading');
                                                                    Helper.confirmModal({
                                                                        title: 'ลบ Rank',
                                                                        description: 'ยืนยันการลบ Rank, ต้องการลบ Rank ' + this.props.id + 'ใช่หรือไม่ ?',
                                                                        submit_text: 'ยืนยัน',
                                                                        onConfirm: () => {
                                                                            window.$(ref).removeClass('loading');
                                                                            this.delete(this.props.id);
                                                                        },
                                                                        onCancel: () => {
                                                                            window.$(ref).removeClass('loading');
                                                                        }
                                                                    });
                                                                })
                                                            }}
                                                        >ลบ</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}