import { Link, navigate } from '@reach/router';
import React from 'react';
import { Helper } from '../../Helper';
import { skitz_api_host, ApplicationKey } from './../../services/HttpClient'
import { getRequestReport } from '../../services/ReportedServices';
import { getStores } from '../../services/StoreServices';
import Pagination from '../../Components/Pagination';
import { getAngPao, getAngPaoById } from '../../services/Distribute';
const default_row = 10;
const _serverUrl = skitz_api_host;
const baseUrlStore = `${_serverUrl}/store`;
const baseUrlUser = `${_serverUrl}/user`;
export default class MusicRequest extends React.Component {
    constructor() {
        super();
        let monthList = [];
        for (let index = 1; index <= 12; index++) {
            monthList.push(index)
        }
        let date = new Date();
        let now_year = date.getFullYear();
        let yearList = [];
        for (let index = 2020; index <= now_year; index++) {
            yearList.push(index)
        }
        this.state = {
            is_loading: true,
            data: [],
            storeList: [],
            userList: [],
            filter: {
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                rows: default_row,
                storeId: Helper.getParameterByName('storeId') ? Helper.getParameterByName('storeId') : '',
                userId: Helper.getParameterByName('userId') ? Helper.getParameterByName('userId') : '',
                typeId: Helper.getParameterByName('typeId') ? Helper.getParameterByName('typeId') : '',
                statusId: Helper.getParameterByName('statusId') ? Helper.getParameterByName('statusId') : '',
            },
            monthList: monthList,
            yearList: yearList,
            rowsList: [
                20, 50, 100, 150, 200, 1000
            ],
            count_all: 0,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                filter: {
                    page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                    rows: default_row,
                    storeId: Helper.getParameterByName('storeId') ? Helper.getParameterByName('storeId') : '',
                    userId: Helper.getParameterByName('userId') ? Helper.getParameterByName('userId') : '',
                    typeId: Helper.getParameterByName('typeId') ? Helper.getParameterByName('typeId') : '',
                    statusId: Helper.getParameterByName('statusId') ? Helper.getParameterByName('statusId') : '',
                },
                count_all: 0
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.pathname === '/rank' && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                is_loading: true,
                filter: {
                    page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                    rows: default_row,
                    storeId: Helper.getParameterByName('storeId') ? Helper.getParameterByName('storeId') : '',
                    userId: Helper.getParameterByName('userId') ? Helper.getParameterByName('userId') : '',
                    typeId: Helper.getParameterByName('typeId') ? Helper.getParameterByName('typeId') : '',
                    statusId: Helper.getParameterByName('statusId') ? Helper.getParameterByName('statusId') : '',
                },
                count_all: 0
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let filter = { ...this.state.filter };
        let storeList = [...this.state.storeList];
        if (this.state.storeList.length == 0) {
            let store = await getStores();
            if (!store.error) {
                storeList = store;
            }
        }
        let res = await getAngPao(filter);
        let items = [];
        let count_all = 0;
        if (!res.error) {
            items = res.items;
            count_all = res.total;
        }

        this.setState({
            data: items,
            storeList: storeList,
            is_loading: false,
            count_all: count_all,
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายการขอเพลง</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">
                                        รายการขอเพลง
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-12 col-md-3'>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายการขอเพลง</h3>
                                    </div>
                                    <div className="card-body">
                                        <form
                                            ref={(ref) => {
                                                this.$filterForm = window.$(ref);
                                                this.$submit = this.$filterForm.find('button[type="submit"]');
                                                this.$selectYear = this.$filterForm.find('select[name="year"]');
                                                this.$selectMonth = this.$filterForm.find('select[name="month"]');
                                            }}

                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                let data = Helper.getFormInputObject(this.$filterForm);
                                                // let url = Helper.getQueryStringLinkFromObject({
                                                //     ...data
                                                // })
                                                // navigate(url)
                                                let filter = {}
                                                for (const key in data) {
                                                    if (data[key]) {
                                                        filter[key] = data[key]
                                                    }
                                                }
                                                this.setState({
                                                    filter: filter,
                                                    is_loading: true
                                                }, () => {
                                                    this.fetch();
                                                })
                                            }}
                                        >
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>
                                                    <div className='form-group'>
                                                        <div className='d-flex'>
                                                            <label>ร้าน</label>
                                                            {
                                                                this.state.filter.storeId ?
                                                                    <div className='right-floated'>
                                                                        <div className='link'
                                                                            onClick={() => {
                                                                                if (this.$storefilter) {
                                                                                    this.$storefilter.val(null).trigger('change');
                                                                                }
                                                                                this.$submit.trigger('click')
                                                                            }}
                                                                        >ยกเลิก</div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <select className={"form-control"}
                                                            ref={(ref) => {
                                                                this.$storefilter = window.$(ref);
                                                                this.$storefilter.select2({
                                                                    placeholder: 'เลือกร้านทั้งหมด',
                                                                    // ajax: {
                                                                    //     headers: {
                                                                    //         'applicationkey': ApplicationKey,
                                                                    //     },
                                                                    //     url: baseUrlStore,
                                                                    //     data: function (params) {
                                                                    //         var query = {
                                                                    //             q: params.term,
                                                                    //         }
                                                                    //         return query;
                                                                    //     },
                                                                    //     processResults: function (data) {
                                                                    //         console.log("processResults")
                                                                    //         let items = [];
                                                                    //         if (!data.error) {
                                                                    //             for (const item of data.items) {
                                                                    //                 items.push({
                                                                    //                     id: item.id,
                                                                    //                     text: item.name
                                                                    //                 })
                                                                    //             }
                                                                    //         }
                                                                    //         return {
                                                                    //             results: items
                                                                    //         };
                                                                    //     },
                                                                    // }
                                                                });
                                                                if (this.state.filter.storeId) {
                                                                    this.$storefilter.val(this.state.filter.storeId).trigger('change');
                                                                }
                                                                this.$storefilter.off().on("select2:select", () => {
                                                                    this.$submit.trigger('click')
                                                                })
                                                            }}
                                                            name={"storeId"}
                                                            style={{ flex: 1 }}
                                                            defaultValue={this.state.filter.storeId}
                                                        >
                                                            <option value={""}>เลือกร้านทั้งหมด</option>
                                                            {
                                                                this.state.storeList.map((store, i) => {
                                                                    return (
                                                                        <option value={store.id} key={i}>{store.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className='col-12 col-md-6'>
                                                    <div className='form-group'>
                                                        <div className='d-flex'>
                                                            <label>ผู้ใช้งาน</label>
                                                            {
                                                                this.state.filter.userId ?
                                                                    <div className='right-floated'>
                                                                        <div className='link'
                                                                            onClick={() => {
                                                                                if (this.$userfilter) {
                                                                                    this.$userfilter.val(null).trigger('change');
                                                                                }
                                                                                this.$submit.trigger('click')
                                                                            }}
                                                                        >ยกเลิก</div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <select className={"form-control"}
                                                            ref={(ref) => {
                                                                this.$userfilter = window.$(ref);
                                                                this.$userfilter.select2({
                                                                    placeholder: 'เลือกผู้ใช้งานทั้งหมด',
                                                                    ajax: {
                                                                        headers: {
                                                                            'applicationkey': ApplicationKey,
                                                                        },
                                                                        url: baseUrlUser,
                                                                        data: function (params) {
                                                                            var query = {
                                                                                q: params.term,
                                                                            }
                                                                            return query;
                                                                        },
                                                                        processResults: function (data) {
                                                                            let items = [];
                                                                            if (!data.error) {
                                                                                for (const item of data.items) {
                                                                                    items.push({
                                                                                        id: item.userId,
                                                                                        text: item.displayName
                                                                                    })
                                                                                }
                                                                            }
                                                                            return {
                                                                                results: items
                                                                            };
                                                                        },
                                                                    }
                                                                });

                                                                if (this.state.filter.userId) {
                                                                    this.$userfilter.val(this.state.filter.userId).trigger('change');
                                                                }
                                                                this.$userfilter.off().on("select2:select", () => {
                                                                    this.$submit.trigger('click')
                                                                })
                                                            }}
                                                            name={"userId"}
                                                            defaultValue={this.state.filter.userId}
                                                        />
                                                    </div>

                                                </div>
                                                <div className='col-12 col-md-6'>
                                                    <div className='form-group'>
                                                        <div className='d-flex'>
                                                            <label>ประเภท</label>
                                                            {
                                                                this.state.filter.typeId ?
                                                                    <div className='right-floated'>
                                                                        <div className='link'
                                                                            onClick={() => {
                                                                                if (this.$typeFilter) {
                                                                                    this.$typeFilter.val(null).trigger('change');
                                                                                }
                                                                                this.$submit.trigger('click')
                                                                            }}
                                                                        >ยกเลิก</div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <select
                                                            ref={(ref) => {
                                                                this.$typeFilter = window.$(ref);
                                                                this.$typeFilter.select2({
                                                                    placeholder: 'ประเภททั้งหมด',
                                                                    minimumResultsForSearch: -1
                                                                });
                                                                this.$typeFilter.off().on("select2:select", () => {
                                                                    this.$submit.trigger('click')
                                                                })
                                                            }}
                                                            className='form-control'
                                                            name={"typeId"}
                                                            defaultValue={this.state.filter.typeId}
                                                        >
                                                            <option value={""}>ประเภททั้งหมด</option>
                                                            <option value="1">อั่งเปาปกติ</option>
                                                            <option value="2">อั่งเปาแบบสุ่ม</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className='col-12 col-md-6'>
                                                    <div className='form-group'>
                                                        <div className='d-flex'>
                                                            <label>สถานะ</label>
                                                            {
                                                                this.state.filter.statusId ?
                                                                    <div className='right-floated'>
                                                                        <div className='link'
                                                                            onClick={() => {
                                                                                if (this.$statusFilter) {
                                                                                    this.$statusFilter.val(null).trigger('change');
                                                                                }
                                                                                this.$submit.trigger('click')
                                                                            }}
                                                                        >ยกเลิก</div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <select
                                                            ref={(ref) => {
                                                                this.$statusFilter = window.$(ref);
                                                                this.$statusFilter.select2({
                                                                    placeholder: 'สถานะทั้งหมด',
                                                                    minimumResultsForSearch: -1
                                                                });
                                                                this.$statusFilter.off().on("select2:select", () => {
                                                                    this.$submit.trigger('click')
                                                                })
                                                            }}
                                                            className='form-control'
                                                            name={"statusId"}
                                                            defaultValue={this.state.filter.statusId}
                                                        >
                                                            <option value={""}>สถานะทั้งหมด</option>
                                                            <option value="1">กำลังแจก</option>
                                                            <option value="2">สำเร็จ</option>
                                                            <option value="3">หมดอายุ</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                            <input type="hidden" name="page" value={this.state.filter.page} />
                                            <input type="hidden" name="rows" value={this.state.filter.rows} />
                                            <button
                                                className="btn btn-default"
                                                onClick={() => {
                                                    this.setState({
                                                        filter: {}
                                                    }, () => {
                                                        if (this.$storefilter) {
                                                            this.$storefilter.val(null).trigger('change');
                                                        }
                                                        if (this.$userfilter) {
                                                            this.$userfilter.val(null).trigger('change');
                                                        }
                                                        if (this.$statusFilter) {
                                                            this.$statusFilter.val(null).trigger('change');
                                                        }
                                                        if (this.$typeFilter) {
                                                            this.$typeFilter.val(null).trigger('change');
                                                        }
                                                        this.fetch();
                                                    })
                                                }}
                                            >Clear</button>
                                            {/* <Link to={"/staticts"} className="btn btn-default">Clear</Link> */}
                                            <button type="submit" style={{ display: 'none' }}></button>
                                        </form>
                                    </div>

                                </div>
                            </div>

                            <div className='col-12 col-md-9'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: 200 }}>AngPao Number</th>
                                                    <th style={{ width: 200 }}>ผู้ส่ง</th>
                                                    {/* <th style={{ width: 150 }}>จำนวนผู้รับ</th> */}
                                                    <th style={{ width: 200 }}>ร้าน</th>
                                                    <th style={{ width: 150 }}>ประเภท</th>
                                                    <th style={{ width: 150 }}>สถานะ</th>
                                                    <th style={{ textAlign: 'right', width: 150 }}>จำนวนส่ง</th>
                                                    <th style={{ textAlign: 'right', width: 150 }}>จำนวนรับ</th>
                                                    <th style={{ textAlign: 'right', width: 200 }}>ราคา</th>
                                                    <th style={{ textAlign: 'right', width: 300 }}>วันที่</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !this.state.is_loading
                                                        && this.state.data.length === 0 ?
                                                        <tr>
                                                            <td colSpan={4}>ไม่มีข้อมูล</td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                    this.state.is_loading
                                                        && this.state.data.length === 0 ?
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <div className="ui active inline loader small" ></div>
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                    this.state.data.length > 0 ?
                                                        <>
                                                            {
                                                                this.state.data.map((_data, _data_i) => {
                                                                    let type = "-";
                                                                    if (_data.typeId == 1) {
                                                                        type = "อั่งเปาปกติ"
                                                                    } else if (_data.typeId == 2) {
                                                                        type = "อั่งเปาแบบสุ่ม"
                                                                    }
                                                                    let status = "-";
                                                                    if (_data.statusId == 1) {
                                                                        status = "กำลังแจก"
                                                                    } else if (_data.statusId == 2) {
                                                                        status = "สำเร็จ"
                                                                    } else if (_data.statusId == 3) {
                                                                        status = "หมดอายุ"
                                                                    }

                                                                    return (
                                                                        <tr key={_data_i}>
                                                                            <td>{_data.angpaoNumber}</td>
                                                                            <td>{_data.user && _data.user.displayName ? _data.user.displayName : '-'}</td>
                                                                            <td>{_data.store && _data.store.name ? _data.store.name : '-'}</td>
                                                                            <td>{type}</td>
                                                                            <td>{status}</td>
                                                                            <td align='right'><div>{_data.quantity ? Helper.numberFormat(_data.quantity) : 0}</div></td>
                                                                            <td align='right'><div>
                                                                                {
                                                                                    _data.recievedQuantity && _data.recievedQuantity > 0 ?
                                                                                        <span className='link'
                                                                                            onClick={() => {
                                                                                                Helper.reactToDom(window.$('body'),
                                                                                                    <RecieverViewModal
                                                                                                        id={_data.id}
                                                                                                    />)
                                                                                            }}
                                                                                        >
                                                                                            {_data.recievedQuantity > 0 ? _data.recievedQuantity : 0}
                                                                                        </span>
                                                                                        : 0
                                                                                }
                                                                            </div>
                                                                            </td>
                                                                            <td align='right'><div>{_data.totalAmount ? Helper.numberFormat(_data.totalAmount) : 0}</div></td>
                                                                            <td align='right'><div>{_data.created ? Helper.getDateThai(_data.created, { is_show_time: true }) : '-'}</div></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        : null
                                                }

                                            </tbody>
                                        </table>

                                        {
                                            this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                <div className="absolute_loading">
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    {
                                        !this.state.is_loading
                                            && this.state.data.length !== 0 ?
                                            <div className="card-footer clearfix">
                                                <Pagination
                                                    now={this.state.filter.page}
                                                    all_items={this.state.count_all}
                                                    limit={this.state.filter.rows}
                                                    location={this.props.location}
                                                    onReturnPage={(page) => {
                                                        let filter = { ...this.state.filter }
                                                        filter.page = page;
                                                        this.setState({
                                                            is_loading: true,
                                                            filter: filter,
                                                        }, () => {
                                                            this.fetch();
                                                        })
                                                    }}
                                                />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </>
        )
    }
}

class RecieverViewModal extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await getAngPaoById(this.props.id);
        let data = [];
        if (!res.error) {
            data = res.recievers;
        }
        this.setState({
            data: data,
            is_loading: false,
        })
    }

    render() {
        let title = "รายการผู้รับ";
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">{title}</h4>
                            </div>
                            {/* <div className="modal-body">

                            </div> */}
                            <table className="table" style={{ marginBottom: 0 }}>
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: 200 }}>ชื่อ</th>
                                        <th style={{ textAlign: 'right', width: 100 }}>ราคา</th>
                                        <th style={{ textAlign: 'right', width: 300 }}>วันที่รับ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.is_loading
                                            && this.state.data.length > 0 ?
                                            <>
                                                {
                                                    this.state.data.map((_data, _data_i) => {
                                                        if (!_data.reciever) { return null }
                                                        let reciever_name = '-';
                                                        if (_data.reciever && _data.reciever.displayName) {
                                                            reciever_name = _data.reciever.displayName;
                                                        }
                                                        return (
                                                            <tr key={_data_i}>
                                                                <td>{reciever_name}</td>
                                                                <td align='right'><div>{_data.amount ? Helper.numberFormat(_data.amount) : 0}</div></td>
                                                                <td align='right'><div>{_data.updated ? Helper.getDateThai(_data.updated, { is_show_time: true }) : '-'}</div></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                            : null
                                    }{
                                        !this.state.is_loading
                                            && this.state.data.length === 0 ?
                                            <tr>
                                                <td colSpan={4}>ไม่มีข้อมูล</td>
                                            </tr>
                                            : null
                                    }
                                    {
                                        this.state.is_loading
                                            && this.state.data.length === 0 ?
                                            <tr>
                                                <td colSpan={4} style={{ textAlign: 'center' }}>
                                                    <div className="ui active inline loader small" ></div> กำลังโหลด
                                                </td>
                                            </tr>
                                            : null
                                    }

                                </tbody>
                            </table>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}