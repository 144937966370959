import React from 'react';
import StoreTransaction from './../Store/include/StoreTransaction';
import { Link } from '@reach/router';
export default class ExchangeHistory extends React.Component {
    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Store Transaction</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">Store Transaction</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <StoreTransaction
                            typeIds={`21`}
                            showStore={true}
                            selectStore={true}
                        />
                    </div>
                </section>
            </>
        )
    }
}