import React from 'react';
const axios = require('axios').default;


export default class PaymentChecking extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {
        this.run()
    }

    async run() {
        let _data = {};
        _data.transactionUid = this.props.transactionUid;
        let res = await axios.post(window.api_host + '/payment-checking', { ..._data });
        if (res.data.success) {
            window.location.href = res.data.data.redirect_url;
        }


        if (res.data.error) {
            // window.$(document).Toasts('create', {
            //     title: res.data.message,
            //     icon: 'fas fa-times',
            //     autohide: true,
            //     class: "bg-danger",
            //     delay: 3000,
            // });
            // window.location.href = '/payment-failed'
        }
    }

    render() {
        return (
            <>
                <section className={"content"} style={{paddingTop: 15}}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <h1 style={{color: 'green'}}>กำลังตรวจสอบการชำระเงิน</h1>
                        <div className="ui active inline loader" ></div>
                        <p style={{marginTop: 15}}>กรุณารอสักครู่</p>
                    </div>
                </section>
            </>
        )
    }
}