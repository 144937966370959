import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../../Helper';
import GoogleMap from '../../Components/GoogleMap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StorePackage from './include/StorePackage';
import StoreReview from './include/StoreReview';
import StoreFeed from './include/StoreFeed';
import StoreEventAndPromotion from './include/StoreEventAndPromotion';
import StoreUsers from './include/StoreUsers';
import StoreGallery from './include/StoreGallery';
import StoreMenu from './include/StoreMenu';
import Live from './include/Live'
import Artist from './include/Artist'
import OnlineUser from './include/OnlineUser'
import StoreTransaction from './include/StoreTransaction'
import { getStore } from '../../services/StoreServices';
import StoreFoodImages from './include/StoreFoodImages';
const axios = require('axios').default;

export default class View extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
            is_user_loading: true,
            is_package_loading: true,
            is_review_loading: true,
            is_promotion_loading: true,
            users: [],
            promotions: [],
            feeds: [],
            reviews: [],
            package_types: false,
            package: false,
            count_live: 0,
            all_users: 0,

            owner: false,

            store_gallery: [],
            is_gallery_loading: true,
            is_sequence_loading: false,
        }
        this.ArtistRef = React.createRef();
        this.StoreTransactionRef = React.createRef();
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.post(window.api_host + '/store', {
            data: {
                uid: this.props.uid,
                only_main_package: true,
                package_type: true,
            }
        });
        let store = false;
        let package_types = false;
        if (res.data.success) {
            store = res.data.response.store;
            let _store = await getStore(this.props.uid);
            if (!_store.error) {
                store.storeData = _store;
            }
            package_types = res.data.response.package_types;
        }
        this.setState({
            data: store,
            package_types: package_types,
            is_loading: false,
            is_package_loading: false,
        })
    }



    async fetchPromotions() {
        let qs = {
            uid: this.props.uid,
            // page: this.state.page,
            // limit: this.state.limit,
            lang: 'th'
        }
        let res = await axios.get(window.api_host + '/store-promotion?' + window.$.param(qs));
        let promotions_arr = [];
        if (res.data.success) {
            promotions_arr = [...res.data.response.promotions]
        }
        this.setState({
            promotions: promotions_arr,
            is_promotion_loading: false,
        })
    }

    async fetchReview() {
        let res = await axios.get(window.api_host + '/store-reviews?uid=' + this.props.uid);
        let reviews = [];
        if (res.data.success) {
            reviews = [...res.data.response.reviews]
        }
        this.setState({
            reviews: reviews,
            is_review_loading: false,
        })
    }

    async fetchGallery() {
        let qs = {};
        qs.storeUid = this.props.uid;
        let res = await axios.get(window.api_host + '/store-gallery?' + window.$.param(qs));
        let store_gallery = [];
        // console.log(res.data);
        if (res.data.success) {
            store_gallery = res.data.store_gallery;
        }
        this.setState({
            store_gallery: store_gallery,
            is_sequence_loading: false,
            is_gallery_loading: false,
        })
    }




    updateStoreStatus(type) {
        let title = 'เปิดร้าน';
        let description = `ยืนยันการ${title}`;
        if (type === 'close') {
            title = 'ปิดร้าน';
            description = `หากทำการปิดร้านระบบจะทำการปิดระบบการใช้งานของผู้ใช้งานทั้งหมดในร้าน, ยืนยันการ${title} ?`;
        }

        Helper.confirmModal({
            title: title,
            description: description,
            onConfirm: () => {
                this.setState({
                    is_loading: true
                }, async () => {
                    let res = false;
                    let message = '';
                    if (type === 'close') {
                        res = await axios.post(window.api_host + '/close-store', { storeUid: this.props.uid });
                        message = 'ปิดร้านสำเร็จ';
                    }
                    if (type === 'open') {
                        res = await axios.post(window.api_host + '/open-store', { storeUid: this.props.uid });
                        message = 'เปิดร้านสำเร็จ';
                    }
                    if (res.data.code === 200) {
                        window.$(document).Toasts('create', {
                            title: message,
                            icon: 'fas fa-check',
                            autohide: true,
                            class: "bg-success",
                            delay: 3000,
                        });
                        this.fetch();
                    }
                    if (res.data.code !== 200) {
                        let description = false;
                        if (res.data.description) {
                            description = res.data.description
                        }
                        window.$(document).Toasts('create', {
                            title: res.data.message,
                            description: description,
                            icon: 'fas fa-times',
                            autohide: true,
                            class: "bg-danger",
                            delay: 3000,
                        });
                        this.fetch();
                    }
                })
            }
        })


    }

    // async fetchPackage() {
    //     let res = await axios.get(window.api_host + '/store-package?uid=' + this.props.uid);
    //     let _package = false;
    //     if (res.data.response) {
    //         _package = res.data.response;
    //     }

    //     this.setState({
    //         package: _package,
    //         is_package_loading: false,
    //     })
    // }

    async delete() {
        let data = { storeUid: this.props.uid };
        Helper.confirmModal({
            title: 'ยกเลิกร้าน',
            description: 'ยืนยันการยกเลิกร้าน' + this.state.data.nameTH,
            onConfirm: async () => {
                this.setState({
                    is_loading: true,
                    is_user_loading: true,
                    is_package_loading: true,
                    is_review_loading: true,
                    is_promotion_loading: true,
                }, async () => {
                    try {
                        let ref = await axios.delete(window.api_host + '/store?' + window.$.param(data));
                        // console.log(ref.data);
                        if (ref.data.code === 200 && ref.data.success) {
                            window.$(document).Toasts('create', {
                                title: 'แก้ไขข้อมูลสำเร็จ',
                                icon: 'fas fa-check',
                                autohide: true,
                                class: "bg-success",
                                delay: 3000,
                            })
                            this.fetch();
                        }
                        if (ref.data.code === 200 && ref.data.error) {
                            let message = {
                                icon: 'fas fa-times',
                                autohide: true,
                                class: "bg-danger",
                                delay: 3000,
                            }
                            message.title = ref.data.message;
                            if (ref.data.description) {
                                message.description = ref.data.description
                            }
                            window.$(document).Toasts('create', { ...message });
                            this.setState({
                                is_loading: false,
                                is_user_loading: false,
                                is_package_loading: false,
                                is_review_loading: false,
                                is_promotion_loading: false,
                            })
                        }
                    } catch (error) {
                        window.$(document).Toasts('create', {
                            title: 'ไม่สามารถเพิ่มข้อมูลได้',
                            body: 'เพิ่มข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง',
                            icon: 'fas fa-check',
                            autohide: true,
                            class: "bg-danger",
                            delay: 3000,
                        })
                        this.setState({
                            is_loading: false,
                            is_user_loading: false,
                            is_package_loading: false,
                            is_review_loading: false,
                            is_promotion_loading: false,
                        })
                    }
                })

            },
            onCancel: () => {
                this.setState({
                    is_loading: false
                })
            }
        })
    }

    chanageStatus(status) {
        this.setState({
            is_loading: true
        }, async () => {
            let update_data = {
                status: status,
                storeUid: this.props.uid
            }
            // let res = await axios.post(window.api_host + '/store-update-status', update_data);
            await axios.post(window.api_host + '/store-update-status', update_data);
            // console.log(res);
            this.fetch();
        })
    }



    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">{this.props.type === 'place' ? 'สถานที่' : 'ร้านค้า'} </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/" + this.props.type}>{this.props.type === 'place' ? 'สถานที่' : 'ร้านค้า'}</Link></li>
                                    <li className="breadcrumb-item active">ดูข้อมูล</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={"col-12 col-md-3"}>
                                {/* STORE INFO */}
                                <div className="card card-outline">
                                    <div className="card-body box-profile">
                                        {
                                            this.state.is_loading ?
                                                <div className="absolute_loading">
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && !this.state.data ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    ไม่มีข้อมูล
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.data ?
                                                <>
                                                    <div className="flex row">
                                                        {
                                                            this.props.type === 'place' ?
                                                                <div>
                                                                    <div>
                                                                        <div className="dropdown">
                                                                            <button
                                                                                className="btn btn-tools dropdown-toggle"
                                                                                type="button"
                                                                                id="dropdownMenuButton"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                                style={{ padding: 0 }}
                                                                            >
                                                                                {
                                                                                    this.state.data.status ?
                                                                                        <>
                                                                                            {
                                                                                                this.state.data.status === 'online' ?
                                                                                                    <>
                                                                                                        <i className="fas fa-circle" style={{ color: 'green' }} /> ออนไลน์
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                this.state.data.status === 'offline' ?
                                                                                                    <>
                                                                                                        <i className="fas fa-circle" style={{ color: 'grey' }} /> ออฟไลน์
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                this.state.data.status === 'pause' ?
                                                                                                    <>
                                                                                                        <i className="fas fa-circle" style={{ color: 'orange' }} /> รอชำระเงิน
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                this.state.data.status === 'cancel' ?
                                                                                                    <>
                                                                                                        <i className="fas fa-circle" style={{ color: 'red' }} /> ยกเลิก
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                            </button>
                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                {
                                                                                    this.state.data.status !== 'online' ?
                                                                                        <button
                                                                                            type="button"
                                                                                            className="dropdown-item"
                                                                                            onClick={() => { this.chanageStatus('online') }}
                                                                                        >
                                                                                            <i className="fas fa-circle" style={{ color: 'green' }} /> ออนไลน์
                                                                                        </button>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    this.state.data.status !== 'offline' ?
                                                                                        <button
                                                                                            type="button"
                                                                                            className="dropdown-item"
                                                                                            onClick={() => { this.chanageStatus('offline') }}
                                                                                        >
                                                                                            <i className="fas fa-circle" style={{ color: 'grey' }} /> ออฟไลน์
                                                                                        </button>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    this.state.data.status !== 'cancel' ?
                                                                                        <button
                                                                                            type="button"
                                                                                            className="dropdown-item"
                                                                                            onClick={() => {
                                                                                                this.delete()
                                                                                            }}
                                                                                        >
                                                                                            <i className="fas fa-circle" style={{ color: 'red' }} /> ยกเลิก
                                                                                        </button>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            this.state.data.isPrivate ?
                                                                                <span className="badge badge-info">ส่วนตัว</span>
                                                                                :
                                                                                <span className="badge badge-dark">สาธารณะ</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div>
                                                                    {
                                                                        this.state.data.status ?
                                                                            <div>
                                                                                {
                                                                                    this.state.data.status === 'online' ?
                                                                                        <>
                                                                                            <i className="fas fa-circle" style={{ color: 'green' }} /> ออนไลน์
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    this.state.data.status === 'offline' ?
                                                                                        <>
                                                                                            <i className="fas fa-circle" style={{ color: 'grey' }} /> ออฟไลน์
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    this.state.data.status === 'pause' ?
                                                                                        <>
                                                                                            <i className="fas fa-circle" style={{ color: 'orange' }} /> รอชำระเงิน
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    this.state.data.status === 'cancel' ?
                                                                                        <>
                                                                                            <i className="fas fa-circle" style={{ color: 'red' }} /> ยกเลิก
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    <div>
                                                                        {
                                                                            this.state.data.isPrivate ?
                                                                                <span className="badge badge-info">ส่วนตัว</span>
                                                                                :
                                                                                <span className="badge badge-dark">สาธารณะ</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                        }
                                                        <div className="right-floated">

                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fas fa-bars"></i>
                                                                </button>
                                                                <div className="dropdown-menu float-right" role="menu">
                                                                    {
                                                                        this.state.data
                                                                            && (this.state.data.status === "online"
                                                                                || this.state.data.status === "offline") ?
                                                                            <>
                                                                                <div className="dropdown-item"
                                                                                    onClick={() => {
                                                                                        if (this.state.data
                                                                                            && this.state.data.status === "offline") {
                                                                                            this.updateStoreStatus('open')
                                                                                        }
                                                                                        if (this.state.data
                                                                                            && this.state.data.status === "online") {
                                                                                            this.updateStoreStatus('close')
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        this.state.data
                                                                                            && this.state.data.status === "offline" ?
                                                                                            <><i className="fas fa-circle" style={{ color: 'green' }} /> เปิดร้าน</>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        this.state.data
                                                                                            && this.state.data.status === "online" ?
                                                                                            <><i className="fas fa-circle" style={{ color: 'grey' }} /> ปิดร้าน</> : null
                                                                                    }
                                                                                </div>
                                                                                <div className="dropdown-divider"></div>
                                                                            </>
                                                                            : null
                                                                    }
                                                                    <Link to={'/' + this.props.type + "/edit/" + this.props.uid} className="dropdown-item"><i className="fas fa-pen" /> แก้ไข</Link>
                                                                    {
                                                                        this.state.data.status !== 'cancel' ?
                                                                            <>
                                                                                <div className="dropdown-divider"></div>
                                                                                <button
                                                                                    className="dropdown-item"
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        this.delete();
                                                                                    }}><i className="fas fa-ban" style={{ color: 'red' }} /> ยกเลิก</button>
                                                                            </>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="profile-user-img img-frame img-fluid img-circle">
                                                            <img
                                                                src={this.state.data.photoPath ? this.state.data.photoPath : "/assets/images/no_upload_image.jpg"}
                                                                alt="Store profile" />
                                                        </div>
                                                    </div>
                                                    {/* <div className="flex-row flex-center  mt-2">
                                                        <Link to={'/' + this.props.type + "/edit/" + this.props.uid} className="link"> แก้ไข</Link>
                                                    </div> */}
                                                    <h3 className="profile-username text-center">{this.state.data.nameTH || '-'}</h3>

                                                    <p className="text-muted text-center">{this.state.data.nameEN || '-'}</p>
                                                    <div className='mb-2' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                        <span style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, padding: '0 10px', borderRight: '1px solid #f4f4f4', alignItems: 'center' }}>
                                                            {this.state.data && this.state.data.storeData && this.state.data.storeData.currentCoin ? Helper.numberFormat(this.state.data.storeData.currentCoin) : 0}
                                                            <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ width: 20, marginLeft: 5 }} />
                                                        </span>
                                                        <span style={{ display: 'flex', flex: 1, padding: '0 10px', borderLeft: '1px solid #f4f4f4', alignItems: 'center' }}>
                                                            {this.state.data && this.state.data.storeData && this.state.data.storeData.currentPoint ? Helper.numberFormat(this.state.data.storeData.currentPoint) : 0}
                                                            <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                                        </span>
                                                    </div>
                                                    <ul className="list-group list-group-unbordered mb-3">
                                                        <li className="list-group-item">
                                                            <b>ผู้ติดตาม</b> <div className="float-right">{Helper.numberFormat(this.state.follow_amount)}</div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <b>ราคา</b> <div className="float-right">฿ {Helper.numberFormat(this.state.data.minPrice)} - {Helper.numberFormat(this.state.data.maxPrice)}</div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <b>จำนวนผู้เข้าร้าน</b> <div className="float-right">{Helper.numberFormat(this.state.data.guestsLimit)}</div>
                                                        </li>
                                                    </ul>

                                                    <div className="flex row">
                                                        <div className={"right-floated"}>{Helper.getDateThai(this.state.data.createdAt, { is_show_time: true })}</div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.data
                                        && this.state.data.package ?
                                        <div className="small-box bg-warning">
                                            <div className="inner">
                                                <h3>{this.state.data.package.option.name}</h3>
                                                {
                                                    this.state.data.current_billing
                                                        ?
                                                        <p>{Helper.getDateThai(new Date(this.state.data.current_billing.expiredAt))}</p>
                                                        :
                                                        "ไม่มีข้อมูล"
                                                }
                                                {
                                                    this.state.data.status === 'pause' ?
                                                        <p>ระงับชั่วคราว (รอชำระเงิน)</p>
                                                        : null
                                                }
                                            </div>
                                            <div className="icon">
                                                <i className="fas fa-className="></i>
                                            </div>
                                            <div className="small-box-footer"
                                                onClick={() => {
                                                    this.package_btn_tabs.trigger('click');
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >ดูข้อมูล <i className="fas fa-arrow-circle-right"></i></div>
                                        </div>
                                        : null
                                }
                                {
                                    this.state.data ?
                                        <>
                                            {
                                                this.state.data
                                                    && this.state.data.coordinate ?
                                                    <div className="form-group">
                                                        <GoogleMap height={150}
                                                            mode={"view"}
                                                            lat={this.state.data.coordinate._latitude}
                                                            lng={this.state.data.coordinate._longitude}
                                                        />
                                                    </div>
                                                    : null
                                            }
                                            <div className="card">
                                                <div className="card-body">
                                                    <strong><i className="far fa-file-alt mr-1"></i> ภาพรวม</strong>
                                                    <p className="text-muted">
                                                        {this.state.data.overview}
                                                    </p>
                                                    <hr />
                                                    <strong><i className="fas fa-map-marker-alt mr-1"></i>ที่อยู่</strong>
                                                    <p className="text-muted">
                                                        {
                                                            this.state.data.contact
                                                                && this.state.data.contact.address ?
                                                                this.state.data.contact.address + ' '
                                                                : null
                                                        }
                                                        {
                                                            this.state.data.contact.province
                                                                && this.state.data.contact.province.name ?
                                                                this.state.data.contact.province.name.th : null
                                                        }
                                                    </p>
                                                    {
                                                        this.state.data.contact
                                                            && this.state.data.contact.email ?
                                                            <>
                                                                <hr />
                                                                <div className="flex row">
                                                                    <div>
                                                                        <strong><i className="far fa-envelope"></i> อีเมล์</strong>
                                                                    </div>
                                                                    <div className="text-muted right-floated">
                                                                        {
                                                                            this.state.data.contact
                                                                                && this.state.data.contact.email ?
                                                                                this.state.data.contact.email
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        this.state.data.contact
                                                            && this.state.data.contact.telephone ?
                                                            <>
                                                                <hr />
                                                                <div className="flex row">
                                                                    <div>
                                                                        <strong><i className="fas fa-mobile-alt"></i> เบอร์โทรศัพท์</strong>
                                                                    </div>
                                                                    <div className="text-muted right-floated">
                                                                        {
                                                                            this.state.data.contact
                                                                                && this.state.data.contact.telephone ?
                                                                                this.state.data.contact.telephone
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        this.state.data.contact
                                                            && this.state.data.contact.website ?
                                                            <>
                                                                <hr />
                                                                <div className="flex row">
                                                                    <div>
                                                                        <strong><i className="fas fa-globe"></i> เว็บไซด์</strong>
                                                                    </div>
                                                                    <div className="text-muted right-floated">
                                                                        {
                                                                            this.state.data.contact
                                                                                && this.state.data.contact.website ?
                                                                                this.state.data.contact.website
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                    }



                                                </div>
                                            </div>
                                            {
                                                this.state.data.workingTime ?
                                                    <div className="card collapsed-card">
                                                        <div className="card-header">
                                                            <div className="card-title">วันเวลาทำการ</div>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.monday}
                                                                title={"วันจันทร์"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.tuesday}
                                                                title={"วันอังคาร"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.wednesday}
                                                                title={"วันพุธ"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.thursday}
                                                                title={"วันพฤหัส"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.friday}
                                                                title={"วันศุกร์"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.saturday}
                                                                title={"วันเสาร์"}
                                                            />
                                                            <hr />
                                                            <DateTimeView
                                                                day={this.state.data.workingTime.sunday}
                                                                title={"วันอาทิตย์"}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                        </>
                                        : null
                                }
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="card card-primary card-outline card-outline-tabs">
                                    <div className="card-header p-0 border-bottom-0">
                                        <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active"
                                                    id="custom-tabs-three-home-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-home"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-home"
                                                    aria-selected="false"
                                                >
                                                    <h5>สมาชิก</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    id="online-user-tab"
                                                    data-toggle="pill"
                                                    href="#online-user"
                                                    role="tab"
                                                    aria-controls="online-user"
                                                    aria-selected="false"
                                                >
                                                    <h5>จัดการคนเข้าร้าน</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    id="custom-tabs-three-profile-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-profile"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-profile"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                        if (this.state.is_promotion_loading) {
                                                            this.fetchPromotions();
                                                        }
                                                    }}
                                                >
                                                    <h5>โปรโมชั่น</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    id="custom-tabs-three-messages-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-messages"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-messages"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                        // console.log(this.StoreFeed)
                                                        this.StoreFeed.fetchPosts();
                                                    }}
                                                >
                                                    <h5>โพส</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    id="custom-tabs-three-settings-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-settings"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-settings"
                                                    aria-selected="true"
                                                    onClick={() => {
                                                        if (this.state.is_review_loading) {
                                                            this.fetchReview();
                                                        }
                                                    }}
                                                >
                                                    <h5>รีวิว</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    ref={(ref) => this.package_btn_tabs = window.$(ref)}
                                                    id="custom-tabs-three-package-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-package"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-settings"
                                                    aria-selected="true"
                                                // onClick={() => {
                                                //     this.getOwnerData();
                                                // }}
                                                >
                                                    <h5>แพ็คเกจ</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    id="gallery-settings-tab"
                                                    data-toggle="pill"
                                                    href="#gallery-settings"
                                                    role="tab"
                                                    aria-controls="gallery-settings"
                                                    aria-selected="true"
                                                    onClick={() => {
                                                        if (this.state.is_gallery_loading) {
                                                            this.fetchGallery();
                                                        }
                                                    }}
                                                >
                                                    <h5>แกลอรี่</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    id="item-menu-settings-tab"
                                                    data-toggle="pill"
                                                    href="#item-menu-settings"
                                                    role="tab"
                                                    aria-controls="item-menu-settings"
                                                    aria-selected="true"
                                                >
                                                    <h5>เมนู</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    id="item-menu-artist-tab"
                                                    data-toggle="pill"
                                                    href="#item-menu-artist"
                                                    role="tab"
                                                    aria-controls="item-menu-artist"
                                                    aria-selected="true"
                                                    onClick={() => {
                                                        this.ArtistRef.current.resetData();
                                                    }}
                                                >
                                                    <h5>นักดนตรี</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    id="item-menu-live-tab"
                                                    data-toggle="pill"
                                                    href="#item-menu-live"
                                                    role="tab"
                                                    aria-controls="item-menu-live"
                                                    aria-selected="true"
                                                >
                                                    <h5>LIVE</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    id="item-menu-exchange-tab"
                                                    data-toggle="pill"
                                                    href="#item-menu-exchange"
                                                    role="tab"
                                                    aria-controls="item-menu-exchange"
                                                    aria-selected="true"
                                                    onClick={() => {
                                                        this.StoreTransactionRef.current.firstFetch();
                                                    }}
                                                >
                                                    <h5>ประวัติการแลกแต้ม</h5>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content" id="custom-tabs-three-tabContent">
                                            <div className="tab-pane fade active show" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                                                <StoreUsers
                                                    uid={this.props.uid}
                                                />
                                            </div>
                                            <div className="tab-pane fade " id="online-user" role="tabpanel" aria-labelledby="online-user-tab">
                                                <OnlineUser
                                                    uid={this.props.uid}
                                                />
                                            </div>
                                            <div className="tab-pane fade " id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                                                <StoreEventAndPromotion
                                                    uid={this.props.uid}
                                                    is_loading={this.state.is_promotion_loading}
                                                    promotions={this.state.promotions}
                                                    fetchPromotion={() => {
                                                        this.setState({
                                                            is_promotion_loading: true,
                                                        }, () => {
                                                            this.fetchPromotions()
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-three-messages" role="tabpanel" aria-labelledby="custom-tabs-three-messages-tab">
                                                <StoreFeed
                                                    ref={(ref) => { this.StoreFeed = ref }}
                                                    uid={this.props.uid}
                                                    is_loading={this.state.is_feed_loading}
                                                    feeds={this.state.feeds}
                                                    data={this.state.data}
                                                />
                                            </div>
                                            <div className="tab-pane fade " id="custom-tabs-three-settings" role="tabpanel" aria-labelledby="custom-tabs-three-settings-tab">
                                                <StoreReview
                                                    reviews={this.state.reviews}
                                                    uid={this.props.uid}
                                                    is_loading={this.state.is_review_loading}
                                                />
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-three-package" role="tabpanel" aria-labelledby="custom-tabs-three-settings-tab">
                                                <StorePackage
                                                    data={this.state.data}
                                                    uid={this.props.uid}
                                                    package_types={this.state.package_types}
                                                    is_loading={this.state.is_package_loading}
                                                    owner={this.state.owner}
                                                    adminUid={this.props.adminUid}
                                                    fetch={() => {
                                                        this.setState({
                                                            is_loading: true,
                                                            is_package_loading: true,
                                                        }, () => {
                                                            this.fetch()
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="tab-pane fade" id="gallery-settings" role="tabpanel" aria-labelledby="gallery-settings-tab">
                                                <StoreGallery
                                                    data={this.state.data}
                                                    uid={this.props.uid}
                                                    is_loading={this.state.is_gallery_loading}
                                                    is_sequence_loading={this.state.is_sequence_loading}
                                                    store_gallery={this.state.store_gallery}
                                                    fetch={() => this.fetchGallery()}
                                                />
                                            </div>
                                            <div className="tab-pane fade" id="item-menu-settings" role="tabpanel" aria-labelledby="item-menu-settings-tab">
                                                {
                                                    this.state.data ?
                                                        <MenuTab
                                                            uid={this.props.uid}
                                                            data={this.state.data}
                                                        />
                                                        : null
                                                }
                                            </div>
                                            <div className="tab-pane fade" id="item-menu-live" role="tabpanel" aria-labelledby="item-menu-live-tab">
                                                <Live
                                                    uid={this.props.uid}
                                                    storeId={this.state.data && this.state.data.storeData ? this.state.data.storeData.id : false}
                                                />
                                            </div>
                                            <div className="tab-pane fade" id="item-menu-artist" role="tabpanel" aria-labelledby="item-menu-artist-tab">
                                                <Artist
                                                    ref={this.ArtistRef}
                                                    uid={this.props.uid}
                                                    id={this.state.data && this.state.data.storeDate ? this.state.data.storeData.id : false}
                                                    is_loading={this.state.is_user_loading}
                                                    users={this.state.users}
                                                    data={{
                                                        all_users: this.state.all_users,
                                                        count_live: this.state.count_live,
                                                        count_average_per_day: this.state.count_average_per_day,
                                                    }}
                                                />
                                            </div>
                                            <div className="tab-pane fade" id="item-menu-exchange" role="tabpanel" aria-labelledby="item-menu-exchange-tab">
                                                <StoreTransaction
                                                    ref={this.StoreTransactionRef}
                                                    uid={this.props.uid}
                                                    id={this.state.data && this.state.data.storeDate ? this.state.data.storeData.id : false}
                                                    is_loading={this.state.is_user_loading}
                                                    disableRunDidMount={true}
                                                    disabledReset={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class MenuTab extends React.Component {
    constructor(props) {
        super(props);
        let selected = 'list';
        if (props.data && props.data.foodRendertype) {
            selected = props.data.foodRendertype;
        }
        this.state = {
            selected: selected
        };
    }

    async updateStore(value) {
        let data = {
            foodRendertype: value
        }
        let ref = await axios.put(`${window.api_host}/update-store/${this.props.uid}/food-render-type`, { ...data });
        let selected = this.state.selected;
        if (ref.data.success) {
            selected = value;
        }
        this.setState({
            selected: selected
        })
    }

    render() {
        return (<>
            <div className='row form-group'>
                <div className='col-12 col-md-4 col-lg-3'>
                    <select
                        className='form-control'
                        defaultValue={this.state.selected}
                        onChange={(e) => {
                            let value = e.target.value;
                            if (this.state.selected == value) { return; }
                            this.updateStore(value)
                        }}
                    >
                        <option value="list">แสดงเป็นรายการ</option>
                        <option value="image">แสดงเมนูภาพ</option>
                    </select>
                </div>
            </div>
            {
                this.state.selected == 'list' ?
                    <StoreMenu
                        uid={this.props.uid}
                    />
                    : null
            }
            {
                this.state.selected == 'image' ?
                    <StoreFoodImages
                        uid={this.props.uid}
                    />
                    : null
            }
        </>
        )
    }
}

// class DateProgress extends React.Component {
//     render() {
//         let return_class = "progress-bar";
//         if (this.props.progress < 25) {
//             return_class += " bg-danger";
//         }
//         if (this.props.progress >= 25 && this.props.progress < 50) {
//             return_class += " bg-warning";
//         }
//         if (this.props.progress >= 50 && this.props.progress < 75) {
//             return_class += " bg-info";
//         }
//         if (this.props.progress >= 75) {
//             return_class += " bg-success";
//         }
//         return (
//             <div>
//                 <div className="flex row">
//                     <b>{this.props.title}</b>
//                     <div className="right-floated">{this.props.amount || 0}</div>
//                 </div>
//                 <div>
//                     <div className="progress">
//                         <div className={return_class} role="progressbar" aria-valuenow={this.props.progress} aria-valuemin="0" aria-valuemax="100" style={{ width: this.props.progress + "%" }}>
//                             <span className="sr-only">{this.props.progress}% Complete (success)</span>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }

class DateTimeView extends React.Component {
    render() {
        return (
            <div className="flex row">
                <div>
                    <strong><i className="far fa-calendar-alt"></i> {this.props.title}</strong>
                </div>
                <div className="text-muted right-floated">
                    {
                        this.props.day
                            && this.props.day.time
                            && this.props.day.time.open
                            && this.props.day.time.close ?
                            <><i className="far fa-clock"></i> {this.props.day.time.open + ' - ' + this.props.day.time.close}</>
                            : 'ปิดทำการ'
                    }
                </div>
            </div>
        );
    }
}

