import React, { useContext, useEffect, useState } from "react";
import { Router } from "@reach/router";
import SignIn from "./SignIn";
// import SignUp from "./SignUp";
// import { Helper } from "../Helper";
// import UserProvider from "../providers/UserProvider";
// import ProfilePage from "./ProfilePage";
import Header from "./../Include/Header";
import SideBar from "./../Include/SideBar";
import Dashboard from "./../Page/Dashboard";
import Transection from './../Page/Payment/Transection'
// import TestPage from './../Page/TestPage';
import Store from './../Page/Store/Index';
import StoreForm from './../Page/Store/Form';
import StoreType from './../Page/Store/Type';
import StoreView from './../Page/Store/View';
import AdminDetail from '../Page/Admin/AdminDetail';
import AdminSetting from './../Page/Admin/AdminSetting';
import Package from './../Page/Package/Index';
import PackageForm from '../Page/Package/Form';
import PackageView from './../Page/Package/View';
import PackageSequence from './../Page/Package/Sequence';
import AppProduct from './../Page/App/Product';
import AppProductForm from './../Page/App/Form';
import Report from './../Page/Report/Index'
import Mockup from './../Page/Mockup';
import Sale from './../Page/Sale/Index';
import Artist from './../Page/Artist/Index';
import ArtistView from './../Page/Artist/View';
import Member from "./../Page/Member/Index";
import MemberView from './../Page/Member/View';
import Language from './../Page/Language/Index';
import AppSetting from './../Page/AppSetting';
import RecommentPlace from '../Page/RecommentPlace/Index';
import RecommentPlaceView from '../Page/RecommentPlace/View';
import RecommentPlaceForm from '../Page/RecommentPlace/Form';
import RegisterStore from '../Page/RegisterStore/Index';
import RegisterStoreView from '../Page/RegisterStore/View';
import RegisterStoreForm from '../Page/RegisterStore/Form';
import Rank from '../Page/Rank/Index'
import RankForm from '../Page/Rank/Form'
import PaymentPackageDetail from '../Page/Payment/PaymentPackageDetail'
import PaymentChecking from '../Page/Payment/PaymentChecking';
import AddPost from '../Page/Post/Add'
import FormEventPromotion from '../Page/Promotion/FormEventPromotion';
import Gift from '../Page/Gift/Index'
import Transaction from '../Page/Transaction/Index'
import CoinPackage from '../Page/CoinPackage/Index'
import Channels from '../Page/TopUpPackage/Channels'
import ChannelsPackage from '../Page/TopUpPackage/ChannelsPackage';
import MusicRequest from '../Page/Staticts/MusicRequest';
import AngPaoHistory from '../Page/AngPao/History';
import TransferUser from '../Page/App/TransferUser';
import ExchangeHistory from '../Page/Exchange/History';
import ExchangPoint from '../Page/Exchange/ExchangPoint';

import { UserContext } from "../providers/UserProvider";

import { db, api_host, api_host_app } from './../firebase';
window.months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม",];
window.segment = window.location.pathname.split('/');

let is_emulators = false;
if (window.location.origin.indexOf('localhost:') !== -1) {
  is_emulators = true;
}
window.api_host = api_host;
if (is_emulators) {
  window.api_host = "http://localhost:5001/skitz-app-test/asia-southeast2/backend";
}
window.api_host_app = api_host_app;
function Application() {
  const user = useContext(UserContext);
  const [isCloseServer, setCloseServer] = useState(false)
  window.adminUid = user.uid;
  // const [isAPI, setIsAPI] = useState(false);
  useEffect(() => {
    // console.log(user)
    db.collection(`constants`).doc(`closeServer`).onSnapshot(async (snapshot) => {
      // console.log("snapshot", snapshot)
      let ref = await snapshot.ref.get();
      let data = ref.data();
      setCloseServer(data.value)
      // setCloseServer(false)
      // snapshot.docChanges().forEach(function (change) {
      //   console.log(change)
      //   let data = change.doc.data();
      //   console.log("data",data)
      //   
      //   var source = snapshot.metadata.fromCache ? "local cache" : "server";
      //   console.log("Data came from " + source);
      // });
    })
  }, []);

  return (
    user && user.uid ?
      isCloseServer && !user.is_super_admin ?
        <ServerClose />
        :
        <div className={"hold-transition sidebar-mini"}>
          <div className="wrapper">
            <Header user={user} />
            <SideBar user={user} />
            <Router className="content-wrapper">
              <Dashboard path="/" />
              <Dashboard path="/dashboard" />
              {
                user.permission
                  && user.permission.store
                  && (
                    user.permission.store.add
                    || user.permission.store.edit
                    || user.permission.store.delete
                    || user.permission.store.view
                  )
                  ?
                  <>
                    <Store path="/store" type={"store"} />
                    <StoreForm path="/store/add" mode="create" type={"store"} />
                    <StoreForm path="/store/edit/:uid" mode="edit" type={"store"} />
                    <StoreType path="/store-types" />
                    <StoreView path="/store/view/:uid" type={"store"} adminUid={user.uid} />
                    <Store path="/place" type={"place"} />
                    <StoreView path="/place/view/:uid" type={"place"} adminUid={user.uid} />
                    <StoreForm path="/place/add" mode="create" type={"place"} />
                    <StoreForm path="/place/edit/:uid" mode="edit" type={"place"} />
                    <Transection path="/transection/:transectionUid" />
                    <RecommentPlace path="/recomment-place" />
                    <RecommentPlaceView path="/recomment-place/view/:uid" />
                    <RecommentPlaceForm path="/recomment-place/edit/:uid" mode="edit" type={"place"} />
                    <PaymentPackageDetail path="/payment/store/:storeUid/payment-package/:packageUid" adminUid={user.uid} />
                    <PaymentChecking path="/payment-checking/:transactionUid" />
                    <RegisterStore path="/register-store" />
                    <RegisterStoreView path="/register-store/view/:uid" />
                    <RegisterStoreForm path="/register-store/edit/:uid" mode="edit" type={"store"} />
                    <AddPost path="/post/add/:uid" mode="add" adminUid={user.uid} />
                    <FormEventPromotion path="/promotion/add/:uid" mode="add" adminUid={user.uid} />
                    <FormEventPromotion path="/promotion/edit/:uid/:eventPromotionUid" mode="edit" adminUid={user.uid} />
                    <ExchangeHistory path="/exchange/transaction" />
                    <ExchangPoint path="/exchange/point" admin={user} />
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.package
                  && (
                    user.permission.package.add
                    || user.permission.package.edit
                    || user.permission.package.delete
                    || user.permission.package.view
                  )
                  ?
                  <>
                    <Package path="/package" />
                    <PackageForm path="/package/add" mode="create" />
                    <PackageForm path="/package/edit/:packageUid/:uid" mode="edit" />
                    <PackageView path="/package/view/:uid" />
                    <PackageSequence path="/package/sequence" />
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.sale
                  && (
                    user.permission.sale.add
                    || user.permission.sale.edit
                    || user.permission.sale.delete
                    || user.permission.sale.view
                  )
                  ?
                  <>
                    <Sale path="/sale" />
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.brand
                  && (
                    user.permission.brand.add
                    || user.permission.brand.edit
                    || user.permission.brand.delete
                    || user.permission.brand.view
                  )
                  ?
                  <>
                    <Artist path="/artist" />
                    <ArtistView path="/artist/view/:uid" />
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.gift
                  && (
                    user.permission.gift.add
                    || user.permission.gift.edit
                    || user.permission.gift.delete
                    || user.permission.gift.view
                  )
                  ?
                  <>
                    <Gift path="/gift" />
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.gift
                  && (
                    user.permission.gift.add
                    || user.permission.gift.edit
                    || user.permission.gift.delete
                    || user.permission.gift.view
                  )
                  ?
                  <>
                    <MusicRequest path="/staticts" />
                  </>
                  : null
              }

              {
                user.permission
                  && user.permission.transaction
                  && (
                    user.permission.transaction.add
                    || user.permission.transaction.edit
                    || user.permission.transaction.delete
                    || user.permission.transaction.view
                  )
                  ?
                  <>
                    <Transaction path="/transaction" user={user} />
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.coinPackage
                  && (
                    user.permission.coinPackage.add
                    || user.permission.coinPackage.edit
                    || user.permission.coinPackage.delete
                    || user.permission.coinPackage.view
                  )
                  ?
                  <>
                    <CoinPackage path="/coin-package" />
                    <Channels path="/package-channels" />
                    <ChannelsPackage path="/package-channels/:packageType/:id" />
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.member
                  && (
                    user.permission.member.add
                    || user.permission.member.edit
                    || user.permission.member.delete
                    || user.permission.member.view
                  )
                  ?
                  <>
                    <Member path="/member" />
                    <MemberView path="/member/view/:uid" adminUid={user.uid} user={user} />
                  </>
                  : null
              }
              <Mockup path="/mock-up" />
              <Mockup path="/language-transfer" languageTransfer={true} />
              <AdminDetail path="/admin-detail/:admin_id" />
              {
                user.permission
                  && user.permission.report
                  && (
                    user.permission.report.add
                    || user.permission.report.edit
                    || user.permission.report.delete
                    || user.permission.report.view
                  )
                  ?
                  <>
                    <Report path="/report" adminUid={user.uid} mode="list" />
                    <Report path="/report/view/:id" adminUid={user.uid} mode="view" />
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.rank
                  && (
                    user.permission.rank.add
                    || user.permission.rank.edit
                    || user.permission.rank.delete
                    || user.permission.rank.view
                  )
                  ?
                  <>
                    <Rank path="/rank/:id" />
                    <Rank path="/rank" />
                    <RankForm path="/rank/add" />
                    <RankForm path="/rank/edit/:id" />
                  </>
                  : null
              }
              {
                user.permission
                  && user.permission.admin
                  && (
                    user.permission.admin.add
                    || user.permission.admin.edit
                    || user.permission.admin.delete
                    || user.permission.admin.view
                  )
                  ?
                  <AdminSetting path="/permission" adminUid={user.uid} user={user} />
                  : null
              }
              {
                user.is_super_admin ?
                  <>
                    <Language path={"/language"} />
                    <AppProduct path={"/app-product"} />
                    <AppProductForm path={"/app-product/add"} />
                    <AppProductForm path={"/app-product/edit/:uid"} />
                    <TransferUser path={"/transfer-user"} />
                    <Mockup path="/test" />
                  </>
                  : null
              }
              <AngPaoHistory path={"/angpao/history"} />
              <AppSetting path={"/app-setting"} />
            </Router>
          </div>
        </div>
      :
      <Router>
        <SignIn path="/" />
      </Router>
  );
}

export default Application;


class ServerClose extends React.Component {
  render() {
    return (
      <div className={"hold-transition login-page"}>
        <div className="login-box" style={{ marginTop: '-15rem', width: 450, maxWidth: '100%' }}>
          <div className="login-logo">
            <img src="assets/images/skitz-white.png" alt="Skitz Logo" style={{ maxHeight: 250 }} />
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <h2 className="login-box-msg" style={{ color: '#000' }}>Server Closed</h2>
              <p style={{ textAlign: 'center', fontSize: 20 }}>ระบบทำการปิดปรับปรุง<br />งดใช้บริการชั่วคราว</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}