import React from 'react';
// import { db } from './../../firebase';
import { Helper } from './../../Helper';
import { Link, navigate } from '@reach/router';
import Pagination from './../../Components/Pagination';
import Chart from "react-google-charts";
import thailand from "./../../thailand.json";
import PreviewPackage from './PreviewPackage';
const axios = require('axios').default;
export default class Index extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        let provinces = [];
        for (const [key, value] of Object.entries(thailand)) {
            let amphoes = [];

            for (const [_key, _value] of Object.entries(value.amphoes)) {
                amphoes.push({ code: _key, amphoe: _value.name.th });
                // console.log(`${_key}: ${_value.name.th}`);
            }
            provinces.push({ code: key, province: value.name.th, amphoes: amphoes })
            // console.log(`${key}: ${value.name.th}`);
        }
        this.state = {
            timerage: 'รายเดือน',
            years: years,
            date: new Date().getTime(),
            year: parseFloat(new Date().getFullYear()),
            month: parseFloat(new Date().getMonth()),
            is_loading: true,
            is_chart_loading: true,
            provinces: provinces,
            count_all_item: 0,
            store_count: 0,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
            province_code: Helper.getParameterByName('province_code') ? Helper.getParameterByName('province_code') : '',
            package_type: Helper.getParameterByName('package_type') ? Helper.getParameterByName('package_type') : '',
            isActive: Helper.getParameterByName('isActive') ? Helper.getParameterByName('isActive') : 'true',
            chart: [],
            monthly_income: 0,
        }
    }

    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                is_loading: true,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
                province_code: Helper.getParameterByName('province_code') ? Helper.getParameterByName('province_code') : '',
                package_type: Helper.getParameterByName('package_type') ? Helper.getParameterByName('package_type') : '',
                isActive: Helper.getParameterByName('isActive') ? Helper.getParameterByName('isActive') : 'true',
                chart: [],
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                keyword: '',
                province_code: '',
                package_type: '',
                isActive: Helper.getParameterByName('isActive') ? Helper.getParameterByName('isActive') : 'true',
                data: [],
                chart: [],
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let filter = {
            keyword: this.state.keyword,
            province_code: this.state.province_code,
            package_type: this.state.package_type,
            isActive: this.state.isActive
        }
        // console.log(filter);
        let res = await axios.post(window.api_host + '/package-list', { page: this.state.page, limit: this.state.limit, filter: filter });
        // console.log(res.data);
        let data = res.data.package;
        let count_all_item = res.data.count_all_item;
        let count_item = res.data.count_item;
        let store_package_count = res.data.store_package_count;
        let chart = res.data.chart;
        let monthly_income = res.data.monthly_income;
        if (chart.length <= 1) { chart.push(['ไม่มีข้อมูล', 0]) }
        // console.log(count_item)
        this.setState({
            data: data,
            count_all_item: count_all_item,
            store_package_count: store_package_count,
            count_item: count_item,
            is_loading: false,
            is_chart_loading: false,
            chart: chart,
            monthly_income: monthly_income,
        })
    }

    render() {
        let percent = (this.state.count_item * 100) / this.state.count_all_item;
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">แพ็คเกจ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">แพ็คเกจ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                {/* STORE DETAILS */}
                                <div className="info-box bg-gradient-yellow">
                                    {
                                        this.state.is_loading ?
                                            <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                            : null
                                    }
                                    <span className="info-box-icon"><i className="fas fa-cubes"></i></span>
                                    <div className="info-box-content">
                                        <div className="flex row">
                                            <div>
                                                <span className="info-box-text">แพ็คเกจในระบบ</span>
                                                <span className="info-box-number">{this.state.count_all_item} แพ็คเกจ</span>
                                            </div>
                                            <div style={{ marginLeft: 'auto' }}>
                                                <Link className={"btn btn-default"} to={window.location.pathname + '/add'}>เพิ่ม</Link>
                                            </div>
                                        </div>

                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: percent + '%' }}></div>
                                        </div>
                                        <span className="progress-description">
                                            จำนวนร้านค้าที่ใช้งาน {this.state.store_package_count} ร้าน
                                        </span>
                                    </div>
                                </div>
                                {/* FILTER */}
                                <div className="card card-outline collapsed-card">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form className="row"
                                            ref={(ref) => { this.$form = window.$(ref) }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                                    return;
                                                }
                                                let data = Helper.getFormInputObject(this.$form);
                                                let url = Helper.getQueryStringLinkFromObject({
                                                    page: 1,
                                                    keyword: data.keyword,
                                                    province_code: data.province_code,
                                                    package_type: data.package_type,
                                                    isActive: data.isActive
                                                })
                                                navigate(url)
                                            }}
                                        >
                                            <div className="col-6 form-group">
                                                <label>ค้นหา</label>
                                                <input name="keyword" className="form-control" placeholder="ค้นหาจากชื่อแพ็คเกจ" />
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>จังหวัด</label>
                                                <select
                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"province_code"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.province_code || ''}
                                                >
                                                    <option value={""}>
                                                        ทั้งหมด
                                                    </option>
                                                    {
                                                        this.state.provinces.map((provinces, provinces_i) => {
                                                            return (
                                                                <option value={provinces.code} key={provinces_i}>
                                                                    {provinces.province}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>ประเภท</label>
                                                <select
                                                    className={"form-control"}
                                                    name={"package_type"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.province_code || ''}
                                                >
                                                    <option value={""}>ทั้งหมด</option>
                                                    <option value={"Main"}>Main Package</option>
                                                    <option value={"Offer"}>Offer</option>
                                                </select>
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>สถานะ</label>
                                                <select
                                                    className={"form-control"}
                                                    name={"isActive"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.isActive || ''}
                                                >
                                                    <option value={"true"}>ใช้งาน</option>
                                                    <option value={"false"}>ไม่ใช้งาน</option>
                                                </select>
                                            </div>
                                            <div className="col-12">
                                                <div className="flex row">
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <button type="submit" className="btn btn-default">ค้นหา</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* STORE LIST */}
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายการแพ็คเกจ</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.data.length > 0
                                                && !this.state.is_loading ?
                                                <>
                                                    <ul className="nav nav-pills flex-column">
                                                        {
                                                            this.state.data.map((_data, _data_i) => {
                                                                let type = _data.type;
                                                                // console.log(_data);
                                                                // if(_data.type === 'Main') {
                                                                //     type = "MM";
                                                                // }
                                                                return (
                                                                    <li className="nav-item" key={_data_i}>
                                                                        <Link to={window.location.pathname + "/view/" + _data.packageUid} className="nav-link">
                                                                            <div className="store-item item-row">
                                                                                <div className="description">
                                                                                    <div>{_data.nameTH}</div>
                                                                                    <div>

                                                                                        {
                                                                                            _data.option
                                                                                                && _data.option.summary
                                                                                                && _data.option.summary.total > 0 ?
                                                                                                <span className="badge badge-primary">{Helper.numberFormat(_data.option.summary.total)} ฿</span>
                                                                                                : <span className="badge badge-success">ฟรี</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-floated">
                                                                                    <div className="small-text text-right">
                                                                                        {type}
                                                                                    </div>
                                                                                    <div className="small-text text-right">{Helper.numberFormat(_data.store_count)}</div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </>
                                                : null
                                        }
                                    </div>
                                    {
                                        !this.state.is_loading
                                            && this.state.data.length !== 0 ?
                                            <div className="card-footer clearfix">
                                                <Pagination
                                                    now={this.state.page}
                                                    all_items={this.state.count_all_item}
                                                    limit={this.state.limit}
                                                    location={this.props.location}
                                                />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="card">
                                    {
                                        this.state.is_chart_loading ?
                                            <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                            : null
                                    }

                                    <div className="card-header">
                                        <h5 className="card-title">รายงาน</h5>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ display: 'block' }}>
                                        <strong>รายได้ต่อเดือน : </strong> {Helper.numberFormat(this.state.monthly_income)} ฿
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="chart">
                                                    <Chart
                                                        width={'100%'}
                                                        height={'300px'}
                                                        chartType="ColumnChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.chart}
                                                        options={{
                                                            // isStacked: true,
                                                            legend: { position: 'top', maxLines: 3 },
                                                            vAxis: { minValue: 0 },
                                                            chartArea: { width: '90%', height: '300px' },
                                                        }}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <PackageList
                                                    data={this.state.data || []}
                                                // uid={this.props.uid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class PackageList extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.post(window.api_host + '/store', {
            data: {
                // uid: this.props.uid,
                package_type: true,
                only_main_package: true,
            }
        });
        let package_types = false;
        if (res.data.success) {
            package_types = res.data.response.package_types;
        }

        this.setState({
            data: package_types,
            is_loading: false,
        })
    }
    render() {
        return (
            <>
                <div className="flex row" style={{ justifyContent: 'center' }}>
                    <div className="flex row">
                        <h6 style={{ fontWeight: 'bold' }}>แพ็คเกจ</h6>
                        <p style={{ marginLeft: 10 }}>หลัก</p>
                    </div>
                    <div className="right-floated">
                        <Link to={"/package/sequence"} className="btn btn-default btn-sm">เรียงลำดับใหม่</Link>
                    </div>
                </div>
                <div className={"package-table-scrollbar"} style={{marginTop: 10}}>
                    <PreviewPackage
                        data={this.state.data}
                    />
                </div>
            </>
        );
    }
}