import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/distribute`;
const angPaoBaseUrl = `${_serverUrl}/distribute/angpao`;
export const getAngPao = async (filter) => {
    let url = `${angPaoBaseUrl}`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getAngPaoById = async (id) => {
    let url = `${angPaoBaseUrl}/${id}`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}