import React from 'react';
import { Link } from "@reach/router"
import { db } from '../../firebase';
const axios = require('axios').default;
// var increment = require('version-incrementer').increment;
export default class Dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            lang_code: [],
            lang_obj: [],
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let lang = await db.collection('/languages').get();
        let version = await db.doc('/versions/language').get();
        let version_data = version.data();
        let langData = await Promise.all(
            lang.docs.map((doc) => {
                return { ...doc.data(), uid: doc.id }
            })
        )
        console.log(langData);
        let lang_code = [];
        let lang_obj = [];
        langData.map((lngData) => {
            lang_code.push(lngData.language_code);
            if (lngData.words && lngData.words.length > 0) {
                if (lngData.language_code === "th") {
                    lngData.words.map((word) => {
                        lang_obj.push({
                            keyword: word.keyword,
                        })
                        return true;
                    })
                }
            }
            return true;
        })
        langData.map((lngData) => {
            if (lngData.words && lngData.words.length > 0) {
                lngData.words.map((word) => {
                    lang_obj.map((lan, lan_i) => {
                        if (word.keyword !== lan.keyword) {
                            return false;
                        }
                        let result = "";
                        if (word.keyword === lan.keyword) {
                            result = word.result
                        }
                        lang_obj[lan_i]['word_' + lngData.language_code] = result;
                        return true;
                    })
                    return true;
                })
            }
            return true;
        })
        // console.log(lang_obj);
        this.setState({
            version: version_data.value,
            lang_code: lang_code,
            lang_obj: lang_obj,
            is_loading: false,
        })
    }

    async update(obj) {
        // console.log(obj)
        this.setState({
            is_loading: true
        }, async () => {
            let newVersion = parseFloat(this.state.version)+1;
            await axios.post(window.api_host + '/update-language', {newVersion: newVersion, langCode: this.state.lang_code, obj: obj});
            // await Promise.all(
            //     this.state.lang_code.map(async (code) => {
            //         let words = obj.filter((_obj) => { return _obj.code === code })[0].words;
            //         await db.doc('/languages/' + code).update({ words: words });
            //         await db.doc('/versions/language').update({ value: newVersion });
            //     })
            // )
            this.fetch();
        })


    }

    render() {
        // if (this.state.is_loading) {
        //     return (<div style={{ padding: '1rem 0', textAlign: 'center' }}><div className="ui active inline loader" ></div></div>)
        // }
        // console.log(this.state.lang_obj);
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการภาษา {this.state.version || null}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to={'/'}>หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการภาษา</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                {
                                    this.state.is_loading ?
                                        <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                        : null
                                }
                                <div className="row">
                                    <div className="col-12 col-md-2">
                                        ลบ
                                        </div>
                                    <div className="col-12 col-md-2">
                                        <div className="form-group">
                                            <label>Keyword</label>
                                        </div>
                                    </div>
                                    {
                                        this.state.lang_code.map((code, code_i) => {
                                            return (
                                                <div className="col-12 col-md-2" key={code_i}>
                                                    <label>ถาษา {code}</label>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                {
                                    this.state.lang_obj ?
                                        <div>
                                            {this.state.lang_obj.map((word, word_i) => {
                                                return (
                                                    <div className="row" key={word_i}>
                                                        <div className="col-12 col-md-1">
                                                            <button className="btn btn-danger"
                                                                onClick={() => {
                                                                    let lang_obj = [...this.state.lang_obj]
                                                                    lang_obj.splice(word_i, 1)
                                                                    this.setState({
                                                                        lang_obj: lang_obj
                                                                    })
                                                                }}
                                                            ><i className="fas fa-trash-alt"></i></button>
                                                        </div>
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    name="keyword"
                                                                    value={word.keyword}
                                                                    onChange={(ref) => {
                                                                        let value = ref.target.value;
                                                                        let lang_obj = [...this.state.lang_obj]
                                                                        lang_obj[word_i].keyword = value;
                                                                        this.setState({
                                                                            lang_obj: lang_obj
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.lang_code.map((code, code_i) => {
                                                                return (
                                                                    <div className="col-12 col-md-2" key={code_i}>
                                                                        <div className="form-group">
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                name={'word_' + code}
                                                                                value={word['word_' + code]}
                                                                                onChange={(ref) => {
                                                                                    let value = ref.target.value;
                                                                                    let lang_obj = [...this.state.lang_obj]
                                                                                    lang_obj[word_i]['word_' + code] = value;
                                                                                    this.setState({
                                                                                        lang_obj: lang_obj
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        : null
                                }
                                <div className="row">
                                    <div className="col-12 col-md-2">
                                        <button className="btn btn-default"
                                            style={{ width: '100%' }}
                                            onClick={() => {
                                                let lang_obj = [...this.state.lang_obj]
                                                let obj = { keyword: '' };
                                                this.state.lang_code.map((code, code_i) => {
                                                    obj['word_' + code] = '';
                                                    return true;
                                                })
                                                lang_obj.push(obj)
                                                this.setState({
                                                    lang_obj: lang_obj,
                                                })
                                            }}
                                        >เพิ่ม</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="flex row">
                                    <div style={{ marginLeft: 'auto' }}>
                                        <button className="btn btn-default"
                                            onClick={() => {
                                                let obj = [];
                                                this.state.lang_code.map((code, code_i) => {
                                                    obj.push({
                                                        code: code,
                                                        words: []
                                                    })
                                                    this.state.lang_obj.map((lang_obj) => {
                                                        obj[code_i].words.push({ keyword: lang_obj.keyword, result: lang_obj['word_' + code] });
                                                        return true;
                                                    })
                                                    return true;
                                                })
                                                this.update(obj)
                                            }}
                                        >บันทึก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}