import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/coin`;
export const getCoinTransaction = async (filter) => {
    let url = `${baseUrl}/transaction`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getArtistCoinTransaction = async (id = false, page = 1, rows = 10, date = false, typeId = false, typeIds = false) => {
    let url = `${baseUrl}/transaction?artistId=${id}&page=${page}&rows=${rows}`;
    if (date) {
        url += `&date=${date}`;
    }
    if (typeId) {
        url += `&typeId=${typeId}`;
    }
    if (typeIds) {
        url += `&typeIds=${typeIds}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getCoinPackage = async (page = 1, rows = 10) => {
    const url = `${baseUrl}/package?page=${page}&rows=${rows}`;
    // console.log(url)
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const deleteCoinPackage = async (id) => {
    const url = `${baseUrl}/package/${id}`;
    // console.log(url)
    try {
        const res = await httpClient.delete(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const addCoinPackage = async (data) => {
    const url = `${baseUrl}/package`;
    // console.log(url)
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const updateCoinPackage = async (id, data) => {
    const url = `${baseUrl}/package/${id}`;
    // console.log(url)
    try {
        const res = await httpClient.put(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}