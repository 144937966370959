import React from 'react';
import { Helper } from './../../Helper';
import { Link } from '@reach/router';
import thailand from "./../../thailand.json";
const axios = require('axios').default;
export default class Index extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        let provinces = [];
        for (const [key, value] of Object.entries(thailand)) {
            let amphoes = [];

            for (const [_key, _value] of Object.entries(value.amphoes)) {
                amphoes.push({ code: _key, amphoe: _value.name.th });
                // console.log(`${_key}: ${_value.name.th}`);
            }
            provinces.push({ code: key, province: value.name.th, amphoes: amphoes })
            // console.log(`${key}: ${value.name.th}`);
        }
        this.state = {
            timerage: 'รายวัน',
            years: years,
            date: new Date().getTime(),
            year: parseFloat(new Date().getFullYear()),
            month: parseFloat(new Date().getMonth()),
            is_loading: true,
            is_chart_loading: true,
            provinces: provinces,
            store_all_count: 0,
            store_count: 0,
            data: [],
            store_types: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
            province_code: Helper.getParameterByName('province_code') ? Helper.getParameterByName('province_code') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            store_type: Helper.getParameterByName('store_type') ? Helper.getParameterByName('store_type') : '',
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {

        let filter = {
            keyword: this.state.keyword,
            province_code: this.state.province_code,
            with_storetype_list: true,
            store_type: this.state.store_type,
            status: this.state.status,
        }
        // console.log(filter);
        let res = await axios.get(window.api_host + '/store-type-list', {
            page: this.state.page,
            limit: this.state.limit,
            filter: filter
        });
        // console.log(res.data);
        let data = res.data.data;

        this.setState({
            data: data,
            is_loading: false,
            is_chart_loading: false,
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ประเภทร้านค้า</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/store">ร้านค้า</Link></li>
                                    <li className="breadcrumb-item active">ประเภทร้านค้า</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายการประเภทร้านค้า</h3>
                                        <div className="card-tools">
                                            <button type="text" className="btn btn-primary"
                                                onClick={() => {
                                                    Helper.reactToDom(window.$('body'),
                                                        <TypeForm
                                                            mode="create"
                                                            onSuccess={() => {
                                                                this.fetch();
                                                            }}
                                                        />
                                                    )
                                                }}
                                            >เพิ่ม</button>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.data.length > 0
                                                && !this.state.is_loading ?
                                                <>
                                                    <ul className="nav nav-pills flex-column">
                                                        {
                                                            this.state.data.map((_data, _data_i) => {
                                                                return (
                                                                    <li className="nav-item" key={_data_i}>
                                                                        <div className="nav-link">
                                                                            <div className="store-item item-row">
                                                                                <div className="icon" style={{ width: 30, height: 30 }}><i className="fas fa-tag" style={{ fontSize: 20 }}></i></div>
                                                                                <div className="description">
                                                                                    {_data.name}
                                                                                </div>
                                                                                <div className="right-floated">
                                                                                    <div className="btn-group">
                                                                                        <button type="text" className="btn btn-default"
                                                                                            onClick={() => {
                                                                                                Helper.reactToDom(window.$('body'),
                                                                                                    <TypeForm
                                                                                                        data={_data}
                                                                                                        mode="edit"
                                                                                                        onSuccess={() => {
                                                                                                            this.fetch();
                                                                                                        }}
                                                                                                    />
                                                                                                )
                                                                                            }}
                                                                                        >แก้ไข</button>
                                                                                        {/* <button type="text" className="btn btn-danger ">ลบ</button> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}
class TypeForm extends React.Component {

    async create(data) {
        let ref = await axios.post(window.api_host + '/create-store-type', { data });
        if (ref.data.code === 200) {
            if (ref.data.success) {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                this.$modal.modal('hide');
                this.props.onSuccess();
            }
            if (ref.data.error) {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มข้อมูลไม่สำเร็จ',
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
                this.$submit.removeClass('loading')
            }
        }
    }
    async update(data) {
        let ref = await axios.post(window.api_host + '/update-store-type', { data });
        if (ref.data.code === 200) {
            if (ref.data.success) {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                this.$modal.modal('hide');
                this.props.onSuccess();
            }
            if (ref.data.error) {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มข้อมูลไม่สำเร็จ',
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
                this.$submit.removeClass('loading')
            }
        }

    }

    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return false; }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        if (this.props.mode === 'create') {
                            this.create(data);
                        }
                        if (this.props.mode === 'edit') {
                            this.update(data);
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                {this.props.mode === 'create' ? 'เพิ่มโปรโมชั่น' : null}
                                {this.props.mode === 'edit' ? 'แก้ไขโปรโมชั่น' : null}
                                {this.props.mode === 'view' ? 'ดูข้อมูลโปรโมชั่น' : null}
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>ชื่อประเภท</label>
                                <input type="text" name="name" defaultValue={this.props.data && this.props.data.name ? this.props.data.name : ''} className="form-control" 
                                        required={true}
                                />
                            </div>
                            {
                                this.props.data && this.props.data.uid ?
                                    <input type="hidden" name="uid" defaultValue={this.props.data.uid} />
                                    : null
                            }
                            {
                                this.props.mode === 'edit' ?
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isActive"
                                            name={"isActive"}
                                            defaultValue={"true"}
                                            defaultChecked={
                                                this.props.data
                                                    && this.props.data.isActive ?
                                                    this.props.data.isActive : false
                                            }
                                        />
                                        <label className="custom-control-label" htmlFor="isActive">ใช้งาน</label>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >
                                ยกเลิก
                                </button>
                            {
                                this.props.mode === 'create' || this.props.mode === 'edit' ?
                                    <button className={"btn btn-primary"} type={"submit"}>บันทึก</button>
                                    : null
                            }
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}