import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from "./../../Helper";
import Pagination from './../../Components/Pagination';
const axios = require('axios').default;

export default class Product extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            count_all_item: 0,
            is_loading: true,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
            type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : '',
            isActive: Helper.getParameterByName('isActive') ? Helper.getParameterByName('isActive') : 'true',
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                count_all_item: 0,
                is_loading: true,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
                type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : '',
                isActive: Helper.getParameterByName('isActive') ? Helper.getParameterByName('isActive') : 'true',
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                data: [],
                count_all_item: 0,
                is_loading: true,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
                type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : '',
                isActive: Helper.getParameterByName('isActive') ? Helper.getParameterByName('isActive') : 'true',
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let qs = {
            page: this.state.page,
            limit: this.state.limit,
            keyword: this.state.keyword,
            type: this.state.type,
            isActive: this.state.isActive || "false",
        }
        let res = await axios.get(window.api_host + '/inapp-products?' + window.$.param(qs));
        let data = [];
        let count_all_item = 0;
        if (res.data.code === 200) {
            data = res.data.data
            count_all_item = res.data.count_all_item
        }
        this.setState({
            data: data,
            count_all_item: count_all_item,
            is_loading: false,
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">App Product</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">App Product</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className={"content"}>
                    <div className="container-fluid">
                        <>
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <Link className="btn btn-primary" to={"/app-product/add"}>
                                            เพิ่มสินค้า
                                        </Link>
                                    </div>
                                    {/* FILTER */}
                                    <div className="card card-outline collapsed-card">
                                        <div className="card-header">
                                            <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>

                                            <div className="card-tools">
                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                    <i className="fas fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <form className="row"
                                                ref={(ref) => { this.$form = window.$(ref) }}
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                                        return;
                                                    }
                                                    let data = Helper.getFormInputObject(this.$form);
                                                    let url = Helper.getQueryStringLinkFromObject({
                                                        page: 1,
                                                        keyword: data.keyword,
                                                        type: data.purchase_type,
                                                        isActive: data.isActive
                                                    })
                                                    navigate(url)
                                                }}
                                            >
                                                <div className="col-6 form-group">
                                                    <label>ค้นหา</label>
                                                    <input name="keyword" className="form-control" placeholder="ค้นหาจากชื่อสมาชิก" defaultValue={this.state.keyword}/>
                                                </div>
                                                <div className="col-6 form-group">
                                                    <label>PurchaseType</label>
                                                    <select
                                                        className={"form-control"}
                                                        name={"purchase_type"}
                                                        style={{ width: '100%' }}
                                                        defaultValue={this.state.purchase_type || ''}
                                                    >
                                                        <option value={""}>
                                                            ทั้งหมด
                                                                </option>
                                                        <option value={"coin"}>
                                                            Coin
                                                                </option>
                                                        <option value={"rank"}>
                                                            Rank
                                                                </option>
                                                        <option value={"sticker"}>
                                                            Sticker
                                                                </option>
                                                    </select>
                                                </div>
                                                <div className="col-6 form-group">
                                                    <label>สถานะ</label>
                                                    <select
                                                        className={"form-control"}
                                                        name={"isActive"}
                                                        style={{ width: '100%' }}
                                                        defaultValue={this.state.isActive || 'true'}
                                                    >
                                                        <option value={"true"}>ใช้งาน</option>
                                                        <option value={"false"}>ไม่ใช้งาน</option>
                                                    </select>
                                                </div>
                                                <div className="col-12">
                                                    <div className="flex row">
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            <button type="submit" className="btn btn-default">ค้นหา</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* STORE LIST */}
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">รายการสมาชิก</h3>
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                    <i className="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            {
                                                !this.state.is_loading
                                                    && this.state.data.length === 0 ?
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                                    : null
                                            }
                                            {
                                                this.state.is_loading ?
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                this.state.data.length > 0
                                                    && !this.state.is_loading ?
                                                    <>
                                                        <ul className="nav nav-pills flex-column">
                                                            {
                                                                this.state.data.map((item, item_i) => {
                                                                    return (
                                                                        <li className="nav-item" key={item_i}>
                                                                            <Link to={"/app-product/edit/" + item.uid} className="nav-link">
                                                                                <div className="store-item item-row">
                                                                                    <div className="icon"><i className="fas fa-mobile-alt"></i></div>
                                                                                    <div className="description">
                                                                                        <div>{item.nameTH}</div>
                                                                                    </div>
                                                                                    <div className="right-floated">
                                                                                        <div className="small-text">{Helper.getDateThai(new Date(item.createdAt).getTime())}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>

                                                    </>
                                                    : null
                                            }
                                        </div>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length !== 0 ?
                                                <div className="card-footer clearfix">
                                                    <Pagination
                                                        now={this.state.page}
                                                        all_items={this.state.count_all_item}
                                                        limit={this.state.limit}
                                                        location={this.props.location}
                                                    />
                                                </div>
                                                : null
                                        }
                                    </div>

                                </div>
                            </div>
                        </>
                    </div>
                </section>
            </>
        );
    }
}