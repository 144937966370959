import React from 'react';
import { Helper } from '../../Helper';
import GoogleMap from '../../Components/GoogleMap';
import { Link, navigate } from '@reach/router';
import { storage } from './../../firebase';
const axios = require('axios').default;

export default class Form extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
            type: [],
            store_types: [],
            provinces: Helper.getAllProvinces(),
            time_step: 15,
            location: {
                lat: false,
                lng: false,
            },
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let data = {};
        data.uid = this.props.uid || false;
        data.store_type = true;
        data.package_type = true;
        data.province = true;
        let res = await axios.get(window.api_host + '/register-store?' + window.$.param({
            ...data
        }));
        let place = res.data.response.data || false;
        let store_types = res.data.response.store_type || [];
        let package_types = res.data.response.package_types || [];
        this.setState({
            data: place,
            store_types: store_types,
            package_types: package_types,
            is_loading: false,
        })
    }

    async update(data) {
        try {
            let ref = await axios.put(window.api_host + '/register-store', { data: data });
            // console.log(this.props.adminUid);
            // console.log(ref)
            if (ref.data.success) {
                window.$(document).Toasts('create', {
                    title: 'แก้ไขข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                if (ref.data && ref.data.remove_image && data.photoPath) {
                    await storage.ref(ref.data.remove_image).delete();
                }
                navigate("/register-store/view/" + data.uid)
            }
            if (ref.data.error) {
                let message = {
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 5000,
                }
                message.title = ref.data.message;
                if (ref.data.description) {
                    message.body = ref.data.description
                }
                if (data.storePath) {
                    Helper.removeFile(data.storePath)
                }
                window.$(document).Toasts('create', { ...message });
                this.submit.removeClass('loading');
                this.$file.parent().find('.btn').removeClass('loading');
                // console.log(this.$file.parent());
            }
        } catch (error) {
            console.log(error)
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถเพิ่มข้อมูลได้',
                body: 'เพิ่มข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            if (data.storePath) {
                Helper.removeFile(data.storePath)
            }
            this.submit.removeClass('loading');
            this.$file.parent().find('.btn').removeClass('loading');
        }
    }


    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ลงทะเบียน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/register-store"}>ลงทะเบียน</Link></li>
                                    {
                                        this.state.data && this.state.data.nameTH ?
                                            <li className="breadcrumb-item"><Link to={"/register-store/view/" + this.props.uid}>{this.state.data.nameTH}</Link></li>
                                            : null
                                    }
                                    <li className="breadcrumb-item active">
                                        {this.props.mode === 'create' ? 'เพิ่ม' : null}
                                        {this.props.mode === 'edit' ? 'แก้ไข' : null}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <form
                                    ref={(ref) => this.$form = window.$(ref)}
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        if (this.submit.hasClass('loading')) { return false; }
                                        this.submit.addClass('loading');
                                        let data = Helper.getFormInputObject(this.$form);
                                        let isPrivate = false;
                                        if (data.isPrivate && data.isPrivate === "true") {
                                            isPrivate = true;
                                        }
                                        data.isPrivate = isPrivate;
                                        if (this.$file && this.$file[0].files[0]) {
                                            await Helper.uploadFile(this.$file, 'store/', {
                                                resize: true,
                                                onSuccess: async (url) => {
                                                    data.storagePath = url.storePath;
                                                    data.photoPath = url.imagePath;
                                                    if (this.props.mode === 'create') {
                                                        this.create(data);
                                                    }
                                                    if (this.props.mode === 'edit') {
                                                        this.update(data);
                                                    }
                                                },
                                                onError: async () => {
                                                    this.submit.removeClass('loading');
                                                }
                                            })
                                        } else {
                                            if (this.props.mode === 'create') {
                                                this.create(data);
                                            }
                                            if (this.props.mode === 'edit') {
                                                this.update(data);
                                            }
                                        }

                                    }}
                                >
                                    {
                                        this.state.is_chart_loading ?
                                            <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                            : null
                                    }

                                    {
                                        this.state.is_loading ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                <div className="ui active inline loader" ></div>
                                            </div>
                                            : null
                                    }
                                    {
                                        !this.state.is_loading ?
                                            <div className={"row"}>
                                                <div className={"col-12 col-md-3"}>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="card-title">โลโก้ร้าน</div>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-minus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div >
                                                                <input name="image"
                                                                    type={"file"}
                                                                    accept="image/*"
                                                                    ref={(ref) => this.$file = window.$(ref)}
                                                                    style={{ display: 'none' }}
                                                                    id="file_image_bank"
                                                                    onChange={(e) => {
                                                                        Helper.readURL(this.$file);
                                                                    }}
                                                                />
                                                                <div id="show_image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    {
                                                                        this.state.data && this.state.data.photoPath ?
                                                                            <img src={this.state.data.photoPath} alt={"PromotionPic"} style={{ maxWidth: '100%', marginBottom: 5 }} />
                                                                            : null
                                                                    }
                                                                </div>
                                                                <label htmlFor="file_image_bank"
                                                                    type={"button"}
                                                                    className="btn btn-default"
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {
                                                                        this.state.data && this.state.data.photoPath ?
                                                                            "เปลี่ยนรูป"
                                                                            : "อัพโหลด"
                                                                    }
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="card-title">ข้อมูลร้าน</div>
                                                        </div>
                                                        <div className="card-body">

                                                            <div className={"form-group"}>
                                                                <label>ชื่อร้าน (ไทย)</label>
                                                                <input type="text" className="form-control" name="nameTH"
                                                                    defaultValue={this.state.data && this.state.data.nameTH ? this.state.data.nameTH : null} />
                                                            </div>
                                                            <div className={"form-group"}>
                                                                <label>ชื่อร้าน (อังกฤษ)</label>
                                                                <input type="text" className="form-control" name="nameEN"
                                                                    defaultValue={this.state.data && this.state.data.nameEN ? this.state.data.nameEN : null} />
                                                            </div>

                                                            <div className="form-group">
                                                                <div className="flex row">
                                                                    <label>ประเภท</label>
                                                                    <div className="right-floated">
                                                                        <Link to={'/store-types'}>ดูข้อมูล</Link>
                                                                    </div>
                                                                </div>
                                                                <select
                                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                                    className={"form-control select2"}
                                                                    name={"storeType"}
                                                                    style={{ width: '100%' }}
                                                                    defaultValue={this.state.data && this.state.data.storeTypeUid ? this.state.data.storeTypeUid : ''}
                                                                >
                                                                    <option value={""}>ไม่ระบุ</option>
                                                                    {
                                                                        this.state.store_types.map((store_type, store_type_i) => {
                                                                            return (
                                                                                <option value={store_type.uid} key={store_type_i}>
                                                                                    {store_type.name}
                                                                                </option>
                                                                            );
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div className="row form-group">
                                                                <div className="col-6">
                                                                    <label>ราคาเริ่มต้น</label>
                                                                    <div className="input-group">
                                                                        <input type="number" className="form-control" name="minPrice"
                                                                            defaultValue={this.state.data && this.state.data.minPrice ? this.state.data.minPrice : 0} />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">฿</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <label>ราคาสิ้นสุด</label>
                                                                    <div className="input-group">
                                                                        <input type="number" className="form-control" name="maxPrice"
                                                                            defaultValue={this.state.data && this.state.data.maxPrice ? this.state.data.maxPrice : 0} />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">฿</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label>จำนวนผู้เข้าร้านสูงสุด</label>
                                                                <div className="input-group">
                                                                    <input type="number" className="form-control" name="guestLimit"
                                                                        defaultValue={this.state.data && this.state.data.guestsLimit ? this.state.data.guestsLimit : 0} />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">คน</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label>ภาพรวม</label>
                                                                <textarea className="form-control" name="overview"
                                                                    defaultValue={this.state.data && this.state.data.overview ? this.state.data.overview : null}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-12 col-md-3"}>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="card-title">ข้อมูลการติดต่อ</div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className={"form-group"}>
                                                                <label>ที่อยู่</label>
                                                                <input type="text" className="form-control" name="address"
                                                                    defaultValue={
                                                                        this.state.data
                                                                            && this.state.data.contact
                                                                            && this.state.data.contact.address ?
                                                                            this.state.data.contact.address
                                                                            : null
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>จังหวัด</label>
                                                                <select
                                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                                    className={"form-control select2"}
                                                                    name={"provinceCode"}
                                                                    style={{ width: '100%' }}
                                                                    defaultValue={
                                                                        this.state.data
                                                                            && this.state.data.contact
                                                                            && this.state.data.contact.provinceCode ?
                                                                            this.state.data.contact.provinceCode
                                                                            : null
                                                                    }
                                                                >
                                                                    {
                                                                        this.state.provinces.map((province, provinces_i) => {
                                                                            return (
                                                                                <option value={province.code} key={provinces_i}>
                                                                                    {province.name.th}
                                                                                </option>
                                                                            );
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className={"form-group"}>
                                                                <label>อีเมล</label>
                                                                <input type="text" className="form-control" name="email"
                                                                    defaultValue={
                                                                        this.state.data
                                                                            && this.state.data.contact
                                                                            && this.state.data.contact.email ?
                                                                            this.state.data.contact.email
                                                                            : null
                                                                    }
                                                                />
                                                            </div>
                                                            <div className={"form-group"}>
                                                                <label>เบอร์โทรศัพท์</label>
                                                                <input type="text" className="form-control" name="telephone"
                                                                    defaultValue={
                                                                        this.state.data
                                                                            && this.state.data.contact
                                                                            && this.state.data.contact.telephone ?
                                                                            this.state.data.contact.telephone.replace('+66', '0')
                                                                            : null
                                                                    }
                                                                />
                                                            </div>
                                                            <div className={"form-group"}>
                                                                <label>เว็บไซด์</label>
                                                                <input type="text" className="form-control" name="website"
                                                                    defaultValue={
                                                                        this.state.data
                                                                            && this.state.data.contact
                                                                            && this.state.data.contact.website ?
                                                                            this.state.data.contact.website
                                                                            : null
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <GoogleMap
                                                        height={300}
                                                        onSelected={(location) => {
                                                            this.lat.val(location.lat);
                                                            this.lng.val(location.lng)
                                                        }}
                                                        lat={this.state.data && this.state.data.coordinate && this.state.data.coordinate._latitude ? this.state.data.coordinate._latitude : false}
                                                        lng={this.state.data && this.state.data.coordinate && this.state.data.coordinate._longitude ? this.state.data.coordinate._longitude : false}
                                                    />
                                                    <input type="hidden" ref={(ref) => { this.lat = window.$(ref) }} name="lat" value={this.state.data && this.state.data.coordinate && this.state.data.coordinate._latitude ? this.state.data.coordinate._latitude : false} />
                                                    <input type="hidden" ref={(ref) => { this.lng = window.$(ref) }} name="lng" value={this.state.data && this.state.data.coordinate && this.state.data.coordinate._longitude ? this.state.data.coordinate._longitude : false} />
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="card-title">เวลาเปิดทำการ</div>
                                                        </div>
                                                        <div className="card-body">
                                                            {/* MONDAY  */}
                                                            <div>
                                                                <WorkingTimeInput
                                                                    data={this.state.data
                                                                        && this.state.data.workingTime
                                                                        && this.state.data.workingTime.monday ?
                                                                        this.state.data.workingTime.monday
                                                                        : null
                                                                    }
                                                                    day={"monday"}
                                                                    day_title={"วันจันทร์"}
                                                                    time_step={this.state.time_step}
                                                                />
                                                                <div className="divider" />
                                                            </div>
                                                            {/* // TUESDAY */}
                                                            <div>
                                                                <WorkingTimeInput
                                                                    data={this.state.data
                                                                        && this.state.data.workingTime
                                                                        && this.state.data.workingTime.tuesday ?
                                                                        this.state.data.workingTime.tuesday
                                                                        : null
                                                                    }
                                                                    day={"tuesday"}
                                                                    day_title={"วันอังคาร"}
                                                                    time_step={this.state.time_step}
                                                                />
                                                                <div className="divider" />
                                                            </div>
                                                            {/* // WEDNESDAY */}
                                                            <div>
                                                                <WorkingTimeInput
                                                                    data={this.state.data
                                                                        && this.state.data.workingTime
                                                                        && this.state.data.workingTime.wednesday ?
                                                                        this.state.data.workingTime.wednesday
                                                                        : null
                                                                    }
                                                                    day={"wednesday"}
                                                                    day_title={"วันพุธ"}
                                                                    time_step={this.state.time_step}
                                                                />
                                                                <div className="divider" />
                                                            </div>
                                                            {/* // THURSDAY */}
                                                            <div>
                                                                <WorkingTimeInput
                                                                    data={this.state.data
                                                                        && this.state.data.workingTime
                                                                        && this.state.data.workingTime.thursday ?
                                                                        this.state.data.workingTime.thursday
                                                                        : null
                                                                    }
                                                                    day={"thursday"}
                                                                    day_title={"วันพฤหัส"}
                                                                    time_step={this.state.time_step}
                                                                />
                                                                <div className="divider" />
                                                            </div>
                                                            {/* // FRIDAY */}
                                                            <div>
                                                                <WorkingTimeInput
                                                                    data={this.state.data
                                                                        && this.state.data.workingTime
                                                                        && this.state.data.workingTime.friday ?
                                                                        this.state.data.workingTime.friday
                                                                        : null
                                                                    }
                                                                    day={"friday"}
                                                                    day_title={"วันศุกร์"}
                                                                    time_step={this.state.time_step}
                                                                />
                                                                <div className="divider" />
                                                            </div>
                                                            {/* // SATURDAY */}
                                                            <div>
                                                                <WorkingTimeInput
                                                                    data={this.state.data
                                                                        && this.state.data.workingTime
                                                                        && this.state.data.workingTime.saturday ?
                                                                        this.state.data.workingTime.saturday
                                                                        : null
                                                                    }
                                                                    day={"saturday"}
                                                                    day_title={"วันเสาร์"}
                                                                    time_step={this.state.time_step}
                                                                />
                                                                <div className="divider" />
                                                            </div>
                                                            {/* // SUNDAY */}
                                                            <div>
                                                                <WorkingTimeInput
                                                                    data={this.state.data
                                                                        && this.state.data.workingTime
                                                                        && this.state.data.workingTime.sunday ?
                                                                        this.state.data.workingTime.sunday
                                                                        : null
                                                                    }
                                                                    day={"sunday"}
                                                                    day_title={"วันอาทิตย์"}
                                                                    time_step={this.state.time_step}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="card-footer">
                                                            <div>
                                                                <label className="custom-control custom-switch" style={{ marginBottom: 0 }}>
                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id={"isPrivate"}
                                                                        name={"isPrivate"}
                                                                        defaultValue={"true"}
                                                                        defaultChecked={
                                                                            this.state.data
                                                                                && this.state.data.isPrivate ?
                                                                                this.state.data.isPrivate : false
                                                                        }
                                                                    />
                                                                    <label className="custom-control-label" htmlFor={"isPrivate"}> ไม่แสดงสถานที่บนแอพ</label>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="card-title">ข้อมูลผู้สมัคร</div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="form-group">
                                                                <label>ชื่อผู้สมัคร</label>
                                                                <input type="text"
                                                                    name="providerName"
                                                                    className="form-control"
                                                                    defaultValue={
                                                                        this.state.data
                                                                            && this.state.data.user
                                                                            && this.state.data.user.displayName ?
                                                                            this.state.data.user.displayName
                                                                            : ''
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>เบอร์โทรศัพท์</label>
                                                                <input type="text"
                                                                    name="providerPhoneNumber"
                                                                    className="form-control"
                                                                    defaultValue={
                                                                        this.state.data
                                                                            && this.state.data.user
                                                                            && this.state.data.user.phoneNumber ?
                                                                            this.state.data.user.phoneNumber.replace('+66', '0')
                                                                            : ''
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>อีเมล</label>
                                                                <input type="email"
                                                                    name="providerEmail"
                                                                    className="form-control"
                                                                    defaultValue={
                                                                        this.state.data
                                                                            && this.state.data.user
                                                                            && this.state.data.user.email ?
                                                                            this.state.data.user.email
                                                                            : ''
                                                                    }
                                                                />
                                                            </div>
                                                            <input type="hidden"
                                                                name="password"
                                                                className="form-control"
                                                                defaultValue={
                                                                    this.state.data
                                                                        && this.state.data.user
                                                                        && this.state.data.user.password ?
                                                                        this.state.data.user.password
                                                                        : ''
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            : null
                                    }

                                    {
                                        !this.state.is_loading ?
                                            <div className="flex row mb-5">
                                                <Link to={this.props.mode === 'edit' && this.props.uid ? "/register-store/view/" + this.props.uid : "/store"}
                                                    className="btn btn-default btn-lg"
                                                    style={{ marginRight: 15 }}
                                                >ยกเลิก</Link>
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <input name="freeDate" type="hidden" value={"0"} />
                                                    {
                                                        this.props.mode === 'edit' ?
                                                            <>
                                                                <input name="uid" type="hidden" value={this.props.uid} />
                                                                <input name="packageUid" type="hidden" value={this.state.data.type === 'place' ? "4yvDPstPnEOCzI3bQd9X" : this.state.data.packageUid} />
                                                                <input name="packageOptionUid" type="hidden" value={this.state.data.packageOptionUid} />
                                                                <input name="status" type="hidden" value={this.state.data.status || 'pause'} />
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        this.props.mode === 'create' && this.props.type !== 'place' ?
                                                            <input name="status" type="hidden" value={"pause"} />
                                                            : null
                                                    }
                                                    {
                                                        this.props.mode === 'create' && this.props.type === 'place' ?
                                                            <input name="status" type="hidden" value={"online"} />
                                                            : null
                                                    }
                                                    <input name="type" type="hidden" value={this.props.type} />
                                                    <button type="submit"
                                                        className="btn btn-primary btn-lg"
                                                        ref={(ref) => { this.submit = window.$(ref) }}
                                                    >
                                                        {this.props.mode === 'create' ? 'เพิ่ม' : null}
                                                        {this.props.mode === 'edit' ? 'บันทึก' : null}
                                                    </button>
                                                </div>
                                            </div>
                                            : null
                                    }

                                </form>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

class WorkingTimeInput extends React.Component {
    workingTimeSelect(ref) {
        let _ref = window.$(ref.target);
        if (_ref.prop("checked") === true) {
            _ref.parents('.sub-days').find('.day-times').show();
        }
        else if (_ref.prop("checked") === false) {
            _ref.parents('.sub-days').find('.day-times').hide();
        }
    }
    setTimepicker(ref) {
        window.$(ref).datetimepicker({
            format: 'HH:mm',
            stepping: this.props.time_step,
        })
    }
    render() {
        return (
            <div className="day-group">
                {/* <label htmlFor="sundayOpen">วันอาทิตย์ </label> */}
                <div className="sub-days">
                    <label className="custom-control custom-switch">
                        <input type="checkbox"
                            className="custom-control-input"
                            id={this.props.day + 'Open'}
                            name={this.props.day + "Open"}
                            defaultValue={"true"}
                            defaultChecked={
                                this.props.data
                                    && this.props.data.isOpen ?
                                    this.props.data.isOpen : false
                            }
                            onChange={(ref) => {
                                this.workingTimeSelect(ref)
                            }}
                        />
                        <label className="custom-control-label" htmlFor={this.props.day + 'Open'}>{this.props.day_title}</label>
                    </label>
                    <div className="day-times" style={{
                        display: this.props.data
                            && this.props.data.isOpen ? "block" : "none"
                    }}>
                        <div className="row">
                            <div className="col-5">
                                <div className="input-group date" id={this.props.day + '_timepicker_open'} data-target-input="nearest"
                                    ref={(ref) => {
                                        this.setTimepicker(ref);
                                    }}
                                >
                                    <input type="text"
                                        name={this.props.day + "TimeOpen"}
                                        className="form-control datetimepicker-input"
                                        data-target={"#" + this.props.day + '_timepicker_open'}
                                        data-toggle="datetimepicker"
                                        defaultValue={
                                            this.props.data
                                                && this.props.data.time
                                                && this.props.data.time.open ?
                                                this.props.data.time.open : '00:00'
                                        }
                                    />
                                    <div className="input-group-append" data-target={"#" + this.props.day + '_timepicker_open'} data-toggle="datetimepicker">
                                        <div className="input-group-text"><i className="fas fa-door-open"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <i className="fas fa-minus"></i>
                            </div>
                            <div className="col-5">
                                <div className="input-group date" id={this.props.day + '_timepicker_close'} data-target-input="nearest"
                                    ref={(ref) => {
                                        this.setTimepicker(ref);
                                    }}
                                >
                                    <input type="text"
                                        name={this.props.day + "TimeClose"}
                                        className="form-control datetimepicker-input"
                                        data-target={"#" + this.props.day + '_timepicker_close'}
                                        data-toggle="datetimepicker"
                                        defaultValue={
                                            this.props.data
                                                && this.props.data.time
                                                && this.props.data.time.close ?
                                                this.props.data.time.close : '00:00'
                                        }
                                    />
                                    <div className="input-group-append" data-target={"#" + this.props.day + '_timepicker_close'} data-toggle="datetimepicker">
                                        <div className="input-group-text"><i className="fas fa-door-closed"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}