import React from 'react';
import { Link } from '@reach/router';
import { Helper } from './../../Helper';
import Pagination from './../../Components/Pagination';
import { db } from '../../firebase';
const axios = require('axios').default;
const default_limit = 10;
export default class ReportPost extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            reports: [],
            type: Helper.getParameterByName("type") && Helper.getParameterByName("type") !== '' ? Helper.getParameterByName("type") : false,
            page: Helper.getParameterByName("page") && Helper.getParameterByName("page") !== '' ? Helper.getParameterByName("page") : 1,
            count_all_item: 0,
            limit: Helper.getParameterByName("limit") && Helper.getParameterByName("limit") !== '' ? Helper.getParameterByName("limit") : default_limit,
            report_type: Helper.getParameterByName("report_type") && Helper.getParameterByName("report_type") !== '' ? Helper.getParameterByName("report_type") : false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                reports: [],
                type: Helper.getParameterByName("type") && Helper.getParameterByName("type") !== '' ? Helper.getParameterByName("type") : false,
                page: Helper.getParameterByName("page") && Helper.getParameterByName("page") !== '' ? Helper.getParameterByName("page") : 1,
                limit: Helper.getParameterByName("limit") && Helper.getParameterByName("limit") !== '' ? Helper.getParameterByName("limit") : default_limit,
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let qs = {};
        qs.report_type = "post";
        if (this.state.type) {
            qs.type = this.state.type;
        }
        qs.page = this.state.page;
        qs.limit = this.state.limit;
        if (this.state.storeUid) {
            qs.storeUid = this.state.storeUid;
        }
        let res = await axios.get(window.api_host + '/get-report?' + window.$.param(qs));
        let reports = [...this.state.reports];
        let count_all_item = 0;
        if (res.data.success) {
            reports = this.state.reports.concat(res.data.reports);
            let is_show_more = res.data.reports.length >= this.state.limit;
            // console.log(is_show_more)
            count_all_item = res.data.count_all_item;
            this.setState({
                is_loading: false,
                reports: reports,
                is_show_more: is_show_more
            }, () => {
                if (this.loadMore) {
                    this.loadMore.removeClass("loading");
                }
            })
        }
    }

    render() {
        return (
            <div className="card card-primary card-outline">
                <div className="card-header">
                    <h3 className="card-title">รายงานโพส</h3>

                    {/* <div className="card-tools">
                    </div> */}
                </div>
                <div className="card-body p-0">
                    <div className="mailbox-controls">
                        <div className="btn btn-group">
                            <Link to={'/report?report_type=post'} className={!this.state.type || this.state.type === '' ? "btn btn-default active" : "btn btn-default"} disabled={!this.state.type || this.state.type === ''}>ทั้งหมด</Link>
                            <Link to={'/report?report_type=post&type=1'} className={this.state.type && this.state.type == 1 ? "btn btn-default active" : "btn btn-default"} disabled={this.state.type && this.state.type === 'violent'}>Bugs</Link>
                            <Link to={'/report?report_type=post&type=2'} className={this.state.type && this.state.type == 2 ? "btn btn-default active" : "btn btn-default"} disabled={this.state.type && this.state.type === 'violent'}>อื่นๆ</Link>
                            <Link to={'/report?report_type=post&type=4'} className={this.state.type && this.state.type == 4 ? "btn btn-default active" : "btn btn-default"} disabled={this.state.type && this.state.type === 'violent'}>เนื้อหารุนแรง</Link>
                            <Link to={'/report?report_type=post&type=3'} className={this.state.type && this.state.type == 3 ? "btn btn-default active" : "btn btn-default"} disabled={this.state.type && this.state.type === 'spam'}>แสปม</Link>
                            <Link to={'/report?report_type=post&type=5'} className={this.state.type && this.state.type == 5 ? "btn btn-default active" : "btn btn-default"} disabled={this.state.type && this.state.type === 'indecent'}>อนาจาร</Link>
                            <Link to={'/report?report_type=post&type=6'} className={this.state.type && this.state.type == 6 ? "btn btn-default active" : "btn btn-default"} disabled={this.state.type && this.state.type === 'piracy'}>ละเมิดลิขสิทธิ์</Link>
                        </div>
                    </div>
                    <div className="table-responsive mailbox-messages">
                        <table className="table table-hover table-striped">

                            <thead>
                                <tr>
                                    <th scope="col" width={200}>วันที่รายงาน</th>
                                    <th scope="col" width={100}>ประเภท</th>
                                    <th scope="col" width={200}>วันที่โพส</th>
                                    <th scope="col" width={150}>ร้าน</th>
                                    <th scope="col" width={150}>ผู้โพส</th>
                                    <th scope="col" width={150}>ผู้รายงาน</th>
                                    <th scope="col" width="100">สถานะ</th>
                                    <th scope="col" align={'right'} width={100}>ระบบ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.is_loading ?
                                        <tr><td colSpan={8}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="ui active inline loader small" ></div></div></td></tr>
                                        : null
                                }
                                {
                                    !this.state.is_loading ?
                                        this.state.reports.length > 0 ?
                                            this.state.reports.map((report, report_i) => {
                                                var timezoneCal = new Date().getTimezoneOffset();
                                                let post_created = report.post.created;
                                                let date_gettime_post = new Date(post_created).getTime();
                                                let report_created = report.created;
                                                let date_gettime = new Date(report_created).getTime();
                                                let report_created_sum = 0;
                                                let post_created_sum = 0;
                                                if (timezoneCal < 0) {
                                                    report_created_sum = date_gettime + ((timezoneCal * (-60)) * 1000)
                                                    post_created_sum = date_gettime_post + ((timezoneCal * (-60)) * 1000)
                                                } else {
                                                    report_created_sum = date_gettime - ((timezoneCal * (-60)) * 1000)
                                                    post_created_sum = date_gettime_post - ((timezoneCal * (-60)) * 1000)
                                                }
                                                report_created = report_created_sum;
                                                post_created = post_created_sum;
                                                return (
                                                    <tr key={report_i} className="custom-tr">
                                                        <td >{report.created ? Helper.getDateThai(report_created, { is_show_time: true }) : null}</td>
                                                        <td>{Helper.getReportStatus(report.typeId)}</td>
                                                        <td >{report.post.created ? Helper.getDateThai(post_created, { is_show_time: true }) : null}</td>
                                                        <td className="mailbox-name">
                                                            <Link to={"/store/view/" + report.post.storeFireBaseUid}>{report.post.store.name}</Link>
                                                        </td>
                                                        <td className="mailbox-name">
                                                            {
                                                                report.post && report.post.user ?
                                                                    <Link to={`/member/view/${report.post.userFireBaseUid}`}>
                                                                        {report.post.user.displayName}
                                                                    </Link>
                                                                    : null
                                                            }
                                                        </td>
                                                        <td className="mailbox-name">
                                                            {
                                                                report.reportUser ?
                                                                    <Link to={`/member/view/${report.reportUser.userFireBaseUid}`}>
                                                                        {report.reportUser.displayName}
                                                                    </Link>
                                                                    :
                                                                    null
                                                            }
                                                        </td>
                                                        {/* <td className="mailbox-attachment">{report.text}</td> */}
                                                        <td >{report.statusId && report.statusId == 1 ? <span className="badge badge-primary">รอการแก้ไข</span> : <span className="badge badge-danger">แก้ไขแล้ว</span>}</td>
                                                        <td className="mailbox-date">
                                                            <div className="btn-group">
                                                                <Link className="btn btn-default btn-sm"
                                                                    to={"/report/view/" + report.id + '?report_type=post'}
                                                                >
                                                                    <i className="fas fa-search" />
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }) : <tr>
                                                <td colSpan={8}>
                                                    ไม่มีรายงาน
                                                </td>
                                            </tr>
                                        : null
                                }
                                {
                                    this.state.is_show_more ?
                                        <tr>
                                            <td colSpan={8}>
                                                <button class="btn btn-default"
                                                    ref={(e) => this.loadMore = window.$(e)}
                                                    onClick={() => {
                                                        if (this.loadMore.hasClass("loading")) { return; }
                                                        this.loadMore.addClass("loading");
                                                        this.setState({
                                                            page: this.state.page + 1,
                                                        }, () => {
                                                            this.fetch();
                                                        })
                                                    }}
                                                >โหลดเพิ่ม</button>
                                            </td>
                                        </tr>
                                        : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div className="card-footer p-0">
                    <div className="mailbox-controls">
                        <Pagination
                            now={this.state.page}
                            all_items={this.state.count_all_item}
                            limit={this.state.limit}
                            location={this.props.location}
                        />
                    </div>
                </div> */}
            </div >
        );
    }
}