import React from 'react';
import { Link, navigate } from '@reach/router';
import GoogleMap from './../../Components/GoogleMap';
import { Helper } from './../../Helper';
const axios = require('axios').default;
export default class View extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
        }
    }

    async componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.get(window.api_host + '/recomment-place?' + window.$.param({
            uid: this.props.uid,
        }));
        let data = false;
        if (res.data.success) {
            data = res.data.response.data
        }
        let disableEdit = true
        if (data
            && data.status !== 'approved'
            && data.status !== 'not_approve') {
            disableEdit = false;
        }
        this.setState({
            data: data,
            disableEdit: disableEdit,
            is_loading: false,
        })
    }

    async onApprove() {
        let res = await axios.post(`${window.api_host}/approve-recomment-place`, { uid: this.props.uid });
        // console.log(res);
        if (res.data.success) {
            this.fetch();
        }
    }
    async onCancel() {
        let res = await axios.delete(`${window.api_host}/recomment-place?` + window.$.param({ uid: this.props.uid }));
        // console.log(res);
        if (res.data.success) {
            this.fetch();
        }
    }



    render() {
        // console.log(this.state.data.photoPath)
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">สถานที่แนะนำ </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/recomment-place">สถานที่แนะนำ</Link></li>
                                    {
                                        this.state.data ?
                                            <li className="breadcrumb-item active">{this.state.data.nameTH}</li>
                                            : null
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={"col-12 col-md-3"}>
                                {/* STORE INFO */}
                                <div className="card card-outline">
                                    <div className="card-body box-profile">
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && !this.state.data ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    ไม่มีข้อมูล
                                                </div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data ?
                                                <>
                                                    <div className="flex row">
                                                        <div>
                                                            <div>{Helper.getStoreStatus(this.state.data.status)}</div>
                                                            <div>
                                                                {
                                                                    this.state.data.isPrivate ?
                                                                        <span className="badge badge-info">ส่วนตัว</span>
                                                                        :
                                                                        <span className="badge badge-dark">สาธารณะ</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={"right-floated"}>
                                                            {
                                                                !this.state.disableEdit ?
                                                                    <Link to={"/recomment-place/edit/" + this.state.data.uid} className="link"> แก้ไข</Link>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.data.approvedAt ?
                                                                    <span>{Helper.getDateThai(this.state.data.approvedAt, { is_show_time: true })}</span>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="profile-user-img img-frame img-fluid img-circle">
                                                            <img
                                                                src={this.state.data.photoPath ? this.state.data.photoPath : "/assets/images/no_upload_image.jpg"}
                                                                alt="Store profile" />
                                                        </div>
                                                    </div>
                                                    <h3 className="profile-username text-center">{this.state.data.nameTH || '-'}</h3>

                                                    <p className="text-muted text-center">{this.state.data.nameEN || '-'}</p>

                                                    <ul className="list-group list-group-unbordered mb-3">
                                                        <li className="list-group-item">
                                                            <b>ผู้ติดตาม</b> <div className="float-right">{Helper.numberFormat(this.state.follow_amount)}</div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <b>ราคา</b> <div className="float-right">฿ {Helper.numberFormat(this.state.data.minPrice)} - {Helper.numberFormat(this.state.data.maxPrice)}</div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <b>จำนวนผู้เข้าร้าน</b> <div className="float-right">{Helper.numberFormat(this.state.data.guestsLimit)}</div>
                                                        </li>
                                                    </ul>

                                                    <div className="flex row">

                                                        <div className={"right-floated"}>{Helper.getDateThai(this.state.data.createdAt, { is_show_time: true })}</div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                                {
                                    !this.state.is_loading
                                        && this.state.data ?
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><i className="far fa-file-alt mr-1"></i> ภาพรวม</strong>
                                                <p className="text-muted">
                                                    {this.state.data.overview}
                                                </p>
                                                <hr />
                                                <strong><i className="fas fa-map-marker-alt mr-1"></i>ที่อยู่</strong>
                                                <p className="text-muted">
                                                    {
                                                        this.state.data.contact
                                                            && this.state.data.contact.address ?
                                                            this.state.data.contact.address + ' '
                                                            : null
                                                    }
                                                    {
                                                        this.state.data.contact.province
                                                            && this.state.data.contact.province.name ?
                                                            this.state.data.contact.province.name.th : null
                                                    }
                                                </p>
                                                <hr />
                                                <div className="flex row">
                                                    <div>
                                                        <strong><i className="far fa-envelope"></i> อีเมล์</strong>
                                                    </div>
                                                    <div className="text-muted right-floated">
                                                        {
                                                            this.state.data.contact
                                                                && this.state.data.contact.email ?
                                                                this.state.data.contact.email
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex row">
                                                    <div>
                                                        <strong><i className="fas fa-mobile-alt"></i> เบอร์โทรศัพท์</strong>
                                                    </div>
                                                    <div className="text-muted right-floated">
                                                        {
                                                            this.state.data.contact
                                                                && this.state.data.contact.telephone ?
                                                                this.state.data.contact.telephone
                                                                : null
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className={"col-12 col-md-3"}>
                                {
                                    !this.state.is_loading
                                        && this.state.data.workingTime ?
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="card-title">วันเวลาทำการ</div>
                                            </div>
                                            <div className="card-body">
                                                <DateTimeView
                                                    day={this.state.data.workingTime.monday}
                                                    title={"วันจันทร์"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.tuesday}
                                                    title={"วันอังคาร"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.wednesday}
                                                    title={"วันพุธ"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.thursday}
                                                    title={"วันพฤหัส"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.friday}
                                                    title={"วันศุกร์"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.saturday}
                                                    title={"วันเสาร์"}
                                                />
                                                <hr />
                                                <DateTimeView
                                                    day={this.state.data.workingTime.sunday}
                                                    title={"วันอาทิตย์"}
                                                />
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className={"col-12 col-md-6"}>
                                {
                                    !this.state.is_loading
                                        && this.state.data ?
                                        <>
                                            {
                                                this.state.data
                                                    && this.state.data.coordinate
                                                    && this.state.data.coordinate._latitude
                                                    && this.state.data.coordinate._longitude ?
                                                    <GoogleMap
                                                        height={425}
                                                        mode={"view"}
                                                        lat={this.state.data.coordinate._latitude}
                                                        lng={this.state.data.coordinate._longitude}
                                                    />
                                                    : null
                                            }

                                        </>
                                        : null
                                }
                            </div>
                        </div>
                        {
                            !this.state.is_loading
                                && !this.state.disableEdit ?
                                <div className="right-floated">

                                    <button
                                        ref={(ref) => {
                                            if (window.$(ref).hasClass('loading')) { return; }
                                            window.$(ref).off().on('click', () => {
                                                window.$(ref).addClass('loading');
                                                Helper.confirmModal({
                                                    title: "ยืนยันการยกเลิกสถานที่",
                                                    description: "ต้องการยกเลิกสถานที่แนะนำนี้ใช่หรือไม่ ?",
                                                    onCancel: () => {
                                                        window.$(ref).removeClass('loading');
                                                    },
                                                    onConfirm: () => {
                                                        this.setState({
                                                            is_loading: true
                                                        }, () => {
                                                            this.onCancel();
                                                        })
                                                    }
                                                })
                                            })
                                        }}
                                        type="button"
                                        className="btn btn-danger btn-lg mb-2 inverted"
                                    >ยกเลิก</button>
                                    <button
                                        ref={(ref) => {
                                            if (window.$(ref).hasClass('loading')) { return; }
                                            window.$(ref).off().on('click', () => {
                                                window.$(ref).addClass('loading');
                                                Helper.confirmModal({
                                                    title: "ยืนยันการอนุมัติสถานที่",
                                                    description: "ต้องการอนุมัติสถานที่แนะนำนี้ใช่หรือไม่ ?",
                                                    onCancel: () => {
                                                        window.$(ref).removeClass('loading');
                                                    },
                                                    onConfirm: () => {
                                                        this.setState({
                                                            is_loading: true
                                                        }, () => {
                                                            this.onApprove();
                                                        })
                                                    }
                                                })
                                            })
                                        }}
                                        disabled={this.state.data
                                            && (
                                                !this.state.data.contact
                                                || !this.state.data.contact.address
                                                || !this.state.data.nameTH
                                                || !this.state.data.nameEN
                                                || !this.state.data.coordinate
                                            )
                                        }
                                        style={{ marginLeft: 15 }}
                                        type="button"
                                        className="btn btn-success btn-lg mb-2 inverted"
                                    >อนุมัติ</button>
                                </div>
                                : null
                        }
                    </div>
                </section>
            </>
        );
    }
}

class DateTimeView extends React.Component {
    render() {
        return (
            <div className="flex row">
                <div>
                    <strong><i className="far fa-calendar-alt"></i> {this.props.title}</strong>
                </div>
                <div className="text-muted right-floated">
                    {
                        this.props.day
                            && this.props.day.time
                            && this.props.day.time.open
                            && this.props.day.time.close ?
                            <><i className="far fa-clock"></i> {this.props.day.time.open + ' - ' + this.props.day.time.close}</>
                            : 'ปิดทำการ'
                    }
                </div>
            </div>
        );
    }
}