import React from 'react';
import { Helper } from './../../Helper';

export default class PreviewPackage extends React.Component {
    render() {
        return (
            <>
                <div className={"package-table-scrollbar"}>
                    <table className="table table-condensed">
                        <thead>
                            <tr>
                                <th><div style={{ width: 150 }} /></th>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <th key={item_i}>
                                                <div style={{ textAlign: 'center', width: 110 }}>{item.name}</div>
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div>ระบบโพส</div>
                                    <ul style={{ marginBottom: 0 }}>
                                        {/* <li>ผู้ใช้งาน</li> */}
                                        <li>ร้าน</li>
                                        <li>ลบโพส</li>
                                    </ul>
                                </td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                <div>
                                                    {
                                                        item.options.features.haveTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div>
                                                {/* <div>
                                                    {
                                                        item.options.features.canGuestPostTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div> */}
                                                <div>
                                                    {
                                                        item.options.features.canStorePostTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        item.options.features.canStoreRemoveTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div>
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            {/* <tr>
                                <td>ระบบสกิดบนแอพพลิเคชั่น</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveSkitz ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr> */}
                            <tr>
                                <td>บังคับผู้ใช้งานออกจากระบบ (เตะผู้ใช้งาน)</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.canKickMember ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            {/* <tr>
                                <td>แบนผู้ใช้งาน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveBanUser ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr> */}
                            <tr>
                                <td>ระบบข่าวสารของร้าน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.canManageEvent ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ระบบจัดการผู้ดูแลระบบและสิทธิ์การเข้าถึง</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.havePermissionStore ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ดูรูปโปรไฟล์ผู้ใช้งาน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.isPreviewMemberProfileStore ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ระบบนักดนตรี/Live</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveArtistLive ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ระบบจัดการเมนู</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveMenu ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>จำนวนผู้เข้าสู่ระบบสูงสุด</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {Helper.numberFormat(item.options.features.onlineCapacity)} คน
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ราคา/เดือน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {Helper.numberFormat(item.options.summary.total)}
                                            </td>
                                        );
                                    })
                                }
                            </tr>

                        </tbody>
                    </table>
                </div>

            </>
        );
    }
}