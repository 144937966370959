import React from 'react';

export default class CustomDateInput extends React.Component {
    render() {
        let date = new Date(this.props.value);
        return (
            <>
                <button className={this.props.size === 'sm' ? "form-control-sm form-control" : "form-control"}
                    onClick={this.props.onClick}
                    type={"button"}
                    style={{ color: !this.props.value ? '#ccc' : null, textAlign: "left" }}
                >
                    {this.props.value ? date.getDate() + ' ' + window.months_th[parseFloat(date.getMonth())] + ' ' + (date.getFullYear() + 543) : 'วัน เดือน ปี'}
                </button>
            </>
        );
    }
}