import React from 'react';
import { Helper } from './../Helper';
import { Link } from '@reach/router';
import Pagination from './../Components/Pagination';
import { db } from './../firebase';
import { getReportDaily, getReportSummary, getReportOverview } from './../services/ReportServices'
import { getUsers } from '../services/UserServices';
const axios = require('axios').default;
export default class Dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            new_members: [],
            new_member_count: 0,

            new_stores: [],
            new_store_count: 0,

            new_places: [],
            new_place_count: 0,

            reports: [],

            is_loading: true,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.get(window.api_host + '/master-dashboard');
        // console.log(res.data);
        let new_members = [];
        let new_stores = [];
        let new_places = [];
        let reports = [];
        let new_member_count = 0;
        let new_store_count = 0;
        let new_place_count = 0;
        if (res.data.success && res.data.members) {
            new_members = res.data.members;
            new_member_count = res.data.new_member_count;
        }
        if (res.data.success && res.data.new_stores) {
            new_stores = res.data.new_stores;
            new_store_count = res.data.new_store_count;
        }
        if (res.data.success && res.data.new_places) {
            new_places = res.data.new_places;
            new_place_count = res.data.new_place_count;
        }
        if (res.data.success && res.data.reports) {
            for (const report of res.data.reports) {
                let push_data = report;
                // console.log(report)
                let storeRef = await db.collection('stores').doc(report.storeUid).get();
                let name = 'ไม่มีข้อมูล';
                if (storeRef.data() && storeRef.data().nameTH) {
                    name = storeRef.data().nameTH;
                }
                push_data.store = {
                    // ...storeRef.data(),
                    name: name,
                    uid: storeRef.id,
                }
                if (report.reportBy === 'user') {
                    let userRef = await db.collection('users').doc(report.relate_id).get();
                    let name = 'ไม่มีข้อมูล';
                    let link = false;
                    if (userRef.data() && userRef.data().displayName) {
                        name = userRef.data().displayName;
                        link = true;
                    }
                    push_data.reporter = {
                        // ...userRef.data(),
                        displayName: name,
                        link: link ? '/member/view/' + userRef.id : false,
                    }
                }
                if (report.reportBy === 'store') {
                    let name = 'ไม่มีข้อมูล';
                    let link = false;
                    if (push_data && push_data.store && push_data.store.name) {
                        name = push_data.store.name;
                        link = true;
                    }
                    push_data.reporter = {
                        // ...userRef.data(),
                        displayName: name,
                        link: link ? '/store/view/' + push_data.store.uid : false,
                    }
                }
                if (report.poster_by === 'user') {
                    let userRef = await db.collection('users').doc(report.poster_id).get();
                    let name = 'ไม่มีข้อมูล';
                    let link = false;
                    if (userRef.data() && userRef.data().displayName) {
                        name = userRef.data().displayName;
                        link = true;
                    }
                    push_data.poster = {
                        // ...userRef.data(),
                        displayName: name,
                        link: link ? '/member/view/' + userRef.id : false,
                    }
                }
                if (report.poster_by === 'store') {
                    let name = 'ไม่มีข้อมูล';
                    let link = false;
                    if (report && report.store && report.store.name) {
                        name = report.store.name;
                        link = true;
                    }
                    push_data.poster = {
                        // ...userRef.data(),
                        displayName: name,
                        link: link ? '/store/view/' + report.store.uid : false,
                    }
                }
                reports.push(push_data);
            }
        }
        let dialy = false;
        let _dialy = await getReportDaily();
        if (!_dialy.error) {
            dialy = _dialy;
        }
        let summary = false;
        let _summary = await getReportSummary();
        if (!_summary.error) {
            summary = _summary;
        }
        let overview = false;
        let _overview = await getReportOverview();
        let member = await getUsers(1, 1);
        if (!_overview.error) {
            overview = _overview;
            overview.totalUser = member.total || 0;
        }
        this.setState({
            dialy: dialy,
            summary: summary,
            overview: overview,
            new_members: new_members,
            new_member_count: new_member_count,
            new_stores: new_stores,
            new_store_count: new_store_count,
            new_places: new_places,
            new_place_count: new_place_count,
            reports: reports,
            is_loading: false,
        })
    }


    render() {
        if (this.state.is_loading) {
            return (<div style={{ padding: '1rem 0', textAlign: 'center' }}><div className="ui active inline loader" ></div></div>)
        }

        return (
            <>
                <div className="content-header">

                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">

                            <div className='col-12 col-md-10'>
                                <div className='row'>
                                    <div className='col-12 col-md-3 mb-2'>
                                        <ReportDetail
                                            dialy={this.state.dialy}
                                            summary={this.state.summary}
                                            overview={this.state.overview}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3 mb-2">
                                        <div className="info-box bg-gradient-info">
                                            <span className="info-box-icon"><i className="fas fa-store-alt"></i></span>
                                            <div className="info-box-content">
                                                <div className="flex row">
                                                    <div>
                                                        <span className="info-box-text">ร้านค้าใหม่</span>
                                                        <span className="info-box-number">{this.state.new_store_count} ร้าน</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <CardList
                                            title={"รายการร้านค้าใหม่"}
                                            data={this.state.new_stores}
                                            location={this.props.location}
                                            count_all={this.state.new_store_count}
                                            redirectUrl={'/store/view/'}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3 mb-2">
                                        <div className="info-box bg-gradient-green">
                                            <span className="info-box-icon"><i className="fas fa-map-marked-alt"></i></span>
                                            <div className="info-box-content">
                                                <div className="flex row">
                                                    <div>
                                                        <span className="info-box-text">สถานที่ใหม่</span>
                                                        <span className="info-box-number">{this.state.new_place_count} สถานที่</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <CardList
                                            title={"รายการสถานที่ใหม่"}
                                            data={this.state.new_places}
                                            location={this.props.location}
                                            count_all={this.state.new_place_count}
                                            redirectUrl={'/place/view/'}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3 mb-2">
                                        <div className="info-box bg-gradient-blue">
                                            <span className="info-box-icon"><i className="fas fa-users"></i></span>
                                            <div className="info-box-content">
                                                <div className="flex row">
                                                    <div>
                                                        <span className="info-box-text">สมาชิกใหม่</span>
                                                        <span className="info-box-number">{this.state.new_member_count} คน</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <CardList
                                            title={"รายการสมาชิกใหม่"}
                                            data={this.state.new_members}
                                            location={this.props.location}
                                            count_all={this.state.new_member_count}
                                            customRenderList={(item, item_i) => {
                                                return (
                                                    <li className="nav-item" key={item_i}>
                                                        <Link to={"/member/view/" + item.uid} className="nav-link">
                                                            <div className="store-item item-row">
                                                                {
                                                                    item.photoPath ?
                                                                        <div className="icon">
                                                                            <div>
                                                                                <img src={item.photoPath} alt={"MemberIMG"} />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="icon">
                                                                            <div>
                                                                                <img src={window.location.origin + "/assets/images/no_upload_image.jpg"} alt={"NoUpload"} />
                                                                            </div>
                                                                        </div>
                                                                }
                                                                <div className="description">
                                                                    <div>{item.displayName}</div>
                                                                    <div>
                                                                        {
                                                                            item.isOnline ?
                                                                                <span className="badge badge-success">อยู่ในร้าน</span>
                                                                                :
                                                                                <span className="badge badge-secondary">ออกจากร้าน</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="right-floated">
                                                                    <div className="small-text">{Helper.getDateThai(new Date(item.createdAt).getTime())}</div>
                                                                    <div className="small-text">{Helper.getTime(new Date(item.createdAt).getTime())}</div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class CardList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            page: 1,
            limit: 6,
            count_all: props.count_all,
            is_loading: true,
        }
    }

    componentDidMount() {
        this.fetch();
    }
    fetch() {
        let prepareData = Helper.prepareDataLimit(this.props.data, this.state.limit, this.state.page);
        let return_data = {};
        return_data.item = prepareData;
        this.setState({
            data: return_data.item,
            is_loading: false
        })
    }



    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{this.props.title ? this.props.title : 'รายการ'}</h3>
                </div>
                {/* STORE LIST */}
                <div className="card-body p-0" style={{ minHeight: this.state.count_all > 5 ? 442 : null }}>
                    {
                        !this.state.is_loading
                            && this.state.data.length === 0 ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                            : null
                    }
                    {
                        this.state.is_loading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                <div className="ui active inline loader small" ></div>
                            </div>
                            : null
                    }
                    {
                        this.state.data.length > 0
                            && !this.state.is_loading ?
                            <>
                                <ul className="nav nav-pills flex-column">
                                    {
                                        this.state.data.map((_data, _data_i) => {
                                            let photoPath = false;
                                            if (_data.photoPath && _data.photoPath.length > 0) {
                                                photoPath = _data.photoPath[0];
                                            }
                                            if (this.props.customRenderList) {
                                                return this.props.customRenderList(_data, _data_i)
                                            }
                                            let url = window.location.pathname + "/view/";
                                            if (this.props.redirectUrl) {
                                                url = this.props.redirectUrl;
                                            }
                                            return (
                                                <li className="nav-item" key={_data_i}>
                                                    <Link to={url + _data.uid} className="nav-link">
                                                        <div className="store-item item-row">
                                                            {
                                                                photoPath ?
                                                                    <div className="icon">
                                                                        <div>
                                                                            <img src={photoPath} alt={"StoreIMG"} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="icon">
                                                                        <div>
                                                                            <img src={window.location.origin + "/assets/images/no_upload_image.jpg"} alt={"NoUpload"} />
                                                                        </div>
                                                                    </div>
                                                            }
                                                            <div className="description">
                                                                <div>{_data.nameTH}</div>
                                                                <div>
                                                                    {
                                                                        _data.store_type ?
                                                                            <span className="badge badge-info" style={{ marginRight: 5 }}>{_data.store_type.name}</span>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.status && _data.status === 'online' ?
                                                                            <span className="badge badge-success">ออนไลน์</span>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.status && _data.status === 'offline' ?
                                                                            <span className="badge badge-secondary">ออฟไลน์</span>
                                                                            : null
                                                                    }
                                                                    {
                                                                        (_data.status && _data.status === 'pause')
                                                                            || !_data.status ?
                                                                            <span className="badge badge-warning">ระงับ</span>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.status && _data.status === 'cancel' ?
                                                                            <span className="badge badge-danger">ยกเลิก</span>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="right-floated">
                                                                <div className="small-text">{Helper.getDateThai(new Date(_data.createdAt).getTime())}</div>
                                                                <div className="small-text">{Helper.getTime(new Date(_data.createdAt).getTime())}</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                            </>
                            : null
                    }
                </div>
                {
                    !this.props.disabledPagination
                        && this.state.data.length !== 0 ?
                        <div className="card-footer clearfix">
                            <Pagination
                                now={this.state.page}
                                all_items={this.state.count_all}
                                limit={this.state.limit}
                                location={this.props.location}
                                onReturnPage={(page) => {
                                    this.setState({
                                        is_loading: true,
                                        page: page,
                                    }, () => {
                                        this.fetch();
                                    })
                                }}
                            />
                        </div>
                        : null
                }
            </div>
        );
    }
}
class ReportDetail extends React.Component {
    render() {
        return (
            <>
                <h5>Overview</h5>
                <div className='card'>
                    <div >
                        <ul className='list-group'>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>ออนไลน์</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.overview && this.props.overview.totalOnline ? Helper.numberFormat(this.props.overview.totalOnline) : 0
                                        } <i className='fa fa-check-circle' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>พรีเมี่ยม</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.overview && this.props.overview.totalPremiumUser ? Helper.numberFormat(this.props.overview.totalPremiumUser) : 0
                                        } <i className='fa fa-user-circle' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>ร้าน</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.overview && this.props.overview.totalStore ? Helper.numberFormat(this.props.overview.totalStore) : 0
                                        } <i className='fa fa-store' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>สมาชิก</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.overview && this.props.overview.totalUser ? Helper.numberFormat(this.props.overview.totalUser) : 0
                                        } <i className='fa fa-users' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>การสกิด</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.overview && this.props.overview.totalSkitz ? Helper.numberFormat(this.props.overview.totalSkitz) : 0
                                        } <i className='fa fa-users' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>ขอเพลง</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.overview && this.props.overview.totalMusicRequest ? Helper.numberFormat(this.props.overview.totalMusicRequest) : 0
                                        } <i className='fa fa-users' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <h5>Dialy</h5>
                <div className='card'>
                    <div >
                        <ul className='list-group'>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>ออนไลน์</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.dialy && this.props.dialy.totalOnline ? Helper.numberFormat(this.props.dialy.totalOnline) : 0
                                        } <i className='fa fa-users' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>สมัครพรีเมี่ยม</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.dialy && this.props.dialy.newPremiumUser ? Helper.numberFormat(this.props.dialy.newPremiumUser) : 0
                                        } <i className='fa fa-user-circle' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>สมัครสมาชิก</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.dialy && this.props.dialy.newUser ? Helper.numberFormat(this.props.dialy.newUser) : 0
                                        } <i className='fa fa-user-circle' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>การขอเพลง</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.dialy && this.props.dialy.totalRequest ? Helper.numberFormat(this.props.dialy.totalRequest) : 0
                                        } <i className='fa fa-music' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>การสกิด</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.dialy && this.props.dialy.totalSkitz ? Helper.numberFormat(this.props.dialy.totalSkitz) : 0
                                        } <i className='fa fa-hand-paper' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>การเข้าใช้งาน Application</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.dialy && this.props.dialy.userCheckedIn ? Helper.numberFormat(this.props.dialy.userCheckedIn) : 0
                                        } <i className='fa fa-mobile-alt' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>สมาชิกเช็คอิน</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.dialy && this.props.dialy.totalCheckedIn ? Helper.numberFormat(this.props.dialy.totalCheckedIn) : 0
                                        } <i className='fa fa-map-marker-alt' style={{ color: '#999' }}></i>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <h5>Total</h5>
                <div className='card'>
                    <div >
                        <ul className='list-group'>
                            <li className='list-group-item'>
                                <div>ยอดการแจก Airdrop</div>
                                <div className='d-flex'>
                                    <label className='mb-0'>{
                                        this.props.summary && this.props.summary.totalAirdropCount ? Helper.numberFormat(this.props.summary.totalAirdropCount) : 0
                                    } ครั้ง
                                    </label>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.summary && this.props.summary.totalAirdrop ? Helper.numberFormat(this.props.summary.totalAirdrop) : 0
                                        } <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ width: 15, marginTop: -2 }} />
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div>ยอดการแจกอังเปา</div>
                                <div className='d-flex'>
                                    <label className='mb-0'>{
                                        this.props.summary && this.props.summary.totalAngpaoCount ? Helper.numberFormat(this.props.summary.totalAngpaoCount) : 0
                                    } ครั้ง
                                    </label>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.summary && this.props.summary.totalAngpao ? Helper.numberFormat(this.props.summary.totalAngpao) : 0
                                        } <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ width: 15, marginTop: -2 }} />
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div>ยอดการส่งของขวัญ (Gift)</div>
                                <div className='d-flex'>
                                    <label className='mb-0'>{
                                        this.props.summary && this.props.summary.totalChatGiftCount ? Helper.numberFormat(this.props.summary.totalChatGiftCount) : 0
                                    } ครั้ง
                                    </label>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.summary && this.props.summary.totalChatGift ? Helper.numberFormat(this.props.summary.totalChatGift) : 0
                                        } <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ width: 15, marginTop: -2 }} />
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div>ยอดสมัคร Premium</div>
                                <div className='d-flex'>
                                    <label className='mb-0'>{
                                        this.props.summary && this.props.summary.totalPremiumCount ? Helper.numberFormat(this.props.summary.totalPremiumCount) : 0
                                    } ครั้ง
                                    </label>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.summary && this.props.summary.totalPremium ? Helper.numberFormat(this.props.summary.totalPremium) : 0
                                        } <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ width: 15, marginTop: -2 }} />
                                    </label>
                                </div>
                            </li>
                            <li className='list-group-item'>
                                <div>ยอดการส่ง Superchat</div>
                                <div className='d-flex'>
                                    <label className='mb-0'>{
                                        this.props.summary && this.props.summary.totalSuperChatCount ? Helper.numberFormat(this.props.summary.totalSuperChatCount) : 0
                                    } ครั้ง
                                    </label>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.summary && this.props.summary.totalSuperChat ? Helper.numberFormat(this.props.summary.totalSuperChat) : 0
                                        } <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ width: 15, marginTop: -2 }} />
                                    </label>
                                </div>
                            </li>
                            {/* <li className='list-group-item'>
                                <div className='d-flex'>
                                    <div>ยอดเติมเงินสะสม</div>
                                    <label className='right-floated mb-0'>
                                        {
                                            this.props.summary && this.props.summary.totalTopup ? Helper.numberFormat(this.props.summary.totalTopup) : 0
                                        } <small style={{ color: '#999' }}>บาท</small>
                                    </label>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}