import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/user`;
export const getUsers = async (page = 1, limit = 10, genderId, minAge, maxAge, q, isOnline, storeFirebaseUid) => {
    let url = `${baseUrl}?page=${page}&rows=${limit}`;
    if (q) {
        url += `&q=${q}`;
    }
    console.log(isOnline)
    if (isOnline) {
        url += `&isOnline=${isOnline}`;
    }
    if (storeFirebaseUid) {
        url += `&storeFirebaseUid=${storeFirebaseUid}`;
    }
    if (maxAge) {
        url += `&maxAge=${maxAge}`;
    }
    if (genderId) {
        url += `&genderId=${genderId}`;
    }
    if (minAge) {
        url += `&minAge=${minAge}`;
    }
    if (maxAge) {
        url += `&maxAge=${maxAge}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getUser = async (firebaseUid = false) => {
    let url = `${baseUrl}/${firebaseUid}`;
    // console.log(url)
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}