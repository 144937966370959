import React from 'react';
import { Link } from '@reach/router';
import { Helper } from './../../Helper';
import ReportPost from './Post';
import ReportUser from './User';
import ViewPost from './ViewPost';

export default class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            report_type: Helper.getParameterByName("report_type") && Helper.getParameterByName("report_type") !== '' ? Helper.getParameterByName("report_type") : false,
        }
    }

    componentDidUpdate(prevProps) {
        // console.log(this.props.location)
        // console.log(prevProps.location)

        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                report_type: Helper.getParameterByName("report_type") && Helper.getParameterByName("report_type") !== '' ? Helper.getParameterByName("report_type") : false,
            })
        }
    }



    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ข้อมูลรายงาน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    {
                                        this.props.mode === 'view' ?
                                            <li className="breadcrumb-item">
                                                <Link to={"/report?report_type=" + this.state.report_type}>
                                                    {
                                                        this.state.report_type === 'post' ? "รายงานโพส" : null
                                                    }
                                                </Link>
                                            </li>
                                            :
                                            <li className="breadcrumb-item active">ข้อมูลผู้รายงาน</li>
                                    }
                                    {
                                        this.props.mode === 'view' && this.state.report_type === 'post' ?
                                            <li className="breadcrumb-item active">ข้อมูลโพส</li>
                                            : null
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-2 col-xs-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายงาน</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <ul className="nav nav-pills flex-column">
                                            <li className={this.state.report_type && this.state.report_type === 'post' ? "nav-item active" : "nav-item"}>
                                                <Link to="/report?report_type=post"
                                                    className="nav-link"
                                                    style={{
                                                        paddingTop: '.8rem',
                                                        paddingBottom: '.8rem',
                                                        color: this.state.report_type && this.state.report_type === 'post' ? '#007bff' : null,
                                                    }}>
                                                    <i className="fas fa-file"></i> โพส
                                                {/* <span className="badge bg-primary float-right">0</span> */}
                                                </Link>
                                            </li>
                                            <li className={this.state.report_type && this.state.report_type === 'user' ? "nav-item active" : "nav-item"}>
                                                <Link to="/report?report_type=user"
                                                    className="nav-link"
                                                    style={{
                                                        paddingTop: '.8rem',
                                                        paddingBottom: '.8rem',
                                                        color: this.state.report_type && this.state.report_type === 'user' ? '#007bff' : null,
                                                    }}>
                                                    <i className="fas fa-exclamation"></i> ผู้ใช้งาน
                                                {/* <span className="badge bg-primary float-right">0</span> */}
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-10 col-xs-12">
                                {
                                    this.props.mode === 'list'
                                        && this.state.report_type
                                        && this.state.report_type === 'post' ?
                                        <ReportPost
                                            {...this.props}
                                        />
                                        : null
                                }
                                {
                                    this.props.mode === 'view'
                                        && this.state.report_type
                                        && this.state.report_type === 'post' ?
                                        <ViewPost
                                            {...this.props}
                                        />
                                        : null
                                }
                                {
                                    this.props.mode === 'list'
                                        && this.state.report_type
                                        && this.state.report_type === 'user' ?
                                        <ReportUser
                                            {...this.props}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
