import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/payment`;
export const getPaymentTransaction = async (userId = false, page = 1, rows = 10, date = false, status = false, channel = false, q = false) => {
    let url = `${baseUrl}/transaction?page=${page}&rows=${rows}`;
    if (userId) {
        url += `&userId=${userId}`;
    }
    if (q) {
        url += `&q=${q}`;
    }
    if (date) {
        url += `&date=${date}`;
    }
    if (status) {
        url += `&statusId=${status}`;
    }
    if (channel) {
        url += `&channelId=${channel}`;
    }
    // console.log(url)
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const paymentRefund = async (data) => {
    let url = `${baseUrl}/refund`;
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}