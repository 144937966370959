import React from 'react';
import { Link } from '@reach/router';
import { Helper } from './../../Helper';
import Pagination from './../../Components/Pagination';
import ArtistPayment from './Include/ArtistPayment';
import ExchangeRequest from './Include/ExchangeRequest';
import ArtistLiveHistory from './Include/ArtistLiveHistory';
import { getArtistInfo } from '../../services/ArtistServices';
const axios = require('axios').default;

const payment_default_limit = 10;
export default class View extends React.Component {
    constructor() {
        super();
        this.state = {
            data: false,
            is_loading: true,
        }
        this.ArtistInformationDetailRef = React.createRef();
        this.ArtistPaymentRef = React.createRef();
        this.ArtistLiveRef = React.createRef();
        this.ExchangeRequestRef = React.createRef();
    }
    componentDidMount() {
        this.fetch();
        this.ArtistInformationDetailRef.current.fetchFollower();
    }

    async fetch() {
        let res = await getArtistInfo(this.props.uid)
        let item = false;
        if (!res.error) {
            item = res;
        }
        this.setState({
            is_loading: false,
            data: item,
        })
    }
    banUser() {
        Helper.confirmModal({
            title: `แบนผู้ใช้งาน`,
            description: `ยืนยันการแบนผู้ใช้งาน`,
            onConfirm: async () => {
                let data = {};
                data.uid = this.props.uid;
                data.adminUid = this.props.adminUid;
                let res = await axios.post(`${window.api_host}/user-ban`, { ...data });
                // console.log(res.data);
                if (res.data.success) {
                    this.fetch();
                    Helper.messageTop({ message: "แบน User นี้แล้ว", type_class: 'alert-success' })
                }
                if (res.data.error) {
                    Helper.messageTop({ message: res.data.message })
                }
            }
        })
    }

    unBanUser() {
        Helper.confirmModal({
            title: `ปลดแบนผู้ใช้งาน`,
            description: `ยืนยันการปลดแบนผู้ใช้งาน`,
            onConfirm: async () => {
                let data = {};
                data.uid = this.props.uid;
                data.adminUid = this.props.adminUid;
                let res = await axios.post(`${window.api_host}/user-unban`, { ...data });
                // console.log(res.data);
                if (res.data.success) {
                    this.fetch();
                    Helper.messageTop({ message: "แบน User นี้แล้ว", type_class: 'alert-success' })
                }
                if (res.data.error) {
                    Helper.messageTop({ message: res.data.message })
                }
            }
        })
    }
    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">นักดนตรี </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/artist">นักดนตรี</Link></li>
                                    <li className="breadcrumb-item active">ดูข้อมูล</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={"col-12 col-md-3"}>
                                {/* ARTIST INFO */}
                                <div className="card card-outline">
                                    <div className="card-body box-profile">

                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, flexDirection: 'column' }}>
                                                    <div className="ui active inline loader" ></div> <br />
                                                    กำลังโหลดข้อมูล
                                                </div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data ?
                                                <>
                                                    <div className={`flex row`}>
                                                        {
                                                            this.state.data.isBanned ?
                                                                <div className={`flex row`} style={{ alignItems: `center` }}>
                                                                    <i className="fas fa-circle" style={{ color: 'red', marginRight: 5 }} /> ถูกแบน
                                                                </div>
                                                                : null
                                                        }
                                                        <div className={`right-floated`}>
                                                            <div className="dropdown">
                                                                <button
                                                                    className="btn btn-tools dropdown-toggle"
                                                                    type="button"
                                                                    id="dropdownMenuButton"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                    style={{ padding: 0 }}
                                                                >
                                                                    <i className="fas fa-bars"></i>
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                                                    {
                                                                        !this.state.data.isBanned ?
                                                                            <button
                                                                                type="button"
                                                                                className="dropdown-item"
                                                                                onClick={() => {
                                                                                    this.banUser()
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-circle" style={{ color: 'red' }} /> แบน
                                                                            </button>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.state.data.isBanned ?
                                                                            <button
                                                                                type="button"
                                                                                className="dropdown-item"
                                                                                onClick={() => {
                                                                                    this.unBanUser()
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-circle" style={{ color: 'green' }} /> ปลดแบน
                                                                            </button>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="profile-user-img img-frame img-fluid img-circle">
                                                            <img
                                                                src={this.state.data && this.state.data.imageUrl ? this.state.data.imageUrl : "/assets/images/no_img.png"}
                                                                alt="User profile" />
                                                        </div>
                                                    </div>
                                                    <h3 className="profile-username text-center">{this.state.data.name || '-'}</h3>
                                                    <div className='mb-2' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                        <span style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, padding: '0 10px', borderRight: '1px solid #f4f4f4', alignItems: 'center' }}>
                                                            {Helper.numberFormat(this.state.data.currentCoin)}
                                                            <div style={{ display: 'inline-flex', width: 15, height: 15, borderRadius: 35, marginLeft: 3, backgroundColor: "orange", overflow: 'hidden', marginLeft: 2, alignItems: 'center', justifyContent: 'center' }}>
                                                                <span style={{ color: 'white', fontSize: 10, fontWeight: 'bold', textTransform: 'uppercase' }}>C</span>
                                                            </div>
                                                        </span>
                                                        <span style={{ display: 'flex', flex: 1, padding: '0 10px', borderLeft: '1px solid #f4f4f4', alignItems: 'center' }}>
                                                            {Helper.numberFormat(this.state.data.currentPoint)}
                                                            <div style={{ display: 'inline-flex', width: 15, height: 15, borderRadius: 35, marginLeft: 3, backgroundColor: "#50AFFF", overflow: 'hidden', marginLeft: 2, alignItems: 'center', justifyContent: 'center' }}>
                                                                <span style={{ color: 'white', fontSize: 10, fontWeight: 'bold', textTransform: 'uppercase' }}>P</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div className='text-center'>
                                                        {
                                                            this.state.data
                                                                && this.state.data.isOnline ?
                                                                <span className="badge badge-success">ออนไลน์</span>
                                                                : null
                                                        }
                                                        {
                                                            this.state.data
                                                                && !this.state.data.isOnline ?
                                                                <span className="badge badge-secondary">ออฟไลน์</span>
                                                                : null
                                                        }
                                                    </div>

                                                    <ul className="list-group list-group-unbordered mb-3 mt-2">
                                                        <li className="list-group-item">
                                                            <b>Subscribe</b> <div className="float-right">{Helper.numberFormat(this.state.data.follower)}</div>
                                                        </li>
                                                        {/* <li className="list-group-item">
                                                            <b>Like</b> <div className="float-right">{Helper.numberFormat(this.state.data.like)}</div>
                                                        </li> */}
                                                    </ul>
                                                    <div className="flex row">
                                                        <div>
                                                            {
                                                                this.state.data
                                                                    && this.state.data.inStore ?
                                                                    <span className="badge badge-success">อยู่ในร้าน</span>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.data
                                                                    && !this.state.data.inStore ?
                                                                    <span className="badge badge-secondary">ไม่อยู่ในร้าน</span>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div className="right-floated">
                                                            {
                                                                this.state.data && this.state.data.store && this.state.data.clientLastActiveAt ?
                                                                    <>{this.state.data.store.name}  <span className="badge badge-secondary">{Helper.getDateThai(new Date(this.state.data.clientLastActiveAt), { is_show_time: true })}</span></>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        {/* <div className="flex row">
                                            <div><strong>วัน/เดือน/ปีเกิด</strong></div>
                                            <div className="right-floated">
                                                {this.state.data && this.state.data.birthDate ? Helper.getDateThai(new Date(this.state.data.birthDate)) : '-'}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="flex row">
                                            <div><strong>อายุ</strong></div>
                                            <div className="right-floated">
                                                {this.state.data && this.state.data.age && this.state.data.age !== undefined ? this.state.data.age : '-'}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="flex row">
                                            <div><strong>เพศ</strong></div>
                                            <div className="right-floated">
                                                {this.state.data && this.state.data.sex && this.state.data.sex === 'man' ? "ชาย" : null}
                                                {this.state.data && this.state.data.sex && this.state.data.sex === 'woman' ? "หญิง" : null}
                                                {
                                                    !this.state.data
                                                        || !this.state.data.sex ? '-' : null
                                                }
                                            </div>
                                        </div>
                                        <hr /> */}
                                        <div className="flex row">
                                            <div><strong>เบอร์โทรศัพท์</strong></div>
                                            <div className="text-muted right-floated">
                                                {this.state.data && this.state.data.phoneNumber ? this.state.data.phoneNumber : '-'}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="flex row">
                                            <div><strong>อีเมล์</strong></div>
                                            <div className="text-muted right-floated">
                                                {this.state.data && this.state.data.email ? this.state.data.email : "-"}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="card card-primary card-outline card-outline-tabs">
                                    <div className="card-header p-0 border-bottom-0">
                                        <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active"
                                                    id="custom-tabs-three-information-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-information"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-information"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                        this.ArtistInformationDetailRef.current.fetchFollower();
                                                    }}
                                                >
                                                    <h5>ข้อมูลนักดนตรี</h5>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"
                                                    id="custom-tabs-three-checkin-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-checkin"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-checkin"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                        this.ArtistLiveRef.current.firstTimeFetch();
                                                    }}
                                                >
                                                    <h5>ข้อมูลการ Live</h5>
                                                </a>
                                            </li>
                                            {
                                                this.state.data ?
                                                    <li className="nav-item">
                                                        <a className="nav-link"
                                                            id="custom-tabs-three-payment-tab"
                                                            data-toggle="pill"
                                                            href="#custom-tabs-three-payment"
                                                            role="tab"
                                                            aria-controls="custom-tabs-three-payment"
                                                            aria-selected="false"
                                                            onClick={() => {
                                                                this.ArtistPaymentRef.current.firstTimeFetch();
                                                            }}
                                                        >
                                                            <h5>ข้อมูลการเงิน</h5>
                                                        </a>
                                                    </li>
                                                    :

                                                    <li className="nav-item">
                                                        <a className="nav-link disabled"
                                                        >
                                                            <h5>ข้อมูลการเงิน</h5>
                                                        </a>
                                                    </li>
                                            }
                                            {
                                                this.state.data ?
                                                    <li className="nav-item">
                                                        <a className="nav-link"
                                                            id="custom-tabs-exchange-tab"
                                                            data-toggle="pill"
                                                            href="#custom-tabs-exchange"
                                                            role="tab"
                                                            aria-controls="custom-tabs-exchange"
                                                            aria-selected="false"
                                                            onClick={() => {
                                                                this.ExchangeRequestRef.current.firstTimeFetch();
                                                            }}
                                                        >
                                                            <h5>ข้อมูลการเบิกเงิน</h5>
                                                        </a>
                                                    </li>
                                                    :

                                                    <li className="nav-item">
                                                        <a className="nav-link disabled"
                                                        >
                                                            <h5>ข้อมูลการเบิกเงิน</h5>
                                                        </a>
                                                    </li>
                                            }
                                        </ul>
                                    </div>

                                    <div className="tab-content" id="custom-tabs-three-tabContent">
                                        <div className="tab-pane fade active show" id="custom-tabs-three-information" role="tabpanel" aria-labelledby="custom-tabs-three-information-tab">
                                            <ArtistInformationDetail
                                                ref={this.ArtistInformationDetailRef}
                                                data={this.state.data}
                                                is_loading={this.state.is_loading}
                                                uid={this.props.uid}
                                            />
                                        </div>
                                        <div className="tab-pane fade " id="custom-tabs-three-checkin" role="tabpanel" aria-labelledby="custom-tabs-three-checkin-tab">
                                            <ArtistLiveHistory
                                                ref={this.ArtistLiveRef}
                                                is_loading={this.state.is_loading}
                                                uid={this.props.uid}
                                                location={this.props.location}
                                            />
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-three-payment" role="tabpanel" aria-labelledby="custom-tabs-three-payment-tab">
                                            <ArtistPayment
                                                ref={this.ArtistPaymentRef}
                                                user={this.state.data}
                                                uid={this.props.uid}
                                                is_loading={this.state.is_loading}
                                            />
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-exchange" role="tabpanel" aria-labelledby="custom-tabs-exchange-tab">
                                            <ExchangeRequest
                                                ref={this.ExchangeRequestRef}
                                                user={this.state.data}
                                                uid={this.props.uid}
                                                storeuid={this.props.storeuid}
                                                is_loading={this.state.is_loading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class ArtistInformationDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            images: [],
            is_loading: true,
            page_image: 1,
            limit_image: 12,
            default_image: [],
            image_all: 0,

            follower: [],
            count_follower: 0,
            count_all_follower: 0,
            page_follower: 1,
            limit_follower: 12,
            is_follower_loading: true,
        }
    }

    fetchImages() {
        let data = {};
        let images = Helper.prepareDataLimit(this.state.default_image, this.state.limit_image, this.state.page_image, { mergePage: true });
        data.images = images;
        data.count_all = this.state.default_image.length;
        return data;
    }

    async fetchFollower() {
        let data = {};
        data.uid = this.props.uid;
        data.page = this.state.page_follower;
        data.limit = this.state.limit_follower;
        let res = await axios.get(window.api_host + '/artist-follower?' + window.$.param(data));
        // console.log(res); 
        let follower = [];
        let count_follower = 0;
        let count_all_follower = 0;
        if (res.data.success) {
            follower = res.data.users;
            count_follower = res.data.count_item;
            count_all_follower = res.data.count_all_item;
        }

        this.setState({
            follower: follower,
            count_follower: count_follower,
            count_all_follower: count_all_follower,
            is_follower_loading: false
        })
    }

    componentDidUpdate(prevProps) {
        if ((this.props.data && this.props.data.photoPath !== prevProps.data.photoPath)) {
            this.setState({
                default_image: this.props.data && this.props.data.photoPath ? this.props.data.photoPath : [],
            }, () => {
                let images = this.fetchImages();
                this.setState({
                    images: images.images,
                    image_all: images.count_all,
                })
            })
        }
    }

    render() {
        return (
            <div className="card-body" style={{ position: 'relative' }}>
                {
                    this.props.is_loading ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="flex row mb-3">
                            <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>รูปภาพ
                                <div className="badge badge-primary" style={{ marginLeft: 10 }}>
                                    {Helper.numberFormat(this.state.image_all)}
                                </div>
                            </h5>
                        </div>
                        <ul className="users-list square-list clearfix">
                            {
                                this.state.image_all > 0 ?
                                    this.state.images.map((image, image_i) => {
                                        return (
                                            <li className="item" style={{ width: '25%' }} key={image_i}>
                                                <a className="image-item-frame" href={image || "/assets/images/no_img.png"} data-fancybox="users">
                                                    <img src={image || "/assets/images/no_img.png"} alt="User" style={{ borderRadius: 0 }} />
                                                </a>
                                            </li>
                                        )
                                    })
                                    : null
                            }
                            {
                                this.state.image_all === 0 ?
                                    <h5 style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</h5>
                                    : null
                            }
                        </ul>
                        {
                            this.state.image_all > (this.state.page_image * this.state.limit_image) ?
                                <div style={{ textAlign: 'center' }}>
                                    <button className="btn btn-default"
                                        onClick={() => {
                                            this.setState({
                                                page_image: this.state.page_image + 1
                                            }, () => {
                                                let images = this.fetchImages();
                                                this.setState({
                                                    images: images.images,
                                                    image_all: images.count_all,
                                                })
                                            })
                                        }}
                                    >โหลดรูปเพิ่ม</button>
                                </div>
                                : null
                        }
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="flex row mb-3">
                            <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>ผู้สนับสนุน
                                <div className="badge badge-primary" style={{ marginLeft: 10 }}>
                                    {Helper.numberFormat(this.state.count_all_follower)}
                                </div>
                            </h5>
                        </div>

                        {
                            this.state.count_all_follower > 0 ?
                                <>
                                    <ul className="users-list clearfix">
                                        {
                                            this.state.follower.map((follower, follower_i) => {
                                                return (
                                                    <li key={follower_i}>
                                                        <div className="profile-user-img img-frame img-fluid img-circle">
                                                            <img src={follower.photoURL || "/assets/images/no_img.png"} alt="User" />
                                                        </div>
                                                        <Link className="users-list-name" to={'/member/view/' + follower.uid}>{follower.displayName}</Link>
                                                        {/* <span className="users-list-date">{Helper.getDateThai(follower.createdAt, { is_show_time: false })}</span> */}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <hr />
                                </>
                                : null
                        }


                        {
                            this.state.count_all_follower === 0 ?
                                <h5 style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</h5>
                                : null
                        }
                        <Pagination
                            now={this.state.page_follower}
                            all_items={this.state.count_all_follower}
                            limit={this.state.limit_follower}
                            onReturnPage={(page) => {
                                this.setState({
                                    page_follower: page,
                                }, () => {
                                    this.fetchFollower();
                                })
                            }}
                            location={this.props.location}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
