import React from 'react';
import { Helper } from './../Helper';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const axios = require('axios').default;

export default class PostItem extends React.Component {
    deletePost(id) {
        Helper.confirmModal({
            title: 'ลบโพส',
            description: 'ยืนยันการลบโพส',
            submit_text: 'ยืนยัน',
            onConfirm: async () => {
                this.setState({
                    loading_post: true,
                }, async () => {
                    let res = await axios.delete(window.api_host + '/delete-post?post_id=' + id);
                    // if (res.data.image_to_remove && res.data.image_to_remove.length > 0) {
                    //     for (const image of res.data.image_to_remove) {
                    //         storage.ref().child(image.storagePath).delete();
                    //     }
                    // }
                    if (res.data.success) {
                        window.$(document).Toasts('create', {
                            title: 'ลบข้อมูลสำเร็จ',
                            icon: 'fas fa-check',
                            autohide: true,
                            class: "bg-success",
                            delay: 3000,
                        })
                        if (this.props.onDeleteSuccess) {
                            this.props.onDeleteSuccess();
                        }
                    }
                    if (res.data.error) {
                        window.$(document).Toasts('create', {
                            title: res.data.message,
                            icon: 'fas fa-times',
                            autohide: true,
                            class: "bg-danger",
                            delay: 3000,
                        })
                    }
                })
            },
        })
    }
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };
        // console.log(this.props.index)
        let item = this.props.item;
        var timezoneCal = new Date().getTimezoneOffset();
        let _created = this.props.item.created;
        let date_gettime = new Date(_created).getTime();
        let sum = 0;
        if(timezoneCal < 0) {
            sum = date_gettime + ((timezoneCal*(-60))*1000)
        } else {
            sum = date_gettime - ((timezoneCal*(-60))*1000)
        }
        let created = sum;
        return (
            <div key={this.props.index}>
                <div className="timeline-image">

                    {
                        item.userId && item.userImage ?
                            <img src={item.userImage} alt="Feed" />
                            : <img src={"/assets/images/no_img.png"} alt="Feed" />
                    }
                </div>
                <i className="fas fa-user bg-green"></i>
                <div className="timeline-item" style={{ overflow: 'inherit' }}>
                    <span className="time">
                        <div className="dropdown">
                            <button
                                className="btn btn-tools dropdown-toggle remove-after"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ padding: 0 }}
                            >
                                <i className="fas fa-ellipsis-h" />
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {/* <Link className="dropdown-item" to={"/manage-store/edit/" + this.props.item.id}>แก้ไข</Link> */}
                                <button
                                    type="button"
                                    className="dropdown-item"
                                    onClick={() => {
                                        this.deletePost(this.props.item.postId)
                                    }}
                                >ลบ </button>
                            </div>
                        </div>

                    </span>
                    <h3 className="timeline-header">
                        {item.userId ? item.displayName : null}
                        <div className="timeline-sub-header">
                            <small><i className="far fa-calendar-alt"></i> {Helper.getDateThai(new Date(created), { is_show_time: true })}</small>
                        </div>
                    </h3>
                    <div className="timeline-body">
                        {
                            item.images
                                && item.images.length > 0 ?
                                <Slider {...settings} style={{ marginBottom: '1rem' }}>
                                    {
                                        item.images.map((image, image_i) => (
                                            <div key={image_i} className="slide-image-frame"><img src={image.imageUrl} alt="Feed" /></div>
                                        ))
                                    }
                                </Slider>
                                : null
                        }
                        <div className="description-pre-line">
                            {this.props.item.message}
                        </div>
                        <div className="flex row" style={{ marginTop: 5 }}>
                            <div className={"text-descriiption link"}
                                onClick={() => {
                                    // alert('1')
                                    if (!item.commentCount || item.commentCount === 0) { return; }
                                    Helper.reactToDom(window.$('body'),
                                        <CommentList postId={item.postId} />
                                    )
                                }}
                            ><i className={"far fa-comment-dots"} style={{ marginRight: 3 }} /> {this.props.item.commentCount || 0} comments</div>
                            <div className={!this.props.item.emotionCount || this.props.item.emotionCount === 0 ? "right-floated text-descriiption" : "right-floated text-descriiption link"}
                                onClick={() => {
                                    // alert('1')
                                    if (!this.props.item.emotionCount || this.props.item.emotionCount === 0) { return; }
                                    Helper.reactToDom(window.$('body'),
                                        <LikeList postId={item.postId} />
                                    )
                                }}
                            ><i className={"far fa-heart"} style={{ marginRight: 3 }} /> {this.props.item.emotionCount || 0} likes</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

class LikeList extends React.Component {
    constructor() {
        super();
        this.state = {
            list: [],
            page: 1,
            limit: 100,
            is_can_loadmore: false,
        }
    }
    componentDidMount() {
        this.fetch();
    }
    async fetch() {
        let res = await axios.get(window.api_host + '/get-likes?' + window.$.param({ postId: this.props.postId, page: this.state.page, limit: this.state.limit }));
        let list = [...this.state.list];
        let is_can_loadmore = true;
        if (res.data.res) {
            list = this.state.list.concat(res.data.res);
            if (res.data.res.length < this.state.limit) {
                is_can_loadmore = false;
            }
        }
        this.setState({
            list: list,
            is_can_loadmore: is_can_loadmore,
        }, () => {
            if (this.loadMore) {
                this.loadMore.removeClass('loading')
            }
        })
    }

    render() {
        // console.log(this.props.list)
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">รายการคนที่กด Like</h4>
                            </div> */}
                            <div className="modal-body">
                                {
                                    this.state.list.length > 0 ?
                                        this.state.list.map((item, item_i) => {
                                            return (

                                                <div class="store-item item-row form-group" key={item_i}>
                                                    <div class="icon"><div>
                                                        <img src={item.userImage} alt="img" />
                                                    </div>
                                                    </div>
                                                    <div class="description">
                                                        <div>{item.displayName}</div>
                                                    </div>
                                                    <div class="right-floated">
                                                        <div class="small-text text-right">
                                                            {Helper.getDateThai(new Date(item.created), { is_show_time: true })}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }) : null
                                }
                                {
                                    this.state.is_can_loadmore ?
                                        <button type={"button"}
                                            className="btn btn-default"
                                            ref={(e) => { this.loadMore = window.$(e) }}
                                            style={{ width: '100%' }}
                                            onClick={() => {
                                                if (this.loadMore.hasClass('loading')) { return; }
                                                this.loadMore.addClass('loading');
                                                this.setState({
                                                    page: this.state.page + 1,
                                                }, () => {
                                                    this.fetch();
                                                })
                                            }}
                                        >โหลดเพิ่ม</button>
                                        : null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type={"button"} className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                        let settime = window.setTimeout(() => {
                                            this.$modal.remove();
                                            clearTimeout(settime);
                                        }, 500)
                                    }}
                                >ปิด</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}


class CommentList extends React.Component {
    constructor() {
        super();
        this.state = {
            list: [],
            page: 1,
            limit: 5,
            is_can_loadmore: false,
        }
    }
    componentDidMount() {
        this.fetch();
    }
    async fetch() {
        let res = await axios.get(window.api_host + '/get-comments?' + window.$.param({ postId: this.props.postId, page: this.state.page, limit: this.state.limit }));
        let list = [...this.state.list];
        let is_can_loadmore = true;
        if (res.data.res) {
            list = this.state.list.concat(res.data.res);
            if (res.data.res.length < this.state.limit) {
                is_can_loadmore = false;
            }
        }
        this.setState({
            list: list,
            is_can_loadmore: is_can_loadmore,
        }, () => {
            if (this.loadMore) {
                this.loadMore.removeClass('loading')
            }
        })
    }

    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">รายการคนที่กด Like</h4>
                            </div> */}
                            <div className="modal-body">
                                {
                                    this.state.list.length > 0 ?
                                        this.state.list.map((item, item_i) => {
                                            return (
                                                <div class="store-item item-row form-group" key={item_i}>
                                                    <div class="icon"><div>
                                                        <img src={item.userImage} alt="img" />
                                                    </div>
                                                    </div>
                                                    <div class="description">
                                                        <div>{item.displayName}</div>
                                                        <div>{item.message}</div>
                                                    </div>
                                                    <div class="right-floated">
                                                        <div class="small-text text-right">
                                                            {Helper.getDateThai(new Date(item.created), { is_show_time: true })}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }) : null
                                }
                                {
                                    this.state.is_can_loadmore ?
                                        <button type={"button"}
                                            className="btn btn-default"
                                            ref={(e) => { this.loadMore = window.$(e) }}
                                            style={{ width: '100%' }}
                                            onClick={() => {
                                                if (this.loadMore.hasClass('loading')) { return; }
                                                this.loadMore.addClass('loading');
                                                this.setState({
                                                    page: this.state.page + 1,
                                                }, () => {
                                                    this.fetch();
                                                })
                                            }}
                                        >โหลดเพิ่ม</button>
                                        : null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type={"button"} className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                        let settime = window.setTimeout(() => {
                                            this.$modal.remove();
                                            clearTimeout(settime);
                                        }, 500)
                                    }}
                                >ปิด</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}