import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { functions } from "firebase";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const firebaseProviderConfig = {
    apiKey: "AIzaSyC4ZhoR1bSpAx2V0xNXGm5grc7KLaXWo_M",
    authDomain: "skitz-provider.firebaseapp.com",
    projectId: "skitz-provider",
    storageBucket: "skitz-provider.appspot.com",
    messagingSenderId: "720504107071",
    appId: "1:720504107071:web:02dc9985badde56151888a",
    measurementId: "G-7VEK15Q9SS"
};

// Initialize Firebase
const main = firebase.initializeApp(firebaseConfig, 'main');
const provider = firebase.initializeApp(firebaseProviderConfig, 'provider');

// const isLocalhost = Boolean(
//   window.location.hostname === 'localhost' ||
//   // [::1] is the IPv6 localhost address.
//   window.location.hostname === '[::1]' ||
//   // 127.0.0.0/8 are considered localhost for IPv4.
//   window.location.hostname.match(
//     /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//   )
// );
// if (isLocalhost) {

//   console.log('local');

//   // firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
// }

export const api_host_app = `https://${process.env.REACT_APP_CLOUND_FUNCTION_HOST}/app`;
export const api_host = `https://${process.env.REACT_APP_CLOUND_FUNCTION_HOST}/backend`;
export const auth = main.auth();
export const authProvider = provider.auth();
export const db = main.firestore();
export const storage = main.storage();
export const firestore = (lat, lng) => { return new firebase.firestore.GeoPoint(lat, lng) };
