import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/report`;
export const getReportDaily = async (filter) => {
    let url = `${baseUrl}/daily`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getReportSummary = async (filter) => {
    let url = `${baseUrl}/summary`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getReportOverview = async (filter) => {
    let url = `${baseUrl}/overview`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}