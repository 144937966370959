import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/chat/gift`;
export const getGift = async (page = 1, rows = 10) => {
    const url = `${baseUrl}?page=${page}&rows=${rows}`;
    // console.log(url)
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return {error: true, ...error};
    }
}
export const deleteGift = async (id) => {
    const url = `${baseUrl}/${id}`;
    // console.log(url)
    try {
        const res = await httpClient.delete(url)
        return res.data;
    } catch (error) {
        return {error: true, ...error};
    }
}
export const addGift = async (data) => {
    const url = `${baseUrl}`;
    // console.log(url)
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return {error: true, ...error};
    }
}
export const updateGift = async (id,data) => {
    const url = `${baseUrl}/${id}`;
    // console.log(url)
    try {
        const res = await httpClient.put(url, data)
        return res.data;
    } catch (error) {
        return {error: true, ...error};
    }
}