import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../Helper';
import { getCoinTransaction } from '../../services/CoinServices';
import { getStore, getStores, getStoreSummary } from '../../services/StoreServices';
import { getExchange, updateExchangeRequest } from '../../services/ExchangeServices';
import { getSetting } from '../../services/SettingsServices';
import CustomDateInput from '../../Components/CustomDateInput';
import DatePicker from "react-datepicker";

const default_limit = 5;
export default class ExchangPoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            data: [],
            stores: [],
            page: 1,
            date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : '',
            q: Helper.getParameterByName('q') ? Helper.getParameterByName('q') : '',
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '1',
            total_items: 0,
            count_items: 0,
            storeId: props.id || false,
            storeFirebaseUid: props.uid || false,
            summary: false,
            store: false,
        }
    }

    componentDidMount() {
        if (!this.props.disableRunDidMount) {
            this.fetch();
        }
    }

    firstFetch() {
        if (this.state.data.length == 0) {
            this.fetch();
        }
    }

    async fetch() {
        let storeId = this.state.storeId;
        let storeFirebaseUid = this.state.storeFirebaseUid;
        let data = [...this.state.data];
        let page = 1;
        let is_done = true;
        let totalItems = 0;
        let itemEnd = 0;
        let filter = {}
        filter.page = this.state.page;
        filter.rows = this.state.limit;
        // filter.typeIds = `19,21`;
        filter.typeId = 2;
        if (this.state.status) {
            filter.statusId = this.state.status
        }
        if (this.state.date) {
            let _date = Helper.getDateToEdit(this.state.date);
            let day = String(_date.getDate()).padStart(2, '0')
            let month = String((_date.getMonth() + 1)).padStart(2, '0')
            let dateFormat = `${day}-${month}-${_date.getFullYear()}`;
            filter.date = dateFormat;
        }
        if (this.state.q) {
            filter.q = this.state.q
        }
        let stores = [...this.state.stores];
        if (this.state.stores.length == 0) {
            let storeRef = await getStores();
            if (!storeRef.error) {
                stores = storeRef;
            }
        }
        // filter.typeId = 1;
        let store = this.state.store;
        if ((!storeId && storeFirebaseUid && !this.props.id)) {
            let storeRes = await getStore(storeFirebaseUid);
            if (!storeRes.error) {
                store = storeRes;
                storeId = storeRes.id;
            } else {
                window.$(document).Toasts('create', {
                    title: 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาติดต่อผู้ดูแลระบบ',
                    icon: 'fas fa-ban',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
                this.setState({
                    is_loading: false,
                    storeId: false,
                    data: [],
                    page: 1,
                    is_done: true,
                    totalItems: 0,
                    itemEnd: 0,
                })
                return;
            }
        }

        if (storeId) {
            filter.storeId = storeId;
        }
        let summary = this.state.summary;
        if (storeFirebaseUid) {
            let summaryRef = await getStoreSummary(storeFirebaseUid)
            if (!summaryRef.error) {
                summary = summaryRef;
            }
        } else {
            summary = false;
        }

        let res = await getExchange(filter);
        if (!res.error) {
            data = data.concat(res.items);
            page = res.page == res.endPage ? this.state.page : (this.state.page + 1);
            is_done = res.page == res.endPage;
            totalItems = res.total;
            itemEnd = res.itemEnd;
        }

        let setting = this.state.setting;
        if (!this.state.setting) {
            let settingRef = await getSetting();
            if (!settingRef.error) {
                setting = settingRef;
            }
        }
        this.setState({
            is_loading: false,
            storeId: storeId,
            storeFirebaseUid: storeFirebaseUid,
            stores: stores,
            data: data,
            page: page,
            is_done: is_done,
            total_items: totalItems,
            count_items: itemEnd,
            setting: setting,
            summary: summary
        }, () => {
            if (this.$viewMore) {
                this.$viewMore.removeClass('loading')
            }
        })
    }
    loadMore() {
        this.fetch();
    }
    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">แลกแต้ม</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">แลกแต้ม</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                {/* FILTER */}
                                <div className="card card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form className="row"
                                            ref={(ref) => { this.$form = window.$(ref) }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                                    return;
                                                }
                                                let data = Helper.getFormInputObject(this.$form);
                                                if (data.storeId) {
                                                    let getStore = this.state.stores.filter((e) => { return e.id == data.storeId })[0]
                                                    data.storeFirebaseUid = getStore.fireBaseUid;
                                                } else {
                                                    data.storeFirebaseUid = false;
                                                }
                                                // let url = Helper.getQueryStringLinkFromObject({
                                                //     ...data
                                                // })
                                                // navigate(url)
                                                this.setState({
                                                    is_loading: true,
                                                    page: 1,
                                                    data: [],
                                                    limit: default_limit,
                                                    ...data
                                                }, () => {
                                                    this.fetch();
                                                })
                                            }}
                                        >
                                            {/* <div className="col-6 form-group">
                                                <label>ค้นหา </label>
                                                <input type="text" name="q" className="form-control" defaultValue={this.state.q} />
                                            </div> */}
                                            <div className='form-group'>
                                                <div className='d-flex'>
                                                    <label>ร้าน</label>
                                                    {
                                                        this.state.is_loading ?
                                                            <div className={"absolute_loading"}>
                                                                <div className="ui active inline loader" ></div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.storeId ?
                                                            <div className='right-floated'>
                                                                <div className='link'
                                                                    onClick={() => {
                                                                        if (this.$storefilter) {
                                                                            this.$storefilter.val(null).trigger('change');
                                                                        }
                                                                    }}
                                                                >ยกเลิก</div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <select className={"form-control"}
                                                    ref={(ref) => {
                                                        this.$storefilter = window.$(ref);
                                                        this.$storefilter.select2({
                                                            placeholder: 'เลือกร้านทั้งหมด',
                                                        });

                                                        // this.$storefilter.off().on("select2:select", () => {
                                                        //     this.$submit.trigger('click')
                                                        // })
                                                    }}
                                                    name={"storeId"}
                                                    style={{ flex: 1 }}
                                                    defaultValue={this.state.month}
                                                >
                                                    <option value={""}>เลือกร้านทั้งหมด</option>
                                                    {
                                                        this.state.stores.map((store, i) => {
                                                            return (
                                                                <option value={store.id} key={i}>{store.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-12 form-group">
                                                <label className="d-flex">วันที่
                                                    {
                                                        this.state.date ?
                                                            <span className="right-floated date-cancle-icon"
                                                                onClick={() => {
                                                                    this.setState({ date: "" })
                                                                }}
                                                            >  ยกเลิก
                                                            </span>
                                                            : null
                                                    }
                                                </label>
                                                <div style={{ position: 'relative' }}>

                                                    <DatePicker
                                                        onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                                        onChange={date => {
                                                            let _date = new Date(date);
                                                            this.setState({
                                                                date: _date.getTime(),
                                                            })
                                                        }}
                                                        locale="th-TH"
                                                        selected={this.state.date ? Helper.getDateToEdit(this.state.date) : false}
                                                        placeholderText={"วัน / เดือน / ปี"}
                                                        name={"birthDay"}
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        withPortal
                                                        renderCustomHeader={({
                                                            date,
                                                            changeYear,
                                                            changeMonth,
                                                            decreaseMonth,
                                                            increaseMonth,
                                                            prevMonthButtonDisabled,
                                                            nextMonthButtonDisabled
                                                        }) => {
                                                            let now = new Date();
                                                            let selected_date = new Date(date);
                                                            // months_th
                                                            const years = [];
                                                            let i;
                                                            for (i = 1980; i <= now.getFullYear(); i++) {
                                                                years.push(i)
                                                            }
                                                            return (
                                                                <div
                                                                    style={{
                                                                        margin: 10,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                        className={"btn btn-primary "}
                                                                        type={"button"}
                                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                    >
                                                                        <i className="fas fa-chevron-left" />
                                                                    </button>
                                                                    <select
                                                                        className={"form-control"}
                                                                        value={selected_date.getFullYear()}
                                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                                        style={{ borderRadius: 0 }}
                                                                    >
                                                                        {years.map(option => (
                                                                            <option key={option} value={option} >
                                                                                {option + 543}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <select
                                                                        className={"form-control"}
                                                                        value={window.months_th[selected_date.getMonth()]}
                                                                        onChange={({ target: { value } }) =>
                                                                            changeMonth(window.months_th.indexOf(value))
                                                                        }
                                                                        style={{ borderRadius: 0 }}
                                                                    >
                                                                        {window.months_th.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                        className={"btn btn-primary"}
                                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                        type={"button"}
                                                                    >
                                                                        <i className="fas fa-chevron-right" />
                                                                    </button>
                                                                </div>
                                                            )
                                                        }}
                                                        customInput={<CustomDateInput />}
                                                    />
                                                    <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />


                                                </div>
                                            </div>


                                            <div className="col-12 form-group">
                                                <label>สถานะ</label>
                                                <select
                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"status"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.status || ''}
                                                >
                                                    <option value={""}>
                                                        ทั้งหมด
                                                    </option>
                                                    <option value={"1"}>
                                                        รอการยืนยัน
                                                    </option>
                                                    <option value={"2"}>
                                                        สำเร็จ
                                                    </option>
                                                    <option value={"3"}>
                                                        ยกเลิก
                                                    </option>
                                                </select>
                                            </div>

                                            <div className="col-12">
                                                <div className="flex row">
                                                    {
                                                        this.props.disabledReset ?
                                                            null
                                                            :
                                                            <Link to="/exchange/point" className="btn btn-default">รีเซ็ท</Link>
                                                    }
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <button type="submit" className="btn btn-default">ค้นหา</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                {
                                    this.state.summary ?
                                        <div className="card">
                                            <div className="card-body pt-0 pb-0">
                                                <ul className="list-group list-group-unbordered mb-0 pb-0">
                                                    <li className="list-group-item border-top-0 ">
                                                        <b>แต้มปัจจุบัน</b>
                                                        <div className="float-right">{Helper.numberFormat(this.state.summary.currentPoint)} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>แลกแต้มทั้งหมด</b>
                                                        <div className="float-right">{Helper.numberFormat(this.state.summary.totalExchangePoint)} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>ส่วนต่างทั้งหมด</b>
                                                        <div className="float-right">{Helper.numberFormat(this.state.summary.totalCommissionTHB)} บาท</div>
                                                    </li>
                                                    <li className="list-group-item border-0 ">
                                                        <b>จำนวนเงินทั้งหมด</b>
                                                        <div className="float-right">{Helper.numberFormat(this.state.summary.totalExchangeTHB)} บาท</div>
                                                    </li>

                                                </ul>
                                                {

                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="col-12 col-md-4">

                                {
                                    this.state.is_loading ?
                                        <div className={"absolute_loading"}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                <RenderList
                                    admin={this.props.admin}
                                    data={this.state.data}
                                    isCanRefund={this.props.user && this.props.user.permission && this.props.user.permission.transaction && this.props.user.permission.transaction.refund ? true : false}
                                    onRefund={(obj) => {
                                        console.log('obj', obj)
                                        let datas = [...this.state.data];
                                        let i = 0;
                                        for (const data of datas) {
                                            if (data.id == obj.id) {
                                                datas[i] = obj;
                                            }
                                            i++;
                                        }
                                        this.setState({
                                            data: datas,
                                        })
                                    }}
                                    showStore={this.props.showStore}
                                    setting={this.state.setting}
                                    updateItems={(items) => {
                                        this.setState({
                                            data: items
                                        })
                                    }}
                                />
                                <div className="d-flex mt-2 align-items-center">
                                    {
                                        !this.state.is_loading &&
                                            !this.state.is_done ?
                                            <button
                                                className="btn btn-default"
                                                onClick={(ref) => {
                                                    let node = window.$(ref.target);
                                                    if (node.hasClass('loading')) { return }
                                                    node.addClass('loading');
                                                    this.setState({
                                                        is_loading: true
                                                    }, () => {
                                                        this.fetch()
                                                    })
                                                }}
                                            >โหลดเพิ่ม</button>
                                            :
                                            !this.state.is_done ?
                                                <button className="btn btn-default loading">โหลดเพิ่ม</button>
                                                : null
                                    } <span className='right-floated'>{this.state.count_items}/{this.state.total_items}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


class RenderList extends React.Component {
    render() {
        if (!this.props.data || (this.props.data.length === 0)) {
            return (
                <li className="list-group-item">
                    <strong>ไม่มีข้อมูลการชำระเงิน</strong>
                </li>
            )
        }
        return (
            <>
                {
                    this.props.data.map((item, i) => {
                        let statusText = "";
                        if (item.statusId == 1) {
                            statusText = 'รอการยืนยัน'
                        }
                        if (item.statusId == 2) {
                            statusText = 'สำเร็จ'
                        }
                        if (item.statusId == 3) {
                            statusText = 'ยกเลิก'
                        }

                        let amount = 0;
                        let bonusAmount = 0;
                        if (this.props.setting && this.props.setting.storeCommissionRate) {
                            let d = (parseFloat(item.pointAmount) / 100) * parseFloat(this.props.setting.storeCommissionRate)
                            amount = item.pointAmount + d;
                            bonusAmount = d;
                        }
                        return (
                            <li key={i} className="list-group-item">
                                <div className="d-flex">
                                    <p className='mb-0'><strong>ผู้ทำรายการ : </strong>{item.store.name}</p>
                                    {
                                        item.created ?
                                            <small className="right-floated mb-0">{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</small>
                                            : null
                                    }
                                </div>
                                <hr className="mt-2 mb-2" />
                                <div className="d-flex">
                                    <div>
                                        <div className="d-flex ">
                                            <p className='mb-0 mr-2'>บัญชี : </p>
                                            <div>
                                                {item.bankName ? <div>{item.bankName}</div> : null}
                                                {item.accountName ? <div>{item.accountName}</div> : null}
                                                {item.accountNumber ? <div>{item.accountNumber}</div> : null}
                                            </div>
                                        </div>
                                        <p className='mb-0'>สถานะ :
                                            <span style={{
                                                color:
                                                    item.statusId == 1 ? 'orange' :
                                                        item.statusId == 2 ? 'green' :
                                                            item.statusId == 3 ? "gray" :
                                                                '#000'
                                            }}> {statusText}</span>
                                            {
                                                item.statusId == 2
                                                    && item.transferSlip ?
                                                    <a
                                                        href={item.transferSlip}
                                                        data-fancybox={"transferSlip" + item.id}
                                                        className="ml-2"
                                                    >
                                                        หลักฐานการชำระเงิน
                                                    </a>
                                                    : null
                                            }
                                        </p>
                                        {
                                            item.updatedUser ?
                                                <>
                                                    <p className='mb-0'>ผู้ทำรายการ : {item.updatedUser} </p>
                                                    {
                                                        item.updated ?
                                                            <div><small>{Helper.getDateThai(item.updated, { is_show_time: true, plus_hours: 7 })}</small></div>
                                                            : null
                                                    }
                                                </>
                                                : null
                                        }
                                        {
                                            item.statusId == 1 ?
                                                <button
                                                    type="button"
                                                    className='btn btn-default mt-3'
                                                    onClick={() => {
                                                        Helper.reactToDom(window.$('body'),
                                                            <RenderConfirmModal
                                                                setting={this.props.setting}
                                                                item={item}
                                                                admin={this.props.admin}
                                                                onSuccess={(item) => {
                                                                    let items = [...this.props.data];
                                                                    items[i] = {
                                                                        ...items[i],
                                                                        statusId: item.statusId,
                                                                        updatedUser: item.updatedUser,
                                                                        updated: item.updated
                                                                    };
                                                                    this.props.updateItems(items)
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                >ยืนยันแลกแต้ม</button>
                                                : null
                                        }
                                    </div>
                                    <div className="right-floated" style={{ paddingLeft: 15, minWidth: 180 }}>

                                        <div className="d-flex">
                                            <div className="text-left" style={{ flex: 1 }}>
                                                จำนวนแต้ม
                                            </div>
                                            <div>{item.pointAmount ? Helper.numberFormat(item.pointAmount, { decimal: 2 }) : 0} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                        </div>
                                        {
                                            this.props.setting.storeCommissionRate ?
                                                <>
                                                    <div className="d-flex">
                                                        <div className="text-left" style={{ flex: 1 }}>
                                                            โบนัส {this.props.setting.storeCommissionRate}%
                                                        </div>
                                                        <div>{Helper.numberFormat(bonusAmount, { decimal: 2 })} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="text-left" style={{ flex: 1 }}>
                                                            แต้มรวม
                                                        </div>
                                                        <div>{Helper.numberFormat(amount, { decimal: 2 })} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        <hr className="mt-1 mb-1" />

                                        <div className="d-flex">
                                            <div className="text-left" style={{ flex: 1 }}>
                                                ได้รับเงิน
                                            </div>
                                            <div>{item.currencyAmount ? Helper.numberFormat(item.currencyAmount) : 0} บาท</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </>
        )
    }


}


class RenderConfirmModal extends React.Component {
    constructor() {
        super();
        this.state = {
        }
    }

    async onConfirm(data) {
        let $submit = window.$('button[type="submit"]');
        data.statusId = 2;
        data.updatedUser = this.props.admin.displayName;
        let res = await updateExchangeRequest(this.props.item.id, data);
        if (!res.error) {
            Helper.messageTop({ message: 'ทำการแลกเหรียญเรียบร้อยแล้ว', type_class: 'alert-success' })
            this.$modal.modal('hide');
            if (this.props.onSuccess) {
                this.props.onSuccess(res);
            }
        } else {
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' })
            $submit.removeClass('loading');
            return;
        }
    }

    async onCancel() {
        let $cancel_btn = window.$('#cancel_btn');
        if ($cancel_btn.hasClass('loading')) { return; }
        $cancel_btn.addClass('loading')
        let res = await updateExchangeRequest(this.props.item.id, { statusId: 3, updatedUser: this.props.admin.displayName });
        console.log(`onCancel response`, res)
        if (!res.error) {
            Helper.messageTop({ message: 'ทำการยกเลิกแลกเหรียญเรียบร้อยแล้ว', type_class: 'alert-success' })
            this.$modal.modal('hide');
            if (this.props.onSuccess) {
                this.props.onSuccess(res);
            }
        } else {
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' })
            $cancel_btn.removeClass('loading');
            return;
        }
    }

    render() {
        let title = "แลกเหรียญ";
        let item = this.props.item;
        let statusText = "";
        if (item.statusId == 1) {
            statusText = 'รอการยืนยัน'
        }
        if (item.statusId == 2) {
            statusText = 'สำเร็จ'
        }
        if (item.statusId == 3) {
            statusText = 'ยกเลิก'
        }

        let amount = 0;
        let bonusAmount = 0;
        if (this.props.setting && this.props.setting.storeCommissionRate) {
            let d = (parseFloat(item.pointAmount) / 100) * parseFloat(this.props.setting.storeCommissionRate)
            amount = item.pointAmount + d;
            bonusAmount = d;
        }
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            if (!this.$file || this.$file[0].files.length == 0) {
                                window.$(document).Toasts('create', {
                                    title: 'กรุณาอัพโหลดรูปสลิป',
                                    icon: 'fas fa-times',
                                    autohide: true,
                                    class: "bg-danger",
                                    delay: 3000,
                                })
                                $submit.removeClass('loading');
                                return;
                            }
                            if (this.$file && this.$file[0].files.length > 0) {
                                await Helper.uploadFile(this.$file, 'exchange/', {
                                    resize: false,
                                    onSuccess: async (url) => {
                                        // data.storagePath = url.storePath;
                                        data.transferSlip = url.imagePath;
                                        this.onConfirm(data)
                                    },
                                    onError: async () => {
                                        window.$(document).Toasts('create', {
                                            title: 'อัพโหลดรูปภาพไม่สำเร็จกรุณาลองใหม่อีกครั้ง',
                                            icon: 'fas fa-times',
                                            autohide: true,
                                            class: "bg-danger",
                                            delay: 3000,
                                        })
                                        $submit.removeClass('loading');
                                    }
                                })
                            }
                            return;

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">{title}</h4>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex">
                                    <p className='mb-0'><strong>ผู้ทำรายการ : </strong>{item.store.name}</p>
                                    {
                                        item.created ?
                                            <p className="right-floated mb-0">{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</p>
                                            : null
                                    }
                                </div>
                                <hr className="mt-2 mb-2" />
                                <div className="d-flex">
                                    <div>
                                        <div className="d-flex ">
                                            <p className='mb-0 mr-2'>บัญชี : </p>
                                            <div>
                                                {item.bankName ? <div>{item.bankName}</div> : null}
                                                {item.accountName ? <div>{item.accountName}</div> : null}
                                                {item.accountNumber ? <div>{item.accountNumber}</div> : null}
                                            </div>
                                        </div>
                                        <p className='mb-0'>สถานะ :
                                            <span style={{
                                                color:
                                                    item.statusId == 1 ? 'orange' :
                                                        item.statusId == 2 ? 'green' :
                                                            item.statusId == 3 ? "gray" :
                                                                '#000'
                                            }}> {statusText}</span>
                                        </p>
                                        {
                                            item.updatedUser ?
                                                <p className='mb-0'>ผู้ทำรายการ : {item.updatedUser} ({Helper.getDateThai(item.updated, { is_show_time: true, plus_hours: 7 })})</p>
                                                : null
                                        }
                                    </div>
                                    <div className="right-floated" style={{ paddingLeft: 15, minWidth: 180 }}>

                                        <div className="d-flex">
                                            <div className="text-left" style={{ flex: 1 }}>
                                                จำนวนแต้ม
                                            </div>
                                            <div>{item.pointAmount ? Helper.numberFormat(item.pointAmount, { decimal: 2 }) : 0} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                        </div>
                                        {
                                            this.props.setting.storeCommissionRate ?
                                                <>
                                                    <div className="d-flex">
                                                        <div className="text-left" style={{ flex: 1 }}>
                                                            โบนัส {this.props.setting.storeCommissionRate}%
                                                        </div>
                                                        <div>{Helper.numberFormat(bonusAmount, { decimal: 2 })} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="text-left" style={{ flex: 1 }}>
                                                            แต้มรวม
                                                        </div>
                                                        <div>{Helper.numberFormat(amount, { decimal: 2 })} <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, height: 20, marginLeft: 0 }} /></div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        <hr className="mt-1 mb-1" />

                                        <div className="d-flex">
                                            <div className="text-left" style={{ flex: 1 }}>
                                                ได้รับเงิน
                                            </div>
                                            <div>{item.currencyAmount ? Helper.numberFormat(item.currencyAmount) : 0} บาท</div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="mt-2 mb-2" />
                                <div >
                                    <input name="image"
                                        type={"file"}
                                        accept="image/*"
                                        ref={(ref) => this.$file = window.$(ref)}
                                        style={{ display: 'none' }}
                                        id="file_image_bank"
                                        onChange={async (e) => {
                                            Helper.readImage(this.$file)
                                        }}
                                    />
                                    <div id="show_image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {
                                            this.state.data && this.state.data.image ?
                                                <img src={this.state.data.image} alt={"PromotionPic"} style={{ maxWidth: '100%', marginBottom: 5 }} />
                                                : null
                                        }
                                    </div>
                                    <label htmlFor="file_image_bank"
                                        type={"button"}
                                        className="btn btn-default"
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            this.state.data && this.state.data.image ?
                                                "เปลี่ยนรูปหลักฐานการโอนเงิน"
                                                : "อัพโหลดหลักฐานการโอนเงิน"
                                        }
                                    </label>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className='d-flex w-100'>
                                    <div>

                                        <button type="button"
                                            className="btn btn-default"
                                            onClick={() => {
                                                this.$modal.modal('hide');
                                                if (this.props.onClose) { this.props.onClose(); }
                                            }}
                                        >ปิด</button>
                                    </div>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <button
                                            id="cancel_btn"
                                            type="button"
                                            className={"btn btn-danger mr-3"}
                                            onClick={() => {
                                                this.onCancel();
                                            }}
                                        >
                                            ยกเลิกแลกแต้ม
                                        </button>
                                        <button type="submit" className={"btn btn-primary"}>ยืนยันแลกแต้ม</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div >
            </>
        )
    }
}