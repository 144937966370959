import React from 'react';
import { Helper, SortableContainer, SortableItem, DragHandle } from '../../../Helper';
import { storage } from '../../../firebase';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const axios = require('axios').default;
export default class StoreGallery extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let filter = {
            storeUid: this.props.uid,
        }
        let res = await axios.get(`${window.api_host}/food/images?${window.$.param(filter)}`);
        let data = [];
        if (res.data.success) {
            data = res.data.foods;
        }
        this.setState({
            is_loading: false,
            data: data,
        })
    }
    async create(url) {
        let ref = await axios.post(`${window.api_host}/food/images`, {
            ...url,
            storeUid: this.props.uid
        });
        if (ref.data.success) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มรูปภาพสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            this.fetch();
            this.$file_btn.removeClass('loading');
        }
        if (ref.data.error) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มรูปภาพไม่สำเร็จ',
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            // this.fetch();
            this.$file_btn.removeClass('loading');
        }
    }

    async delete(data, options) {
        let params = {
            uid: data.uid,
            storeUid: this.props.uid
        }
        let ref = await axios.delete(`${window.api_host}/food/images?${window.$.param(params)}`);
        if (ref.data.success) {
            await storage.ref().child(data.storePath).delete();
            window.$(document).Toasts('create', {
                title: 'ลบรูปภาพสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            // this.fetch();
            // this.$file_btn.removeClass('loading');
            if (options.onSuccess) {
                options.onSuccess();
            }
        }
        if (ref.data.error) {
            window.$(document).Toasts('create', {
                title: 'ลบรูปภาพไม่สำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            // this.fetch();
            // this.$file_btn.removeClass('loading');
            if (options.onError) {
                options.onError();
            }
        }
    }

    async setSequence(oldIndex, newIndex) {
        console.log(oldIndex, newIndex)
        let defaultData = this.state.data;
        let data = [...this.state.data];
        let moveData = this.state.data[oldIndex];
        data.splice(oldIndex, 1);
        let newData = [];
        let index = 0;
        if (newIndex == 0) {
            newData.push(moveData)
        }
        for (const _data of data) {
            newData.push(_data)
            index++;
            if (index == newIndex) {
                newData.push(moveData)
            }
        }

        this.setState({
            data: newData,
        }, async () => {
            let updateSequence = [];
            let sequence = 1;
            for (const _data of newData) {
                updateSequence.push({
                    uid: _data.uid,
                    sequence: sequence
                });
                sequence++;
            }
            let res = await axios.put(`${window.api_host}/food/images/sequence/${this.props.uid}`, { update: updateSequence });
            if (res.data.success) {
                this.setState({
                    is_loading: false,
                })
            }
            if (res.data.error) {
                this.setState({
                    is_loading: false,
                    data: defaultData
                })

                window.$(document).Toasts('create', {
                    title: 'เกิดข้อผิดพลาด, กรุณาลองใหม่อีกครั้ง',
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
            }
        })


    }

    render() {
        return (
            <>
                <div className="flex row">
                    <h6 style={{ fontWeight: 'bold' }}>แกลอรี่</h6>
                </div>
                <div className="form-group">
                    <input name="image"
                        type={"file"}
                        accept="image/*"
                        ref={(ref) => {
                            this.$file = window.$(ref);
                            this.$file_btn = this.$file.parent().find('.btn');
                        }}
                        style={{ display: 'none' }}
                        id="file_image_food"
                        onChange={async (e) => {
                            if (this.$file_btn.hasClass('loading')) { return false; }
                            await Helper.uploadFile(this.$file, 'store-gallery/', {
                                onSuccess: (url) => {
                                    this.create(url);
                                },
                                onError: () => {
                                    this.$file_btn.removeClass('loading');
                                },
                                resize: false
                            })
                        }}
                    />
                    <label htmlFor="file_image_food"
                        type={"button"}
                        className="btn btn-default"
                    >
                        อัพโหลด
                    </label>
                </div>
                <SortableContainer key={"promotion-slider"}
                    onSortEnd={({ oldIndex, newIndex }) => {
                        this.setSequence(oldIndex, newIndex); return;
                        this.setState({
                            is_loading: true
                        }, () => {
                            this.setSequence(oldIndex, newIndex);
                        })

                    }}
                    useDragHandle
                    axis={'xy'}
                >
                    <div className={"row"}>

                        {
                            this.state.is_loading
                                && !this.state.data ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            this.state.is_loading
                                && this.state.data ?
                                <div className='absolute_loading'>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.data.length === 0 ?
                                <h5 style={{ padding: '1rem', textAlign: 'center' }}>ไม่มีข้อมูล</h5>
                                : null
                        }
                        {
                            !this.state.is_loading ?
                                this.state.data.map((data, data_i) => {
                                    return (
                                        <SortableItem
                                            key={data_i}
                                            index={data_i}
                                        >
                                            <div
                                                className={"col-sm-12 col-lg-3"}
                                                key={data_i}
                                            >
                                                <div className={"card"}>
                                                    <div className={"card-body"} style={{ padding: '0.5rem' }}>
                                                        <div className={"promotion-slide-frame"}>
                                                            <div className="promotion-drag-btn">
                                                                <DragHandle key={`item-${data_i}`}

                                                                >
                                                                    <button className={"btn btn-default"}>
                                                                        <i className="fas fa-arrows-alt" />
                                                                    </button>
                                                                </DragHandle>
                                                            </div>
                                                            <div className={"promotion-delete"}>
                                                                <button className={"btn btn-danger"}
                                                                    ref={(ref) => {
                                                                        window.$(ref).off().on('click', () => {
                                                                            if (window.$(ref).hasClass('loading')) { return; }
                                                                            window.$(ref).addClass('loading');
                                                                            Helper.confirmModal({
                                                                                title: "ยืนยันลบรูปสไลด์",
                                                                                description: "ต้องการลบรูปสไลด์นี้หรือไม่ ?",
                                                                                onConfirm: () => {
                                                                                    this.delete(data, {
                                                                                        onSuccess: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                            this.fetch();
                                                                                        },
                                                                                        onError: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                        }
                                                                                    });
                                                                                },
                                                                                onCancel: () => {
                                                                                    window.$(ref).removeClass('loading');
                                                                                }
                                                                            });
                                                                        })
                                                                    }
                                                                    }
                                                                >
                                                                    <i className={"fas fa-trash-alt"} />
                                                                </button>
                                                            </div>
                                                            <a href={data.imagePath ? data.imagePath : null} data-fancybox="gallery">
                                                                <img src={data.imagePath ? data.imagePath : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SortableItem>
                                    );
                                })
                                : null
                        }
                    </div>
                </SortableContainer>
            </>
        );
    }
}