import React from 'react';
import { Helper } from '../../../Helper';
import { Link } from '@reach/router'
import { storage } from '../../../firebase';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomDateInput from '../../../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import PostItem from '../../../Components/PostItem';
const axios = require('axios').default;
export default class StoreFeed extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        this.state = {
            loading_post: true,
            posts: [],
            all_posts: 0,
            post_all: 0,
            page_posts: 1,
            limit_posts: 5,
            page: 1,
            loaded_page: 0,
            limit: 5,
            data: [],
            default_data: [],
            count_all_item: 0,
            count_item: 0,
            count_member_all: 0,
            count_member_today: 0,
            count_store_all: 0,
            count_store_today: 0,
            timerage: 'รายปี',
            years: years,
            date: new Date().getTime(),
            year: parseFloat(new Date().getFullYear()),
            month: parseFloat(new Date().getMonth()),
            postBy: false,
        }
    }
    async fetchPosts() {
        if(this.state.page  <= this.state.loaded_page) {return;}
        let data = [];
        let data_request = {};
        data_request.lang = 'th';
        data_request.storeUid = this.props.uid;
        data_request.postBy = this.state.postBy;
        data_request.page = this.state.page;
        data_request.limit = parseFloat(this.state.limit);
        data_request.timerage = this.state.timerage;
        let start_date = false;
        let end_date = false;
        if (this.state.timerage === 'รายปี') {
            // alert(this.state.year)
            start_date = new Date(this.state.year, 0, 1);
            end_date = new Date(this.state.year + 1, 0, 1);

            data_request.start_date = Helper.formatDate(start_date);
            data_request.end_date = Helper.formatDate(end_date);
        }
        if (this.state.timerage === 'รายเดือน') {
            // alert(this.state.year, this.state.month)
            let selected_month = parseFloat(this.state.month);
            start_date = new Date(this.state.year, selected_month, 1);
            end_date = new Date(this.state.year, selected_month + 1, 1);

            data_request.start_date = Helper.formatDate(start_date);
            data_request.end_date = Helper.formatDate(end_date);
        }
        if (this.state.timerage === 'รายวัน') {
            // alert(this.state.date)
            let date = new Date(this.state.date);
            start_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

            data_request.start_date = Helper.formatDate(start_date);
            data_request.end_date = Helper.formatDate(end_date);
        }
        // data.storeUid = 1;
        let posts_ref = await axios.post(window.api_host + '/get-store-posts', { ...data_request });
        let summary = {}
        if (posts_ref.data.success) {
            data = posts_ref.data.res;
            summary = posts_ref.data.summary;
        }
        let return_data = {};
        return_data.item = this.state.data.concat(data);
        return_data.count = data.length;
        let is_show_more_btn = data.length >= this.state.limit;
        // return return_data;
        this.setState({
            loading_post: false,
            data: return_data.item,
            all_posts: summary.totalUserPost + summary.totalStorePost || 0,
            count_all_item: data.length,
            count_member_all: summary.totalUserPost || 0,
            count_member_today: summary.todayUserPost || 0,
            count_store_all: summary.todayStorePost || 0,
            count_store_today: summary.totalStorePost || 0,
            is_show_more_btn: is_show_more_btn,
            loaded_page: this.state.page,
        })
    }

    // deletePost(id) {
    //     Helper.confirmModal({
    //         title: 'ลบโพส ' + id,
    //         description: 'ยืนยันการลบโพส',
    //         submit_text: 'ยืนยัน',
    //         onConfirm: async () => {
    //             this.setState({
    //                 loading_post: true,
    //             }, async () => {
    //                 let res = await axios.post(window.api_host + '/delete-post', { post_id: id });
    //                 if (res.data.image_to_remove && res.data.image_to_remove.length > 0) {
    //                     for (const image of res.data.image_to_remove) {
    //                         storage.ref().child(image.storagePath).delete();
    //                     }
    //                 }
    //                 if (res.data.success) {
    //                     window.$(document).Toasts('create', {
    //                         title: 'ลบข้อมูลสำเร็จ',
    //                         icon: 'fas fa-check',
    //                         autohide: true,
    //                         class: "bg-success",
    //                         delay: 3000,
    //                     })
    //                     this.fetchPosts();
    //                 }
    //                 if (res.data.error) {
    //                     window.$(document).Toasts('create', {
    //                         title: res.data.message,
    //                         icon: 'fas fa-times',
    //                         autohide: true,
    //                         class: "bg-danger",
    //                         delay: 3000,
    //                     })
    //                 }
    //             })
    //         },
    //     })
    // }

    // componentDidMount() {
    //     this.fetchPosts();
    // }

    render() {
        return (
            <div className="row" style={{ position: 'relative' }}>
                {
                    this.props.is_loading ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                <div className="col-12 col-md-3">
                    <div>
                        <h6 style={{ fontWeight: 'bold' }}>ข้อมูลจำนวนโพสสมาชิก</h6>
                        <ul className="list-group list-group-unbordered no-border-lastchild mb-3">
                            <li className="list-group-item">
                                <b>โพสทั้งหมด</b> <div className="float-right">{this.state.count_member_all}</div>
                            </li>
                            <li className="list-group-item">
                                <b>โพสวันนี้</b> <div className="float-right">{this.state.count_member_today}</div>
                            </li>
                            {/* <li className="list-group-item">
                                <b>โพสเฉลี่ยต่อวัน</b> <div className="float-right">0</div>
                            </li> */}
                        </ul>
                    </div>
                    <div>
                        <h6 style={{ fontWeight: 'bold' }}>ข้อมูลจำนวนโพสของร้าน</h6>
                        <ul className="list-group list-group-unbordered no-border-lastchild mb-3">
                            <li className="list-group-item">
                                <b>โพสทั้งหมด</b> <div className="float-right">{this.state.count_store_all}</div>
                            </li>
                            <li className="list-group-item">
                                <b>โพสวันนี้</b> <div className="float-right">{this.state.count_store_today}</div>
                            </li>
                            {/* <li className="list-group-item">
                                <b>โพสเฉลี่ยต่อวัน</b> <div className="float-right">0</div>
                            </li> */}
                        </ul>
                    </div>
                    <Link to={`/post/add/${this.props.uid}`} className="btn btn-primary" >เพิ่มโพส</Link>
                </div>
                <div className="col-12 col-md-5">
                    {/* <div className={"row form-group"}>
                        <div className="col-12 col-md-3 form-group">
                            <div style={{ width: '100%' }}>
                                <select className={"form-control form-control-sm"}
                                    name={"timerage"}
                                    defaultValue={this.state.timerage}
                                    onChange={(e) => {
                                        this.setState({
                                            timerage: e.target.value,
                                            loading_post: true,
                                        }, () => {
                                            this.fetchPosts();
                                        })
                                    }}
                                >
                                    <option value={"รายวัน"}>รายวัน</option>
                                    <option value={"รายเดือน"}>รายเดือน</option>
                                    <option value={"รายปี"}>รายปี</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            {
                                this.state.timerage === 'รายวัน' ?
                                    <div className="full-date">
                                        <DatePicker
                                            onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                            onChange={date => {
                                                this.setState({
                                                    date: new Date(date).getTime(),
                                                    loading_post: true,
                                                }, () => {
                                                    this.fetchPosts();
                                                })
                                            }}
                                            locale="th-TH"
                                            selected={Helper.getDateToEdit(this.state.date)}
                                            placeholderText={"วัน / เดือน / ปี"}
                                            name={"birthDay"}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            withPortal
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                            }) => {
                                                let now = new Date();
                                                let selected_date = new Date(date);
                                                // months_th
                                                const years = [];
                                                let i;
                                                for (i = 1980; i <= now.getFullYear(); i++) {
                                                    years.push(i)
                                                }
                                                return (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}
                                                    >
                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                            className={"btn btn-primary "}
                                                            type={"button"}
                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                        >
                                                            <i className="fas fa-chevron-left" />
                                                        </button>
                                                        <select
                                                            className={"form-control"}
                                                            value={selected_date.getFullYear()}
                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                            style={{ borderRadius: 0 }}
                                                        >
                                                            {years.map(option => (
                                                                <option key={option} value={option} >
                                                                    {option + 543}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <select
                                                            className={"form-control"}
                                                            value={window.months_th[selected_date.getMonth()]}
                                                            onChange={({ target: { value } }) =>
                                                                changeMonth(window.months_th.indexOf(value))
                                                            }
                                                            style={{ borderRadius: 0 }}
                                                        >
                                                            {window.months_th.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                            className={"btn btn-primary"}
                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                            type={"button"}
                                                        >
                                                            <i className="fas fa-chevron-right" />
                                                        </button>
                                                    </div>
                                                )
                                            }}
                                            customInput={<CustomDateInput size={"sm"} />}
                                        />
                                        <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                                    </div>
                                    : <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                            }
                            <div className={this.state.timerage === 'รายเดือน' ? "flex row merge-selectbox" : "flex row"}>

                                {
                                    this.state.timerage === 'รายเดือน' ?
                                        <select className={"form-control form-control-sm"}
                                            name={"month"}
                                            style={{ flex: 1 }}
                                            onChange={(e) => {
                                                this.setState({
                                                    month: e.target.value,
                                                    loading_post: true,
                                                }, () => {
                                                    this.fetchPosts();
                                                })
                                            }}
                                            defaultValue={this.state.month}
                                        >
                                            {
                                                window.months_th.map((month, month_i) => (
                                                    <option value={month_i} key={month_i}>{month}</option>
                                                ))
                                            }
                                        </select>
                                        : null
                                }
                                {
                                    this.state.timerage === 'รายปี'
                                        || this.state.timerage === 'รายเดือน' ?
                                        <select className={"form-control form-control-sm"}
                                            style={{ flex: 1 }}
                                            name={"year"}
                                            onChange={(e) => {
                                                this.setState({
                                                    year: e.target.value,
                                                    loading_post: true,
                                                }, () => {
                                                    this.fetchPosts();
                                                })
                                            }}
                                            defaultValue={this.state.year}
                                        >
                                            {
                                                this.state.years.map((years) => (
                                                    <option value={years} key={years}>{years}</option>
                                                ))
                                            }
                                        </select>
                                        : null
                                }
                            </div>
                        </div>
                    </div> */}
                    {
                        this.state.loading_post ?
                            <div className={"absolute_loading"}>
                                <div className="ui active inline loader" ></div>
                            </div>
                            : null
                    }
                    <div className="timeline">

                        {
                            !this.state.data
                                || this.state.data.length === 0 ?
                                <div>
                                    <i className="far fa-file bg-gray"></i>
                                    <div className="timeline-item">
                                        <div className="timeline-body" style={{ fontSize: 18 }}><div className="description-pre-line">ไม่มีข้อมูล</div></div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            this.state.data.map((item, item_i) => {
                                return (
                                    <React.Fragment
                                        key={item_i}
                                    >
                                        <PostItem
                                            item={item}
                                            index={item_i}
                                            onDeleteSuccess={() => {
                                                this.setState({
                                                    loading_post: true
                                                }, () => {
                                                    this.fetchPosts();
                                                })
                                            }}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }

                        <div>
                            {
                                this.state.is_show_more_btn ?
                                    <i className="fas fa-angle-down bg-gray"
                                        onClick={() => {
                                            this.setState({
                                                page: this.state.page + 1,
                                                loading_post: true,
                                            }, () => {
                                                this.fetchPosts();
                                            })
                                        }}
                                    />
                                    :
                                    <i className="fas fa-minus bg-gray" />
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}