import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/store`;
export const getStore = async (firebaseUid) => {
    const url = `${baseUrl}/${firebaseUid}`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getStores = async (filter) => {
    let url = `${baseUrl}/list`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const getStorePackageChannels = async (id) => {
    const url = `${baseUrl}/channel/${id}/package`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const createStorePackageChannels = async (id, data) => {
    const url = `${baseUrl}/channel/${id}/package`;
    // console.log(url)
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const updateStorePackageChannels = async (packageId, data) => {
    const url = `${baseUrl}/channel/package/${packageId}`;
    // console.log(url)
    try {
        const res = await httpClient.put(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const deleteStorePackageChannels = async (packageId) => {
    const url = `${baseUrl}/channel/package/${packageId}`;
    try {
        const res = await httpClient.delete(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const getStoreSummary = async (uid) => {
    const url = `${baseUrl}/${uid}/summary`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}