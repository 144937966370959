import React from 'react';
import { Link } from '@reach/router';
import { db } from '../../firebase';
import { Helper } from '../../Helper';
const axios = require('axios').default;

export default class AdminDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: false,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        // console.log(this.props.admin_id)
        db.doc("admin/" + this.props.admin_id).get().then((doc) => {
            let data = doc.data();
            this.setState({
                data: data
            })
        })
    }


    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ข้อมูลผู้ใช้งาน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูลผู้ใช้งาน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className="col-sm-12 col-lg-4">
                                <div className="card">
                                    <div className="card-header flex row" >
                                        <h3 className="card-title">{this.state.data ? this.state.data.displayName : null}</h3>
                                        {
                                            this.state.data ?
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <div className={'link'}
                                                        onClick={() => {
                                                            Helper.reactToDom(
                                                                window.$('body'),
                                                                <ChangePassword
                                                                    uid={this.props.admin_id}
                                                                    data={this.state.data}
                                                                    onDone={() => {
                                                                        this.fetchData();
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    >เปลี่ยนรหัสผ่าน</div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className={"card-body"}>
                                        <div>
                                            <div>
                                                {
                                                    this.state.data.permission
                                                        && this.state.data.permission.store
                                                        && this.state.data.permission.store.add
                                                        && this.state.data.permission.store.edit
                                                        && this.state.data.permission.store.delete
                                                        && this.state.data.permission.store.view
                                                        ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">จัดการร้านค้า</label>
                                            </div>
                                            <div>
                                                {
                                                    this.state.data.permission
                                                        && this.state.data.permission.member
                                                        && this.state.data.permission.member.add
                                                        && this.state.data.permission.member.edit
                                                        && this.state.data.permission.member.delete
                                                        && this.state.data.permission.member.view
                                                        ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">จัดการสมาชิก</label>
                                            </div>
                                            <div>
                                                {
                                                    this.state.data.permission
                                                        && this.state.data.permission.brand
                                                        && this.state.data.permission.brand.add
                                                        && this.state.data.permission.brand.edit
                                                        && this.state.data.permission.brand.delete
                                                        && this.state.data.permission.brand.view
                                                        ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">จัดการนักคนตรี</label>
                                            </div>
                                            <div>
                                                {
                                                    this.state.data.permission
                                                        && this.state.data.permission.package
                                                        && this.state.data.permission.package.add
                                                        && this.state.data.permission.package.edit
                                                        && this.state.data.permission.package.delete
                                                        && this.state.data.permission.package.view
                                                        ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">จัดการแพ็คเกจ</label>
                                            </div>
                                            {/* <div>
                                                {
                                                    this.state.data.permission
                                                        && this.state.data.permission.sale
                                                        && this.state.data.permission.sale.add
                                                        && this.state.data.permission.sale.edit
                                                        && this.state.data.permission.sale.delete
                                                        && this.state.data.permission.sale.view
                                                        ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">จัดการเซลส์</label>
                                            </div> */}
                                            <div>
                                                {
                                                    this.state.data.permission
                                                        && this.state.data.permission.admin
                                                        && this.state.data.permission.admin.add
                                                        && this.state.data.permission.admin.edit
                                                        && this.state.data.permission.admin.delete
                                                        && this.state.data.permission.admin.view
                                                        ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">จัดการผู้ดูแล</label>
                                            </div>
                                            <div>
                                                {
                                                    this.state.data.permission
                                                        && this.state.data.permission.report
                                                        && this.state.data.permission.report.add
                                                        && this.state.data.permission.report.edit
                                                        && this.state.data.permission.report.delete
                                                        && this.state.data.permission.report.view
                                                        ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">จัดการรายงาน</label>
                                            </div>

                                            <div>
                                                {
                                                    this.state.data.permission
                                                        && this.state.data.permission.gift
                                                        && this.state.data.permission.gift.add
                                                        && this.state.data.permission.gift.edit
                                                        && this.state.data.permission.gift.delete
                                                        && this.state.data.permission.gift.view
                                                        ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">Gift</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {
                                this.state.data.is_super_admin ?
                                    <div className='col-12 col-md-3'>
                                        <div className='card' style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                            <Link to={"/language"} className={"btn btn-default"}>จัดการภาษา</Link>
                                            <Link to={"/app-setting"} className={"btn btn-default"} >จัดการ App</Link>
                                            <Link to={"/app-product"} className={"btn btn-default"} >จัดการ AppProduct</Link>
                                            <Link to={"/transfer-user"} className={"btn btn-default"} >จัดการย้ายร้าน</Link>
                                            <Link to={"/language-transfer"} className={"btn btn-default"} >language transfer</Link>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

class ChangePassword extends React.Component {
    async update(data) {

        try {
            let _update = await axios.post(window.api_host + '/change-password-master', {
                userUID: this.props.uid,
                data: data,
            });
            if (_update.data.error) {
                window.$(document).Toasts('create', {
                    title: _update.data.message,
                    body: _update.data.description,
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                });
                this.$form = this.$modal.find('form');
                let $submit = this.$form.find('button[type="submit"]');
                $submit.removeClass('loading');
            }
            if (_update.data.success) {
                window.$(document).Toasts('create', {
                    title: 'เปลี่ยนรหัสผ่านสำเร็จ',
                    body: 'ปลี่ยนรหัสผ่านเรียบร้อยแล้ว',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                this.$modal.modal('hide');
                let settime = window.setTimeout(() => {
                    this.$modal.remove();
                    clearTimeout(settime);
                }, 500);
                this.props.onDone();
            }
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
                body: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.$form = this.$modal.find('form');
            let $submit = this.$form.find('button[type="submit"]');
            $submit.removeClass('loading');
        }
    }

    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            data.old_password = this.props.data.password
                            this.update(data);
                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">เปลี่ยนรหัสผ่าน</h4>
                            </div>
                            <div className="modal-body">
                                <div className={"form-group"}>
                                    <label>รหัสผ่านเก่า</label>
                                    <input type="password" name={"password"} className={"form-control"} />
                                </div>
                                <div className={"form-group"}>
                                    <label>รหัสผ่านใหม่</label>
                                    <input type="password" name={"new_password"} className={"form-control"} />
                                </div>
                                <div className={"form-group"}>
                                    <label>ยืนยันรหัสผ่านใหม่</label>
                                    <input type="password" name={"confirm_new_password"} className={"form-control"} />
                                </div>
                                <p>รหัสผ่านอย่างน้อย 6 ตัวอักษร</p>
                            </div>
                            <div className="modal-footer">
                                <button type={"button"} className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                        let settime = window.setTimeout(() => {
                                            this.$modal.remove();
                                            clearTimeout(settime);
                                        }, 500)
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}