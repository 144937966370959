import React from 'react';
import { Helper } from '../../../Helper';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// const axios = require('axios').default;
export default class StoreReview extends React.Component {
    constructor() {
        super();
        this.state = {
            page: 1,
            limit: 5,
            data: [],
            default_data: [],
            star_one_count: 0,
            star_two_count: 0,
            star_three_count: 0,
            star_four_count: 0,
            star_five_count: 0,
            star_one: 0,
            star_two: 0,
            star_three: 0,
            star_four: 0,
            star_five: 0,
            all_items: 0,
            all_star: 0,
            all_star_count: 0,
        }
    }
    fetch() {
        let data = Helper.prepareDataLimit(this.state.default_data, this.state.limit, this.state.page, { mergePage: true });
        let all_items = this.state.default_data.length;
        let all_star = 0;
        let all_star_count = 0;
        let star_one = 0;
        let star_two = 0;
        let star_three = 0;
        let star_four = 0;
        let star_five = 0;
        let star_one_count = 0;
        let star_two_count = 0;
        let star_three_count = 0;
        let star_four_count = 0;
        let star_five_count = 0;
        this.state.default_data.map((data) => {
            all_star += parseFloat(data.star);
            all_star_count += 1;
            if (parseFloat(data.star) === 1) {
                star_one_count += 1;
                star_one += data.star;
            }
            if (parseFloat(data.star) === 2) {
                star_two_count += 1;
                star_two += data.star;
            }
            if (parseFloat(data.star) === 3) {
                star_three_count += 1;
                star_three += data.star;
            }
            if (parseFloat(data.star) === 4) {
                star_four_count += 1;
                star_four += data.star;
            }
            if (parseFloat(data.star) === 5) {
                star_five_count += 1;
                star_five += data.star;
            }
            return true;
        })

        this.setState({
            data: data,
            all_items: all_items,
            star_one: star_one,
            star_two: star_two,
            star_three: star_three,
            star_four: star_four,
            star_five: star_five,
            star_one_count: star_one_count,
            star_two_count: star_two_count,
            star_three_count: star_three_count,
            star_four_count: star_four_count,
            star_five_count: star_five_count,
            all_star: all_star,
            all_star_count: all_star_count,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.reviews && this.props.reviews.length !== prevProps.reviews.length) {
            this.setState({
                default_data: this.props.reviews
            }, () => {
                this.fetch();
            })
        }
    }
    render() {
        let summaryStar = (this.state.all_star * 100) / (this.state.all_items * 5) || 0;
        let allSummaryStar = (summaryStar * 5) / 100;
        let allStar = [];
        for (let index = 1; index <= 5; index++) {
            allStar.push(index <= parseFloat(allSummaryStar) ? true : false)
        }
        let star_one_percent = this.state.star_one_count * 100 / this.state.all_star_count || 0;
        let star_two_percent = this.state.star_two_count * 100 / this.state.all_star_count || 0;
        let star_three_percent = this.state.star_three_count * 100 / this.state.all_star_count || 0;
        let star_four_percent = this.state.star_four_count * 100 / this.state.all_star_count || 0;
        let star_five_percent = this.state.star_five_count * 100 / this.state.all_star_count || 0;
        // console.log(star_five_percent);
        return (
            <div className="row" style={{ position: 'relative' }}>
                {
                    this.props.is_loading ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                <div className="col-12 col-md-3">
                    <div>
                        <div className="rating-box">
                            <div className="number">
                                {
                                    allSummaryStar.toFixed(1)
                                }
                            </div>
                            <div className="star">
                                {
                                    allStar.map((active, active_index) => {
                                        return (<i key={active_index} className="fas fa-star" style={active ? { color: '#ffc107' } : null}></i>)
                                    })
                                }
                            </div>
                            <p>{Helper.numberFormat(this.state.all_items)} ความคิดเห็น</p>
                        </div>
                        <ul className="list-group list-group-unbordered no-border-lastchild mb-3">
                            <li className="list-group-item">
                                <div className="flex row">
                                    <b style={{ width: 20 }}>5</b>
                                    <div style={{ flex: 1 }} className="flex-center">
                                        <div className="progress progress-xs progress-striped active " style={{ width: '100%' }}>
                                            <div className={"progress-bar bg-success"} role="progressbar" aria-valuenow={star_five_percent} aria-valuemin="0" aria-valuemax="100" style={{ width: star_five_percent + "%" }}>
                                                <span className="sr-only">{star_five_percent}% Complete (success)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex row">
                                    <b style={{ width: 20 }}>4</b>
                                    <div style={{ flex: 1 }} className="flex-center">
                                        <div className="progress progress-xs progress-striped active " style={{ width: '100%' }}>
                                            <div className={"progress-bar bg-primary"} role="progressbar" aria-valuenow={100} aria-valuemin="0" aria-valuemax="100" style={{ width: star_four_percent + "%" }}>
                                                <span className="sr-only">{star_four_percent}% Complete (success)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex row">
                                    <b style={{ width: 20 }}>3</b>
                                    <div style={{ flex: 1 }} className="flex-center">
                                        <div className="progress progress-xs progress-striped active " style={{ width: '100%' }}>
                                            <div className={"progress-bar bg-info"} role="progressbar" aria-valuenow={star_three_percent} aria-valuemin="0" aria-valuemax="20" style={{ width: star_three_percent + "%" }}>
                                                <span className="sr-only">{star_three_percent}% Complete (success)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex row">
                                    <b style={{ width: 20 }}>2</b>
                                    <div style={{ flex: 1 }} className="flex-center">
                                        <div className="progress progress-xs progress-striped active " style={{ width: '100%' }}>
                                            <div className={"progress-bar bg-warning"} role="progressbar" aria-valuenow={star_two_percent} aria-valuemin="0" aria-valuemax="100" style={{ width: star_two_percent + "%" }}>
                                                <span className="sr-only">{star_two_percent}% Complete (success)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex row">
                                    <b style={{ width: 20 }}>1</b>
                                    <div style={{ flex: 1 }} className="flex-center">
                                        <div className="progress progress-xs progress-striped active " style={{ width: '100%' }}>
                                            <div className={"progress-bar bg-danger"} role="progressbar" aria-valuenow={star_one_percent} aria-valuemin="0" aria-valuemax="100" style={{ width: star_one_percent + "%" }}>
                                                <span className="sr-only">{star_one_percent}% Complete (success)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-9">
                    <div className="timeline">
                        {
                            this.state.data.map((item, item_i) => {
                                let active_star = [];
                                for (let index = 1; index <= 5; index++) {
                                    let active = false;
                                    if (index <= parseFloat(item.star)) {
                                        active = true;
                                    }
                                    active_star.push(active);
                                }
                                return (
                                    <div key={item_i}>
                                        <div className="timeline-image">
                                            <img src={item.user && item.user.photoURL ? item.user.photoURL : "/assets/images/no_img.png"} alt="PromotionEvent" />
                                        </div>
                                        <i className="fas fa-user bg-green"></i>
                                        <div className="timeline-item">
                                            <span className="time"><i className="far fa-calendar-alt"></i> {Helper.getDateThai(new Date(item.createAt), { is_show_time: true })}</span>
                                            <h3 className="timeline-header">{item.user.displayName}</h3>
                                            <div className="timeline-body">
                                                <div className="star-ratting">
                                                    {
                                                        active_star.map((active, active_index) => {
                                                            return (
                                                                <i className="fas fa-star" style={{ color: active ? 'rgb(255, 193, 7)' : '#ccc' }} key={active_index} />
                                                            );
                                                        })
                                                    }
                                                </div>
                                                <div className="description-pre-line">{item.comment}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div>
                            {
                                (this.state.page * this.state.limit) < this.state.default_data.length ?
                                    <i className="fas fa-angle-down bg-gray"
                                        onClick={() => {
                                            this.setState({
                                                page: this.state.page + 1,
                                            }, () => {
                                                this.fetch();
                                            })
                                        }}
                                    />
                                    :
                                    <i className="fas fa-minus bg-gray" />
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}