import React from 'react';
import { Helper } from './../../Helper';
import { Link, navigate } from '@reach/router';
import Pagination from './../../Components/Pagination';
import { db } from './../../firebase';
const axios = require('axios').default;

export default class AdminSetting extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            admin_count: 0,
            is_loading: true,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: Helper.getCookie('user') && Helper.getCookie('user') !== "" ? JSON.parse(Helper.getCookie('user')).uid : false,
        }
    }


    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    fetchData() {
        db.collection("admin").orderBy('displayName', 'asc').get().then(async (doc) => {
            let data = [];
            let prepare_data = [];
            let count = 0;
            let admin_count = 0;
            let get_data = await Promise.all(
                doc.docs.map(async (element) => {
                    return {
                        ...element.data(),
                        id: element.id,
                    };

                })
            );
            admin_count = get_data.length;
            get_data = get_data.filter((_data) => {
                return _data !== false
            })
            if (this.state.status && this.state.status !== "") {
                get_data = get_data.filter((_data) => {
                    return _data.status === this.state.status
                })
            }

            get_data.forEach((_data, _data_i) => {
                let match_search = 0;
                if (this.state.name && this.state.name !== "") {
                    match_search = _data.displayName.indexOf(this.state.name);
                    if (match_search !== -1) {
                        count++;
                        prepare_data.push({ ..._data });
                    }
                } else {
                    count++;
                    prepare_data.push({ ..._data });
                }
            })
            prepare_data.forEach((__data, __data_i) => {
                if (__data_i < (this.state.limit * this.state.page)
                    && __data_i > (((this.state.limit * this.state.page) - this.state.limit) - 1)
                ) {
                    data.push(__data)
                }
            })
            this.setState({
                data: data,
                is_loading: false,
                all_items: count,
                admin_count: admin_count,
            })
        })
    }



    async delete(data, options) {
        // auth.deleteUser(data.id);
        try {
            let _delete = await axios.post(window.api_host + '/delete-user-master', {
                userUID: this.state.userUID,
                deleteUserUID: data.id
            });
            if (_delete.data.code === 200 && _delete.data.success) {
                window.$(document).Toasts('create', {
                    title: 'ลบข้อมูลสำเร็จ',
                    body: 'ทำการลบข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                this.fetchData();
            }
            if (_delete.data.code === 200 && _delete.data.error) {
                window.$(document).Toasts('create', {
                    title: _delete.data.message,
                    body: _delete.data.description,
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                });
                options.onError();
            }
        } catch (error) {
            console.log(error);
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ไม่สามารถลบได้',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
        }

    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ผู้ดูแลและสิทธิ์การเข้าถึง</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ผู้ดูแลและสิทธิ์การเข้าถึง</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายชื่อผู้ดูแล</h3>
                                <div style={{ marginLeft: 15 }}>
                                    <button className={"btn btn-primary btn-xs"}
                                        onClick={() => {
                                            Helper.reactToDom(
                                                window.$('body'),
                                                <AdminSettingForm
                                                    mode={"create"}
                                                    onCreate={(data) => {
                                                        this.fetchData();
                                                    }}
                                                />
                                            );
                                        }}
                                    >
                                        เพิ่มผู้ดูแล
                                    </button>
                                </div>
                                <div className={"card-right"}>
                                    <div style={{ marginRight: 10 }}>ทั้งหมด {this.state.admin_count} คน</div>
                                </div>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm" ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                page: 1,
                                                name: data.name,
                                            })
                                            navigate(url)
                                        }}
                                    >
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อ" defaultValue={this.state.name} />

                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%' }}>ชื่อ</th>
                                            <th>สิทธิ์การเข้าถึง</th>
                                            <th style={{ width: 200 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.displayName}
                                                                {
                                                                    _data.is_super_admin ?
                                                                        <div className="badge badge-success" style={{ marginLeft: 5 }}>Super Admin</div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td >
                                                                <div className="flex row admin-icon-permission">
                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.store
                                                                            && _data.permission.store.add
                                                                            && _data.permission.store.edit
                                                                            && _data.permission.store.delete
                                                                            && _data.permission.store.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-store"></i> <div className="text">ร้านค้า</div>
                                                                    </div>
                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.member
                                                                            && _data.permission.member.add
                                                                            && _data.permission.member.edit
                                                                            && _data.permission.member.delete
                                                                            && _data.permission.member.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-users"></i> <div className="text">สมาชิก</div>
                                                                    </div>
                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.member
                                                                            && _data.permission.member.transaction_history
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-file-invoice"></i> <div className="text">ประวัติการชำระเงินของสมาชิก</div>
                                                                    </div>
                                                                    {/* <div
                                                                        style={_data.permission
                                                                            && _data.permission.member
                                                                            && _data.permission.member.refund
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-undo"></i> <div className="text">คืนเงิน</div>
                                                                    </div> */}
                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.brand
                                                                            && _data.permission.brand.add
                                                                            && _data.permission.brand.edit
                                                                            && _data.permission.brand.delete
                                                                            && _data.permission.brand.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-cubes"></i> <div className="text">นักดนตรี</div>
                                                                    </div>
                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.package
                                                                            && _data.permission.package.add
                                                                            && _data.permission.package.edit
                                                                            && _data.permission.package.delete
                                                                            && _data.permission.package.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-cubes"></i> <div className="text">แพ็คเกจ</div>
                                                                    </div>
                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.report
                                                                            && _data.permission.report.add
                                                                            && _data.permission.report.edit
                                                                            && _data.permission.report.delete
                                                                            && _data.permission.report.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-bug"></i> <div className="text">รายงาน</div>
                                                                    </div>
                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.staticts
                                                                            && _data.permission.staticts.add
                                                                            && _data.permission.staticts.edit
                                                                            && _data.permission.staticts.delete
                                                                            && _data.permission.staticts.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-chart-bar"></i> <div className="text">สถิติ</div>
                                                                    </div>
                                                                    {/* <div
                                                                        style={_data.permission
                                                                            && _data.permission.rank
                                                                            && _data.permission.rank.add
                                                                            && _data.permission.rank.edit
                                                                            && _data.permission.rank.delete
                                                                            && _data.permission.rank.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-user-tag"></i> <div className="text">ลำดับสมาชิก</div>
                                                                    </div> */}
                                                                    {/* <div 
                                                                        style={_data.permission
                                                                            && _data.permission.sale
                                                                            && _data.permission.sale.add
                                                                            && _data.permission.sale.edit
                                                                            && _data.permission.sale.delete
                                                                            && _data.permission.sale.view
                                                                             ? {opacity: 1} : {opacity: 0.5}}
                                                                        >
                                                                        <i className="fas fa-user-tie"></i> <div className="text">เซลส์</div>
                                                                    </div> */}
                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.admin
                                                                            && _data.permission.admin.add
                                                                            && _data.permission.admin.edit
                                                                            && _data.permission.admin.delete
                                                                            && _data.permission.admin.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-user-cog"></i> <div className="text">สิทธิ์การเข้าถึง</div>
                                                                    </div>
                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.gift
                                                                            && _data.permission.gift.add
                                                                            && _data.permission.gift.edit
                                                                            && _data.permission.gift.delete
                                                                            && _data.permission.gift.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-gift"></i> <div className="text">gift</div>
                                                                    </div>

                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.transaction
                                                                            && _data.permission.transaction.add
                                                                            && _data.permission.transaction.edit
                                                                            && _data.permission.transaction.delete
                                                                            && _data.permission.transaction.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-file-invoice"></i> <div className="text">ประวัติการชำระเงิน</div>
                                                                    </div>

                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.transaction
                                                                            && _data.permission.transaction.refund
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-undo"></i> <div className="text">คืนเงิน</div>

                                                                    </div>
                                                                    <div
                                                                        style={_data.permission
                                                                            && _data.permission.coinPackage
                                                                            && _data.permission.coinPackage.add
                                                                            && _data.permission.coinPackage.edit
                                                                            && _data.permission.coinPackage.delete
                                                                            && _data.permission.coinPackage.view
                                                                            ? { opacity: 1 } : { opacity: 0.5 }}
                                                                    >
                                                                        <i className="fas fa-gifts"></i> <div className="text">Coin Pacakge</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    {
                                                                        !_data.is_super_admin || this.props.user.is_super_admin ?
                                                                            <>
                                                                                {
                                                                                    this.props.user.is_super_admin ?
                                                                                        <button className="btn btn-default"
                                                                                            onClick={() => {
                                                                                                Helper.reactToDom(
                                                                                                    window.$('body'),
                                                                                                    <AdminSettingForm
                                                                                                        mode={"edit"}
                                                                                                        data={_data}
                                                                                                        adminUid={this.props.adminUid}
                                                                                                        onUpdate={(data) => {
                                                                                                            this.fetchData();
                                                                                                        }}
                                                                                                    />
                                                                                                );
                                                                                            }}
                                                                                        >สิทธิ์การเข้าถึง</button>
                                                                                        : null
                                                                                }
                                                                                {!_data.is_super_admin ?
                                                                                    <button type="button" className="btn btn-danger"
                                                                                        ref={(ref) => {
                                                                                            window.$(ref).off().on('click', () => {
                                                                                                if (window.$(ref).hasClass('loading')) { return; }
                                                                                                window.$(ref).addClass('loading');
                                                                                                Helper.confirmModal({
                                                                                                    title: "ยืนยันลบผู้ดูแล",
                                                                                                    description: "ต้องการลบผู้ดูแลนี้หรือไม่ ?",
                                                                                                    onConfirm: () => {
                                                                                                        window.$(ref).removeClass('loading');
                                                                                                        this.delete(_data);
                                                                                                    },
                                                                                                    onCancel: () => {
                                                                                                        window.$(ref).removeClass('loading');
                                                                                                    }
                                                                                                });
                                                                                            })
                                                                                        }
                                                                                        }
                                                                                    >ลบ</button>
                                                                                    : null}

                                                                            </>
                                                                            : null
                                                                    }

                                                                    {/* <Link to={'/user/' + _data.id} type="button" className="btn btn-default">ดูข้อมูล</Link> */}
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class AdminSettingForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data || {},
        }
    }

    async create(data) {
        try {
            let ref = await axios.post(window.api_host + '/create-user-master', { data: data });
            if (ref.data.code === 200 && ref.data.success) {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มผู้ใช้งานสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                this.$modal.modal('hide');
                this.props.onCreate()
            }
            if (ref.data.code === 200 && ref.data.error) {
                let error = {
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                };
                error.title = ref.data.message;
                if (ref.data.description) {
                    error.body = ref.data.description;
                }
                window.$(document).Toasts('create', {
                    ...error
                });
                this.$form.find('button[type="submit"]').removeClass('loading')
            }
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ไม่สามารถลบได้',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.$form.find('button[type="submit"]').removeClass('loading')
        }
    }

    async update(data) {
        try {
            let ref = await axios.post(window.api_host + '/update-user-master', { data: data });
            // console.log(this.props.adminUid);
            if (ref.data.code === 200 && ref.data.success) {
                window.$(document).Toasts('create', {
                    title: 'แก้ไขข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                if (ref.data.user.uid === this.props.adminUid) {
                    let __date = ref.data.user;
                    __date.login_time = new Date().getTime();
                    delete __date.password;
                    Helper.setCookie('user', JSON.stringify(__date), 1);
                    window.location.href = window.location;
                }
                this.$modal.modal('hide');
                this.props.onUpdate()
            }
            if (ref.data.code === 200 && ref.data.error) {
                let message = {
                    icon: 'fas fa-times',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                }
                message.title = ref.data.message;
                if (ref.data.description) {
                    message.description = ref.data.description
                }
                window.$(document).Toasts('create', { ...message });
                this.$form.find('button[type="submit"]').removeClass('loading')
            }
        } catch (error) {
            console.log(error)
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ไม่สามารถลบได้',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.$form.find('button[type="submit"]').removeClass('loading')
        }
    }

    setSelectAll(ref) {
        let _ref = window.$(ref.target);
        if (_ref.prop("checked") === true) {
            _ref.parents('.input-field').find('input[type="checkbox"]').prop('checked', true);
            _ref.parents('.input-field').find('.radio-list').addClass('show').slideDown();
        }
        else if (_ref.prop("checked") === false) {
            _ref.parents('.input-field').find('input[type="checkbox"]').prop('checked', false);
            _ref.parents('.input-field').find('.radio-list').removeClass('show').slideUp();
        }
    }
    checkSelectall(ref) {
        let _ref = window.$(ref.target);
        if (_ref.prop("checked") === true) {
            let count_all_checked_input = _ref.parents('.radio-list').find('input[type="checkbox"]:checked').length;
            let count_checked_input = _ref.parents('.radio-list').find('input[type="checkbox"]:checked').length;
            if (count_all_checked_input === count_checked_input) {
                _ref.parents('.input-field').find('.main-input-checkbox').find('input[type="checkbox"]').prop('checked', true);
            }
        }
        else if (_ref.prop("checked") === false) {
            _ref.parents('.input-field').find('.main-input-checkbox').find('input[type="checkbox"]').prop('checked', false);
        }
    }

    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            data.is_super_admin = JSON.parse(data.is_super_admin);
                            if (this.props.mode === 'edit') {
                                this.update(data);
                            }
                            if (this.props.mode === 'create') {
                                this.create(data);
                            }

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลผู้ดูแล</h4>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.data.id ?
                                        <input type="hidden" name="id" defaultValue={this.state.data.id} />
                                        : null
                                }
                                <input type="hidden" name="is_super_admin" defaultValue={this.state.data.is_super_admin || false} />
                                {
                                    this.props.mode === 'create' ?
                                        <>
                                            <div className={"form-group"}>
                                                <label>อีเมล์</label>
                                                <input className={"form-control"} type="email" name="email" defaultValue={this.state.data.email || ''} />
                                            </div>
                                            <div className={"form-group"}>
                                                <label>รหัสผ่าน <span className="small-text">* อย่างน้อย 6 ตัวอักษร</span></label>
                                                <input type={"password"} className={"form-control"} required={true} name="password" defaultValue={this.state.data.password || ''} />
                                            </div>
                                        </>
                                        : null
                                }
                                <div className={"form-group"}>
                                    <label>ชื่อ</label>
                                    <input className={"form-control"} name="displayName" defaultValue={this.state.data.displayName || ''} disabled={this.props.mode === 'edit'} />
                                </div>
                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_store_permission"
                                            name={"all_store_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.store
                                                    && this.state.data.permission.store.add
                                                    && this.state.data.permission.store.edit
                                                    && this.state.data.permission.store.delete
                                                    && this.state.data.permission.store.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_store_permission">สิทธิ์การจัดการร้านค้า</label>
                                    </div>
                                    <div className="flex row radio-list">
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_store_permission"
                                                name={"add_store_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.store && this.state.data.permission.store.add ? this.state.data.permission.store.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_store_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_store_permission"
                                                name={"edit_store_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.store && this.state.data.permission.store.edit ? this.state.data.permission.store.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_store_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_store_permission"
                                                name={"delete_store_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.store && this.state.data.permission.store.delete ? this.state.data.permission.store.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_store_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_store_permission"
                                                name={"view_store_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.store && this.state.data.permission.store.view ? this.state.data.permission.store.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_store_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_member_permission"
                                            name={"all_member_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.member
                                                    && this.state.data.permission.member.add
                                                    && this.state.data.permission.member.edit
                                                    && this.state.data.permission.member.delete
                                                    && this.state.data.permission.member.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_member_permission">สิทธิ์การจัดการสมาชิก</label>
                                    </div>
                                    <div className={
                                        this.state.data.permission
                                            && this.state.data.permission.member
                                            && this.state.data.permission.member.add
                                            && this.state.data.permission.member.edit
                                            && this.state.data.permission.member.delete
                                            && this.state.data.permission.member.view ? "flex row radio-list show" : "flex row radio-list"}>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="transaction_history_member_permission"
                                                name={"transaction_history_member_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.member && this.state.data.permission.member.transaction_history ? this.state.data.permission.member.transaction_history : false}
                                                onChange={(ref) => {
                                                    // this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="transaction_history_member_permission">ดูประวัติการชำระเงิน</label>
                                        </div>
                                        {/* <div className="custom-control custom-switch">
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="refund_member_permission"
                                                name={"refund_member_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.member && this.state.data.permission.member.refund ? this.state.data.permission.member.refund : false}
                                                onChange={(ref) => {
                                                    // this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="refund_member_permission">Refund</label>
                                        </div> */}
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_member_permission"
                                                name={"add_member_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.member && this.state.data.permission.member.add ? this.state.data.permission.member.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_member_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_member_permission"
                                                name={"edit_member_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.member && this.state.data.permission.member.edit ? this.state.data.permission.member.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_member_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_member_permission"
                                                name={"delete_member_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.member && this.state.data.permission.member.delete ? this.state.data.permission.member.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_member_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_member_permission"
                                                name={"view_member_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.member && this.state.data.permission.member.view ? this.state.data.permission.member.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_member_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div>

                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_brand_permission"
                                            name={"all_brand_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.brand
                                                    && this.state.data.permission.brand.add
                                                    && this.state.data.permission.brand.edit
                                                    && this.state.data.permission.brand.delete
                                                    && this.state.data.permission.brand.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_brand_permission">สิทธิ์การจัดการนักดนตรี</label>
                                    </div>
                                    <div className="flex row radio-list">
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_brand_permission"
                                                name={"add_brand_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.brand && this.state.data.permission.brand.add ? this.state.data.permission.admin.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_brand_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_brand_permission"
                                                name={"edit_brand_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.brand && this.state.data.permission.brand.edit ? this.state.data.permission.admin.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_brand_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_brand_permission"
                                                name={"delete_brand_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.brand && this.state.data.permission.brand.delete ? this.state.data.permission.admin.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_brand_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_brand_permission"
                                                name={"view_brand_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.brand && this.state.data.permission.brand.view ? this.state.data.permission.admin.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_brand_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div>

                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_package_permission"
                                            name={"all_package_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.package
                                                    && this.state.data.permission.package.add
                                                    && this.state.data.permission.package.edit
                                                    && this.state.data.permission.package.delete
                                                    && this.state.data.permission.package.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_package_permission">สิทธิ์การจัดการแพ็คเกจ</label>
                                    </div>
                                    <div className="flex row radio-list">
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_package_permission"
                                                name={"add_package_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.package && this.state.data.permission.package.add ? this.state.data.permission.package.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_package_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_package_permission"
                                                name={"edit_package_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.package && this.state.data.permission.package.edit ? this.state.data.permission.package.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_package_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_package_permission"
                                                name={"delete_package_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.package && this.state.data.permission.package.delete ? this.state.data.permission.package.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_package_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_package_permission"
                                                name={"view_package_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.package && this.state.data.permission.package.view ? this.state.data.permission.package.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_package_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_sale_permission"
                                            name={"all_sale_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.sale
                                                    && this.state.data.permission.sale.add
                                                    && this.state.data.permission.sale.edit
                                                    && this.state.data.permission.sale.delete
                                                    && this.state.data.permission.sale.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_sale_permission">สิทธิ์การจัดการเซล์และคอมมิชชั่น</label>
                                    </div>
                                    <div className="flex row radio-list">
                                        <div className="custom-control custom-switch" style={{display: "none"}}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_sale_permission"
                                                name={"add_sale_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.sale && this.state.data.permission.sale.add ? this.state.data.permission.sale.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_sale_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{display: "none"}}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_sale_permission"
                                                name={"edit_sale_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.sale && this.state.data.permission.sale.edit ? this.state.data.permission.sale.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_sale_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{display: "none"}}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_sale_permission"
                                                name={"delete_sale_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.sale && this.state.data.permission.sale.delete ? this.state.data.permission.sale.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_sale_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{display: "none"}}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_sale_permission"
                                                name={"view_sale_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.sale && this.state.data.permission.sale.view ? this.state.data.permission.sale.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_sale_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div> */}
                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_user_permission"
                                            name={"all_user_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.admin
                                                    && this.state.data.permission.admin.add
                                                    && this.state.data.permission.admin.edit
                                                    && this.state.data.permission.admin.delete
                                                    && this.state.data.permission.admin.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_user_permission">สิทธิ์การจัดการผู้ดูแลและสิทธิ์การเข้าถึง</label>
                                    </div>
                                    <div className="flex row radio-list">
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_user_permission"
                                                name={"add_user_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.admin && this.state.data.permission.admin.add ? this.state.data.permission.admin.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_user_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_user_permission"
                                                name={"edit_user_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.admin && this.state.data.permission.admin.edit ? this.state.data.permission.admin.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_user_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_user_permission"
                                                name={"delete_user_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.admin && this.state.data.permission.admin.delete ? this.state.data.permission.admin.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_user_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_user_permission"
                                                name={"view_user_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.admin && this.state.data.permission.admin.view ? this.state.data.permission.admin.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_user_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div>

                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_report_permission"
                                            name={"all_report_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.report
                                                    && this.state.data.permission.report.add
                                                    && this.state.data.permission.report.edit
                                                    && this.state.data.permission.report.delete
                                                    && this.state.data.permission.report.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_report_permission">สิทธิ์การจัดการรายงาน</label>
                                    </div>
                                    <div className="flex row radio-list">
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_report_permission"
                                                name={"add_report_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.report && this.state.data.permission.report.add ? this.state.data.permission.admin.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_report_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_report_permission"
                                                name={"edit_report_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.report && this.state.data.permission.report.edit ? this.state.data.permission.admin.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_report_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_report_permission"
                                                name={"delete_report_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.report && this.state.data.permission.report.delete ? this.state.data.permission.admin.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_report_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_report_permission"
                                                name={"view_report_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.report && this.state.data.permission.report.view ? this.state.data.permission.admin.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_report_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div>


                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_staticts_permission"
                                            name={"all_staticts_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.staticts
                                                    && this.state.data.permission.staticts.add
                                                    && this.state.data.permission.staticts.edit
                                                    && this.state.data.permission.staticts.delete
                                                    && this.state.data.permission.staticts.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_staticts_permission">สิทธิ์การเข้าถึงข้อมูลสถิติ</label>
                                    </div>
                                    <div className="flex row radio-list">
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_staticts_permission"
                                                name={"add_staticts_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.staticts && this.state.data.permission.staticts.add ? this.state.data.permission.admin.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_staticts_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_staticts_permission"
                                                name={"edit_staticts_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.staticts && this.state.data.permission.staticts.edit ? this.state.data.permission.admin.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_staticts_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_staticts_permission"
                                                name={"delete_staticts_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.staticts && this.state.data.permission.staticts.delete ? this.state.data.permission.admin.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_staticts_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_staticts_permission"
                                                name={"view_staticts_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.staticts && this.state.data.permission.staticts.view ? this.state.data.permission.admin.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_staticts_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_rank_permission"
                                            name={"all_rank_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.rank
                                                    && this.state.data.permission.rank.add
                                                    && this.state.data.permission.rank.edit
                                                    && this.state.data.permission.rank.delete
                                                    && this.state.data.permission.rank.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_rank_permission">สิทธิ์การจัดการ Rank</label>
                                    </div>
                                    <div className="flex row radio-list">
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_rank_permission"
                                                name={"add_rank_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.rank && this.state.data.permission.rank.add ? this.state.data.permission.admin.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_rank_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_rank_permission"
                                                name={"edit_rank_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.rank && this.state.data.permission.rank.edit ? this.state.data.permission.admin.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_rank_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_rank_permission"
                                                name={"delete_rank_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.rank && this.state.data.permission.rank.delete ? this.state.data.permission.admin.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_rank_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_rank_permission"
                                                name={"view_rank_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.rank && this.state.data.permission.rank.view ? this.state.data.permission.admin.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_rank_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div> */}

                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_gift_permission"
                                            name={"all_gift_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.gift
                                                    && this.state.data.permission.gift.add
                                                    && this.state.data.permission.gift.edit
                                                    && this.state.data.permission.gift.delete
                                                    && this.state.data.permission.gift.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_gift_permission">สิทธิ์การจัดการ Gift</label>
                                    </div>
                                    <div className="flex row radio-list">
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_gift_permission"
                                                name={"add_gift_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.gift && this.state.data.permission.gift.add ? this.state.data.permission.gift.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_gift_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_gift_permission"
                                                name={"edit_gift_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.gift && this.state.data.permission.gift.edit ? this.state.data.permission.gift.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_gift_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_gift_permission"
                                                name={"delete_gift_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.gift && this.state.data.permission.gift.delete ? this.state.data.permission.gift.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_gift_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_gift_permission"
                                                name={"view_gift_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.gift && this.state.data.permission.gift.view ? this.state.data.permission.gift.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_gift_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div>

                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_transaction_permission"
                                            name={"all_transaction_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.transaction
                                                    && this.state.data.permission.transaction.add
                                                    && this.state.data.permission.transaction.edit
                                                    && this.state.data.permission.transaction.delete
                                                    && this.state.data.permission.transaction.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_transaction_permission">สิทธิ์การจัดการประวัติการชำระเงิน</label>
                                    </div >
                                    <div className={
                                        this.state.data.permission
                                            && this.state.data.permission.transaction
                                            && this.state.data.permission.transaction.add
                                            && this.state.data.permission.transaction.edit
                                            && this.state.data.permission.transaction.delete
                                            && this.state.data.permission.transaction.view ? "flex row radio-list show" : "flex row radio-list"}>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="refund_transaction_permission"
                                                name={"refund_transaction_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.transaction && this.state.data.permission.transaction.refund ? this.state.data.permission.transaction.refund : false}
                                                onChange={(ref) => {
                                                    // this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="refund_transaction_permission">คืนเงิน (Refund)</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_transaction_permission"
                                                name={"add_transaction_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.transaction && this.state.data.permission.transaction.add ? this.state.data.permission.transaction.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_transaction_permission">เพิ่ม</label>
                                        </div >
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_transaction_permission"
                                                name={"edit_transaction_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.transaction && this.state.data.permission.transaction.edit ? this.state.data.permission.transaction.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_transaction_permission">แกไข</label>
                                        </div >
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_transaction_permission"
                                                name={"delete_transaction_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.transaction && this.state.data.permission.transaction.delete ? this.state.data.permission.transaction.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_transaction_permission">ลบ</label>
                                        </div >
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_transaction_permission"
                                                name={"view_transaction_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.transaction && this.state.data.permission.transaction.view ? this.state.data.permission.transaction.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_transaction_permission">ดูข้อมูล</label>
                                        </div >
                                    </div >
                                </div >

                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="all_coinPackage_permission"
                                            name={"all_coinPackage_permission"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.permission
                                                    && this.state.data.permission.coinPackage
                                                    && this.state.data.permission.coinPackage.add
                                                    && this.state.data.permission.coinPackage.edit
                                                    && this.state.data.permission.coinPackage.delete
                                                    && this.state.data.permission.coinPackage.view
                                                    ? true : false
                                            }
                                            onChange={(ref) => {
                                                this.setSelectAll(ref)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="all_coinPackage_permission">สิทธิ์การจัดการแพ็กเกจการเติมเหรีนญ</label>
                                    </div>
                                    <div className="flex row radio-list">
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="add_coinPackage_permission"
                                                name={"add_coinPackage_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.coinPackage && this.state.data.permission.coinPackage.add ? this.state.data.permission.admin.add : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="add_coinPackage_permission">เพิ่ม</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="edit_coinPackage_permission"
                                                name={"edit_coinPackage_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.coinPackage && this.state.data.permission.coinPackage.edit ? this.state.data.permission.admin.edit : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="edit_coinPackage_permission">แกไข</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="delete_coinPackage_permission"
                                                name={"delete_coinPackage_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.coinPackage && this.state.data.permission.coinPackage.delete ? this.state.data.permission.admin.delete : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="delete_coinPackage_permission">ลบ</label>
                                        </div>
                                        <div className="custom-control custom-switch" style={{ display: "none" }}>
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                id="view_coinPackage_permission"
                                                name={"view_coinPackage_permission"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.data.permission && this.state.data.permission.coinPackage && this.state.data.permission.coinPackage.view ? this.state.data.permission.admin.view : false}
                                                onChange={(ref) => {
                                                    this.checkSelectall(ref)
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="view_coinPackage_permission">ดูข้อมูล</label>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div >
                    </form >
                </div >
            </>
        );
    }
}