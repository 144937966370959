import React from 'react';
import { Link } from '@reach/router';
import PreviewPackage from './PreviewPackage';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import { db } from './../../firebase';
const axios = require('axios').default;

export default class Sequence extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            default_data: [],
            is_loading: true,
            is_sequence_loading: true,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.post(window.api_host + '/store', {
            data: {
                // uid: this.props.uid,
                package_type: true,
                only_main_package: true,
            }
        });
        let package_types = false;
        if (res.data.success) {
            package_types = res.data.response.package_types;
        }

        this.setState({
            data: package_types,
            default_data: package_types,
            is_loading: false,
            is_sequence_loading: false,
        })
    }

    setSequence = (oldIndex, newIndex) => {
        if (oldIndex === newIndex) { return; }
        // alert("setSequence");
        let update_sequence = [];
        let before_move = parseFloat(this.state.data[oldIndex].sequence);
        let after_move = parseFloat(this.state.data[newIndex].sequence);
        let move_slide_id = this.state.data[oldIndex].uid;
        // console.log(before_move)
        // console.log(after_move)
        update_sequence.push({
            id: move_slide_id,
            sequence: after_move,
        })
        let move_type = 'moveup';
        if (after_move < before_move) {
            move_type = 'movedown';
        }
        this.state.data.map((item) => {
            let sq = parseFloat(item.sequence);
            if (move_type === 'movedown'
                && sq >= after_move
                && sq < before_move
                && item.id !== move_slide_id
            ) {
                update_sequence.push({
                    id: item.uid,
                    sequence: sq + 1,
                })
            }
            if (move_type === 'moveup'
                && sq <= after_move
                && sq > before_move
                && item.id !== move_slide_id
            ) {
                update_sequence.push({
                    id: item.uid,
                    sequence: sq - 1,
                })
            }
            return true;
        })
        // console.log(update_sequence);
        this.setState({
            is_sequence_loading: true,
        }, async () => {
            await update_sequence.map(async (_update_sequence, update_sequence_i) => {
                await db.doc('packages/' + _update_sequence.id).update({ sequence: _update_sequence.sequence });
                if ((update_sequence_i + 1) === update_sequence.length) {
                    await this.fetch()
                }
                return true;
            });;
        })
    };


    render() {

        const DragHandle = sortableHandle(() => <div className="drag-icon"><i className="fas fa-sort"></i></div>);
        const SortableItem = sortableElement(({ item }) => (
            <li className={"list-group-item"}>
                <div className="flex row">
                    <div className="flex row" style={{ alignItems: 'center' }}>
                        <DragHandle />
                        <div>{item.name}</div>
                    </div>
                </div>
            </li>
        ));

        const SortableContainer = sortableContainer(({ children }) => {
            return <ul className="list-group">{children}</ul>;
        });
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">เรียงลำดับแพ็คเกจ </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/package">แพ็คเกจ</Link></li>
                                    <li className="breadcrumb-item active">เรียงลำดับแพ็คเกจ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3">

                                {
                                    this.state.is_sequence_loading ?
                                        <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                        : null
                                }
                                <SortableContainer
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        this.setSequence(oldIndex, newIndex);
                                    }}
                                    useDragHandle
                                >
                                    {this.state.data.map((item, item_i) => (
                                        <SortableItem key={`item-${item_i}`} index={item_i} item={item} />
                                    ))}
                                </SortableContainer>
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="card">
                                    <div className="card-body">
                                        <h6>ตัวอย่างการแสดงผล</h6>
                                        {
                                            this.state.is_sequence_loading ?
                                                <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                                : null
                                        }
                                        <PreviewPackage
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}