import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../../Helper';
const axios = require('axios').default;

export default class Form extends React.Component {
    constructor() {
        super();
        this.state = {
            purchaseTypeSelected: false,
            is_loading: true,
            ranks: [],
            stickers: [],
            rankUid: false,
            rankOptionUid: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let qs = {};
        if (this.props.uid) {
            qs.uid = this.props.uid;
        }
        qs.get_rank_data = true;
        qs.get_sticker_data = true;
        let res = await axios.get(window.api_host + '/inapp-product?' + window.$.param(qs));
        // console.log(res.data);
        let ranks = [];
        let stickers = [];
        let product = false;
        if (res.data.success) {
            if (res.data.response.ranks) {
                ranks = res.data.response.ranks;
            }
            if (res.data.response.stickers) {
                stickers = res.data.response.stickers;
            }
            if (res.data.response.product) {
                product = res.data.response.product;
            }
        }
        let purchaseTypeSelected = 'coin';
        let rankUid = false;
        let rankOptionUid = false;
        if (product) {
            purchaseTypeSelected = product.purchaseType;
        }
        if (product && product.purchaseType === 'rank') {
            let value_split = product.value.split(',');
            rankUid = value_split[0];
            rankOptionUid = value_split[1];
        }
        this.setState({
            is_loading: false,
            ranks: ranks,
            stickers: stickers,
            product: product,
            purchaseTypeSelected: purchaseTypeSelected,
            rankUid: rankUid,
            rankOptionUid: rankOptionUid,
        })
    }
    prepareDataBeforeCallAPI(data) {
        console.log(data);
        let _data = { ...data };
        _data.isActive = false;
        if(data.isActive) {
            _data.isActive = true;
        }
        if (data.purchaseType === 'rank') {
            _data.value = data.rankUid + ',' + data.rankOptionUid;
        }
        if (data.isActive) {
            _data.isActive = JSON.parse(data.isActive)
        }
        delete _data.rankUid;
        delete _data.rankOptionUid;
        return _data;
    }


    async create(data) {
        let _data = this.prepareDataBeforeCallAPI(data)
        let res = await axios.post(window.api_host + '/inapp-product-create', {
            ..._data
        });
        if (res.data.success) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            navigate("/app-product")
        }
        if (res.data.error) {
            window.$(document).Toasts('create', {
                title: res.data.message,
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.submit.removeClass('loading');
        }

    }
    async update(data) {
        let _data = this.prepareDataBeforeCallAPI(data)
        let res = await axios.post(window.api_host + '/inapp-product-update', {
            ..._data
        });
        if (res.data.success) {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            navigate("/app-product")
        }
        if (res.data.error) {
            window.$(document).Toasts('create', {
                title: res.data.message,
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.submit.removeClass('loading');
        }
    }

    getRankOptions() {
        let options = [];
        if (this.state.rankUid) {
            let rank = this.state.ranks.filter((e) => {
                return e.uid = this.state.rankUid;
            })
            options = rank[0];
        }
        // console.log(this.state.rankUid);
        // console.log(options);
        return options;
    }

    render() {
        let rankOptions = this.getRankOptions();
        // console.log(this.state.product);
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">{this.props.uid ? 'Edit' : 'Add'} Product</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/app-product">App Product</Link></li>
                                    <li className="breadcrumb-item active">{this.props.uid ? 'Edit' : 'Add'} Product</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <form className="card"
                                    style={{ position: 'relative' }}
                                    ref={(ref) => {
                                        this.$form = window.$(ref)
                                        this.submit = this.$form.find('button[type="submit"]');
                                    }}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (this.submit.hasClass('loading')) { return false; }
                                        this.submit.addClass('loading');
                                        let data = Helper.getFormInputObject(this.$form);
                                        if (!data.uid) {
                                            this.create(data);
                                        }
                                        if (data.uid) {
                                            this.update(data);
                                        }
                                    }}
                                >

                                    {
                                        this.state.is_loading ?
                                            <div className="absolute_loading">
                                                <div className="ui active inline loader small" ></div>
                                            </div>
                                            : null
                                    }
                                    <div className="card-header">
                                        <div className="card-title">
                                            {this.props.uid ? 'Edit' : 'Add'} Product
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <input type="hidden" className="form-control" name="productType" value={"consumable"} />
                                        <input type="hidden" className="form-control" name="platform" value={"ios"} />
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>ProductID</label>
                                                    <input type="text" className="form-control" name="productID" defaultValue={this.state.product && this.state.product.productID ? this.state.product.productID : ''} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Currency Type</label>
                                                    <select
                                                        className={"form-control"}
                                                        name={"currencyType"}
                                                        style={{ width: '100%' }}
                                                        defaultValue={this.state.product && this.state.product.currencyType ? this.state.product.currencyType : ''}
                                                    >
                                                        <option value={"real"}>Real</option>
                                                        <option value={"coin"}>Coin</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>ชื่อสินค้า (ไทย)</label>
                                                    <input type="text" className="form-control" name="nameTH"
                                                        defaultValue={this.state.product && this.state.product.nameTH ? this.state.product.nameTH : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>ชื่อสินค้า (อังกฤษ)</label>
                                                    <input type="text" className="form-control" name="nameEN"
                                                        defaultValue={this.state.product && this.state.product.nameEN ? this.state.product.nameEN : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Purchase Type</label>
                                                    <select
                                                        className={"form-control"}
                                                        name={"purchaseType"}
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                purchaseTypeSelected: e.target.value
                                                            })
                                                        }}
                                                        value={this.state.purchaseTypeSelected || ''}
                                                    >
                                                        <option value={"coin"}>Coin</option>
                                                        <option value={"rank"}>Rank</option>
                                                        {/* <option value={"sticker"}>Sticker</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                {
                                                    this.state.purchaseTypeSelected
                                                        && this.state.purchaseTypeSelected === 'coin' ?
                                                        <div className="form-group">
                                                            <label>จำนวน Coin</label>
                                                            <input name="value" type="number" className="form-control"
                                                                defaultValue={this.state.product && this.state.product.value ? this.state.product.value : ''}
                                                            />
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    this.state.purchaseTypeSelected
                                                        && this.state.purchaseTypeSelected === 'rank' ?
                                                        <>
                                                            <div className="form-group">
                                                                <label>เลือก Rank</label>
                                                                <select
                                                                    className={"form-control"}
                                                                    name={"rankUid"}
                                                                    style={{ width: '100%' }}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            rankUid: e.target.value
                                                                        })
                                                                    }}
                                                                    defaultValue={this.state.rankUid || ''}
                                                                >
                                                                    <option value={""}>
                                                                        เลือก Rank
                                                                </option>
                                                                    {
                                                                        this.state.ranks.map((rank, rank_i) => {
                                                                            return (<option value={rank.uid} key={rank_i}>{rank.nameTH}</option>);
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {
                                                                rankOptions
                                                                    && rankOptions.options
                                                                    && rankOptions.options.length > 0 ?
                                                                    <div className="form-group">
                                                                        <label>เลือก Option</label>
                                                                        <select
                                                                            className={"form-control"}
                                                                            name={"rankOptionUid"}
                                                                            style={{ width: '100%' }}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    rankOptionUid: e.target.value
                                                                                })
                                                                            }}
                                                                            defaultValue={this.state.rankOptionUid || ''}
                                                                        >
                                                                            {
                                                                                rankOptions.options.map((rank, rank_i) => {
                                                                                    return (<option value={rank.uid} key={rank_i}>{rank.nameTH}</option>);
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                                }
                                                {
                                                    this.state.purchaseTypeSelected
                                                        && this.state.purchaseTypeSelected === 'sticker' ?
                                                        <>
                                                            <div className="form-group">
                                                                <label>เลือก Sticky</label>
                                                                <select
                                                                    className={"form-control"}
                                                                    name={"rankUid"}
                                                                    style={{ width: '100%' }}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            rankUid: e.target.value
                                                                        })
                                                                    }}
                                                                >
                                                                    <option value={""}>
                                                                        เลือก Sticky
                                                                </option>
                                                                    {
                                                                        this.state.stickers.map((sticker, sticker_i) => {
                                                                            return (<option value={sticker.uid} key={sticker_i}>{sticker.nameTH}</option>);
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </div>


                                            {
                                                this.props.uid ?
                                                    <>
                                                        <div className="col-12">
                                                            {
                                                                this.state.product ?
                                                                    <div className="custom-control custom-switch">
                                                                        <input type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="isActive"
                                                                            name={"isActive"}
                                                                            defaultValue={"true"}
                                                                            defaultChecked={
                                                                                this.state.product
                                                                                    && this.state.product.isActive ?
                                                                                    this.state.product.isActive : false
                                                                            }
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="isActive">ใช้งาน</label>
                                                                    </div>
                                                                    : null
                                                            }
                                                            <input type="hidden" className="form-control" name="uid" value={this.props.uid} />
                                                        </div>
                                                    </>
                                                    :
                                                    <input type="hidden" className="form-control" name="isActive" value={"true"} />
                                            }
                                        </div>


                                    </div>
                                    <div className="card-footer">
                                        <div className="flex row">
                                            <div className="right-floated">
                                                <button className="btn btn-primary" type={'submit'}>{this.props.uid ? 'บันทึก' : 'เพิ่ม'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}