import React, { Component, createContext } from "react";
import { db } from "../firebase";
import { Helper } from "../Helper";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  constructor() {
    super();
    let user = Helper.getCookie('user') && Helper.getCookie('user') !== '' ? JSON.parse(Helper.getCookie('user')) : false;
    this.state = {
      user: user,
      loading: true,
    }
  }

  componentDidMount = async () => {
    if (!this.state.user && window.location.pathname !== "/") {
      window.location = '/'
    }
    let user = await db.doc('/admin/' + this.state.user.uid).get();
    let user_data = false;
    if(user.exists) {
      user_data = await user.data();
      user_data.uid = user.id;
    }
    this.setState({
      user: user_data,
      loading: false,
    })
  };

  render() {
    // console.log(this.state.user)
    if (this.state.loading) {
      return (
        <>
          <div style={{ padding: 15, display: 'flex', alignItems: 'center', backgroundColor: '#f4f6f9' }}>
            <div className="ui active inline loader small" style={{ marginRight: 5 }}></div> <span style={{ fontSize: 18 }}>
              Loading ...
            </span>
          </div>
        </>
      )
    }
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
