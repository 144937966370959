import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/coin/exchange`;
export const getExchangeTransaction = async (filter) => {
    let url = `${baseUrl}/transaction`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    console.log(url, filter)
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getExchange = async (filter) => {
    let url = `${baseUrl}/request`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getExchangeList = async (filter) => {
    let url = `${baseUrl}/request/list`;
    if (filter) {
        url += `?${window.$.param(filter)}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const exchangeRequest = async (data) => {
    const url = `${baseUrl}/request`;
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const updateExchangeRequest = async (id, data) => {
    const url = `${baseUrl}/request/${id}`;
    try {
        const res = await httpClient.put(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}