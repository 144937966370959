import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../../../Helper';
import { db } from './../../../firebase'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pagination from '../../../Components/Pagination';
import { getUsers } from '../../../services/UserServices';
const axios = require('axios').default;
export default class OnlineUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guests: [],
            all_guests: [],
            all_posts: [],
            limit_posts: 5,
            page_posts: 1,
            limit_guests: 10,
            page_guests: 1,
            post_all: 0,
            guest_all: 0,
            count_all_item: 0,
            count_member_all: 0,
            count_member_today: 0,
            count_store_all: 0,
            count_store_today: 0,
            loading_store: true,
            loading_post: true,
            loading_guest: true,
        }
    }

    async fetch() {

        // let res = await getUsers(this.state.page_guests, this.state.limit_guests, false, false, false, false, true, this.props.uid);
        // let items = [];
        // let all_guests = 0;
        // let guest_all = 0;
        // if (!res.error) {
        //     items = res.items;
        //     all_guests = res.total;
        //     guest_all = res.total;
        // }
        // this.setState({
        //     loading_guest: false,
        //     all_guests: all_guests,
        //     guests: items,
        //     guest_all: guest_all,
        // })


        db.collection('stores/' + this.props.uid + '/live').where('status', '==', 'stay').onSnapshot(async rmSnap => {
            let items = [];
            for (const doc of rmSnap.docs) {
                let item = {};
                let doc_data = doc.data();
                item.user = doc_data.user;
                item.uid = doc.id;
                items.push(item);
            }
            let guests = {
                item: [],
                count: 0,
            };
            if (items.length > 0) {
                guests = await this.fetchGuests(items);
            }
            // console.log('items',items);
            // console.log('guests',guests)
            this.setState({
                loading_guest: false,
                all_guests: items,
                guests: guests.item,
                guest_all: guests.count,
            })
        });
    }

    componentDidMount() {
        this.fetch();
    }
    fetchGuests(data) {
        let prepareData = Helper.prepareDataLimit(data, this.state.limit_guests, this.state.page_guests);
        let return_data = {};
        return_data.item = prepareData;
        return_data.count = data.length;
        return return_data;
    }
    async kickUser(data) {
        Helper.confirmModal({
            title: 'บังคับผู้ใช้งาน ' + data.user.displayName + ' ออกจากระบบ',
            description: 'ยืนยันการบังคับผู้ใช้งานออกจากระบบ',
            submit_text: 'ยืนยัน',
            onConfirm: async () => {
                let res = await axios.post(window.api_host + '/kick-user-store', {
                    userUid: data.uid,
                    storeUid: this.props.uid
                })
                if (res.data.code === 200) {
                    window.$(document).Toasts('create', {
                        title: data.user.displayName + ' ออกจากระบบแล้ว',
                        icon: 'fas fa-check',
                        autohide: true,
                        class: "bg-success",
                        delay: 3000,
                    })
                }
                if (res.data.code !== 200) {
                    window.$(document).Toasts('create', {
                        title: res.data.message,
                        icon: 'fas fa-times',
                        autohide: true,
                        class: "bg-danger",
                        delay: 3000,
                    })
                }
            },
        })
    }

    switchStoreAllUsers(options) {
        Helper.confirmModal({
            title: 'ยืนยันการย้ายร้าน',
            description: 'ยืนยันการนำผู้ใช้งานทั้งหมดย้ายร้าน ?',
            onCancel: () => {
                if (options.onCancel) {
                    options.onCancel();
                }
            },
            onConfirm: async () => {
                let data = {
                    storeUid: this.props.uid,
                    toStoreUid: 'PBRD8zJRpfYaTz03TgkZ'
                }
                let res = await axios.post(window.api_host + '/switch-store', data);
                if (res.data.code === 200) {
                    window.$(document).Toasts('create', {
                        title: 'ย้ายผู้ใช้งานออกจากร้านเรียบร้อยแล้ว',
                        icon: 'fas fa-check',
                        autohide: true,
                        class: "bg-success",
                        delay: 3000,
                    })
                }
            }
        })
    }
    switchStoreUser(userUid, options) {
        Helper.confirmModal({
            title: 'ยืนยันการย้ายร้าน',
            description: 'ยืนยันการนำผู้ใช้งานนี้ย้ายร้าน ?',
            onCancel: () => {
                if (options.onCancel) {
                    options.onCancel();
                }
            },
            onConfirm: async () => {
                let data = {
                    storeUid: this.props.uid,
                    toStoreUid: 'PBRD8zJRpfYaTz03TgkZ',
                    userUid: userUid
                }
                let res = await axios.post(window.api_host + '/switch-store', data);
                if (res.data.code === 200) {
                    window.$(document).Toasts('create', {
                        title: 'ย้ายผู้ใช้งานออกจากร้านเรียบร้อยแล้ว',
                        icon: 'fas fa-check',
                        autohide: true,
                        class: "bg-success",
                        delay: 3000,
                    })
                }
            }
        })
    }

    render() {
        // console.log(this.props.data)
        return (
            <div className="row" style={{ position: 'relative' }}>
                {
                    this.state.loading_guest ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                <div className="col-12 col-md-3">
                    <div>
                        <h6 style={{ fontWeight: 'bold' }}>ข้อมูลจำนวนสมาชิกที่ออนไลน์</h6>
                        <ul className="list-group list-group-unbordered no-border-lastchild mb-3">
                            <li className="list-group-item">
                                <b>ออนไลน์ทั้งหมด</b> <div className="float-right">{Helper.numberFormat(this.state.guest_all)}</div>
                            </li>
                        </ul>
                    </div>
                    {/* <div>
                        <button
                            ref={(ref) => {
                                let _this = window.$(ref);
                                _this.off().on('click', () => {
                                    if (_this.hasClass('loading')) return;
                                    _this.addClass('loading');
                                    this.switchStoreAllUsers({
                                        onCancel: () => {
                                            _this.removeClass('loading')
                                        },
                                        onConfirm: () => {
                                            _this.removeClass('loading')
                                        }
                                    })
                                })
                            }}
                            className="btn btn-info"
                        >ย้านร้านทั้งหมด</button>
                    </div> */}
                </div>
                <div className="col-12 col-md-9">
                    <ul className="users-list clearfix">
                        {
                            this.state.guest_all > 0 ?
                                this.state.guests.map((guests, guests_i) => {
                                    // console.log(guests)
                                    return (
                                        <li key={guests_i} className="promotion-slide-frame flex-column" style={{ width: '20%' }} >
                                            {/* <div className="promotion-delete" style={{ left: 10, right: 'auto' }}>
                                                <button
                                                    ref={(ref) => {
                                                        let _this = window.$(ref);
                                                        _this.off().on('click', () => {
                                                            if (_this.hasClass('loading')) return;
                                                            _this.addClass('loading');
                                                            this.switchStoreUser(guests.uid, {
                                                                onCancel: () => {
                                                                    _this.removeClass('loading')
                                                                },
                                                                onConfirm: () => {
                                                                    _this.removeClass('loading')
                                                                }
                                                            })
                                                        })
                                                    }}
                                                    className="btn btn-info btn-circle"
                                                >
                                                    <i className="fas fa-exchange-alt" />
                                                </button>
                                            </div> */}
                                            <div className="promotion-delete">
                                                <button
                                                    onClick={() => {
                                                        this.kickUser(guests);
                                                    }}
                                                    className="btn btn-danger btn-circle"
                                                >
                                                    <i className="fas fa-sign-out-alt" />
                                                </button>
                                            </div>
                                            <div className="profile-user-img img-frame img-fluid img-circle">
                                                <img src={guests && guests.user && guests.user.photoURL ? guests.user.photoURL : "/assets/images/no_img.png"} alt="User" />
                                            </div>
                                            {
                                                guests.user
                                                    && guests.user.displayName ?
                                                    <Link className="users-list-name" to={'/member/view/' + guests.uid}>{guests.user.displayName}</Link>
                                                    : null
                                            }
                                            {
                                                guests.createdAt ?
                                                    <span className="users-list-date">{Helper.getDateThai(guests.createdAt, { is_show_time: false })}</span>
                                                    : null
                                            }
                                        </li>
                                    )
                                })
                                : null
                        }
                    </ul>
                    {
                        this.state.guest_all > 0 ?
                            <Pagination
                                now={this.state.page_guests}
                                all_items={this.state.guest_all}
                                limit={this.state.limit_guests}
                                onReturnPage={(page) => {
                                    // console.log(_guests);
                                    this.setState({
                                        page_guests: page,
                                        loading_guest: true,
                                    }, async () => {
                                        this.fetch()
                                    })
                                }}
                                location={this.props.location}
                            />
                            : null
                    }
                </div>
            </div>
        );
    }
}