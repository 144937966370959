import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../../Helper';
import { getCoinTransaction } from '../../../services/CoinServices';
import { getStore, getStores } from '../../../services/StoreServices';
import CustomDateInput from '../../../Components/CustomDateInput';
import DatePicker from "react-datepicker";

const default_limit = 5;
export default class Transaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            data: [],
            stores: [],
            page: 1,
            date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : '',
            q: Helper.getParameterByName('q') ? Helper.getParameterByName('q') : '',
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            total_items: 0,
            count_items: 0,
            storeId: props.id || false,
        }
    }

    componentDidMount() {
        if (!this.props.disableRunDidMount) {
            this.fetch();
        }
    }

    firstFetch() {
        if (this.state.data.length == 0) {
            this.fetch();
        }
    }

    async fetch() {
        let storeId = this.state.storeId;
        let data = [...this.state.data];
        let page = 1;
        let is_done = true;
        let totalItems = 0;
        let itemEnd = 0;
        let filter = {}
        filter.page = this.state.page;
        filter.rows = this.state.limit;
        // filter.typeIds = `19,21`;
        if (this.props.typeIds) {
            filter.typeIds = this.props.typeIds;
        }
        if (this.state.date) {
            let _date = Helper.getDateToEdit(this.state.date);
            let day = String(_date.getDate()).padStart(2, '0')
            let month = String((_date.getMonth() + 1)).padStart(2, '0')
            let dateFormat = `${day}-${month}-${_date.getFullYear()}`;
            filter.date = dateFormat;
        }
        if (this.state.q) {
            filter.q = this.state.q
        }
        let stores = [...this.state.stores];
        if (this.props.selectStore && this.state.stores.length == 0) {
            let storeRef = await getStores();
            if (!storeRef.error) {
                stores = storeRef;
            }
        }
        // filter.typeId = 1;
        if (!storeId && this.props.uid && !this.props.id) {
            let storeRes = await getStore(this.props.uid);
            if (!storeRes.error) {
                storeId = storeRes.id;
            } else {
                window.$(document).Toasts('create', {
                    title: 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาติดต่อผู้ดูแลระบบ',
                    icon: 'fas fa-ban',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
                this.setState({
                    is_loading: false,
                    storeId: false,
                    data: [],
                    page: 1,
                    is_done: true,
                    totalItems: 0,
                    itemEnd: 0,
                })
                return;
            }
        }
        if (storeId) {
            filter.storeId = storeId;
        }
        let res = await getCoinTransaction(filter);
        if (!res.error) {
            data = data.concat(res.items);
            page = res.page == res.endPage ? this.state.page : (this.state.page + 1);
            is_done = res.page == res.endPage;
            totalItems = res.total;
            itemEnd = res.itemEnd;
        }

        this.setState({
            is_loading: false,
            storeId: storeId,
            stores: stores,
            data: data,
            page: page,
            is_done: is_done,
            total_items: totalItems,
            count_items: itemEnd,
        }, () => {
            if (this.$viewMore) {
                this.$viewMore.removeClass('loading')
            }
        })
    }
    loadMore() {
        this.fetch();
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12 col-md-3">
                        {/* FILTER */}
                        <div className="card card-outline">
                            <div className="card-header">
                                <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>
                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <form className="row"
                                    ref={(ref) => { this.$form = window.$(ref) }}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                            return;
                                        }
                                        let data = Helper.getFormInputObject(this.$form);
                                        // let url = Helper.getQueryStringLinkFromObject({
                                        //     ...data
                                        // })
                                        // navigate(url)
                                        this.setState({
                                            is_loading: true,
                                            page: 1,
                                            data: [],
                                            limit: default_limit,
                                            ...data
                                        }, () => {
                                            this.fetch();
                                        })
                                    }}
                                >
                                    {/* <div className="col-6 form-group">
                                                <label>ค้นหา </label>
                                                <input type="text" name="q" className="form-control" defaultValue={this.state.q} />
                                            </div> */}
                                    {
                                        this.props.selectStore ?
                                            <div className='form-group'>
                                                <div className='d-flex'>
                                                    <label>ร้าน</label>
                                                    {
                                                        this.state.is_loading ?
                                                            <div className={"absolute_loading"}>
                                                                <div className="ui active inline loader" ></div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.storeId ?
                                                            <div className='right-floated'>
                                                                <div className='link'
                                                                    onClick={() => {
                                                                        if (this.$storefilter) {
                                                                            this.$storefilter.val(null).trigger('change');
                                                                        }
                                                                    }}
                                                                >ยกเลิก</div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <select className={"form-control"}
                                                    ref={(ref) => {
                                                        this.$storefilter = window.$(ref);
                                                        this.$storefilter.select2({
                                                            placeholder: 'เลือกร้านทั้งหมด',
                                                        });

                                                        // this.$storefilter.off().on("select2:select", () => {
                                                        //     this.$submit.trigger('click')
                                                        // })
                                                    }}
                                                    name={"storeId"}
                                                    style={{ flex: 1 }}
                                                    defaultValue={this.state.month}
                                                >
                                                    <option value={""}>เลือกร้านทั้งหมด</option>
                                                    {
                                                        this.state.stores.map((store, i) => {
                                                            return (
                                                                <option value={store.id} key={i}>{store.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            : null
                                    }

                                    <div className="col-12 form-group">
                                        <label className="d-flex">วันที่
                                            {
                                                this.state.date ?
                                                    <span className="right-floated date-cancle-icon"
                                                        onClick={() => {
                                                            this.setState({ date: "" })
                                                        }}
                                                    >  ยกเลิก
                                                    </span>
                                                    : null
                                            }
                                        </label>
                                        <div style={{ position: 'relative' }}>

                                            <DatePicker
                                                onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                                onChange={date => {
                                                    let _date = new Date(date);
                                                    this.setState({
                                                        date: _date.getTime(),
                                                    })
                                                }}
                                                locale="th-TH"
                                                selected={this.state.date ? Helper.getDateToEdit(this.state.date) : false}
                                                placeholderText={"วัน / เดือน / ปี"}
                                                name={"birthDay"}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                withPortal
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    let now = new Date();
                                                    let selected_date = new Date(date);
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear(); i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={selected_date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[selected_date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput />}
                                            />
                                            <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />


                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="flex row">
                                            {
                                                this.props.disabledReset ?
                                                    null
                                                    :
                                                    <Link to="/exchange/transaction" className="btn btn-default">รีเซ็ท</Link>
                                            }
                                            <div style={{ marginLeft: 'auto' }}>
                                                <button type="submit" className="btn btn-default">ค้นหา</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <div className="card">
                            <div className="card-header align-items-center" style={{ display: 'flex' }}>
                                <h3 className="card-title">ประวัติการชำระเงิน</h3>

                            </div>
                            <div className="card-body">

                                {
                                    this.state.is_loading ?
                                        <div className={"absolute_loading"}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                <RenderPaymentTransactionList
                                    data={this.state.data}
                                    isCanRefund={this.props.user && this.props.user.permission && this.props.user.permission.transaction && this.props.user.permission.transaction.refund ? true : false}
                                    onRefund={(obj) => {
                                        console.log('obj', obj)
                                        let datas = [...this.state.data];
                                        let i = 0;
                                        for (const data of datas) {
                                            if (data.id == obj.id) {
                                                datas[i] = obj;
                                            }
                                            i++;
                                        }
                                        this.setState({
                                            data: datas,
                                        })
                                    }}
                                    showStore={this.props.showStore}
                                />
                                <div className="d-flex mt-2 align-items-center">
                                    {
                                        !this.state.is_loading &&
                                            !this.state.is_done ?
                                            <button
                                                className="btn btn-default"
                                                onClick={(ref) => {
                                                    let node = window.$(ref.target);
                                                    if (node.hasClass('loading')) { return }
                                                    node.addClass('loading');
                                                    this.setState({
                                                        is_loading: true
                                                    }, () => {
                                                        this.fetch()
                                                    })
                                                }}
                                            >โหลดเพิ่ม</button>
                                            :
                                            !this.state.is_done ?
                                                <button className="btn btn-default loading">โหลดเพิ่ม</button>
                                                : null
                                    } <span className='right-floated'>{this.state.count_items}/{this.state.total_items}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


class RenderPaymentTransactionList extends React.Component {
    render() {
        if (!this.props.data || (this.props.data.length === 0)) {
            return (
                <li className="list-group-item">
                    <strong>ไม่มีข้อมูลการชำระเงิน</strong>
                </li>
            )
        }
        return (
            <>
                {
                    this.props.data.map((item, i) => {
                        let amount = item.amount ?? 0;
                        let amountType = "p";
                        if (item.typeId == 18) {
                            amountType = "c"
                        }
                        let name = "-";
                        if (item.user) {
                            name = item.user.name;
                        }
                        if (item.artists) {
                            name = item.artists.name
                        }
                        return (
                            <li key={i} className="list-group-item">
                                <div className="d-flex">
                                    <div>
                                        <p className='mb-0'><strong>#{item.transactionNumber}</strong></p>
                                        <p className="mb-0">ผู้ทำรายการ : {name}</p>
                                        <div className="d-flex align-items-center">
                                            <p className='mb-0'>รายการ : {item.type.name}</p>
                                        </div>
                                        {
                                            this.props.showStore
                                                && item.store ?
                                                <div className="d-flex align-items-center">
                                                    <p className='mb-0'>ร้าน : {item.store.name}</p>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    {
                                        item.created ?
                                            <div className="right-floated mb-0">
                                                <p>
                                                    <strong>{Helper.numberFormat(amount)}</strong>
                                                    {
                                                        amountType == 'c' ? <img src={`${window.location.origin}/assets/img/skitzcoin.png`} style={{ width: 20, marginLeft: 5 }} /> : <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                                    }
                                                </p>
                                                <p >{Helper.getDateThai(item.created, { is_show_time: true, plus_hours: 7 })}</p>
                                            </div>
                                            : null
                                    }
                                </div>
                            </li>
                        )
                    })
                }
            </>
        )
    }


}
