import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/coin/channel`;
export const getChannels = async (id) => {
    let url = `${_serverUrl}/payment/subchannels/list?`;
    if (id) {
        url += `channelId=${id}`;
    }
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getPackageChannels = async (id) => {
    const url = `${baseUrl}/${id}/package`;
    // console.log(url)
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const createPackageChannels = async (id, data) => {
    const url = `${baseUrl}/${id}/package`;
    // console.log(url)
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const updatePackageChannels = async (packageId, data) => {
    const url = `${baseUrl}/package/${packageId}`;
    // console.log(url)
    try {
        const res = await httpClient.put(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const deletePackageChannels = async (packageId) => {
    const url = `${baseUrl}/package/${packageId}`;
    // console.log(url)
    try {
        const res = await httpClient.delete(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}