import React from 'react';
import { Helper } from '../../Helper';
import { Link, navigate } from '@reach/router';
import Pagination from '../../Components/Pagination';
import Chart from "react-google-charts";
import CustomDateInput from '../../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import thailand from "../../thailand.json";
import { getUsers } from '../../services/UserServices';
const axios = require('axios').default;
const default_limit = 10;
export default class Index extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        let provinces = [];
        for (const [key, value] of Object.entries(thailand)) {
            let amphoes = [];

            for (const [_key, _value] of Object.entries(value.amphoes)) {
                amphoes.push({ code: _key, amphoe: _value.name.th });
                // console.log(`${_key}: ${_value.name.th}`);
            }
            provinces.push({ code: key, province: value.name.th, amphoes: amphoes })
            // console.log(`${key}: ${value.name.th}`);
        }
        this.state = {
            timerage: 'รายวัน',
            years: years,
            date: new Date().getTime(),
            year: parseFloat(new Date().getFullYear()),
            month: parseFloat(new Date().getMonth()),
            is_loading: true,
            is_chart_loading: true,
            provinces: provinces,
            count_all_item: 0,
            count_item: 0,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
            province_code: Helper.getParameterByName('province_code') ? Helper.getParameterByName('province_code') : '',
            maxAge: Helper.getParameterByName('maxAge') ? Helper.getParameterByName('maxAge') : 100,
            minAge: Helper.getParameterByName('minAge') ? Helper.getParameterByName('minAge') : 0,
            gender: Helper.getParameterByName('gender') ? Helper.getParameterByName('gender') : '',
            isOnline: Helper.getParameterByName('isOnline') ? Helper.getParameterByName('isOnline') : false,
        }
    }

    componentDidMount() {
        this.fetch();

        this.fetchChart();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                timerage: 'รายปี',
                date: new Date().getTime(),
                year: Helper.getParameterByName('year') ? parseFloat(Helper.getParameterByName('year')) : parseFloat(new Date().getFullYear()),
                month: Helper.getParameterByName('month') ? parseFloat(Helper.getParameterByName('month')) : parseFloat(new Date().getMonth()),
                is_loading: true,
                // is_chart_loading: true,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
                province_code: Helper.getParameterByName('province_code') ? Helper.getParameterByName('province_code') : '',
                maxAge: Helper.getParameterByName('maxAge') ? Helper.getParameterByName('maxAge') : 100,
                minAge: Helper.getParameterByName('minAge') ? Helper.getParameterByName('minAge') : 0,
                gender: Helper.getParameterByName('gender') ? Helper.getParameterByName('gender') : '',
                isOnline: Helper.getParameterByName('isOnline') ? Helper.getParameterByName('isOnline') : false,
                // chart: [],
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                timerage: 'รายปี',
                date: new Date().getTime(),
                year: parseFloat(new Date().getFullYear()),
                month: parseFloat(new Date().getMonth()),
                is_loading: true,
                // is_chart_loading: true,
                page: 1,
                limit: default_limit,
                keyword: '',
                province_code: '',
                maxAge: Helper.getParameterByName('maxAge') ? Helper.getParameterByName('maxAge') : 100,
                minAge: Helper.getParameterByName('minAge') ? Helper.getParameterByName('minAge') : 0,
                gender: Helper.getParameterByName('gender') ? Helper.getParameterByName('gender') : '',
                isOnline: Helper.getParameterByName('isOnline') ? Helper.getParameterByName('isOnline') : false,
                data: [],
                // chart: [],
            }, () => {
                this.fetch();
            })
        }
    }

    async fetch() {
        let filter = {
            keyword: this.state.keyword,
            gender: this.state.gender,
            minAge: this.state.minAge,
            maxAge: this.state.maxAge,
            isOnline: this.state.isOnline
        }
        let res = await getUsers(this.state.page, this.state.limit, filter.gender, filter.minAge, filter.maxAge, filter.keyword, filter.isOnline);
        console.log(res)
        // let res = await axios.get(window.api_host + '/users-list?' + window.$.param({ page: this.state.page, limit: this.state.limit, filter: filter }));
        // console.log(res);
        let data = [];
        let count_all_item = 0;
        let count_item = 0;
        if (!res.error) {
            data = res.items;
            count_all_item = res.total;
            count_item = res.total;
        }
        // let chart = await this.fetchChart();
        this.setState({
            data: data,
            count_all_item: count_all_item,
            count_item: count_item,
            // chart: chart,
            is_loading: false,
        })
    }


    async fetchChart() {
        let res = await axios.post(window.api_host + '/get-user-chart', {
            date: this.state.date,
            month: this.state.month,
            year: this.state.year,
            timerage: this.state.timerage
        });
        let chart = res.data.chart;
        if (chart.length <= 1) { chart.push(['ไม่มีข้อมูล', 0]) }
        this.setState({
            chart: res.data.chart,
            is_chart_loading: false,
        })
    }

    async callChart() {
        this.fetchChart()
    }

    render() {
        let percent = (this.state.count_item * 100) / this.state.count_all_item;
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">สมาชิก</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">สมาชิก</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                {/* STORE DETAILS */}
                                <div className="info-box bg-gradient-blue">
                                    <span className="info-box-icon"><i className="fas fa-users"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">สมาชิกในระบบ</span>
                                        <span className="info-box-number">{this.state.count_all_item} คน</span>

                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: percent + '%' }}></div>
                                        </div>
                                        <span className="progress-description">
                                            จำนวนสมาชิก {this.state.count_item} คน
                                        </span>
                                    </div>
                                </div>
                                {/* FILTER */}
                                <div className="card card-outline collapsed-card">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>

                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form className="row"
                                            ref={(ref) => { this.$form = window.$(ref) }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                                    return;
                                                }
                                                let data = Helper.getFormInputObject(this.$form);
                                                let url = Helper.getQueryStringLinkFromObject({
                                                    page: 1,
                                                    ...data
                                                })
                                                navigate(url)
                                            }}
                                        >
                                            <div className="col-6 form-group">
                                                <label>ค้นหา</label>
                                                <input name="keyword" className="form-control" placeholder="ค้นหาจากชื่อสมาชิก" defaultValue={this.state.keyword} />
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>เพศ</label>
                                                <select
                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"gender"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.gender || ''}
                                                >
                                                    <option value={""}>
                                                        ทั้งหมด
                                                    </option>
                                                    <option value={"1"}>
                                                        ชาย
                                                    </option>
                                                    <option value={"2"}>
                                                        หญิง
                                                    </option>
                                                </select>
                                            </div>
                                            <div className='col-12'>
                                                <label>
                                                    <input type="checkbox" name="isOnline" value={true} defaultChecked={this.state.isOnline} />
                                                    <span className={"ml-2"}>ออนไลน์</span>
                                                </label>
                                            </div>
                                            <div className="col-12 from-group">
                                                <input type="text"
                                                    className="js-range-slider"
                                                    // name="age-range"
                                                    data-type="double"
                                                    data-min="0"
                                                    data-max="100"
                                                    data-from={this.state.minAge}
                                                    data-to={this.state.maxAge}
                                                    data-grid="true"
                                                    ref={(ref) => {
                                                        window.$(ref).ionRangeSlider({
                                                            onChange: (e) => {
                                                                this.$minAge.val(e.from);
                                                                this.$maxAge.val(e.to);
                                                            }
                                                        });
                                                    }}

                                                />
                                                <input type={"hidden"} ref={(ref) => { this.$minAge = window.$(ref) }} name="minAge" value={this.state.minAge} />
                                                <input type={"hidden"} ref={(ref) => { this.$maxAge = window.$(ref) }} name="maxAge" value={this.state.maxAge} />
                                            </div>
                                            <div className="col-12">
                                                <div className="flex row">
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <button type="submit" className="btn btn-default">ค้นหา</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* STORE LIST */}
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายการสมาชิก</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.data.length > 0
                                                && !this.state.is_loading ?
                                                <>
                                                    <ul className="nav nav-pills flex-column">
                                                        {
                                                            this.state.data.map((item, item_i) => {
                                                                return (
                                                                    <li className="nav-item" key={item_i}>
                                                                        <Link to={"/member/view/" + item.fireBaseUid} className="nav-link">
                                                                            <div className="store-item item-row">
                                                                                <div className="icon">
                                                                                    {
                                                                                        item.userImage ?
                                                                                            <div><img src={item.userImage} alt="UserIMG" /></div>
                                                                                            : <i className="fas fa-user"></i>
                                                                                    }

                                                                                </div>
                                                                                <div className="description">
                                                                                    <div>{item.displayName}</div>
                                                                                    <div>
                                                                                        {
                                                                                            item.isOnline ?
                                                                                                <span className="badge badge-success">ออนไลน์</span>
                                                                                                :
                                                                                                <span className="badge badge-secondary">ออฟไลน์</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-floated">
                                                                                    <div className="small-text">{Helper.getDateThai(new Date(item.createdAt).getTime())}</div>
                                                                                    <div className="small-text">{Helper.getTime(new Date(item.createdAt).getTime())}</div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </>
                                                : null
                                        }
                                    </div>
                                    {
                                        !this.state.is_loading
                                            && this.state.data.length !== 0 ?
                                            <div className="card-footer clearfix">
                                                <Pagination
                                                    now={this.state.page}
                                                    all_items={this.state.count_item}
                                                    limit={this.state.limit}
                                                    location={this.props.location}
                                                />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-9">

                                <div className="card">
                                    {
                                        this.state.is_chart_loading ?
                                            <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                            : null
                                    }

                                    <div className="card-header">
                                        <h5 className="card-title">รายงาน</h5>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ display: 'block' }}>
                                        <div className={"row form-group"}>
                                            <div className="col-12 col-md-3 form-group">
                                                <div style={{ width: '100%' }}>
                                                    <select className={"form-control form-control-sm"}
                                                        name={"timerage"}
                                                        defaultValue={this.state.timerage}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                timerage: e.target.value,
                                                                is_chart_loading: true,
                                                            }, () => {
                                                                this.callChart();
                                                            })
                                                        }}
                                                    >
                                                        <option value={"รายวัน"}>รายวัน</option>
                                                        <option value={"รายเดือน"}>รายเดือน</option>
                                                        <option value={"รายปี"}>รายปี</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                {
                                                    this.state.timerage === 'รายวัน' ?
                                                        <div className="full-date">
                                                            <DatePicker
                                                                onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                                                onChange={date => {
                                                                    this.setState({
                                                                        date: new Date(date).getTime(),
                                                                        is_chart_loading: true,
                                                                    }, () => {
                                                                        this.callChart();
                                                                    })
                                                                }}
                                                                locale="th-TH"
                                                                selected={Helper.getDateToEdit(this.state.date)}
                                                                placeholderText={"วัน / เดือน / ปี"}
                                                                name={"birthDay"}
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                withPortal
                                                                renderCustomHeader={({
                                                                    date,
                                                                    changeYear,
                                                                    changeMonth,
                                                                    decreaseMonth,
                                                                    increaseMonth,
                                                                    prevMonthButtonDisabled,
                                                                    nextMonthButtonDisabled
                                                                }) => {
                                                                    let now = new Date();
                                                                    let selected_date = new Date(date);
                                                                    // months_th
                                                                    const years = [];
                                                                    let i;
                                                                    for (i = 1980; i <= now.getFullYear(); i++) {
                                                                        years.push(i)
                                                                    }
                                                                    return (
                                                                        <div
                                                                            style={{
                                                                                margin: 10,
                                                                                display: "flex",
                                                                                justifyContent: "center"
                                                                            }}
                                                                        >
                                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                                className={"btn btn-primary "}
                                                                                type={"button"}
                                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                            >
                                                                                <i className="fas fa-chevron-left" />
                                                                            </button>
                                                                            <select
                                                                                className={"form-control"}
                                                                                value={selected_date.getFullYear()}
                                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                                style={{ borderRadius: 0 }}
                                                                            >
                                                                                {years.map(option => (
                                                                                    <option key={option} value={option} >
                                                                                        {option + 543}
                                                                                    </option>
                                                                                ))}
                                                                            </select>

                                                                            <select
                                                                                className={"form-control"}
                                                                                value={window.months_th[selected_date.getMonth()]}
                                                                                onChange={({ target: { value } }) =>
                                                                                    changeMonth(window.months_th.indexOf(value))
                                                                                }
                                                                                style={{ borderRadius: 0 }}
                                                                            >
                                                                                {window.months_th.map(option => (
                                                                                    <option key={option} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))}
                                                                            </select>

                                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                                className={"btn btn-primary"}
                                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                                type={"button"}
                                                                            >
                                                                                <i className="fas fa-chevron-right" />
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                }}
                                                                customInput={<CustomDateInput size={"sm"} />}
                                                            />
                                                            <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                                                        </div>
                                                        : <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                                                }
                                                <div className={this.state.timerage === 'รายเดือน' ? "flex row merge-selectbox" : "flex row"}>

                                                    {
                                                        this.state.timerage === 'รายเดือน' ?
                                                            <select className={"form-control form-control-sm"}
                                                                name={"month"}
                                                                style={{ flex: 1 }}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        month: e.target.value,
                                                                        is_chart_loading: true,
                                                                    }, () => {
                                                                        this.callChart();
                                                                    })
                                                                }}
                                                                defaultValue={this.state.month}
                                                            >
                                                                {
                                                                    window.months_th.map((month, month_i) => (
                                                                        <option value={month_i} key={month_i}>{month}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            : null
                                                    }
                                                    {
                                                        this.state.timerage === 'รายปี'
                                                            || this.state.timerage === 'รายเดือน' ?
                                                            <select className={"form-control form-control-sm"}
                                                                style={{ flex: 1 }}
                                                                name={"year"}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        year: e.target.value,
                                                                        is_chart_loading: true,
                                                                    }, () => {
                                                                        this.callChart()
                                                                    })
                                                                }}
                                                                defaultValue={this.state.year}
                                                            >
                                                                {
                                                                    this.state.years.map((years) => (
                                                                        <option value={years} key={years}>{years}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="text-center">
                                                    <strong>
                                                        {
                                                            this.state.timerage === 'รายวัน' ?
                                                                <>วันที่ {Helper.getDateThai(this.state.date)}</>
                                                                : null
                                                        }
                                                        {
                                                            this.state.timerage === 'รายเดือน' ?
                                                                <>เดือน {window.months_th[this.state.month]}</>
                                                                : null
                                                        }
                                                        {
                                                            this.state.timerage === 'รายปี' ?
                                                                <>ปี {this.state.year}</>
                                                                : null
                                                        }
                                                    </strong>
                                                </p>

                                                <div className="chart">
                                                    <Chart
                                                        width={'100%'}
                                                        height={'300px'}
                                                        chartType="ColumnChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.chart}
                                                        options={{
                                                            // isStacked: true,
                                                            legend: { position: 'top', maxLines: 3 },
                                                            vAxis: { minValue: 0 },
                                                            chartArea: { width: '90%', height: '300px' },
                                                        }}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}