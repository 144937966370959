import React from 'react';
import { navigate } from '@reach/router';
import { Helper, PacakageOptions } from '../../../Helper';
import OwnerFormModal from '../../../Components/OwnerFormModal'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { QRCode } from 'react-qrcode-logo';
import { Link } from '@reach/router';
const axios = require('axios').default;

export default class StorePackage extends React.Component {
    calculateTotalPrice(billing) {
        if (billing.paymentBy === 'creditcard') {
            return Helper.numberFormat(parseFloat(billing.summary.total_price).toFixed(2))
        }
        if (billing.paymentBy === 'adminService' && billing.paymentByDetails && billing.paymentByDetails.receiveAmount) {
            return Helper.numberFormat(parseFloat(billing.paymentByDetails.receiveAmount).toFixed(2))
        }
    }
    render() {
        let expiredDate = new Date();
        let nextMonth = 2;
        if (this.props.data && this.props.data.billing && this.props.data.billing.length > 0) {
            expiredDate = new Date(this.props.data.billing[0].expiredAt);
            nextMonth = 1;
        }
        let expiredMonth = expiredDate.getMonth() + nextMonth;
        let expiredYear = expiredDate.getFullYear();
        // console.log(this.props);
        // console.log(this.props.owner);
        if (this.props.is_loading) {
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="ui active inline loader" ></div>
                        <p style={{ marginTop: 10 }}>กำลังโหลดข้อมูล</p>
                    </div>
                </>
            )
        }
        if (!this.props.data || !this.props.data.owner) {
            return (
                <div>
                    <h4>ไม่มีข้อมูลเจ้าของร้าน</h4>
                    <button className="btn btn-primary btn-lg"
                        onClick={() => {
                            Helper.reactToDom(window.$('body'),
                                <OwnerFormModal
                                    storeUid={this.props.uid}
                                    onSuccess={() => {
                                        this.props.fetch();
                                        navigate(`/store/view/${this.props.uid}`)
                                    }}
                                />
                            )
                        }}
                    >เพิ่มเจ้าของร้าน</button>
                    <div>
                        <QRCode value={`https://skitz-app.web.app/stores/${this.props.uid}`}
                            logoImage={window.location.origin + "/assets/images/skitz-qr-logo.png"}
                            size={250}
                        />
                    </div>
                    {
                        this.props.data.adminAction
                        && this.props.data.adminAction.filter((e) => { return e.action_type === "owner" && (e.action === 'delete_owner' || e.action === 'create_owner') }).length > 0 ?

                            <button
                                type="button"
                                className="btn btn-default btn-lg"
                                onClick={() => {
                                    Helper.reactToDom(window.$('body'),
                                        <OwnerHistoryModal
                                            data={this.props.data}
                                        />
                                    )
                                }}
                            >
                                <i className="fas fa-history"></i> ประวัติ
                            </button>
                            : null
                    }
                </div>
            );
        }
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-md-8">

                        <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active"
                                    id="package-history-tab"
                                    data-toggle="pill"
                                    href="#package-history-content"
                                    role="tab"
                                    aria-controls="package-tab-content"
                                    aria-selected="false"
                                >
                                    <h5>ประวัติการชำระเงิน</h5>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    id="package-payment-tab"
                                    data-toggle="pill"
                                    href="#package-payment-content"
                                    role="tab"
                                    aria-controls="package-tab-content"
                                    aria-selected="false"
                                >
                                    <h5>รายการแพ็คเกจ</h5>
                                </a>
                            </li>

                        </ul>

                        <div className="tab-content" id="package-tab-content">
                            <div className="tab-pane fade active show" id="package-history-content" role="tabpanel" aria-labelledby="package-history-tab" style={{ paddingTop: 15 }}>

                                <div className="flex row">
                                    <h6 style={{ fontWeight: 'bold' }}>
                                        {/* เก็บเงินครั้งต่อไป */}
                                        ประวัติการเรียกเก็บเงิน
                                    </h6>
                                </div>
                                <table className="table table-condensed table-hover">
                                    <thead>
                                        <tr>
                                            <th>วันที่</th>
                                            <th>รายละเอียด</th>
                                            {/* <th>ผู้สมัคร</th> */}
                                            <th>วิธีชำระเงิน</th>
                                            <th>จำนวน</th>
                                            {/* <th>สถานะ</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.props.data
                                                || !this.props.data.billing
                                                || this.props.data.billing.length === 0 ?
                                                <tr >
                                                    <td>ไม่เคยมีการชำระเงิน</td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.props.data
                                                && this.props.data.billing ?
                                                this.props.data.billing.map((billing, billing_i) => {
                                                    let date = Helper.monthRage(billing.expiredAt)
                                                    return (
                                                        <tr key={billing_i}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                window.open('/transection/' + billing.transectionUid, "_blank")
                                                                // navigate();
                                                            }}
                                                        >
                                                            <td>
                                                                <div>{Helper.getDateThai(new Date(billing.createdAt), { is_show_time: false })}</div>
                                                                <div>{Helper.getTime(new Date(billing.createdAt))}</div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {
                                                                        billing.packagePaymentType === 'renew' ?
                                                                            <>ต่ออายุ</>
                                                                            : null
                                                                    }
                                                                    {
                                                                        billing.packagePaymentType === 'upgrade' ?
                                                                            <>อัพเกรด</>
                                                                            : null
                                                                    }
                                                                    {
                                                                        billing.packagePaymentType === 'downgrade' ?
                                                                            <>ลดกรด</>
                                                                            : null
                                                                    }
                                                                    แพ็คเกจ : {billing.package.name}
                                                                </div>
                                                                <div>
                                                                    {date.startMonth}/{date.startYear} - {date.expiredMonth}/{date.expiredYear}
                                                                </div>
                                                            </td>
                                                            {/* <td>{billing.provider.displayName}</td> */}
                                                            <td>

                                                                {PacakageOptions[billing.paymentBy]}
                                                            </td>
                                                            <td>{this.calculateTotalPrice(billing)}</td>
                                                            {/* <td>{billing.isPaid ? 'ชำระเงินแล้ว' : 'ยังไม่ได้ชำระเงิน'}</td> */}
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="tab-pane fade " id="package-payment-content" role="tabpanel" aria-labelledby="package-payment-tab" style={{ paddingTop: 15 }}>
                                <PackageList
                                    data={this.props.package_types}
                                    uid={this.props.uid}
                                    package={this.props.data.package}
                                    next_billing={this.props.owner.next_billing}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <OwnerDetailsBox
                            data={this.props.data}
                            deleteSuccess={() => {
                                this.props.fetch();
                                navigate(`/place/view/${this.props.uid}`)
                            }}
                            adminUid={this.props.adminUid}
                        />

                        <div className="flex row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <QRCode value={`https://skitz-app.web.app/stores/${this.props.uid}`}
                                logoImage={window.location.origin + "/assets/images/skitz-qr-logo.png"}
                                size={250}
                            />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

class PackageList extends React.Component {
    render() {
        let current_index = 0;
        this.props.data.map((item, item_i) => {
            // console.log(item)
            if (item.uid === this.props.package.uid) {
                current_index = item_i
            }
            return true;
        })
        // let now = new Date();
        // console.log(this.props);
        return (
            <>
                <div className="flex row">
                    <h6 style={{ fontWeight: 'bold' }}>แพ็คเกจ</h6>
                </div>
                <div className="package-table-scrollbar">
                    <table className="table table-condensed">
                        <thead>
                            <tr>
                                <th><div style={{ width: 150 }} /></th>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <th key={item_i}>
                                                <div style={{ color: item_i === current_index ? 'blue' : null, textAlign: 'center', minWidth: 110 }}>
                                                    {
                                                        item_i === current_index ?
                                                            <i className="fas fa-thumbtack" style={{ marginRight: 5 }}></i>
                                                            : null
                                                    } {item.name}
                                                </div>
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>
                                    <div>ระบบโพส</div>
                                    <ul style={{ marginBottom: 0 }}>
                                        {/* <li>ผู้ใช้งาน</li> */}
                                        <li>ร้าน</li>
                                        <li>ลบโพส</li>
                                    </ul>
                                </td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                <div>
                                                    {
                                                        item.options.features.haveTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div>
                                                {/* <div>
                                                    {
                                                        item.options.features.canGuestPostTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div> */}
                                                <div>
                                                    {
                                                        item.options.features.canStorePostTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        item.options.features.canStoreRemoveTimeline ?
                                                            <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                            :
                                                            <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                    }
                                                </div>
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            {/* <tr>
                                <td>ระบบสกิดบนแอพพลิเคชั่น</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveSkitz ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr> */}
                            <tr>
                                <td>บังคับผู้ใช้งานออกจากระบบ (เตะผู้ใช้งาน)</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.canKickMember ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            {/* <tr>
                                <td>แบนผู้ใช้งาน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveBanUser ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr> */}
                            <tr>
                                <td>ระบบข่าวสารของร้าน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.canManageEvent ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ระบบจัดการผู้ดูแลระบบและสิทธิ์การเข้าถึง</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.havePermissionStore ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ดูรูปโปรไฟล์ผู้ใช้งาน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.isPreviewMemberProfileStore ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ระบบนักดนตรี/Live</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveArtistLive ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ระบบจัดการเมนู</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {
                                                    item.options.features.haveMenu ?
                                                        <i className="fas fa-check" style={{ color: 'green' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i>
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>จำนวนผู้เข้าสู่ระบบสูงสุด</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {Helper.numberFormat(item.options.features.onlineCapacity)} คน
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td>ราคา/เดือน</td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i} align="center">
                                                {Helper.numberFormat(item.options.summary.total)} บาท
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                            <tr>
                                <td></td>
                                {
                                    this.props.data.map((item, item_i) => {
                                        return (
                                            <td key={item_i}>
                                                {
                                                    item_i === current_index
                                                        && item.options
                                                        && item.options.summary
                                                        && parseFloat(item.options.summary.total) !== 0
                                                        && !this.props.next_billing
                                                        ?
                                                        <Link className="btn btn-primary" style={{ width: '100%' }} to={`/payment/store/${this.props.uid}/payment-package/${item.uid}`}>ต่ออายุแพ็คเกจ</Link>
                                                        : null
                                                }
                                                {
                                                    (
                                                        item_i === current_index
                                                        && item.options
                                                        && item.options.summary
                                                        && parseFloat(item.options.summary.total) === 0
                                                    ) || (
                                                            item_i === current_index
                                                            && item.options
                                                            && item.options.summary
                                                            && parseFloat(item.options.summary.total) !== 0
                                                            && this.props.next_billing
                                                        )
                                                        ?
                                                        <button className="btn btn-primary disabled" style={{ width: '100%' }} type={"button"}>แพ็กเกจปัจจุบัน</button>
                                                        : null
                                                }
                                                {
                                                    item_i < current_index && !this.props.next_billing ?
                                                        <Link className="btn btn-default" style={{ width: '100%' }} to={`/payment/store/${this.props.uid}/payment-package/${item.uid}`}>ปรับลดแพ็คเกจ</Link>
                                                        : null
                                                }
                                                {
                                                    item_i < current_index && this.props.next_billing ?
                                                        <button className="btn btn-default disabled" style={{ width: '100%' }} type={"button"}>ปรับลดแพ็คเกจ</button>
                                                        : null
                                                }
                                                {
                                                    item_i > current_index
                                                        // && !this.props.next_billing
                                                        ?
                                                        <Link className="btn btn-success" style={{ width: '100%' }} to={`/payment/store/${this.props.uid}/payment-package/${item.uid}`}>อัพเกรดแพ็คเกจ</Link>
                                                        : null
                                                }
                                                {/* {
                                                    item_i > current_index
                                                        && this.props.next_billing ?
                                                        <button className="btn btn-success disabled" style={{ width: '100%' }} type={"button"}>อัพเกรดแพ็คเกจ</button>
                                                        : null
                                                } */}
                                            </td>
                                        );
                                    })
                                }
                            </tr>

                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

class OwnerDetailsBox extends React.Component {
    deleteOwner() {
        let name = this.props.data && this.props.data.owner && this.props.data.owner.displayName ? this.props.data.owner.displayName : '-'
        Helper.confirmModal({
            title: `ยกเลิกเจ้าของร้าน!`,
            description: `ต้องการยกเลิกคุณ "${name}" จากการเป็นเจ้าของร้าน "${this.props.data.nameTH}" ใช่หรือไม่ ?<br/>หากยกเลิกแล้วร้านจะมีสถานะเป็น "สถานที่"`,
            onConfirm: () => {
                this.deleteOwnerConfirmed();
            },
            onCancel: () => {
                this.deleteBtn.removeClass('loading');
            }
        })
    }
    async deleteOwnerConfirmed() {
        let data = {};
        data.uid = this.props.data.owner.uid;
        data.adminUid = this.props.adminUid;
        let res = await axios.delete(`${window.api_host}/owner?${window.$.param(data)}`);
        if (res.data.success) {
            window.$(document).Toasts('create', {
                title: 'ลบเจ้าของร้านสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-success",
                delay: 3000,
            })
            this.props.deleteSuccess();
        }
        if (res.data.error) {
            let message = {
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 5000,
            }
            message.title = res.data.message;
            // console.log(ref.data);
            if (res.data.description) {
                message.body = res.data.description
            }
            if (data.storePath) {
                Helper.removeFile(data.storePath)
            }
            window.$(document).Toasts('create', { ...message });
            this.deleteBtn.removeClass('loading');
        }
    }
    render() {
        return (
            <div className="card card-outline">
                <div className="card-body box-profile">
                    <div className="flex row">
                        <div className="right-floated">
                            <button
                                ref={(ref) => {
                                    this.deleteBtn = window.$(ref);
                                    this.deleteBtn.off().on('click', () => {
                                        if (this.deleteBtn.hasClass('loading')) { return; }
                                        this.deleteBtn.addClass('loading');
                                        this.deleteOwner();
                                    })
                                }}
                                type="button"
                                className="btn btn-default btn-block"
                            >
                                <i className="fas fa-user-slash"></i>
                            </button>
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="profile-user-img img-frame img-fluid img-circle">
                            <img
                                src={"/assets/images/no_img.png"}
                                alt="Owner profile" />
                        </div>
                    </div>

                    <h3 className="profile-username text-center">
                        {this.props.data && this.props.data.owner && this.props.data.owner.displayName ? this.props.data.owner.displayName : '-'}
                    </h3>

                    <p className="text-muted text-center" style={{ marginBottom: 0 }}>เบอร์โทรศัพท์: {this.props.data && this.props.data.owner && this.props.data.owner.phoneNumber ? this.props.data.owner.phoneNumber.replace('+66', '0') : '-'}</p>
                    <p className="text-muted text-center">อีเมล : {this.props.data && this.props.data.owner && this.props.data.owner.email ? this.props.data.owner.email : '-'}</p>

                    <ul className="list-group list-group-unbordered mb-3">
                        <li className="list-group-item">
                            <b>CURRENT PACKAGE</b>
                            <div className="float-right">{this.props.data && this.props.data.package && this.props.data.package.name ? this.props.data.package.name : '-'}</div>
                        </li>
                        <li className="list-group-item">
                            <b>สถานะการชำระเงิน</b>
                            <div className="float-right">
                                {
                                    this.props.data.isExpired && this.props.data.billing.length === 0 && (this.props.data && this.props.data.package && this.props.data.package.uid !== "oLobsnwyNB76pNPkdoYj") ?
                                        <><i className="fas fa-circle" style={{ color: 'grey' }}></i> ไม่เคยมีการชำระเงิน</>
                                        : null
                                }
                                {
                                    this.props.data.isExpired && this.props.data.billing.length > 0 && (this.props.data && this.props.data.package && this.props.data.package.uid !== "oLobsnwyNB76pNPkdoYj") ?
                                        <><i className="fas fa-circle" style={{ color: 'orange' }}></i> ระงับชั่วคราว</>
                                        : null
                                }
                                {
                                    !this.props.data.isExpired || (this.props.data && this.props.data.package && this.props.data.package.uid === "oLobsnwyNB76pNPkdoYj") ?
                                        <><i className="fas fa-circle" style={{ color: 'green' }}></i> กำลังใช้งาน</>
                                        : null
                                }
                                { }
                            </div>
                        </li>
                        {/* <li className="list-group-item">
                            <b>การชำระเงิน</b>
                            <div className="float-right">
                                {
                                    this.props.data
                                        && this.props.data.package.default_card
                                        && this.props.data.package.default_card
                                        && this.props.data.package.default_card.last_digits
                                        ?
                                        this.props.data.package.default_card.last_digits
                                        :
                                        "-"
                                }
                            </div>
                        </li> */}
                    </ul>
                    {
                        this.props.data && this.props.data.owner && this.props.data.owner.createAdmin ?
                            <div className="flex row">
                                <div style={{ flex: 1 }}>
                                    <div>
                                        <strong>สร้างโดย </strong>{this.props.data.owner.createAdmin.displayName}
                                    </div>
                                    {
                                        this.props.data && this.props.data.owner && this.props.data.owner.createAdmin.createdAt ?
                                            <div className="text-muted"> {Helper.getDateThai(this.props.data.owner.createAdmin.createdAt, { is_show_time: true })}</div>
                                            : null
                                    }
                                </div>
                                <div className="right floated">
                                    <button
                                        type="button"
                                        className="btn btn-default btn-block"
                                        onClick={() => {
                                            Helper.reactToDom(window.$('body'),
                                                <OwnerHistoryModal
                                                    data={this.props.data}
                                                />
                                            )
                                        }}
                                    >
                                        <i className="fas fa-history"></i>
                                    </button>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        )
    }
}

class OwnerHistoryModal extends React.Component {

    componentDidMount() {
        this.$modal.modal('show');
    }
    render() {
        console.log(this.props.data);
        let actions = this.props.data.adminAction.filter((e) => { return e.action_type === "owner" && (e.action === 'delete_owner' || e.action === 'create_owner') })
        return (
            <div ref={(ref) => {
                this.$modal = window.$(ref);
                this.$modal.modal('show');
                this.$modal.on('hidden.bs.modal', () => {
                    if (this.props.onCancel) {
                        this.props.onCancel();
                    }
                    let settime = window.setTimeout(() => {
                        this.$modal.remove();
                        clearTimeout(settime);
                    }, 500)
                });
            }}
                className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">ประวัติการเปลี่ยนแปลงเจ้าของ</h4>
                        </div>
                        <ul className="list-group list-group-unbordered no-border-lastchild mb-0" style={{ maxHeight: 600, overflowY: 'auto', marginBottom: 0 }}>
                            {
                                actions.map((action, action_i) => {
                                    let message = ``;
                                    if (action.action === 'delete_owner') {
                                        message = ` ลบเจ้าของร้าน `;
                                    }
                                    if (action.action === 'create_owner') {
                                        message = ` เพิ่มเจ้าของร้าน `;
                                    }
                                    return (
                                        <li className="list-group-item" key={action_i}>
                                            <div className="flex row" style={{ paddingLeft: 15, paddingRight: 15 }}>
                                                <div style={{ flex: 1 }}>
                                                    {`${action.displayName} ${message} ${action.provider.displayName}`}
                                                </div>
                                                <div className="floated right">
                                                    {Helper.getDateThai(action.createdAt, { is_show_time: true })}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (this.props.onCancel) {
                                        this.props.onCancel();
                                    }
                                    this.$modal.modal('hide');
                                }}
                            >ปิด</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}