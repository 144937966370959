import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../Helper';
import Pagination from './../../Components/Pagination';
import Chart from "react-google-charts";
import CustomDateInput from './../../Components/CustomDateInput';
import DatePicker from "react-datepicker";
const axios = require('axios').default;

let default_limit = 10;
export default class Index extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        let provinces = Helper.getAllProvinces();
        this.state = {
            timerage: 'รายเดือน',
            years: years,
            date: new Date().getTime(),
            year: parseFloat(new Date().getFullYear()),
            month: parseFloat(new Date().getMonth()),
            is_loading: true,
            is_chart_loading: true,
            provinces: provinces,
            count_all_item: 0,
            count_item: 0,
            data: [],
            store_types: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
            province_code: Helper.getParameterByName('province_code') ? Helper.getParameterByName('province_code') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            store_type: Helper.getParameterByName('store_type') ? Helper.getParameterByName('store_type') : '',
            count_wait_approve: 0,
            count_approved: 0,
            count_not_approved: 0,
            all_items: 0,
        }
    }
    componentDidMount() {
        this.fetch();
        this.fetchChart();
    }

    componentDidUpdate(prevProps) {
        // console.log(this.props.location)
        // console.log(prevProps.location)
        let obj = {};
        if (this.props.type !== prevProps.type) {
            obj.is_chart_loading = true;
            obj.chart = [];
            this.fetchChart();
        }
        if (this.props.location.search !== prevProps.location.search) {

            this.setState({
                data: [],
                is_loading: true,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                keyword: Helper.getParameterByName('keyword') ? Helper.getParameterByName('keyword') : '',
                province_code: Helper.getParameterByName('province_code') ? Helper.getParameterByName('province_code') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                store_type: Helper.getParameterByName('store_type') ? Helper.getParameterByName('store_type') : '',
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
                date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : new Date().getTime(),
                all_items: 0,
                ...obj,
            }, () => {
                if (this.props.type !== prevProps.type) {
                    this.fetchChart();
                }
                this.fetch();
            })
        }
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: default_limit,
                year: parseFloat(new Date().getFullYear()),
                month: parseFloat(new Date().getMonth()),
                date: new Date().getTime(),
                keyword: '',
                province_code: '',
                status: '',
                store_type: Helper.getParameterByName('store_type') ? Helper.getParameterByName('store_type') : '',
                all_items: 0,
                data: [],
                ...obj,
            }, () => {
                this.fetch();
                if (this.props.type !== prevProps.type) {
                    this.fetchChart();
                }
            })
        }
    }

    async fetch() {
        let filter = {
            page: this.state.page,
            limit: this.state.limit,
            keyword: this.state.keyword,
            province_code: this.state.province_code,
            store_type: this.state.store_type,
        }
        if (this.state.status) {
            filter.status = this.state.status
        }
        let res = await axios.get(window.api_host + '/register-store?' + window.$.param(filter));

        let data = res.data.response.data;
        let count_all_item = res.data.response.count_all_item;
        let count_item = res.data.response.count_item;
        let count_wait_approve = res.data.response.count_wait_approve;
        let count_approved = res.data.response.count_approved;
        let count_not_approved = res.data.response.count_not_approved;

        let store_types = res.data.response.storeType;
        let all_items = 0;
        if (!this.state.status || this.state.status === '') {
            all_items = res.data.response.count_wait_approve;
        }
        if (this.state.status && this.state.status === 'approved') {
            all_items = res.data.response.count_approved;
        }
        if (this.state.status && this.state.status === 'not_approve') {
            all_items = res.data.response.count_not_approved;
        }
        this.setState({
            data: data,
            count_all_item: count_all_item,
            count_item: count_item,
            all_items: all_items,
            is_loading: false,
            store_types: store_types,
            count_wait_approve: count_wait_approve,
            count_approved: count_approved,
            count_not_approved: count_not_approved,
        })
    }

    async fetchChart() {
        let data = {
            date: this.state.date,
            month: this.state.month,
            year: this.state.year,
            timerage: this.state.timerage,
        };
        let res = await axios.get(window.api_host + '/register-store-chart?' + window.$.param(data));
        let chart = res.data.chart;
        if (chart.length <= 1) { chart.push(['ไม่มีข้อมูล', 0]) }
        this.setState({
            chart: chart,
            is_chart_loading: false,
        })
    }

    async callChart() {
        let chart = await this.fetchChart();
        this.setState({
            chart: chart,
            is_chart_loading: false,
        })
    }

    changeStatusLink(status) {
        if(this.state.status === status) {return;}
        let data = Helper.getFormInputObject(this.$form);
        data.status = status;
        let url = Helper.getQueryStringLinkFromObject({
            page: 1,
            ...data
        })
        navigate(url)
    }

    render() {
        let store_percent = (this.state.count_item * 100) / this.state.count_all_item;

        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    ลงทะเบียน
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ลงทะเบียน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                {/* STORE DETAILS */}
                                <div class="card card-widget widget-user-2">
                                    {
                                        this.state.is_loading ?
                                            <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                            : null
                                    }
                                    <div class={this.props.type === "place" ? "widget-user-header bg-gradient-green" : "widget-user-header bg-gradient-purple"}>
                                        <h3 class="widget-user-username" style={{marginLeft: 0}}>คำขอลงทะเบียนร้านค้า</h3>
                                        <h5 class="widget-user-desc" style={{marginLeft: 0}}>ทั้งหมด {this.state.count_all_item} {this.props.type === "place" ? 'สถานที่' : 'ร้าน'}</h5>
                                    </div>
                                    <div class="card-footer p-0">
                                        <ul class="nav flex-column">
                                            <li class="nav-item">
                                                <div class={this.state.status !== 'not_approve' && this.state.status !== 'approved' ? "nav-link": "nav-link link"} style={{ cursor: this.state.status !== 'not_approve' && this.state.status !== 'approved' ? 'default' : 'pointer' }}
                                                    onClick={() => {
                                                        this.changeStatusLink('')
                                                    }}
                                                >
                                                    รออนุมัติ <span class="float-right badge bg-warning">{this.state.count_wait_approve}</span>
                                                </div>
                                            </li>
                                            <li class="nav-item">
                                                <div class={this.state.status !== 'approved' ? "nav-link link": "nav-link"} style={{ cursor:this.state.status !== 'approved' ? 'pointer' : 'default' }}
                                                    onClick={() => {
                                                        this.changeStatusLink('approved')
                                                    }}
                                                >
                                                    อนุมัติแล้ว <span class="float-right badge bg-success">{this.state.count_approved}</span>
                                                </div>
                                            </li>
                                            <li class="nav-item">
                                                <div class={this.state.status !== 'not_approve' ? "nav-link link": "nav-link"} style={{ cursor: this.state.status !== 'not_approve' ? 'pointer' : 'default' }}
                                                    onClick={() => {
                                                        this.changeStatusLink('not_approve')
                                                    }}
                                                >
                                                    ไม่อนุมัติ <span class="float-right badge bg-danger">{this.state.count_not_approved}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* FILTER */}
                                <div className="card card-outline collapsed-card">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-filter"></i> ตัวกรอง</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form className="row"
                                            ref={(ref) => { this.$form = window.$(ref) }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (this.$form.find('button[type="submit"]').hasClass('loading')) {
                                                    return;
                                                }
                                                let data = Helper.getFormInputObject(this.$form);
                                                let url = Helper.getQueryStringLinkFromObject({
                                                    page: 1,
                                                    ...data
                                                })
                                                navigate(url)
                                            }}
                                        >
                                            <div className="col-6 form-group">
                                                <label>ค้นหา</label>
                                                <input name="keyword" className="form-control" placeholder={this.props.type === "place" ? 'ค้นหาจากชื่อสถานที่' : 'ค้นหาจากชื่อร้านค้า'} />
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>จังหวัด</label>
                                                <select
                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"province_code"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.province_code || ''}
                                                >
                                                    <option value={""}>
                                                        ทั้งหมด
                                                    </option>
                                                    {
                                                        this.state.provinces.map((provinces, provinces_i) => {
                                                            return (
                                                                <option value={provinces.code} key={provinces_i}>
                                                                    {provinces.name.th}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {
                                                this.state.store_types
                                                    && this.state.store_types.length > 0 ?
                                                    <div className="col-6 form-group">
                                                        <div className="flex row">
                                                            <label>ประเภท</label>
                                                            <div className="right-floated">
                                                                <Link to={'/store-types'}>ดูข้อมูล</Link>
                                                            </div>
                                                        </div>
                                                        <select
                                                            ref={(ref) => { window.$(ref).select2(); }}
                                                            className={"form-control select2"}
                                                            name={"store_type"}
                                                            style={{ width: '100%' }}
                                                            defaultValue={this.state.store_type || ''}
                                                        >
                                                            <option value={""}>
                                                                ทั้งหมด
                                                            </option>
                                                            {
                                                                this.state.store_types.map((store_type, store_type_i) => {
                                                                    return (
                                                                        <option
                                                                            value={store_type.uid}
                                                                            key={store_type_i}
                                                                        // selected={this.state.store_type === store_type.uid}
                                                                        >
                                                                            {store_type.name}
                                                                        </option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    : null
                                            }

                                            <div className="col-6 form-group">
                                                <label>สถานะ</label>
                                                <select
                                                    ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"status"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.status || ''}
                                                >
                                                    <option value={""}>
                                                        รออนุมัติ {this.state.count_wait_approve} รายการ
                                                    </option>
                                                    <option value={"approved"}>
                                                        อนุมัติแล้ว {this.state.count_approved} รายการ
                                                    </option>
                                                    <option value={"not_approve"}>
                                                        ไม่อนุมัติ {this.state.count_not_approved} รายการ
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="col-12">
                                                <div className="flex row">
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <button type="submit" className="btn btn-default">ค้นหา</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                
                                {/* STORE LIST */}
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">รายการลงทะเบียน</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>ไม่มีข้อมูล</div>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.data.length > 0
                                                && !this.state.is_loading ?
                                                <>
                                                    <ul className="nav nav-pills flex-column">
                                                        {
                                                            this.state.data.map((_data, _data_i) => {
                                                                let photoPath = false;
                                                                if (_data.photoPath && _data.photoPath.length > 0) {
                                                                    photoPath = _data.photoPath[0];
                                                                }
                                                                return (
                                                                    <li className="nav-item" key={_data_i}>
                                                                        <Link to={window.location.pathname + "/view/" + _data.uid} className="nav-link">
                                                                            <div className="store-item item-row">
                                                                                {
                                                                                    photoPath ?
                                                                                        <div className="icon">
                                                                                            <div>
                                                                                                <img src={photoPath} alt={"StoreIMG"} />
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="icon">
                                                                                            <div>
                                                                                                <img src={window.location.origin + "/assets/images/no_upload_image.jpg"} alt={"NoUpload"} />
                                                                                            </div>
                                                                                        </div>
                                                                                }
                                                                                <div className="description">
                                                                                    <div>{_data.nameTH}
                                                                                        {_data.approvedAt ? <small> - {Helper.getDateThai(_data.approvedAt, {is_show_time: true})}</small> : null}
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            _data.store_type ?
                                                                                                <span className="badge badge-success" style={{ marginRight: 5, backgroundColor: '#6610f2' }}>{_data.store_type.name}</span>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            _data.status && _data.status === 'online' ?
                                                                                                <span className="badge badge-success" style={{ marginRight: 5 }}>ออนไลน์</span>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            _data.status && _data.status === 'offline' ?
                                                                                                <span className="badge badge-secondary" style={{ marginRight: 5 }}>ออฟไลน์</span>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            (_data.status && _data.status === 'pause')
                                                                                                || !_data.status ?
                                                                                                <span className="badge badge-warning" style={{ marginRight: 5 }}>ระงับ</span>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            _data.status && _data.status === 'cancel' ?
                                                                                                <span className="badge badge-danger" style={{ marginRight: 5 }}>ยกเลิก</span>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            _data.status && _data.status === 'not_approve' ?
                                                                                                <span className="badge badge-danger" style={{ marginRight: 5 }}>ไม่อนุมัติ</span>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            _data.status && _data.status === 'approved' ?
                                                                                                <span className="badge badge-success" style={{ marginRight: 5 }}>อนุมัติแล้ว</span>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            _data.status && _data.status === 'wait_approve' ?
                                                                                                <span className="badge badge-warning" style={{ marginRight: 5 }}>รออนุมัติ</span>
                                                                                                : null
                                                                                        }

                                                                                        {
                                                                                            _data.isPrivate ?
                                                                                                <span className="badge badge-info">ส่วนตัว</span>
                                                                                                :
                                                                                                <span className="badge badge-dark">สาธารณะ</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-floated">
                                                                                    {
                                                                                        _data.package ?
                                                                                            <div className="small-text text-right">{_data.package.nameTH}</div>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        _data.billingExpiredAt ?
                                                                                            <div className="small-text text-right">{Helper.getDateThai(new Date(_data.billingExpiredAt).getTime())}</div>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </>
                                                : null
                                        }
                                    </div>
                                    {
                                        !this.state.is_loading
                                            && this.state.data.length !== 0 ?
                                            <div className="card-footer clearfix">
                                                <Pagination
                                                    now={this.state.page}
                                                    all_items={this.state.all_items}
                                                    limit={this.state.limit}
                                                    location={this.props.location}
                                                />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="card">
                                    {
                                        this.state.is_chart_loading ?
                                            <div className={"absolute_loading"}><div className="ui active inline loader" /></div>
                                            : null
                                    }

                                    <div className="card-header">
                                        <h5 className="card-title">รายงาน</h5>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ display: 'block' }}>
                                        <div className={"row form-group"}>
                                            <div className="col-12 col-md-3 form-group">
                                                <div style={{ width: '100%' }}>
                                                    <select className={"form-control form-control-sm"}
                                                        name={"timerage"}
                                                        defaultValue={this.state.timerage}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                timerage: e.target.value,
                                                                is_chart_loading: true,
                                                            }, () => {
                                                                this.callChart();
                                                            })
                                                        }}
                                                    >
                                                        <option value={"รายวัน"}>รายวัน</option>
                                                        <option value={"รายเดือน"}>รายเดือน</option>
                                                        <option value={"รายปี"}>รายปี</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                {
                                                    this.state.timerage === 'รายวัน' ?
                                                        <div className="full-date">
                                                            <DatePicker
                                                                onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                                                onChange={date => {
                                                                    this.setState({
                                                                        date: new Date(date).getTime(),
                                                                        is_chart_loading: true,
                                                                    }, () => {
                                                                        this.callChart();
                                                                    })
                                                                }}
                                                                locale="th-TH"
                                                                selected={Helper.getDateToEdit(this.state.date)}
                                                                placeholderText={"วัน / เดือน / ปี"}
                                                                name={"birthDay"}
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                withPortal
                                                                renderCustomHeader={({
                                                                    date,
                                                                    changeYear,
                                                                    changeMonth,
                                                                    decreaseMonth,
                                                                    increaseMonth,
                                                                    prevMonthButtonDisabled,
                                                                    nextMonthButtonDisabled
                                                                }) => {
                                                                    let now = new Date();
                                                                    let selected_date = new Date(date);
                                                                    // months_th
                                                                    const years = [];
                                                                    let i;
                                                                    for (i = 1980; i <= now.getFullYear(); i++) {
                                                                        years.push(i)
                                                                    }
                                                                    return (
                                                                        <div
                                                                            style={{
                                                                                margin: 10,
                                                                                display: "flex",
                                                                                justifyContent: "center"
                                                                            }}
                                                                        >
                                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                                className={"btn btn-primary "}
                                                                                type={"button"}
                                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                            >
                                                                                <i className="fas fa-chevron-left" />
                                                                            </button>
                                                                            <select
                                                                                className={"form-control"}
                                                                                value={selected_date.getFullYear()}
                                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                                style={{ borderRadius: 0 }}
                                                                            >
                                                                                {years.map(option => (
                                                                                    <option key={option} value={option} >
                                                                                        {option + 543}
                                                                                    </option>
                                                                                ))}
                                                                            </select>

                                                                            <select
                                                                                className={"form-control"}
                                                                                value={window.months_th[selected_date.getMonth()]}
                                                                                onChange={({ target: { value } }) =>
                                                                                    changeMonth(window.months_th.indexOf(value))
                                                                                }
                                                                                style={{ borderRadius: 0 }}
                                                                            >
                                                                                {window.months_th.map(option => (
                                                                                    <option key={option} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))}
                                                                            </select>

                                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                                className={"btn btn-primary"}
                                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                                type={"button"}
                                                                            >
                                                                                <i className="fas fa-chevron-right" />
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                }}
                                                                customInput={<CustomDateInput size={"sm"} />}
                                                            />
                                                            <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                                                        </div>
                                                        : <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                                                }
                                                <div className={this.state.timerage === 'รายเดือน' ? "flex row merge-selectbox" : "flex row"}>

                                                    {
                                                        this.state.timerage === 'รายเดือน' ?
                                                            <select className={"form-control form-control-sm"}
                                                                name={"month"}
                                                                style={{ flex: 1 }}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        month: e.target.value,
                                                                        is_chart_loading: true,
                                                                    }, () => {
                                                                        this.callChart();
                                                                    })
                                                                }}
                                                                defaultValue={this.state.month}
                                                            >
                                                                {
                                                                    window.months_th.map((month, month_i) => (
                                                                        <option value={month_i} key={month_i}>{month}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            : null
                                                    }
                                                    {
                                                        this.state.timerage === 'รายปี'
                                                            || this.state.timerage === 'รายเดือน' ?
                                                            <select className={"form-control form-control-sm"}
                                                                style={{ flex: 1 }}
                                                                name={"year"}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        year: e.target.value,
                                                                        is_chart_loading: true,
                                                                    }, () => {
                                                                        this.callChart()
                                                                    })
                                                                }}
                                                                defaultValue={this.state.year}
                                                            >
                                                                {
                                                                    this.state.years.map((years) => (
                                                                        <option value={years} key={years}>{years}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="text-center">
                                                    <strong>
                                                        {
                                                            this.state.timerage === 'รายวัน' ?
                                                                <>วันที่ {Helper.getDateThai(this.state.date)}</>
                                                                : null
                                                        }
                                                        {
                                                            this.state.timerage === 'รายเดือน' ?
                                                                <>เดือน {window.months_th[this.state.month]}</>
                                                                : null
                                                        }
                                                        {
                                                            this.state.timerage === 'รายปี' ?
                                                                <>ปี {this.state.year}</>
                                                                : null
                                                        }
                                                    </strong>
                                                </p>

                                                <div className="chart">
                                                    <Chart
                                                        width={'100%'}
                                                        height={'300px'}
                                                        chartType="ColumnChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.chart}
                                                        options={{
                                                            // isStacked: true,
                                                            legend: { position: 'top', maxLines: 3 },
                                                            vAxis: { minValue: 0 },
                                                            chartArea: { width: '90%', height: '300px' },
                                                        }}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        )
    }
}