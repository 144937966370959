import React from 'react';
import { Helper } from '../../../Helper';
import { Link } from '@reach/router'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const axios = require('axios').default;

export default class StoreEventAndPromotion extends React.Component {
    constructor() {
        super();
        this.state = {
            page: 1,
            limit: 5,
            promotions: [],
            default_data: [],
            count_data: 0,
            active_type: true,
        }
    }
    fetch() {
        let _data = this.state.default_data.filter((e) => { return e.isVisible === this.state.active_type })
        let count_data = _data.length;
        let data = Helper.prepareDataLimit(_data, this.state.limit, this.state.page, { mergePage: true });
        this.setState({
            promotions: data,
            count_data: count_data,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.promotions && this.props.promotions.filter((e) => { return e.isVisible === this.state.active_type }).length !== prevProps.promotions.filter((e) => { return e.isVisible === this.state.active_type }).length) {
            this.setState({
                default_data: this.props.promotions
            }, () => {
                this.fetch();
            })
        }
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };
        return (
            <div style={{ position: 'relative' }}>
                {
                    this.props.is_loading ?
                        <div className={"absolute_loading"}>
                            <div className="ui active inline loader" ></div>
                        </div>
                        : null
                }
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>

                        <div className="flex row form-group" style={{ alignItems: 'center' }}>
                            <Link to={'/promotion/add/' + this.props.uid} className="btn btn-primary btn-sm">เพิ่มโปรโมชั่นและอีเวนท์</Link>
                            <button
                                className="btn btn-default btn-sm right-floated"
                                onClick={() => {
                                    this.setState({
                                        active_type: !this.state.active_type
                                    }, () => {
                                        this.fetch()
                                    })
                                }}
                            >
                                {
                                    this.state.active_type ?
                                        "ประวัติโปรโมชั่นและอีเวนท์" : "กลับ"
                                }
                            </button>

                        </div>
                        <div className="flex row form-group" >โปรโมชั่นและอีเว้นท์ทั้งหมด : {Helper.numberFormat(this.state.count_data)} รายการ</div>
                        <div className="timeline">
                            {
                                !this.state.promotions
                                    || this.state.promotions.length === 0 ?
                                    <div>
                                        <i className="far fa-file bg-gray"></i>
                                        <div className="timeline-item">
                                            <span className="time"><i className="far fa-calendar-alt"></i> {Helper.getDateThai(new Date(), { is_show_time: true })}</span>
                                            <div className="timeline-body" style={{ fontSize: 18 }}><div className="description-pre-line">ไม่มีข้อมูล</div></div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                this.state.promotions.map((item, item_i) => {
                                    return (
                                        <div key={item_i}>
                                            <i className="fas fa-percentage bg-green"></i>
                                            <div className="timeline-item">
                                                <h3 className="timeline-header">
                                                    <div className="flex row">
                                                        <div>
                                                            <div style={{ marginBottom: 5 }}>
                                                                {item.name + ' '}
                                                                {
                                                                    item.type === 'event' ?
                                                                        <span className="badge badge-primary" >Event</span>
                                                                        : null
                                                                }
                                                                {
                                                                    item.type === 'promotion' ?
                                                                        <span className="badge badge-primary">Promotion</span>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ fontSize: 12 }}>
                                                                {Helper.getDateThai(item.createdAt, { is_show_time: true })}
                                                            </div>
                                                        </div>
                                                        <div className="right-floated btn-group">
                                                            <Link className="btn btn-default btn-sm" to={`/promotion/edit/${this.props.uid}/${item.uid}`}><i className="fas fa-edit"></i></Link>
                                                            <button className="btn btn-default btn-sm"
                                                                onClick={(e) => {
                                                                    let ref = e.target;
                                                                    if (window.$(ref).hasClass('loading')) { return false; }
                                                                    window.$(ref).addClass('loading');
                                                                    Helper.confirmModal({
                                                                        title: 'ยกเลิกแพ็คเกจ',
                                                                        description: 'ยืนยันการยกเลิกแพ็คเกจ',
                                                                        onConfirm: async () => {
                                                                            let ref = await axios.post(window.api_host + '/delete-store-promotion', { storeUid: this.props.uid, uid: item.uid });
                                                                            // console.log(ref.data);
                                                                            if (ref.data.success) {
                                                                                window.$(document).Toasts('create', {
                                                                                    title: 'ลบข้อมูลสำเร็จ',
                                                                                    icon: 'fas fa-check',
                                                                                    autohide: true,
                                                                                    class: "bg-success",
                                                                                    delay: 3000,
                                                                                })
                                                                                if (this.props.fetchPromotion) {
                                                                                    this.props.fetchPromotion();
                                                                                }
                                                                                window.$(ref).removeClass('loading');
                                                                            }
                                                                            if (ref.data.error) {
                                                                                let message = {
                                                                                    icon: 'fas fa-times',
                                                                                    autohide: true,
                                                                                    class: "bg-danger",
                                                                                    delay: 3000,
                                                                                }
                                                                                message.title = ref.data.message;
                                                                                if (ref.data.description) {
                                                                                    message.description = ref.data.description
                                                                                }
                                                                                window.$(document).Toasts('create', { ...message });
                                                                                window.$(ref).removeClass('loading');
                                                                            }
                                                                        },
                                                                        onCancel: () => {
                                                                            window.$(ref).removeClass('loading');
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                <i className="fas fa-trash-alt" style={{ cursor: 'pointer' }} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </h3>
                                                <div className="timeline-body">
                                                    <div className="row">
                                                        {
                                                            item.photoPath
                                                                && item.photoPath.length > 0 ?
                                                                <div className="col-12 col-md-3">
                                                                    <Slider {...settings} style={{ marginBottom: '1rem' }}>
                                                                        {
                                                                            item.photoPath.map((url, url_i) => {
                                                                                return (
                                                                                    <div key={url_i} className="slide-image-frame">
                                                                                        <img src={url || "/assets/images/no_img.png"} alt="PromotionEvent" />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Slider>
                                                                </div>
                                                                : null
                                                        }
                                                        <div className="col-12 col-md-9">
                                                            <div className="description-pre-line">{item.description}</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex row">
                                                        {/* <div className="custom-control custom-switch main-input-checkbox">
                                                            <input type="checkbox"
                                                                className="custom-control-input"
                                                                id={"appVisible" + item_i}
                                                                name={"appVisible"}
                                                                defaultValue={"on"}
                                                                defaultChecked={item.appVisible}
                                                                onChange={() => {
                                                                    let update = {};
                                                                    update.storeUid = this.props.uid;
                                                                    update.uid = item.uid;
                                                                    update.data = {};
                                                                    update.data.appVisible = !item.appVisible;
                                                                    this.setState({
                                                                        is_timeline_loading: true
                                                                    }, () => {
                                                                        this.update(update);
                                                                    })
                                                                }}
                                                            />
                                                            <label className="custom-control-label" htmlFor={"appVisible" + item_i}>การแสดงผลบนแอพ</label>
                                                        </div> */}
                                                        <div>
                                                            {
                                                                item.appVisible ?
                                                                    <i className={"fas fa-check"} style={{ color: 'green' }}></i>
                                                                    :
                                                                    <i className={"fas fa-times"} style={{ color: 'red' }}></i>
                                                            }
                                                            <span style={{ marginLeft: 5 }}>การแสดงผลบนแอพ</span>
                                                        </div>
                                                        {
                                                            item.createUser ?
                                                                <div className="right-floated">
                                                                    {item.createUser.displayName}
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            item.createAdmin ?
                                                                <div className="right-floated">
                                                                    {item.createAdmin.displayName}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div>
                                {
                                    (this.state.page * this.state.limit) < this.state.count_data ?
                                        <i className="fas fa-angle-down bg-gray"
                                            onClick={() => {
                                                this.setState({
                                                    page: this.state.page + 1,
                                                }, () => {
                                                    this.fetch();
                                                })
                                            }}
                                        />
                                        :
                                        <i className="fas fa-minus bg-gray" />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}