import React from 'react';
import { db } from '../../firebase';
import { Link, navigate } from '@reach/router';
import { Helper } from '../../Helper';
// import { storage } from './../../firebase'
const axios = require('axios').default;
export default class ReportPost extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let qs = {};
        qs.report_type = "post";
        qs.id = this.props.id;
        if (this.state.storeUid) {
            qs.storeUid = this.state.storeUid;
        }
        let res = await axios.get(window.api_host + '/get-report?' + window.$.param(qs));
        let report = false;
        if (res.data.success) {
            if (res.data.reports.length > 0) {
                report = res.data.reports[0];
            }
            this.setState({
                is_loading: false,
                report: report
            })
        }
    }
    reslove() {
        let id = this.state.report.id
        Helper.confirmModal({
            title: 'แก้ปัญหาเสร็จแล้ว',
            description: 'ยืนยันการแก้ปัญหาเสร็จแล้ว',
            submit_text: 'ยืนยัน',
            onConfirm: async () => {
                this.setState({
                    loading_post: true,
                }, async () => {
                    let res = await axios.post(window.api_host + '/report-reslove?report_id=' + id);
                    if (res.data.success) {
                        window.$(document).Toasts('create', {
                            title: 'ลบข้อมูลสำเร็จ',
                            icon: 'fas fa-check',
                            autohide: true,
                            class: "bg-success",
                            delay: 3000,
                        })
                        this.fetch();
                    }
                    if (res.data.error) {
                        window.$(document).Toasts('create', {
                            title: res.data.message,
                            icon: 'fas fa-times',
                            autohide: true,
                            class: "bg-danger",
                            delay: 3000,
                        })
                    }
                })
            },
        })
    }
    deletePost(id) {
        Helper.confirmModal({
            title: 'ลบโพส',
            description: 'ยืนยันการลบโพส, หากทำการลบโพศรายงานนี้จะถูกลบไปด้วย',
            submit_text: 'ยืนยัน',
            onConfirm: async () => {
                this.setState({
                    loading_post: true,
                }, async () => {
                    let res = await axios.delete(window.api_host + '/delete-post?post_id=' + id);
                    // if (res.data.image_to_remove && res.data.image_to_remove.length > 0) {
                    //     for (const image of res.data.image_to_remove) {
                    //         storage.ref().child(image.storagePath).delete();
                    //     }
                    // }
                    if (res.data.success) {
                        window.$(document).Toasts('create', {
                            title: 'ลบข้อมูลสำเร็จ',
                            icon: 'fas fa-check',
                            autohide: true,
                            class: "bg-success",
                            delay: 3000,
                        })
                        navigate('/report?report_type=post')
                    }
                    if (res.data.error) {
                        window.$(document).Toasts('create', {
                            title: res.data.message,
                            icon: 'fas fa-times',
                            autohide: true,
                            class: "bg-danger",
                            delay: 3000,
                        })
                    }
                })
            },
        })
    }

    render() {
        if (this.state.is_loading) {
            return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="ui active inline loader small" ></div></div>);
        }
        let report = this.state.report;

        var timezoneCal = new Date().getTimezoneOffset();
        let post_created = report.post.created;
        let date_gettime_post = new Date(post_created).getTime();
        let report_created = report.created;
        let date_gettime = new Date(report_created).getTime();
        let report_created_sum = 0;
        let post_created_sum = 0;
        if (timezoneCal < 0) {
            report_created_sum = date_gettime + ((timezoneCal * (-60)) * 1000)
            post_created_sum = date_gettime_post + ((timezoneCal * (-60)) * 1000)
        } else {
            report_created_sum = date_gettime - ((timezoneCal * (-60)) * 1000)
            post_created_sum = date_gettime_post - ((timezoneCal * (-60)) * 1000)
        }
        report_created = report_created_sum;
        post_created = post_created_sum;
        if (report) {
            return (
                <div className="card card-primary card-outline">
                    <div className="card-header">
                        <h3 className="card-title">ประเภท : {Helper.getReportStatus(report.typeId)}</h3>
                    </div>
                    <div className="card-body p-0">
                        <div className="mailbox-read-info">
                            <h5 style={{ marginBottom: 5 }}>ผู้โพส : {
                                report.post && report.post.user ?
                                    <Link to={`/member/view/${report.post.userFireBaseUid}`}>
                                        {report.post.user.displayName}
                                    </Link>
                                    : null
                            }
                            </h5>
                            <h6>ร้าน :  <Link to={"/store/view/" + report.post.storeFireBaseUid}>{report.post.store.name}</Link>
                                <span className="mailbox-read-time float-right">{Helper.getDateThai(post_created, { is_show_time: true })}</span></h6>
                        </div>
                        <div className="mailbox-read-message">
                            <p>{report.message}</p>
                        </div>
                    </div>
                    {
                        report.post.images
                            && report.post.images.length > 0 ?
                            <div className="card-footer bg-white">
                                <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                                    {
                                        report.post.images.map((image, image_i) => {
                                            return (
                                                <li key={image_i}>
                                                    <a className="mailbox-attachment-icon has-img" style={{ overflow: 'hidden' }} href={image.imageUrl} data-fancybox>
                                                        <img src={image.imageUrl} alt="Attachment" style={{ maxHeight: '100%' }} />
                                                    </a>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>

                            : null
                    }

                    <div className="mailbox-read-info">
                        <h6>ผู้รายงาน : {
                            report.reportUser ?
                                <Link to={`/member/view/${report.reportUser.userFireBaseUid}`}>
                                    {report.reportUser.displayName}
                                </Link>
                                :
                                null
                        }
                            <span className="mailbox-read-time float-right">{Helper.getDateThai(report_created, { is_show_time: true })}</span>
                        </h6>
                    </div>
                    <div className="card-footer">
                        {/* <div className="float-right">
                            <button type="button" className="btn btn-default"><i className="fas fa-reply"></i> Reply</button>
                            <button type="button" className="btn btn-default"><i className="fas fa-share"></i> Forward</button>
                        </div> */}
                        {
                            report.statusId == 1 ?
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.reslove(report.postId)
                                    }}
                                >
                                    <i class="fas fa-thumbs-up"></i> แก้ปัญหาแล้ว
                                </button>
                                :
                                <>
                                    <button type="button"
                                        className="btn btn-success"
                                        disabled={true}
                                    >
                                        <i class="fas fa-thumbs-up"></i> แก้ปัญหาแล้ว
                                    </button>
                                    <span style={{ marginLeft: 15 }}>{Helper.getDateThai(this.state.report.postUpdate, { is_show_time: true })}</span>
                                </>
                        }
                        {
                            report.post.statusId == 2 ?
                                <button type="button"
                                    className="btn btn-default ml-2"
                                    disabled={true}
                                >
                                    <i className="far fa-trash-alt"></i> ลบโพสแล้ว
                                </button>
                                : <button type="button"
                                    className="btn btn-default ml-2"
                                    onClick={() => {
                                        this.deletePost(report.postId)
                                    }}
                                >
                                    <i className="far fa-trash-alt"></i> ลบโพส
                                </button>
                        }

                    </div>
                </div>
            );
        }
        return (
            <h4>ไม่มีข้อมูล</h4>
        )
    }
}