import React from 'react';
import { Helper } from './../../Helper';
import { Link, navigate } from '@reach/router';
import Pagination from './../../Components/Pagination';
import { getGift, addGift, updateGift, deleteGift } from './../../services/GiftServices';
const axios = require('axios').default;

export default class Gift extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            all_items: 0,
            is_loading: true,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: Helper.getCookie('user') && Helper.getCookie('user') !== "" ? JSON.parse(Helper.getCookie('user')).uid : false,
        }
    }


    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetch();
            })
        }
    }
    async fetch() {
        let res = await getGift(this.state.page, this.state.limit)
        console.log(res);
        if (!res.error) {
            let countItem = res.items.length * this.state.page;
            if (countItem > res.total) {
                countItem = res.total;
            }
            this.setState({
                data: res.items,
                all_items: res.total,
                count_item: countItem,
                is_loading: false,
            })
        }
    }
    async delete(data, options) {
        let res = await deleteGift(data.id);
        if (res.error) {
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถลบได้กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            });
            if (options && options.onError) {
                options.onError();
            }
            return;
        }
        this.fetch();
    }
    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Gift</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">Gift</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header align-items-center" style={{ display: 'flex' }}>
                                <h3 className="card-title">Gift</h3>
                                <div style={{ marginLeft: 15 }}>
                                    <button className={"btn btn-primary"}
                                        onClick={() => {
                                            Helper.reactToDom(
                                                window.$('body'),
                                                <GiftForm
                                                    mode={"create"}
                                                    onSuccess={(data) => {
                                                        this.fetch();
                                                    }}
                                                />
                                            );
                                        }}
                                    >
                                        เพิ่ม Gift
                                    </button>
                                </div>

                            </div>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th >ชื่อ</th>
                                            <th style={{ width: 200 }}>Code</th>
                                            <th style={{ width: 200 }}>ราคา</th>
                                            <th style={{ width: 200 }}>ราคา (Kub coin)</th>
                                            <th style={{ width: 200 }}>ค่าธรรมเนียม</th>
                                            <th style={{ width: 200 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                <div className='d-flex d-flex align-items-center'>
                                                                    <img src={_data.image} className="mr-2" style={{ maxWidth: '30px', maxHeight: '30px' }} />
                                                                    {_data.giftName}
                                                                    {
                                                                        _data.superChatAvailable ?
                                                                            <label className='badge bg-success ml-1 mb-0'>Super Chat</label>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>{_data.giftCode}</td>
                                                            <td>{Helper.numberFormat(_data.price)}</td>
                                                            <td>{Helper.numberFormat(_data.kubPrice)}</td>
                                                            <td>{Helper.numberFormat(_data.fee)}</td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <>
                                                                        <button className="btn btn-default"
                                                                            onClick={() => {
                                                                                Helper.reactToDom(
                                                                                    window.$('body'),
                                                                                    <GiftForm
                                                                                        mode={"edit"}
                                                                                        data={_data}
                                                                                        // adminUid={this.props.adminUid}
                                                                                        onSuccess={(data) => {
                                                                                            this.fetch();
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        >แก้ไข</button>
                                                                        <button type="button" className="btn btn-danger"
                                                                            ref={(ref) => {
                                                                                window.$(ref).off().on('click', () => {
                                                                                    if (window.$(ref).hasClass('loading')) { return; }
                                                                                    window.$(ref).addClass('loading');
                                                                                    Helper.confirmModal({
                                                                                        title: "ยืนยันลบ",
                                                                                        description: "ต้องการลบ Gift นี้หรือไม่ ?",
                                                                                        onConfirm: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                            this.delete(_data, {
                                                                                                onError: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                }
                                                                                            });
                                                                                        },
                                                                                        onCancel: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                        }
                                                                                    });
                                                                                })
                                                                            }
                                                                            }
                                                                        >ลบ</button>
                                                                    </>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

class GiftForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data || {},
        }
    }

    async create(data) {
        let res = await addGift(data);
        console.log(res);
        if (!res.error) {
            if (this.props.onSuccess) { this.props.onSuccess() }
            this.$modal.modal('hide');
        } else {

        }
    }

    async update(data) {
        if (!data.image && this.state.data.image) {
            data.image = this.state.data.image;
        }
        let res = await updateGift(this.state.data.id, data);
        console.log(res);
        if (!res.error) {
            Helper.messageTop({ message: 'อัพเดทข้อมูลเรียบร้อยแล้ว', type_class: "alert-success" })
            if (this.props.onSuccess) { this.props.onSuccess() }
            this.$modal.modal('hide');
        } else {

        }
    }

    render() {
        let title = "แก้ไขข้อมูล Gift";
        if (this.props.mode === 'create') {
            title = "เพิ่ม Gift"
        }
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            if (data.superChatAvailable == "on") {
                                data.superChatAvailable = true
                            } else {
                                data.superChatAvailable = false;
                            }
                            if (this.props.mode === 'create' && (!this.$file || this.$file[0].files.length == 0)) {
                                window.$(document).Toasts('create', {
                                    title: 'กรุณาอัพโหลดรูปภาพ',
                                    icon: 'fas fa-times',
                                    autohide: true,
                                    class: "bg-danger",
                                    delay: 3000,
                                })
                                $submit.removeClass('loading');
                                return;
                            }
                            if (this.$file && this.$file[0].files.length > 0) {
                                await Helper.uploadFile(this.$file, 'gift/', {
                                    resize: false,
                                    onSuccess: async (url) => {
                                        // data.storagePath = url.storePath;
                                        data.image = url.imagePath;
                                        if (this.props.mode === 'create') {
                                            this.create(data);
                                        }
                                        if (this.props.mode === 'edit') {
                                            this.update(data);
                                        }
                                    },
                                    onError: async () => {
                                        window.$(document).Toasts('create', {
                                            title: 'อัพโหลดรูปภาพไม่สำเร็จกรุณาลองใหม่อีกครั้ง',
                                            icon: 'fas fa-times',
                                            autohide: true,
                                            class: "bg-danger",
                                            delay: 3000,
                                        })
                                        $submit.removeClass('loading');
                                    }
                                })
                            } else {
                                if (this.props.mode === 'create') {
                                    this.create(data);
                                }
                                if (this.props.mode === 'edit') {
                                    this.update(data);
                                }
                            }

                            return;

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">{title}</h4>
                            </div>
                            <div className="modal-body">
                                <div >
                                    <input name="image"
                                        type={"file"}
                                        accept="image/*"
                                        ref={(ref) => this.$file = window.$(ref)}
                                        style={{ display: 'none' }}
                                        id="file_image_bank"
                                        onChange={async (e) => {
                                            Helper.readImage(this.$file, { maxHeight: 380, maxWidth: 380 })
                                        }}
                                    />
                                    <div id="show_image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {
                                            this.state.data && this.state.data.image ?
                                                <img src={this.state.data.image} alt={"PromotionPic"} style={{ maxWidth: '100%', marginBottom: 5 }} />
                                                : null
                                        }
                                    </div>
                                    <label htmlFor="file_image_bank"
                                        type={"button"}
                                        className="btn btn-default"
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            this.state.data && this.state.data.image ?
                                                "เปลี่ยนรูป"
                                                : "อัพโหลด"
                                        }
                                    </label>
                                </div>
                                <div className={"form-group"}>
                                    <label>ชื่อ</label>
                                    <input className={"form-control"} type="text" name="name" defaultValue={this.state.data.giftName || ''} />
                                </div>
                                <div className={"form-group"}>
                                    <label>Code</label>
                                    <input className={"form-control"} type="text" name="code" defaultValue={this.state.data.giftCode || ''} />
                                </div>
                                <div className={"form-group"}>
                                    <label>ราคา (Kub Coin)</label>
                                    <input className={"form-control"} type="number" name="kubPrice" defaultValue={this.state.data.kubPrice || '1'} />
                                </div>
                                <div className={"form-group"}>
                                    <label>ราคา</label>
                                    <input className={"form-control"} type="number" name="price" defaultValue={this.state.data.price || ''} />
                                </div>
                                <div className={"form-group"}>
                                    <label>ค่าคอมมิชชั่น</label>
                                    <input className={"form-control"} type="number" name="fee" defaultValue={this.state.data.fee || ''} />
                                </div>
                                <div className={"form-group input-field"}>
                                    <div className="custom-control custom-switch main-input-checkbox">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="superChatAvailable"
                                            name={"superChatAvailable"}
                                            defaultValue={"on"}
                                            defaultChecked={
                                                this.state.data.superChatAvailable
                                            }
                                        />
                                        <label className="custom-control-label" htmlFor="superChatAvailable">Superchat Gift</label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}