import React from 'react';
import Foods from './Foods';
import FoodCategory from './FoodCategory';
const axios = require('axios').default;

export default class StoreMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            mode: 'food',
            type: 'food-category',
            categoryUid: false,
            itemTypeUid: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        if (!this.state.itemTypeUid) {
            let res = await axios.get(window.api_host + '/item-type');
            if (res.data.success) {
                if (res.data.item_type && res.data.item_type.length === 1) {
                    let data = {};
                    data.type = 'foods';
                    data.menuType = res.data.item_type[0].code;
                    data.itemTypeUid = res.data.item_type[0].id;
                    this.setState({
                        is_loading: false,
                        ...data
                    })
                }
                if (res.data.item_type && res.data.item_type.length > 1) {
                    let itemType = [...res.data.item_type];
                    this.setState({
                        itemType: itemType,
                    })
                }

            }
        } else {
            this.setState({
                is_loading: false
            })
        }
    }

    renderFood() {
        return (
            <>
                <div className="row">
                    {/* <div className="col-5 col-sm-2">
                        <div className="nav flex-column nav-tabs h-100" >
                            <div className={this.state.type === 'food-category' && this.state.mode === "food" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    this.setState({
                                        type: 'food-category',
                                        categoryUid: false,
                                    })
                                }}
                                style={{ fontSize: 18, cursor: 'pointer' }}
                            >
                                ประเภทอาหาร
                            </div>
                            <div className={this.state.type === 'foods' && this.state.mode === "food" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    this.setState({
                                        type: 'foods',
                                        categoryUid: false,
                                    })
                                }}
                                style={{ fontSize: 18, cursor: 'pointer' }}
                            >
                                รายการอาหาร
                            </div>
                        </div>
                    </div> */}
                    <div className="col-7 col-sm-10">
                        <div className="tab-content" id="vert-tabs-tabContent">
                            <div className="tab-pane text-left fade active show">
                                {
                                    this.state.mode === 'food'
                                        && this.state.type === 'food-category' ?
                                        <FoodCategory
                                            {...this.props}
                                            itemTypeUid={this.state.itemTypeUid}
                                            viewFoods={(obj) => {
                                                // console.log(obj.categoryUid);
                                                this.setState({
                                                    type: 'foods',
                                                    categoryUid: obj.categoryUid,
                                                })
                                            }}
                                        />
                                        :
                                        null
                                }
                                {
                                    this.state.mode === 'food'
                                        && this.state.type === 'foods' ?
                                        <Foods
                                            {...this.props}
                                            itemTypeUid={this.state.itemTypeUid}
                                            categoryUid={this.state.categoryUid}
                                            backToCategory={() => {
                                                this.setState({
                                                    type: 'food-category',
                                                })
                                            }}
                                        />
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }
    render() {
        if (this.state.is_loading) {
            return (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                        <div className="ui active inline loader" ></div>
                    </div>
                </>
            )
        }
        if (this.state.mode === 'food') {
            return this.renderFood();
        }
        return null
    }
}
