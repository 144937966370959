import React from 'react';

export default class MessageTop extends React.Component {

    render() {
        let time = setTimeout(() => {
            this.alert.remove();
            clearTimeout(time);
        }, 5000);
        let alert_class = "alert ";
        if (this.props.type_class) {
            alert_class += " " + this.props.type_class
        } else {
            alert_class += " alert-danger"
        }
        return (
            <div className={"alert_top animate__animated animate__bounceInDown"}
                ref={(ref) => { this.alert = window.$(ref) }}
                onClick={() => {
                    this.alert.remove();
                }}
            >
                {
                    this.props.isLoading ?
                        <div className={"alert alert-info"} role="alert">
                            <div className="ui active inline loader small inverted" ></div> กำลังโหลด
                        </div>
                        :
                        <div className={alert_class} role="alert">
                            {this.props.message}
                        </div>
                }
            </div>
        );
    }
}